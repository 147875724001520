import React, { useEffect, useRef, useState } from 'react'
import {
  View,
  Modal,
  Animated,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import { useKeyboard } from '@react-native-community/hooks'
import { useTranslation } from 'react-i18next'

import { AudioItem } from '../components/AudioList'
import Button from '../components/Button'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import TitleText from '../components/Text/TitleText'
import { TypographyInput } from '../components/Text/Typography'
import useSafeAreaPaddedStyle from '../hooks/useSafeAreaPaddedStyle'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { convertHexToRGBA } from '../utils/colors'

interface AudioDescriptionModalProps {
  audio?: AudioItem
  saveDescription: (item: AudioItem, description: string | undefined) => void
  close: () => void
}

export const AudioDescriptionModal: React.FC<AudioDescriptionModalProps> = ({
  audio,
  saveDescription,
  close,
}) => {
  const { t } = useTranslation()
  const [description, setDescription] = useState<string>()
  const [isFocused, setIsFocused] = useState(false)

  const translateY = useRef(new Animated.Value(0)).current
  const { keyboardShown, keyboardHeight } = useKeyboard()
  const { webLayoutEnabled } = useWebLayout()

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: keyboardShown ? -keyboardHeight + 10 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }, [keyboardShown, keyboardHeight, translateY])

  useEffect(() => {
    setDescription(
      audio?.__typename === 'NewAttachment'
        ? audio.asset.description
        : audio?.description ?? '',
    )
  }, [audio])

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={audio !== undefined}
      animationType="fade"
      onRequestClose={close}>
      <PickerModal close={close} extraTranslateY={translateY}>
        <View
          style={useSafeAreaPaddedStyle(styles.container, {
            insetBottom: !keyboardShown,
          })}>
          <View style={appStyles.inlineContainer}>
            <View style={appStyles.fullSize}>
              <TitleText color="layout.dark" size="medium">
                {t('screens.createMemory.audioOptions.rename')}
              </TitleText>
            </View>
            <TouchableOpacity
              // @ts-ignore
              hitSlop={10}
              onPress={close}>
              <SvgIcon name="close" />
            </TouchableOpacity>
          </View>
          <Separator height={20} />
          <TypographyInput
            placeholder={t('screens.gallery.addDescription')}
            value={description}
            weight="medium"
            style={[
              styles.descriptionInput,
              isFocused && styles.descriptionInputFocused,
            ]}
            placeholderTextColor={convertHexToRGBA(Colors['layout.dark'], 0.5)}
            onChangeText={setDescription}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <Separator height={20} />
          <Button
            text="Save"
            onPress={() => {
              if (audio) {
                saveDescription(audio, description)
              }
              close()
            }}
          />
        </View>
        {!webLayoutEnabled && (
          <View style={styles.keyboardAnimationBackgroundFix} />
        )}
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  descriptionInput: {
    fontSize: 15,
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 16,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  descriptionInputFocused: {
    borderColor: Colors['brand.action'],
  },
  keyboardAnimationBackgroundFix: {
    ...StyleSheet.absoluteFillObject,
    top: '100%',
    bottom: -400,
    marginTop: -1,
    backgroundColor: Colors['layout.light'],
  },
})
