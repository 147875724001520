import React, { useCallback, useMemo } from 'react'
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { Attachment, Person, useGetPersonQuery } from '../../api/types'
import { AudioList } from '../../components/AudioList'
import HeaderIconButton from '../../components/HeaderIconButton'
import Loading from '../../components/Loading'
import { MediaList } from '../../components/MediaList'
import MemoryHeader from '../../components/MemoryHeader'
import Picture from '../../components/Picture'
import Separator from '../../components/Separator'
import ShareButton from '../../components/ShareButton'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'

export default function PersonDetailsScree(): JSX.Element {
  const { t } = useTranslation()
  const { params } = useRoute<RouteProp<MainStackParamsType, 'PersonDetails'>>()
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'PersonDetails'>>()
  const { getPublicClient, isLoggedIn } = useUser()

  const publicClient = useMemo(
    () =>
      params.shareToken
        ? getPublicClient({ shareToken: params.shareToken })
        : undefined,
    [getPublicClient, params.shareToken],
  )

  const { data, loading } = useGetPersonQuery({
    variables: { id: params.personId },
    client: publicClient,
  })

  const person = useMemo(() => data?.person, [data])

  const sharePerson = useMemo(() => {
    if (!params.isShared) return person as Person
  }, [params.isShared, person])

  const [attachmentsMedia, attachmentsAudio] = useMemo(() => {
    const media: Attachment[] = []
    const audio: Attachment[] = []
    person?.attachments?.nodes?.forEach(attachment => {
      if (!attachment.contentType || !attachment.url) return

      if (attachment.contentType.startsWith('audio')) {
        audio.push(attachment)
      } else {
        media.push(attachment)
      }
    })
    return [media, audio]
  }, [person?.attachments?.nodes])

  const mediaDescriptions = useMemo(
    () => person?.attachmentAttachables?.nodes ?? [],
    [person?.attachmentAttachables?.nodes],
  )

  const goEdit = useCallback(() => {
    navigate('PersonForm', {
      editPerson: person,
      attachments: { audio: attachmentsAudio, media: attachmentsMedia },
      attachmentDescriptions: mediaDescriptions,
    })
  }, [navigate, person, attachmentsAudio, attachmentsMedia, mediaDescriptions])

  const renderRelation = () => (
    <Typography weight="regular" style={styles.relationText}>
      {person?.relation && t(`enums.personRelation.${person.relation}`)}
    </Typography>
  )

  const renderFullName = () => (
    <TitleText size="medium" color="layout.dark">
      {!!person?.title && `${t(`profile.personTitle.${person?.title}`)} `}
      {`${person?.firstName} ${person?.lastName}`}
    </TitleText>
  )

  const renderAudioAttachments = (withLargeDividers?: boolean) =>
    attachmentsAudio.length > 0 && (
      <>
        <Separator height={34} />
        <AudioList
          canEdit={false}
          audio={attachmentsAudio}
          withLargeDividers={withLargeDividers}
        />
      </>
    )

  const renderInfoButton = () => (
    <TouchableOpacity
      style={styles.infoContainer}
      onPress={() => navigate('PersonInfo', { person })}>
      <Typography weight="regular" style={styles.infoText}>
        {t('screens.personDetails.details')}
      </Typography>
      <SvgIcon name="arrow" />
    </TouchableOpacity>
  )

  const renderPersonMemories = () => (
    <TouchableOpacity
      style={styles.infoContainer}
      onPress={() => person && navigate('PersonMemories', person)}>
      <View style={appStyles.inlineContainer}>
        <Typography weight="regular" style={styles.infoText}>
          {t('screens.personDetails.relatedMemories')}
        </Typography>
        <Separator width={5} />
        <Typography weight="medium" style={styles.infoText}>
          {person?.memories?.totalCount}
        </Typography>
      </View>
      <SvgIcon name="arrow" />
    </TouchableOpacity>
  )
  const appHeaderStyle = useMemo(
    () =>
      person?.pictureUrl
        ? styles.headerImage
        : { backgroundColor: Colors['layout.light'] },
    [person?.pictureUrl],
  )
  const appHeaderPaddedStyle = useSafeAreaPaddedStyle(
    appHeaderStyle,
    headerOptions,
  )

  const { webLayoutEnabled, setShowFullWidth } = useWebLayout()

  const renderContent = () => {
    if (webLayoutEnabled)
      return (
        <>
          <View style={webStyles.headerHolder}>
            <View style={webStyles.header}>
              <TouchableOpacity
                onPress={() => {
                  if (!isLoggedIn) setShowFullWidth(false)
                  goBack()
                }}>
                <SvgIcon name="back" color={Colors['layout.dark']} />
              </TouchableOpacity>
              <View style={appStyles.fullSize} />
              {sharePerson && <ShareButton shareContent={sharePerson} />}
              {!params.isShared && (
                <>
                  <Separator width={10} />
                  <HeaderIconButton iconName="pencil" onPress={goEdit} />
                </>
              )}
            </View>
          </View>
          <View style={webStyles.maxWidthHolder}>
            <View style={webStyles.contentContainer}>
              <View style={[webStyles.column, webStyles.mainColumn]}>
                {renderRelation()}
                <Separator height={6} />
                {renderFullName()}
                <Separator height={26} />
                <Typography weight="regular" style={styles.bodyText}>
                  {person?.story}
                </Typography>
                <Separator height={26} />
              </View>
              <Separator width={20} />
              <View style={[webStyles.column, webStyles.mediaColumn]}>
                {person?.pictureUrl && (
                  <Picture
                    sourceUri={person.pictureUrl}
                    style={webStyles.highlightedPicture}
                  />
                )}
                {attachmentsMedia.length > 0 && (
                  <>
                    <Separator height={15} />
                    <MediaList
                      useWebVersion
                      canEdit={false}
                      media={attachmentsMedia}
                      mediaDescriptions={mediaDescriptions}
                      galleryTitle={`${person?.firstName} ${person?.lastName}`}
                    />
                  </>
                )}
                <Separator height={34} />
                <View style={styles.sectionDivider} />
                {renderInfoButton()}
                <View style={styles.sectionDivider} />
                {!params.isShared && (
                  <>
                    {renderPersonMemories()}
                    <View style={styles.sectionDivider} />
                  </>
                )}
                {renderAudioAttachments(true)}
              </View>
            </View>
          </View>
        </>
      )

    return (
      <ScrollView
        style={[appStyles.fullSize]}
        contentContainerStyle={styles.scrollContentContainer}>
        {person?.pictureUrl ? (
          <Picture
            hasGradient
            sourceUri={person.pictureUrl}
            style={appHeaderPaddedStyle}>
            <MemoryHeader
              goBack={goBack}
              goEdit={params.isShared ? undefined : goEdit}
              shareContent={sharePerson}
              buttonColor="layout.white"
            />
          </Picture>
        ) : (
          <View style={appHeaderPaddedStyle}>
            <MemoryHeader
              goBack={goBack}
              goEdit={params.isShared ? undefined : goEdit}
              shareContent={sharePerson}
              buttonColor="layout.dark"
              backgroundColor="layout.light"
            />
          </View>
        )}
        <View style={[appStyles.fullSize, styles.content]}>
          <View style={styles.paddedContainer}>
            <Separator height={17} />
            {renderRelation()}
            <Separator height={4} />
            {renderFullName()}
            <Separator height={16} />
            <View style={styles.sectionDivider} />
            {renderInfoButton()}
            <View style={styles.sectionDivider} />
            {!params.isShared && (
              <>
                {renderPersonMemories()}
                <View style={styles.sectionDivider} />
              </>
            )}
          </View>
          {attachmentsMedia.length > 0 && (
            <>
              <Separator height={27} />
              <MediaList
                canEdit={false}
                media={attachmentsMedia}
                mediaDescriptions={mediaDescriptions}
                galleryTitle={`${person?.firstName} ${person?.lastName}`}
              />
            </>
          )}
          <View style={styles.paddedContainer}>{renderAudioAttachments()}</View>
          <View style={styles.paddedContainer}>
            <Separator height={34} />
            <Typography weight="regular" style={styles.bodyText}>
              {person?.story}
            </Typography>
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <View
      style={[appStyles.fullSize, { backgroundColor: Colors['layout.white'] }]}>
      <Loading loading={loading} />
      {renderContent()}
    </View>
  )
}

const borderColor = convertHexToRGBA(Colors['layout.gray'], 0.5)
const webStyles = StyleSheet.create({
  headerHolder: {
    height: 90,
    borderColor,
    ...appStyles.row,
    ...appStyles.center,
    borderBottomWidth: 1,
    backgroundColor: Colors['layout.white'],
  },
  header: {
    maxWidth: 1200,
    marginHorizontal: 50,
    ...appStyles.fullSize,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  maxWidthHolder: {
    paddingTop: 30,
    ...appStyles.row,
    ...appStyles.center,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    maxWidth: 1300,
    height: '100%',
    ...appStyles.row,
    ...appStyles.fullSize,
    paddingHorizontal: 30,
  },
  column: {
    borderRadius: 10,
    marginBottom: 30,
    alignSelf: 'flex-start',
    backgroundColor: Colors['layout.white'],
  },
  mainColumn: {
    flex: 5,
    paddingTop: 30,
    paddingBottom: 50,
    paddingHorizontal: 30,
  },
  mediaColumn: {
    flex: 3,
    padding: 20,
  },
  highlightedPicture: { height: 250, borderRadius: 10 },
})

const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  headerImage: {
    height: 375,
    backgroundColor: Colors['layout.light'],
  },
  content: {
    backgroundColor: Colors['layout.white'],
  },
  relationText: {
    fontSize: 16,
    opacity: 0.7,
    color: Colors['layout.dark'],
  },
  sectionDivider: {
    height: 1,
    opacity: 0.5,
    backgroundColor: Colors['layout.gray'],
  },
  infoContainer: {
    paddingVertical: 5,
    paddingRight: 13,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  infoText: {
    fontSize: 16,
    lineHeight: 19,
  },
  paddedContainer: { paddingHorizontal: 25 },
  bodyText: {
    fontSize: 18,
    lineHeight: 31,
    color: Colors['layout.dark'],
  },
})
