import React from 'react'
import { Modal } from 'react-native'

import ImageViewer from '../components/ImageViewer'
// @ts-ignore
import VideoViewer from '../components/VideoViewer'

export type MediaViewerModalProps = {
  uri: string
  fileType: string
  isVisible: boolean
  close: () => void
}

export const MediaViewerModal: React.FC<MediaViewerModalProps> = ({
  uri,
  fileType,
  isVisible,
  close,
}) => (
  <Modal
    transparent
    statusBarTranslucent
    visible={isVisible}
    animationType="fade"
    onRequestClose={close}>
    {fileType.startsWith('image') ? (
      <ImageViewer uri={uri} onGoBack={close} />
    ) : (
      <VideoViewer uri={uri} onGoBack={close} />
    )}
  </Modal>
)
