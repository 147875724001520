import React, { PropsWithChildren } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

import Separator from './Separator'
import TitleText from './Text/TitleText'
import Typography from './Text/Typography'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { convertHexToRGBA } from '../utils/colors'
import negateHeaderPadding from '../utils/negateHeaderPadding'

export type ListHeaderProps = PropsWithChildren<{
  title: string
  subtitle: string
  negatablePaddingSource?: ViewStyle
}>

const ListHeader: React.FC<ListHeaderProps> = ({
  title,
  subtitle,
  children,
  negatablePaddingSource,
}) => {
  const { webLayoutEnabled } = useWebLayout()
  return (
    <View
      style={[
        styles.titleRow,

        webLayoutEnabled && [
          negateHeaderPadding(negatablePaddingSource),
          styles.webModifier,
        ],
      ]}>
      <View style={appStyles.fullSize}>
        <TitleText size="medium" color="layout.dark">
          {title}
        </TitleText>
        <Typography weight="light" style={styles.subtitle}>
          {subtitle}
        </Typography>
      </View>
      <Separator width={5} />
      {children}
    </View>
  )
}

export default ListHeader

const styles = StyleSheet.create({
  titleRow: {
    ...appStyles.row,
    marginBottom: 20,
  },
  webModifier: {
    paddingBottom: 25,
    borderBottomWidth: 1,
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  subtitle: {
    opacity: 0.7,
  },
})
