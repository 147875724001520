import { useCallback } from 'react'

import { Memory, CommonMemory, DatePrecisionEnum } from '../api/types'
import { useLocale } from '../providers/LocaleProvider'
import { formatDate } from '../utils/date'

export default () => {
  const { locale } = useLocale()

  return useCallback(
    (
      memory:
        | Pick<Memory, 'eventAt' | 'eventAtPrec' | '__typename'>
        | Pick<CommonMemory, 'eventAt' | '__typename'>,
    ) =>
      formatDate(
        new Date(memory.eventAt),
        locale,
        memory.__typename === 'Memory'
          ? memory.eventAtPrec
          : DatePrecisionEnum.Year,
      ),
    [locale],
  )
}
