import React from 'react'

import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import DestructionConfirmationDialog from '../components/DestructionConfirmationDialog'
import { MainStackParamsType } from '../types/navigation-types'

export default function UnsavedChangesModal(): JSX.Element {
  const {
    params: { closeAction },
  } = useRoute<RouteProp<MainStackParamsType, 'UnsavedChanges'>>()
  const { goBack, dispatch } = useNavigation()
  const onClosePress = () => {
    goBack()
    dispatch(closeAction)
  }

  const { t } = useTranslation(undefined, { keyPrefix: 'modals.unsaved' })
  return (
    <DestructionConfirmationDialog
      title={t('title')}
      subtitle={t('subtitle')}
      confirmText={t('closeButton')}
      close={goBack}
      onConfirm={onClosePress}
    />
  )
}
