import React from 'react'
import { View } from 'react-native'

import MemoryCard from './MemoryCard'
import { Memory } from '../../../api/types'
import Separator from '../../../components/Separator'
import { MEMORY_MARGIN } from '../helpers/layoutHelpers'

const MemoriesList: React.FC<{
  memories: Memory[] | undefined
  withTopMargin?: boolean
}> = ({ withTopMargin, memories }) => (
  <>
    {memories?.map((memory, index) => (
      <View key={memory.id}>
        {(withTopMargin || index !== 0) && <Separator height={MEMORY_MARGIN} />}
        <MemoryCard memory={memory} />
      </View>
    ))}
  </>
)

export default MemoriesList
