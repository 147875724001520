import React, { Dispatch, SetStateAction } from 'react'
import { Linking, StyleSheet, TouchableOpacity, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { CheckBox } from './CheckBox'
import Separator from './Separator'
import Typography from './Text/Typography'
import { useLocale } from '../providers/LocaleProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { getTermsLink } from '../utils/landingLinks'

export const TermsAndConditionsRow: React.FC<{
  isAccepted: boolean
  isHighlighted?: boolean
  setIsAccepted: Dispatch<SetStateAction<boolean>>
}> = ({ isAccepted, isHighlighted, setIsAccepted }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'common.acceptances' })
  const { locale } = useLocale()

  return (
    <View style={appStyles.inlineContainer}>
      <TouchableOpacity
        // @ts-ignore
        hitSlop={10}
        style={appStyles.removedOutline}
        onPress={() => setIsAccepted(prev => !prev)}>
        <CheckBox isChecked={isAccepted} isHighlighted={isHighlighted} />
      </TouchableOpacity>
      <Separator width={10} />
      <Typography style={styles.text}>
        {t('promptStart')}
        <Typography
          style={styles.linkText}
          onPress={() => Linking.openURL(getTermsLink(locale))}>
          {t('terms')}
        </Typography>
        {t('promptEnd')}
      </Typography>
    </View>
  )
}

const styles = StyleSheet.create({
  text: { ...appStyles.fullSize, fontSize: 14 },
  linkText: {
    color: Colors['brand.action'],
    textDecorationLine: 'underline',
  },
})
