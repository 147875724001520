import React, { createRef, useCallback, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { Slider } from '@miblanchard/react-native-slider'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import ReactAudioPlayer from 'react-audio-player'

import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import Typography from '../components/Text/Typography'
import TouchableSvg from '../components/TouchableSvg'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'
import { mmss } from '../utils/time'

export default function AudioPlaybackModal(): JSX.Element {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'AudioPlayback'>>()
  const { goBack } = useNavigation<MainStackNavigationType<'AudioPlayback'>>()
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentPosition, setCurrentPosition] = useState(0)
  const [currentDuration, setCurrentDuration] = useState(0)
  const [playTime, setPlayTime] = useState('00:00')
  const [duration, setDuration] = useState('00:00')
  const playerRef = createRef<ReactAudioPlayer>()

  const startPlayback = useCallback(() => {
    // @ts-ignore
    playerRef.current?.audioEl.current?.play()
    setIsPlaying(true)
  }, [playerRef])

  const pausePlayback = useCallback(() => {
    // @ts-ignore
    playerRef.current?.audioEl.current?.pause()
    setIsPlaying(false)
  }, [playerRef])

  const trackPlayer = useCallback(
    (value: number) => {
      setCurrentPosition(value)
      setPlayTime(mmss(Math.floor(value)))
    },
    [setCurrentPosition, setPlayTime],
  )

  const setDurationData = useCallback((durationSeconds: number) => {
    setCurrentDuration(durationSeconds)
    setDuration(mmss(Math.floor(durationSeconds)))
  }, [])

  const onPlaybackEnd = useCallback(() => {
    setCurrentPosition(currentDuration)
    setIsPlaying(false)
  }, [currentDuration])

  const onSeek = useCallback(
    (value: number) => {
      if (!isPlaying) {
        setPlayTime(mmss(Math.floor(value)))
        setCurrentPosition(value)
      }
    },
    [isPlaying],
  )

  const onSeekEnd = useCallback(
    (value: number) => {
      const player = playerRef.current?.audioEl.current
      if (player)
        // @ts-ignore
        player.currentTime = value
      startPlayback()
    },
    [playerRef, startPlayback],
  )

  return (
    <PickerModal close={goBack}>
      <View style={styles.container}>
        <TouchableSvg
          name="close"
          color="layout.dark"
          style={styles.closeButton}
          onPress={goBack}
        />
        <Separator height={10} />
        <Typography weight="medium" numberOfLines={1} style={styles.nameText}>
          {params?.name}
        </Typography>
        <Separator height={10} />
        <ReactAudioPlayer
          ref={playerRef}
          src={params?.url}
          listenInterval={1}
          onListen={trackPlayer}
          onEnded={onPlaybackEnd}
          onLoadedMetadata={({ currentTarget }) =>
            // @ts-ignore
            setDurationData(currentTarget?.duration ?? 0)
          }
        />
        <Separator height={10} />
        <View style={styles.slider}>
          <Slider
            minimumValue={0}
            value={currentPosition}
            maximumValue={currentDuration}
            thumbTintColor={Colors['audio.dark-gray']}
            minimumTrackTintColor={Colors['audio.dark-gray']}
            maximumTrackTintColor={Colors['audio.light-gray']}
            onSlidingStart={pausePlayback}
            onValueChange={values => onSeek(values[0])}
            onSlidingComplete={values => onSeekEnd(values[0])}
          />
        </View>
        <View style={styles.playDataContainer}>
          <Typography style={styles.playDataText}>{playTime}</Typography>
          <Typography style={styles.playDataText}>{duration}</Typography>
        </View>
        <Separator height={10} />
        <TouchableSvg
          name={isPlaying ? 'pause' : 'play'}
          style={styles.playButton}
          color="layout.dark"
          onPress={() => (isPlaying ? pausePlayback() : startPlayback())}
        />
        <Separator height={48} />
      </View>
    </PickerModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  closeButton: { marginLeft: 'auto' },
  nameText: {
    fontSize: 30,
  },
  slider: {
    width: '100%',
  },
  playDataContainer: {
    width: '100%',
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  playDataText: {
    fontSize: 14,
    color: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  playButton: {
    width: 80,
    height: 80,
  },
})
