import React from 'react'
import { ViewProps } from 'react-native'

import RootContainer from '../../../components/RootContainer'
import WebModal from '../../../components/WebModal'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'

export const MediaLibraryWrapper: React.FC<
  ViewProps & { isInAddMode: boolean }
> = ({ children, isInAddMode }) => {
  return isInAddMode ? (
    /* eslint-disable-next-line react/no-children-prop */
    <WebModal children={children} fullHeight />
  ) : (
    /* eslint-disable-next-line react/no-children-prop */
    <RootContainer children={children} contentContainerStyle={style} />
  )
}

const style = {
  ...appStyles.fullSize,
  backgroundColor: Colors['layout.light'],
}
