import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { GlobalEventSubcategory } from './GlobalEventSubcategory'
import { QuizCategory, QuizSubCategory } from '../../../api/types'
import Separator from '../../../components/Separator'
import Switch from '../../../components/Switch'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'

interface GlobalEventCategoryProps {
  category: QuizCategory
  isEnabled: boolean
  enabledSubcategoryIds: string[]
  handleCategoryChange: (categoryId: string, enable: boolean) => void
  handleSubcategoryChange: (subcategoryId: string, enable: boolean) => void
}

const Spacer = () => <Separator height={5} />

export const GlobalEventCategory: React.FC<GlobalEventCategoryProps> = ({
  category,
  isEnabled,
  enabledSubcategoryIds,
  handleCategoryChange,
  handleSubcategoryChange,
}) => (
  <View style={styles.container}>
    <View style={styles.titleContainer}>
      <Typography weight="medium" style={styles.titleText}>
        {category.tag}
      </Typography>
      <Switch
        value={isEnabled}
        onValueChange={() => handleCategoryChange(category.id, !isEnabled)}
      />
    </View>
    {isEnabled && (
      <FlatList
        scrollEnabled={false}
        style={styles.contentContainer}
        ItemSeparatorComponent={Spacer}
        keyExtractor={subCategory => subCategory.id}
        data={category.quizSubCategories?.nodes as QuizSubCategory[]}
        renderItem={({ item }) => (
          <GlobalEventSubcategory
            subcategory={item}
            isEnabled={enabledSubcategoryIds.includes(item.id)}
            handleSubcategoryChange={handleSubcategoryChange}
          />
        )}
      />
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  titleContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  titleText: {
    fontSize: 16,
    lineHeight: 19,
  },
  contentContainer: { marginTop: 15 },
})
