import React from 'react'

import CheckboxCheckedSvg from '../assets/svg/checkbox-checked.svg'
import CheckboxSvg from '../assets/svg/checkbox.svg'
import Colors from '../styles/Colors'

export const CheckBox: React.FC<{
  isChecked: boolean
  isHighlighted?: boolean
}> = ({ isChecked, isHighlighted }) => {
  return isChecked ? (
    <CheckboxCheckedSvg />
  ) : (
    <CheckboxSvg stroke={isHighlighted ? Colors['brand.red'] : undefined} />
  )
}
