import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import {
  CommonActions,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import fixWebmDuration from 'fix-webm-duration'
// @ts-ignore
import { LiveAudioVisualizer } from 'react-audio-visualize'
import { useTranslation } from 'react-i18next'

import { useAudioRecorder } from '../../node_modules/react-audio-voice-recorder'
import { GetLibraryDocument, useCreateAttachmentMutation } from '../api/types'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import Typography from '../components/Text/Typography'
import TouchableSvg from '../components/TouchableSvg'
import { AudioType } from '../hooks/useAudioActionSheet'
import { useDirectUpload } from '../hooks/useDirectUpload'
import { useMediaLibraryState } from '../providers/MediaLibraryProvider'
import { useUser } from '../providers/UserProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../types/navigation-types'
import { mmss } from '../utils/time'

export default function AudioRecorderModal(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'modals.audioRecorder' })
  const { params } = useRoute<RouteProp<MainStackParamsType, 'AudioRecorder'>>()
  const { goBack, dispatch } =
    useNavigation<MainStackNavigationType<'AudioRecorder'>>()
  const { user } = useUser()
  const { uploadMedia } = useDirectUpload()
  const [createAttachment, { client }] = useCreateAttachmentMutation()
  const { setIsUploadingMedia } = useMediaLibraryState()
  const [recordedTime, setRecordedTime] = useState('00:00')
  const [duration, setDuration] = useState<number>()
  const [mimeType, setMimeType] = useState<string>()
  const skipSave = useRef(false)

  const uploadAudio = useCallback(
    (audio: AudioType) => {
      setIsUploadingMedia(true)
      uploadMedia(
        audio.fileCopyUri ?? '',
        audio.description,
        audio.name,
        audio.type,
      )
        .then(({ fileBlobId, description }) =>
          createAttachment({
            variables: {
              input: {
                fileBlobId,
                description,
              },
            },
          }),
        )
        .then(result =>
          client.cache.updateQuery(
            {
              query: GetLibraryDocument,
              variables: { id: user?.libraryId },
            },
            data => {
              if (!data) return
              return {
                library: {
                  id: user?.libraryId,
                  attachments: {
                    nodes: [
                      result.data?.createAttachment,
                      ...(data?.library.attachments?.nodes ?? []),
                    ],
                  },
                },
              }
            },
          ),
        )
        .finally(() => setIsUploadingMedia(false))
    },
    [
      uploadMedia,
      client.cache,
      user?.libraryId,
      createAttachment,
      setIsUploadingMedia,
    ],
  )

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder()

  useEffect(() => {
    if (!recordingTime) return
    setDuration(recordingTime)
    setRecordedTime(mmss(Math.floor(recordingTime)))
  }, [recordingTime])

  useEffect(() => {
    if (mediaRecorder?.mimeType) setMimeType(mediaRecorder.mimeType)
  }, [mediaRecorder?.mimeType])

  useEffect(() => {
    if (!recordingBlob) return
    // @ts-ignore
    mediaRecorder?.stream.getAudioTracks().forEach(stop)
    if (skipSave.current) return

    const date = new Date()
    const formattedDate = date.toLocaleDateString()
    fixWebmDuration(recordingBlob, (duration ?? 0.5) * 1000, blob => {
      const uri = URL.createObjectURL(blob)
      const type = mimeType?.split(';')[0] ?? null

      const recordedAudio = {
        uri,
        name: t('newName') + formattedDate,
        fileCopyUri: uri,
        type,
        size: blob.size,
        description: t('newName') + formattedDate,
      }

      if (params?.routeKey) {
        dispatch({
          ...CommonActions.setParams({
            recordedAudio,
          }),
          source: params.routeKey,
        })
      } else {
        uploadAudio(recordedAudio)
      }
      goBack()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    goBack,
    dispatch,
    duration,
    mimeType,
    uploadAudio,
    recordingBlob,
    params?.routeKey,
  ])

  const close = useCallback(() => {
    skipSave.current = true
    stopRecording()
    goBack()
  }, [goBack, stopRecording])

  return (
    <PickerModal close={close}>
      <View style={styles.container}>
        <TouchableSvg
          name="close"
          color="layout.dark"
          style={styles.closeButton}
          onPress={close}
        />
        <Separator height={10} />
        {isRecording && (
          <>
            <Typography
              weight="medium"
              numberOfLines={1}
              style={styles.titleText}>
              {t('recording')}
            </Typography>
            <Separator height={10} />
            <Typography>{recordedTime}</Typography>
            <Separator height={10} />
            <View style={styles.barContainer}>
              {mediaRecorder?.stream && (
                <LiveAudioVisualizer
                  gap={10}
                  height={30}
                  barWidth={2}
                  fftSize={512}
                  maxDecibels={-10}
                  minDecibels={-80}
                  smoothingTimeConstant={0.4}
                  mediaRecorder={mediaRecorder}
                  barColor={Colors['layout.black']}
                />
              )}
            </View>
            <Separator height={10} />
          </>
        )}
        <TouchableSvg
          color="layout.dark"
          style={styles.recordButton}
          name={isRecording ? 'record-end' : 'record'}
          onPress={() => (isRecording ? stopRecording() : startRecording())}
        />
        <Separator height={48} />
      </View>
    </PickerModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingHorizontal: 24,
    ...appStyles.horizontalCenter,
  },
  closeButton: { marginLeft: 'auto' },
  titleText: { fontSize: 30 },
  recordButton: {
    width: 80,
    height: 80,
  },
  barContainer: {
    height: 30,
    ...appStyles.inlineContainer,
  },
})
