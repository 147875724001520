import React from 'react'
import { View } from 'react-native'

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createStackNavigator } from '@react-navigation/stack'

import TabBar from './TabBar'
import TabHeader from '../components/TabHeader'
import AudioPlaybackModal from '../modals/AudioPlaybackModal'
import AudioRecorderModal from '../modals/AudioRecorderModal'
import CreateContentModal from '../modals/CreateContentModal'
import EditLifeStageModal from '../modals/EditLifeStageModal'
import LanguagePickerModal from '../modals/LanguagePickerModal'
import UnsavedChangesModal from '../modals/UnsavedChangesModal'
import { useMediaLibraryState } from '../providers/MediaLibraryProvider'
import { useWebLayout } from '../providers/WebLayoutProvider'
import DeleteAccountScreen from '../screens/delete-account/DeleteAccountScreen'
import EventsQuizScreen from '../screens/events-quiz-form/EventsQuizScreen'
import EventsQuizStartScreen from '../screens/events-quiz-start/EventsQuizStartScreen'
import EventsQuizSuccessScreen from '../screens/events-quiz-success/EventsQuizSuccessScreen'
import GlobalEventSettingsScreen from '../screens/global-event-settings/GlobalEventSettingsScreen'
import MediaLibraryScreen from '../screens/media-library/MediaLibraryScreen'
import MemoryDetailsScreen from '../screens/memory-details/MemoryDetailsScreen'
import MemoryFormScreen from '../screens/memory-form/MemoryFormScreen'
import MemoryTimePickerScreen from '../screens/memory-form/MemoryTimePickerScreen'
import PeoplePickerScreen from '../screens/memory-form/PeoplePickerScreen'
import MemoryPeopleScreen from '../screens/memory-people/MemoryPeopleScreen'
import PeopleListScreen from '../screens/people-list/PeopleListScreen'
import PersonDetailsScreen from '../screens/person-details/PersonDetailsScreen'
import PersonFormScreen from '../screens/person-form/PersonFormScreen'
import PersonInfoScreen from '../screens/person-info/PersonInfoScreen'
import PersonMemoriesScreen from '../screens/person-memories/PersonMemoriesScreen'
import ProfileSettingsEditScreen from '../screens/profile-settings-edit/ProfileSettingsEditScreen'
import ProfileSettingsViewScreen from '../screens/profile-settings-view/ProfileSettingsViewScreen'
import SettingsScreen from '../screens/settings/SettingsScreen'
import SubscriptionsScreen from '../screens/subscriptions/SubscriptionsScreen'
import OtherMemoriesScreen from '../screens/timeline/OtherMemoriesScreen'
import TimelineScreen from '../screens/timeline/TimelineScreen'
import UnsubscribeScreen from '../screens/unsubscribe/UnsubscribeScreen'
import UpgradeInfoScreen from '../screens/upgrade-info/UpgradeInfoScreen'
import appStyles from '../styles/app-styles'
import {
  MainTabsParamsType,
  MainStackParamsType,
} from '../types/navigation-types'

const MainStack = createStackNavigator<MainStackParamsType>()
const Tab = createBottomTabNavigator<MainTabsParamsType>()

const Tabs = () => {
  const { webLayoutEnabled } = useWebLayout()
  const { isSelecting } = useMediaLibraryState()
  return (
    <Tab.Navigator
      screenOptions={{
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => !webLayoutEnabled && <TabHeader />,
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props =>
        !webLayoutEnabled && !isSelecting && <TabBar {...props} />
      }>
      <Tab.Screen
        name="Timeline"
        component={TimelineScreen}
        options={{ title: 'screens.timeline.title' }}
      />
      <Tab.Screen
        name="OtherMemories"
        component={OtherMemoriesScreen}
        options={{ title: 'screens.otherMemories.title' }}
      />
      <Tab.Screen
        name="People"
        component={PeopleListScreen}
        options={{ title: 'screens.peopleList.title' }}
      />
      <Tab.Screen
        name="MediaLibrary"
        component={MediaLibraryScreen}
        options={{ title: 'screens.mediaLibrary.title' }}
      />
    </Tab.Navigator>
  )
}

const webModalOptions = {
  gestureEnabled: false,
  presentation: 'transparentModal' as const,
}

export default function MainStackNavigator(): JSX.Element {
  return (
    <View style={[appStyles.row, appStyles.fullSize]}>
      <View style={appStyles.fullSize}>
        <MainStack.Navigator
          initialRouteName="Root"
          screenOptions={{ headerShown: false }}>
          <MainStack.Screen name="Root" component={Tabs} />
          <MainStack.Screen
            name="ProfileSettingsView"
            component={ProfileSettingsViewScreen}
            options={{ title: 'screens.settings.profileSettings.title' }}
          />
          <MainStack.Screen
            name="ProfileSettingsEdit"
            component={ProfileSettingsEditScreen}
            options={{
              ...webModalOptions,
              title: 'screens.settings.profileSettings.title',
            }}
          />
          <MainStack.Screen
            name="EventsQuizStart"
            component={EventsQuizStartScreen}
            options={{
              ...webModalOptions,
              title: 'screens.globalEventsQuiz.title',
            }}
          />
          <MainStack.Screen
            name="EventsQuizSuccess"
            component={EventsQuizSuccessScreen}
            options={{
              ...webModalOptions,
              title: 'screens.globalEventsQuiz.title',
            }}
          />
          <MainStack.Screen
            name="GlobalEventSettings"
            component={GlobalEventSettingsScreen}
            options={{ title: 'screens.settings.globalEventSettings' }}
          />
          <MainStack.Screen
            name="LanguagePicker"
            options={{
              ...webModalOptions,
              title: 'modals.languagePicker.title',
            }}
            component={LanguagePickerModal}
          />
          <MainStack.Screen
            name="CreateContent"
            options={{
              ...webModalOptions,
              title: 'modals.languagePicker.title',
            }}
            component={CreateContentModal}
          />
          <MainStack.Screen
            name="AudioPlayback"
            component={AudioPlaybackModal}
            options={{
              ...webModalOptions,
              title: 'screens.mediaLibrary.audio',
            }}
          />
          <MainStack.Screen
            name="AudioRecorder"
            component={AudioRecorderModal}
            options={{
              ...webModalOptions,
              title: 'screens.mediaLibrary.audio',
            }}
          />
          <MainStack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{ title: 'screens.settings.title' }}
          />
          <MainStack.Screen
            name="EventsQuiz"
            component={EventsQuizScreen}
            options={{
              ...webModalOptions,
              title: 'screens.globalEventsQuiz.title',
            }}
          />
          <MainStack.Screen
            name="MemoryDetails"
            component={MemoryDetailsScreen}
            options={{ title: 'screens.memory.title' }}
          />
          <MainStack.Screen
            name="PersonDetails"
            component={PersonDetailsScreen}
            options={{ title: 'screens.person.title' }}
          />
          <MainStack.Screen
            name="PersonInfo"
            component={PersonInfoScreen}
            options={{ title: 'screens.personDetails.title' }}
          />
          <MainStack.Screen
            name="PersonMemories"
            component={PersonMemoriesScreen}
            options={{ title: 'screens.personMemories.title' }}
          />
          <MainStack.Screen
            name="EditLifeStage"
            component={EditLifeStageModal}
            options={{
              ...webModalOptions,
              title: 'modals.editLifeStage.title',
            }}
          />
          <MainStack.Screen
            name="MemoryForm"
            component={MemoryFormScreen}
            options={{
              ...webModalOptions,
              title: 'screens.memory.title',
            }}
          />
          <MainStack.Screen
            name="PersonForm"
            component={PersonFormScreen}
            options={{
              ...webModalOptions,
              title: 'screens.person.title',
            }}
          />
          <MainStack.Screen
            name="MemoryTimePicker"
            component={MemoryTimePickerScreen}
            options={{
              ...webModalOptions,
              title: 'screens.memory.title',
            }}
          />
          <MainStack.Screen
            name="PeoplePicker"
            component={PeoplePickerScreen}
            options={{
              ...webModalOptions,
              title: 'screens.createMemory.tagPerson',
            }}
          />
          <MainStack.Screen
            name="MemoryPeople"
            component={MemoryPeopleScreen}
            options={{ title: 'screens.memoryPeople.title' }}
          />
          <MainStack.Screen
            name="Subscriptions"
            component={SubscriptionsScreen}
            options={{ title: 'screens.settings.subscriptions.title' }}
          />
          <MainStack.Screen
            name="DeleteAccount"
            component={DeleteAccountScreen}
            options={{ title: 'screens.settings.profileSettings.delete.title' }}
          />
          <MainStack.Screen
            name="MediaLibraryPicker"
            component={MediaLibraryScreen}
            options={{
              ...webModalOptions,
              title: 'screens.mediaLibrary.title',
            }}
          />
          <MainStack.Screen name="UpgradeInfo" component={UpgradeInfoScreen} />
          <MainStack.Screen
            name="UnsavedChanges"
            component={UnsavedChangesModal}
            options={{
              ...webModalOptions,
              title: 'modals.unsaved.warning',
            }}
          />
          <MainStack.Screen
            name="Unsubscribe"
            component={UnsubscribeScreen}
            options={{ title: 'screens.settings.subscriptions.cancel.title' }}
          />
        </MainStack.Navigator>
      </View>
    </View>
  )
}
