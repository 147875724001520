import React, { useCallback } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { useTranslation } from 'react-i18next'

import Separator from './Separator'
import SvgIcon from './SvgIcon'
import ButtonText from './Text/ButtonText'
import {
  Memory,
  Person,
  MemoryInput,
  useUpdateMemoryMutation,
  useUpdatePersonMutation,
  useGetShareTokenMutation,
  PersonInput,
} from '../api/types'
import CONFIG from '../config/app-config'
import loggingCore from '../core/logging-core'
import useNativeShare from '../hooks/useNativeShare'
import { MAX_PEOPLE_ROW_COUNT } from '../screens/memory-details/MemoryDetailsScreen'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { convertHexToRGBA } from '../utils/colors'

const ShareButton: React.FC<{
  opacity?: number
  shareContent: Memory | Person
}> = ({ opacity = 1, shareContent }) => {
  const { t } = useTranslation()
  const [getShareToken] = useGetShareTokenMutation()
  const [updateMemory] = useUpdateMemoryMutation()
  const [updatePerson] = useUpdatePersonMutation()
  const nativeShare = useNativeShare()

  const share = useCallback(() => {
    getShareToken()
      .then(({ data }) => {
        if (shareContent.__typename === 'Memory') {
          const input: MemoryInput = {
            memoryTitle: shareContent.memoryTitle,
            memoryType: shareContent.memoryType,
            isShared: true,
          }
          return updateMemory({
            variables: {
              id: shareContent.id,
              input,
              attachments: [],
              personCount: MAX_PEOPLE_ROW_COUNT,
            },
          }).then(() => data?.getShareToken.token)
        }
        if (shareContent.__typename === 'Person') {
          const input: PersonInput = {
            isShared: true,
          }
          return updatePerson({
            variables: {
              id: shareContent.id,
              input,
              attachments: [],
            },
          }).then(() => data?.getShareToken.token)
        }
      })
      .then(token => {
        const shareUrl = `${CONFIG.WEB_APP_URL()}/shared/${
          shareContent.__typename === 'Memory' ? 'user-memory' : 'person'
        }/${shareContent.id}?shareToken=${token}`
        return nativeShare(shareUrl, shareContent)
      })
      .catch(loggingCore.error)
  }, [nativeShare, shareContent, updateMemory, updatePerson, getShareToken])

  return (
    <TouchableOpacity
      style={[
        styles.shareButton,
        opacity === 1 && styles.shadow,
        { backgroundColor: convertHexToRGBA(Colors['layout.white'], opacity) },
      ]}
      onPress={share}>
      <ButtonText size="medium" color="layout.dark">
        {t('common.share')}
      </ButtonText>
      <Separator width={4} />
      <SvgIcon name="share" />
    </TouchableOpacity>
  )
}

export default ShareButton

const styles = StyleSheet.create({
  shareButton: {
    height: 50,
    borderRadius: 20,
    paddingHorizontal: 20,
    ...appStyles.inlineContainer,
  },
  shadow: {
    ...appStyles.lightShadow,
    elevation: 3,
  },
})
