import React, {
  useMemo,
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import { useWindowDimensions, Platform } from 'react-native'

export const WEB_AUTH_NAVIGATOR_ASPECT_RATIO = 680 / 1024
export const WEB_AUTH_NAVIGATOR_MIN_WIDTH = 600

export const SIDE_COLUMN_WIDTH = 400
const CONTENT_MIN_WIDTH = 400

type WebLayoutContextType = {
  width: number
  isWeb: boolean
  isLandscape: boolean
  webLayoutEnabled: boolean
  showRightColumn: boolean
  showFullWidth: boolean
  setShowFullWidth: (showFullWidth: boolean) => void
}

const WebLayoutContext = createContext<WebLayoutContextType>({
  width: 0,
  isWeb: false,
  isLandscape: false,
  webLayoutEnabled: false,
  showRightColumn: false,
  showFullWidth: false,
  setShowFullWidth: () => {},
})

const WebLayoutProvider: React.FC<
  PropsWithChildren<{ preset?: 'auth' | 'fullWidth' }>
> = ({ children, preset }) => {
  const dimensions = useWindowDimensions()
  const [showFullWidth, setShowFullWidth] = useState(false)
  useEffect(() => {
    setShowFullWidth(preset === 'fullWidth')
  }, [preset])

  const value: WebLayoutContextType = useMemo(() => {
    const isWeb = Platform.OS === 'web'
    const isLandscape = dimensions.width > dimensions.height
    const width = dimensions.width

    if (preset === 'auth' && isWeb) {
      const navigatorWidth = Math.max(
        WEB_AUTH_NAVIGATOR_MIN_WIDTH,
        dimensions.height * WEB_AUTH_NAVIGATOR_ASPECT_RATIO,
      )
      const welcomeWidth = dimensions.width - navigatorWidth
      const welcomeAspectRatio = welcomeWidth / dimensions.height

      return {
        isWeb,
        width,
        isLandscape,
        showRightColumn: false,
        webLayoutEnabled: welcomeWidth > 300 && welcomeAspectRatio > 0.33,
        showFullWidth,
        setShowFullWidth,
      }
    }

    return {
      isWeb,
      width,
      isLandscape,
      webLayoutEnabled: isWeb && width >= CONTENT_MIN_WIDTH + SIDE_COLUMN_WIDTH,
      showRightColumn:
        isWeb && width >= CONTENT_MIN_WIDTH + 2 * SIDE_COLUMN_WIDTH,
      showFullWidth,
      setShowFullWidth,
    }
  }, [dimensions, preset, showFullWidth, setShowFullWidth])

  return (
    <WebLayoutContext.Provider value={value}>
      {children}
    </WebLayoutContext.Provider>
  )
}

export default WebLayoutProvider

export function useWebLayout() {
  return useContext(WebLayoutContext)
}
