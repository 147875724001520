import { ViewStyle } from 'react-native'

function negateHeaderPadding(source?: ViewStyle): ViewStyle {
  if (!source) return {}

  const left =
    source.paddingLeft ?? source.paddingHorizontal ?? source.padding ?? 0
  const right =
    source.paddingRight ?? source.paddingHorizontal ?? source.padding ?? 0
  const top = source.paddingTop ?? source.paddingVertical ?? source.padding ?? 0

  return {
    marginTop: -top,
    paddingTop: top,
    marginLeft: -left,
    paddingLeft: left,
    marginRight: -right,
    paddingRight: right,
  }
}

export default negateHeaderPadding
