import React, { useEffect, useRef } from 'react'
import { View, Animated, StyleSheet, TouchableOpacity } from 'react-native'

import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type SwitchProps = {
  value: boolean
  onValueChange: () => void
}

const Switch: React.FC<SwitchProps> = ({
  value,
  onValueChange,
}: SwitchProps) => {
  const translation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(translation, {
      toValue: value ? 30 : 0,
      useNativeDriver: true,
      duration: 200,
    }).start()
  }, [value, translation])

  return (
    <TouchableOpacity
      // @ts-ignore
      hitSlop={10}
      activeOpacity={0.8}
      style={[styles.container, value && styles.active]}
      onPress={onValueChange}>
      <Animated.View
        style={[styles.thumb, { transform: [{ translateX: translation }] }]}>
        <View style={[styles.thumbMiddle, value && styles.active]} />
      </Animated.View>
    </TouchableOpacity>
  )
}

export default Switch

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 30,
    borderRadius: 15,
    paddingHorizontal: 3,
    ...appStyles.verticalCenter,
    backgroundColor: Colors['layout.gray'],
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 12,
    ...appStyles.center,
    backgroundColor: Colors['layout.white'],
  },
  thumbMiddle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: Colors['layout.gray'],
  },
  active: {
    backgroundColor: Colors['brand.action'],
  },
})
