import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import { Picker } from '@react-native-picker/picker'
import { useTranslation } from 'react-i18next'

import Separator from './Separator'
import Typography from './Text/Typography'
import { DatePrecisionEnum } from '../api/types'
import { useLocale } from '../providers/LocaleProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { getMonthLength } from '../utils/monthLength'

export type DatePickerProps = {
  date: Date
  precision?: DatePrecisionEnum
  allowPrecisionChange: boolean
  onDateChange: (date: Date) => void
  setPrecision?: (precision: DatePrecisionEnum) => void
}

const currentYear = new Date().getFullYear()

const years = Array.from({ length: 200 }, (_, i) => i + currentYear - 199)

const DatePicker: React.FC<DatePickerProps> = ({
  date,
  precision,
  onDateChange,
  setPrecision,
  allowPrecisionChange,
}) => {
  const [selectedDay, setSelectedDay] = useState<number | ''>('')
  const [selectedMonth, setSelectedMonth] = useState<number | ''>('')
  const [selectedYear, setSelectedYear] = useState<number>(currentYear)
  const { t } = useTranslation(undefined, { keyPrefix: 'modals.datePicker' })
  const { locale } = useLocale()

  const months = useMemo(() => {
    const today = new Date()
    const monthCount =
      selectedYear < today.getFullYear() ? 12 : today.getMonth() + 1
    return Array.from({ length: monthCount }, (_, i) => {
      const monthDate = new Date(0)
      monthDate.setMonth(i)
      return monthDate.toLocaleString(locale, { month: 'long' })
    })
  }, [locale, selectedYear])

  const days = useMemo(() => {
    const today = new Date()
    const monthLength =
      selectedMonth !== ''
        ? selectedYear < today.getFullYear() || selectedMonth < today.getMonth()
          ? getMonthLength(selectedMonth, selectedYear)
          : today.getDate()
        : 31
    return Array.from({ length: monthLength }, (_, i) => i + 1)
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    setSelectedDay(precision === DatePrecisionEnum.Day ? date.getDate() : '')
    setSelectedMonth(
      precision === DatePrecisionEnum.Year ? '' : date.getMonth(),
    )
    setSelectedYear(date.getFullYear())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDate = useCallback(
    (year: number, month: number | '', day: number | '') => {
      const newDate = new Date(
        year,
        month !== '' ? month : 0,
        day !== '' ? day : 1,
      )
      const adjustedDate = new Date(
        newDate.getTime() - newDate.getTimezoneOffset() * 60000,
      )
      onDateChange(adjustedDate)

      const newPrecision =
        day !== ''
          ? DatePrecisionEnum.Day
          : month !== ''
          ? DatePrecisionEnum.Month
          : DatePrecisionEnum.Year
      setPrecision?.(newPrecision)
    },
    [onDateChange, setPrecision],
  )

  return (
    <View style={appStyles.row}>
      <View style={[styles.pickerContainer, styles.pickerDay]}>
        <Typography style={styles.pickerLabelText}>{t('day')}</Typography>
        <Picker
          style={styles.picker}
          selectedValue={selectedDay}
          onValueChange={value => {
            setSelectedDay(value)
            updateDate(selectedYear, selectedMonth, value)
          }}>
          {allowPrecisionChange && <Picker.Item label="-" value="" />}
          {days.map((day, index) => (
            <Picker.Item key={index} label={day.toString()} value={index + 1} />
          ))}
        </Picker>
      </View>
      <Separator width={Platform.select({ ios: 0, default: 10 })} />
      <View style={[styles.pickerContainer, styles.pickerMonth]}>
        <Typography style={styles.pickerLabelText}>{t('month')}</Typography>
        <Picker
          style={styles.picker}
          selectedValue={selectedMonth}
          onValueChange={value => {
            setSelectedMonth(value)
            updateDate(selectedYear, value, selectedDay)
          }}>
          {allowPrecisionChange && <Picker.Item label="-" value="" />}
          {months.map((month, index) => (
            <Picker.Item key={index} label={month} value={index} />
          ))}
        </Picker>
      </View>
      <Separator width={Platform.select({ ios: 0, default: 10 })} />
      <View style={[styles.pickerContainer, styles.pickerYear]}>
        <Typography style={styles.pickerLabelText}>{t('year')}</Typography>
        <Picker
          style={styles.picker}
          selectedValue={selectedYear}
          onValueChange={value => {
            setSelectedYear(value)
            updateDate(value, selectedMonth, selectedDay)
          }}>
          {years.map((year, index) => (
            <Picker.Item key={index} label={year.toString()} value={year} />
          ))}
        </Picker>
      </View>
    </View>
  )
}
export default DatePicker

const styles = StyleSheet.create({
  pickerContainer: {
    ...appStyles.fullSize,
  },
  pickerLabelText: {
    fontSize: 16,
    marginLeft: Platform.select({ ios: 10 }),
  },
  picker: {
    fontSize: 16,
    borderRadius: 10,
    color: Colors['layout.dark'],
    borderColor: Colors['layout.light'],
    backgroundColor: Platform.select({
      android: Colors['layout.light'],
      default: Colors['layout.white'],
    }),
    paddingVertical: Platform.select({ web: 7 }),
    paddingHorizontal: Platform.select({ web: 12 }),
  },
  pickerDay: {
    minWidth: 12,
  },
  pickerMonth: {
    minWidth: 40,
  },
  pickerYear: {
    minWidth: 35,
  },
})
