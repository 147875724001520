import './locales/config'
import React from 'react'
import { StatusBar } from 'react-native'

import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import Navigation from './navigation/Navigation'
import BannerProvider from './providers/BannerProvider'
import LocaleProvider from './providers/LocaleProvider'
import MediaLibraryProvider from './providers/MediaLibraryProvider'
import RefetchProvider from './providers/RefetchProvider'
import UserProvider from './providers/UserProvider'
import WebLayoutProvider from './providers/WebLayoutProvider'

/* eslint-disable react/jsx-max-depth */
const App = (): JSX.Element => (
  <LocaleProvider>
    <RefetchProvider>
      <UserProvider>
        <ActionSheetProvider>
          <SafeAreaProvider>
            <StatusBar
              translucent
              barStyle="dark-content"
              backgroundColor="#00000000"
            />
            <WebLayoutProvider>
              <BannerProvider>
                <MediaLibraryProvider>
                  <Navigation />
                </MediaLibraryProvider>
              </BannerProvider>
            </WebLayoutProvider>
          </SafeAreaProvider>
        </ActionSheetProvider>
      </UserProvider>
    </RefetchProvider>
  </LocaleProvider>
)
/* eslint-enable react/jsx-max-depth */

export default App
