import React from 'react'
import { Platform, FlatList, StyleSheet, RefreshControl } from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import PersonCard from './components/PersonCard'
import { useGetPeopleQuery } from '../../api/types'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import useSafeAreaPaddedStyle from '../../hooks/useSafeAreaPaddedStyle'
import {
  QueryKey,
  useShouldRefetch,
  useSetShouldRefetch,
} from '../../providers/RefetchProvider'
import appStyles from '../../styles/app-styles'

const Divider = () => <Separator height={9} />

export default function PeopleListScreen(): JSX.Element {
  const { t } = useTranslation()

  const { data, refetch, networkStatus } = useGetPeopleQuery()
  useShouldRefetch(QueryKey.People, refetch)
  const setShouldRefetch = useSetShouldRefetch()

  return (
    <RootContainer>
      <Loading loading={networkStatus === NetworkStatus.loading} />
      <FlatList
        data={data?.people.nodes}
        style={appStyles.fullSize}
        ItemSeparatorComponent={Divider}
        ListFooterComponent={<Separator height={100} />}
        renderItem={({ item }) => <PersonCard item={item} />}
        contentContainerStyle={useSafeAreaPaddedStyle(
          styles.scrollContentContainer,
          { insetBottom: true },
        )}
        refreshControl={
          <RefreshControl
            refreshing={networkStatus === NetworkStatus.refetch}
            onRefresh={() => setShouldRefetch(QueryKey.People)}
          />
        }
        ListHeaderComponent={
          <ListHeader
            title={t('screens.peopleList.title')}
            subtitle={t('screens.peopleList.subtitle')}
            negatablePaddingSource={styles.scrollContentContainer}
          />
        }
      />
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 27,
    paddingHorizontal: 24,
    height: Platform.select({ web: 0 }),
  },
})
