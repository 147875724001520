import React, { useState, useCallback, useEffect } from 'react'
import {
  View,
  Platform,
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'

import { RouteProp, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
import { PasswordInputField } from '../../components/InputFields'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import TitleText from '../../components/Text/TitleText'
import loggingCore from '../../core/logging-core'
import useStateRef from '../../hooks/useStateRef'
import { useLocale } from '../../providers/LocaleProvider'
import { useUser } from '../../providers/UserProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackParamsType } from '../../types/navigation-types'

export default function ResetPasswordScreen(): JSX.Element {
  const {
    params: { reset_password_token, language },
  } = useRoute<RouteProp<UnauthorizedStackParamsType, 'ResetPassword'>>()

  const { setLocale } = useLocale()
  useEffect(() => {
    setLocale(language === 'en' ? 'en' : 'de')
  }, [language, setLocale])
  const { t } = useTranslation()
  const { resetPassword } = useUser()

  const [isLoading, setIsLoading, getIsLoading] = useStateRef(false)

  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [error, setError] = useState<
    '' | 'diff' | 'missing' | 'missing_confirmation' | string
  >('')
  const [apiError, setApiError] = useState<string>('')

  const onRecoverPress = useCallback(() => {
    if (getIsLoading()) return

    if (!password) {
      return setError('missing')
    }
    if (!passwordConfirmation) {
      return setError('missing_confirmation')
    }

    if (password !== passwordConfirmation) {
      return setError('diff')
    }

    setApiError('')
    setIsLoading(true)
    const params = {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token,
    }
    resetPassword(params)
      .catch(e => {
        if (e.json) {
          // @ts-ignore
          e.json().then(({ errors }) => {
            if (errors.password) {
              setApiError(errors.password.join('\n'))
            }
          })
        }
        loggingCore.warn(`Error resetting password`, e)
      })
      .finally(() => setIsLoading(false))
  }, [
    password,
    getIsLoading,
    setIsLoading,
    resetPassword,
    passwordConfirmation,
    reset_password_token,
  ])

  return (
    <SafeAreaView style={appStyles.fullSize}>
      <Loading blocking loading={isLoading} />
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          style={styles.container}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={styles.scrollContentContainer}>
          <View style={styles.languagePickerContainer}>
            <LanguagePickerButton />
          </View>
          <Separator height={35} />
          <TitleText color="layout.dark" size="medium">
            {t('screens.signIn.resetPassword.title')}
          </TitleText>
          <View style={styles.centerBlock}>
            <PasswordInputField
              value={password}
              label={t('screens.signIn.passwordPlaceholder')}
              errorMessage={
                (error !== 'missing_confirmation' && error) ||
                (apiError ? ' ' : '')
              }
              clearError={() => {
                setError('')
              }}
              onChangeValue={setPassword}
            />
            <PasswordInputField
              value={passwordConfirmation}
              label={t('screens.signIn.resetPassword.repeatPassword')}
              errorMessage={
                (error !== 'missing' && error) || (apiError ? ' ' : '')
              }
              clearError={() => {
                setError('')
              }}
              hint={t('profile.passwordHint')}
              onChangeValue={setPasswordConfirmation}
            />
            <Separator height={30} />
            <Button
              text={t('screens.signIn.resetPassword.reset')}
              onPress={onRecoverPress}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 5,
    alignItems: 'center',
  },
  languagePickerContainer: {
    marginLeft: 24,
    marginRight: 'auto',
  },
  centerBlock: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingHorizontal: 25,
    ...appStyles.verticalCenter,
  },
})
