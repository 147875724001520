import React from 'react'
import { View, ListRenderItem, StyleSheet } from 'react-native'

import Typography from './Text/Typography'
import Colors from '../styles/Colors'

export type PersonInfo = {
  label: string
  value: string
}

export const PersonInfoItem: ListRenderItem<PersonInfo> = ({ item }) => (
  <View style={styles.itemContainer}>
    <Typography style={styles.itemLabelText}>{item.label}</Typography>
    <Typography
      weight="medium"
      numberOfLines={1}
      ellipsizeMode="middle"
      style={styles.itemValueText}>
      {item.value}
    </Typography>
  </View>
)

export const PersonInfoItemSeparator = () => <View style={styles.itemDivider} />

const styles = StyleSheet.create({
  itemDivider: {
    height: 1,
    opacity: 0.5,
    backgroundColor: Colors['layout.gray'],
  },
  itemContainer: {
    height: 65,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  itemLabelText: {
    opacity: 0.7,
    fontSize: 13,
    lineHeight: 16,
  },
  itemValueText: {
    fontSize: 15,
    lineHeight: 18,
    paddingRight: 10,
  },
})
