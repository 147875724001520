function parseHexColor(colorHexCode: string): {
  r: number
  g: number
  b: number
} {
  let hex = colorHexCode.replace('#', '')
  if (hex.length === 3) {
    const [r, g, b] = hex
    hex = `${r}${r}${g}${g}${b}${b}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return { r, g, b }
}

/**
 * Converts a hexadecimal color + opacity to a non-transparent color code
 * @param hexColorCode - HEX color (3 or 6 digit)
 * @param opacity - opacity to apply (0 - 1)
 * @param backgroundHexCode - HEX color (3 or 6 digit), defaults to white
 * @returns HEX color 6 digit
 */
export const mixinOpacity = (
  colorHexCode: string,
  opacity: number,
  backgroundHexCode: string = '#FFFFFF',
): string => {
  const color = parseHexColor(colorHexCode)
  const backgroundColor = parseHexColor(backgroundHexCode)

  const keys: ['r', 'g', 'b'] = ['r', 'g', 'b']
  const [r, g, b] = keys.map(key =>
    (
      '0' +
      Math.floor(
        color[key] * opacity + backgroundColor[key] * (1 - opacity),
      ).toString(16)
    ).slice(-2),
  )

  return `#${r}${g}${b}`
}

/**
 * Converts a hexadecimal color + opacity to RGBA string.
 * @param hexCode - HEX color (3 or 6 digit) to be converted to RGBA
 * @param opacity - opacity to apply (0 - 1)
 * @returns `rgba(${number},${number},${number},${number})` - RGBA representation of the passed hex color with given opacity applied
 */
export const convertHexToRGBA = (
  hexCode: string,
  opacity: number,
): `rgba(${number},${number},${number},${number})` => {
  const { r, g, b } = parseHexColor(hexCode)

  return `rgba(${r},${g},${b},${opacity})`
}
