import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import Separator from './Separator'
import SvgIcon from './SvgIcon'
import Typography from './Text/Typography'
import { useGetUserStorageQuery } from '../api/types'
import { useLocale } from '../providers/LocaleProvider'
import Colors from '../styles/Colors'

export default function UserStogrageInfo() {
  const { locale } = useLocale()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.settings' })
  const { data } = useGetUserStorageQuery({ fetchPolicy: 'network-only' })

  const usedStorage = useMemo(
    () => data?.me.usedStorageGb ?? 0,
    [data?.me.usedStorageGb],
  )
  const allocatedStorage = useMemo(
    () => data?.me.allocatedSpaceGb ?? 0,
    [data?.me.allocatedSpaceGb],
  )

  const storageTextColor: keyof typeof Colors = useMemo(() => {
    const storageRatio =
      (data?.me.usedStorageGb ?? 0) / (data?.me.allocatedSpaceGb ?? 1)
    if (storageRatio < 0.7) {
      return 'brand.action'
    }
    if (storageRatio < 0.9) {
      return 'timeline.orange'
    }
    return 'brand.red'
  }, [data?.me.usedStorageGb, data?.me.allocatedSpaceGb])

  return (
    <View style={styles.storageInfoContainer}>
      <SvgIcon name="cloud" />
      <Separator height={8} />
      <Typography style={[styles.storageText, styles.storageTitleText]}>
        {t('storage')}
      </Typography>
      <Typography
        style={[styles.storageText, { color: Colors[storageTextColor] }]}>
        {t('usedStorage', {
          usedStorage: usedStorage.toLocaleString(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }),
          allocatedStorage: allocatedStorage.toLocaleString(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
        })}
      </Typography>
    </View>
  )
}

const styles = StyleSheet.create({
  storageInfoContainer: {
    alignItems: 'center',
  },
  storageText: {
    fontSize: 14,
    lineHeight: 20,
  },
  storageTitleText: { opacity: 0.5 },
})
