import React from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'

import HeaderIconButton from './HeaderIconButton'
import Separator from './Separator'
import ShareButton from './ShareButton'
import SvgIcon from './SvgIcon'
import { Memory, Person } from '../api/types'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

const MemoryHeader: React.FC<{
  goBack: () => void
  goEdit?: () => void
  shareContent?: Memory | Person
  buttonColor: keyof typeof Colors
  backgroundColor?: keyof typeof Colors
}> = ({ goBack, goEdit, shareContent, buttonColor, backgroundColor }) => {
  return (
    <View
      style={[
        style,
        backgroundColor && { backgroundColor: Colors[backgroundColor] },
      ]}>
      <TouchableOpacity
        // @ts-ignore
        hitSlop={35}
        onPress={goBack}>
        <SvgIcon name="back" color={Colors[buttonColor]} />
      </TouchableOpacity>
      <View style={appStyles.inlineContainer}>
        <Separator height={50} />
        {shareContent && (
          <ShareButton opacity={0.8} shareContent={shareContent} />
        )}
        {goEdit && (
          <>
            <Separator width={10} />
            <HeaderIconButton
              iconName="pencil"
              opacity={0.8}
              onPress={goEdit}
            />
          </>
        )}
      </View>
    </View>
  )
}

export default MemoryHeader

const style: ViewStyle = {
  width: '100%',
  paddingTop: 15,
  paddingBottom: 14,
  paddingHorizontal: 25,
  ...appStyles.inlineContainer,
  justifyContent: 'space-between',
}
