import React from 'react'

import ImageViewer from 'react-simple-image-viewer'

import { MediaViewerProps } from '../types/media-viewer-types'

const ImageViewers: React.FC<MediaViewerProps> = ({
  uri,
  onGoBack,
}: MediaViewerProps) => {
  return (
    <ImageViewer
      closeOnClickOutside
      src={[uri]}
      currentIndex={0}
      disableScroll={false}
      onClose={onGoBack}
    />
  )
}

export default ImageViewers
