import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Separator from './Separator'
import SvgIcon from './SvgIcon'
import Typography from './Text/Typography'
import { AudioType } from '../hooks/useAudioActionSheet'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

export type AudioItem =
  | {
      __typename: 'NewAttachment'
      asset: AudioType
    }
  | {
      __typename?: 'Attachment' | undefined
      id: string
      url?: string | null | undefined
      contentType?: string | null | undefined
      signedBlobId?: string | null | undefined
      description?: string | null | undefined
    }

interface AudioListProps {
  canEdit: boolean
  audio: AudioItem[]
  addAudio?: () => void
  withLargeDividers?: boolean
  showAudioActions?: (item: AudioItem) => void
}

export const AudioList: React.FC<AudioListProps> = ({
  canEdit,
  audio,
  addAudio,
  showAudioActions,
  withLargeDividers,
}) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  const renderDivider = () => (
    <View
      style={[styles.itemDivider, withLargeDividers && styles.largeDivider]}
    />
  )

  return (
    <View>
      {canEdit && (
        <>
          <TouchableOpacity
            style={appStyles.inlineContainer}
            onPress={addAudio}>
            <SvgIcon name="add-audio" />
            <Separator width={15} />
            <Typography weight="bold" style={styles.addAudioText}>
              {t('screens.createMemory.newRecording')}
            </Typography>
          </TouchableOpacity>
          {audio.length > 0 && renderDivider()}
        </>
      )}
      {audio.map((item, i) => (
        <View key={String(i)}>
          <View style={styles.itemContainer}>
            <TouchableOpacity
              style={styles.itemContentContainer}
              onPress={() =>
                // @ts-ignore
                navigate('AudioPlayback', {
                  url:
                    item.__typename === 'NewAttachment'
                      ? item.asset.fileCopyUri ?? ''
                      : item.url,
                  name:
                    item.__typename === 'NewAttachment'
                      ? item.asset.name
                      : item.description,
                })
              }
              onLongPress={() => showAudioActions?.(item)}>
              <View style={styles.audioIcon}>
                <SvgIcon name="audio" height={46} width={46} />
              </View>
              <Separator width={15} />
              <Typography weight="bold" style={styles.addAudioText}>
                {item.__typename === 'NewAttachment'
                  ? item.asset.description
                  : item.description}
              </Typography>
            </TouchableOpacity>
            {canEdit && (
              <>
                <Separator width={10} />
                <TouchableOpacity
                  // @ts-ignore
                  hitSlop={10}
                  onPress={() => showAudioActions?.(item)}>
                  <SvgIcon name="options" />
                </TouchableOpacity>
              </>
            )}
          </View>
          {renderDivider()}
        </View>
      ))}
    </View>
  )
}

const AUDIO_BUTTON_SIZE = 46
const styles = StyleSheet.create({
  audioIcon: {
    width: AUDIO_BUTTON_SIZE,
    height: AUDIO_BUTTON_SIZE,
  },
  addAudioText: {
    fontSize: 16,
    flexShrink: 1,
  },
  itemContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  itemContentContainer: {
    flexShrink: 1,
    ...appStyles.fullSize,
    ...appStyles.inlineContainer,
  },
  itemDivider: {
    height: 1,
    marginVertical: 10,
    backgroundColor: Colors['layout.gray'],
  },
  largeDivider: {
    marginVertical: 18,
  },
})
