import { Platform } from 'react-native'

import DeviceInfo from 'react-native-device-info'

import { version } from '../../package.json'
import { EnvironmentEnum } from '../types/environment-types'

function webIsProd(): boolean {
  // @ts-ignore
  const host = window.location.host
  return host === 'app.relefant.eu'
}

export const CONFIG = {
  /**
   * Function that indicates if the app is running the DEMO target
   */
  IS_DEMO: (): boolean =>
    Platform.OS === 'web'
      ? !webIsProd()
      : DeviceInfo.getBundleId() === 'com.relefant.app.demo',
  /**
   * Function that indicates if the app is running the PROD target
   */
  IS_PROD: (): boolean =>
    Platform.OS === 'web'
      ? webIsProd()
      : DeviceInfo.getBundleId() === 'com.relefant.app',
  /**
   * App environment based on bundle identifier
   */
  ENVIRONMENT: (): EnvironmentEnum =>
    CONFIG.IS_DEMO() ? EnvironmentEnum.DEMO : EnvironmentEnum.PROD,
  /**
   * API base url
   */
  API_BASE_URL: (): string =>
    CONFIG.IS_DEMO()
      ? 'https://relefant-demo.mitigate.dev'
      : 'https://backoffice.relefant.eu',
  /**
   * GraphQL endpoint URL
   */
  GRAPHQL_ENDPOINT: (): string => `${CONFIG.API_BASE_URL()}/graphql`,
  /**
   * GraphQL public endpoint URL
   */
  GRAPHQL_ENDPOINT_PUBLIC: (): string =>
    `${CONFIG.API_BASE_URL()}/graphql_public`,
  /**
   * App version
   */
  APP_VERSION: (): string =>
    Platform.OS === 'web'
      ? version
      : `${DeviceInfo.getVersion()} (${DeviceInfo.getBuildNumber()})`,
  /**
   * Web app url
   */
  WEB_APP_URL: (): string =>
    CONFIG.IS_DEMO()
      ? 'https://relefant-app.vercel.app'
      : 'https://app.relefant.eu',
}

export default CONFIG
