import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import SvgIcon from './SvgIcon'
import Typography from './Text/Typography'
import {
  ProfileFormItemType,
  ProfileFormItemKeyType,
} from '../helpers/ProfileFormItems'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface PressableItemPropsType {
  item: ProfileFormItemType
  isSelected: boolean
  onPress: (key: ProfileFormItemKeyType) => void
}

export const PressableItem: React.FC<PressableItemPropsType> = ({
  item,
  isSelected,
  onPress,
}) => (
  <Pressable
    style={({ pressed }) => [
      appStyles.inlineContainer,
      styles.optionsItem,
      isSelected && styles.optionsItemSelected,
      pressed && styles.optionsItemPressed,
    ]}
    onPress={() => onPress(item.key)}>
    {item.icon && (
      <SvgIcon
        name={item.icon}
        color={isSelected ? Colors['brand.action'] : Colors['layout.icon']}
      />
    )}
    <Typography weight="medium" style={styles.optionsItemLabelText}>
      {item.label}
    </Typography>
  </Pressable>
)

const styles = StyleSheet.create({
  optionsItem: {
    padding: 20,
    elevation: 4,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 1,
    borderRadius: 10,
    shadowOpacity: 0.05,
    shadowColor: Colors['layout.shadow'],
    backgroundColor: Colors['layout.white'],
  },
  optionsItemPressed: { backgroundColor: Colors['layout.selected'] },
  optionsItemSelected: {
    elevation: 0,
    shadowOpacity: 0,
    backgroundColor: Colors['layout.selected'],
  },
  optionsItemLabelText: {
    fontSize: 16,
    marginLeft: 20,
  },
})
