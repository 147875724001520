import { useCallback } from 'react'

import imageCompression from 'browser-image-compression'
import md5 from 'js-md5'

import { DirectUploadInput, useCreateDirectUploadMutation } from '../api/types'

const calculateChecksum = async (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(md5.base64(reader.result))
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}

const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri)
  return resp.blob()
}

const options = {
  maxWidthOrHeight: 1280,
}

export const useDirectUpload = () => {
  const [createDirectUpload] = useCreateDirectUploadMutation()

  const uploadMedia: (
    uri?: string,
    description?: string | null,
    name?: string | null,
    type?: string | null,
  ) => Promise<{ fileBlobId: string; description?: string | null }> =
    useCallback(
      async (uri = '', description, name, type) => {
        const blob = await getBlob(uri)
        const compressedFile = type?.startsWith('audio')
          ? blob
          : // @ts-ignore
            await imageCompression(blob, options)
        const checksum = await calculateChecksum(compressedFile)

        const directUploadInput: DirectUploadInput = {
          filename: name ?? uri.split('/').pop() ?? '',
          byteSize: compressedFile.size,
          checksum,
          contentType: type ?? compressedFile.type,
        }

        const createDirectUploadResponse = await createDirectUpload({
          variables: {
            directUploadInput,
          },
        })

        const uploadInfo =
          createDirectUploadResponse.data?.createDirectUpload.directUpload

        if (!uploadInfo) {
          throw new Error('Create direct upload failed')
        }

        const uploadResponse = await fetch(uploadInfo.url, {
          method: 'PUT',
          headers: JSON.parse(uploadInfo.headers),
          body: compressedFile,
        })

        if (!uploadResponse.ok) {
          throw new Error('Direct upload failed')
        }

        return { fileBlobId: uploadInfo.signedBlobId, description }
      },
      [createDirectUpload],
    )

  return { uploadMedia }
}
