import React, { useCallback } from 'react'
import { View, FlatList, StyleSheet, ListRenderItem, Modal } from 'react-native'

import PickerModal from '../components/PickerModal'
import { PressableItem } from '../components/PressableItem'
import Separator from '../components/Separator'
import TitleText from '../components/Text/TitleText'
import TouchableSvg from '../components/TouchableSvg'
import {
  ProfileFormItemType,
  ProfileFormItemKeyType,
} from '../helpers/ProfileFormItems'
import appStyles from '../styles/app-styles'

const Divider = () => <Separator height={10} />

interface ItemPickerModalProps {
  visible: boolean
  title: string
  items: ProfileFormItemType[]
  selectedKey?: ProfileFormItemKeyType
  close: () => void
  setSelectedKey: (key: ProfileFormItemKeyType) => void
}

export const ItemPickerModal: React.FC<ItemPickerModalProps> = ({
  visible,
  title,
  items,
  selectedKey,
  close,
  setSelectedKey,
}) => {
  const renderItem: ListRenderItem<ProfileFormItemType> = useCallback(
    ({ item }) => (
      <PressableItem
        item={item}
        isSelected={item.key === selectedKey}
        onPress={() => {
          setSelectedKey(item.key)
          close()
        }}
      />
    ),
    [selectedKey, setSelectedKey, close],
  )

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={visible}
      animationType="fade"
      onRequestClose={() => {
        close()
      }}>
      <PickerModal close={() => close()}>
        <Separator height={18} />
        <View style={styles.titleRow}>
          <TitleText
            color="layout.dark"
            size="medium"
            style={appStyles.fullSize}>
            {title}
          </TitleText>
          <TouchableSvg
            name="close"
            color="layout.dark"
            onPress={() => close()}
          />
        </View>
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={item => item.key ?? ''}
          ItemSeparatorComponent={Divider}
          contentContainerStyle={styles.flatlistContentContainer}
        />
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  titleRow: {
    paddingHorizontal: 24,
    ...appStyles.inlineContainer,
  },
  flatlistContentContainer: {
    paddingTop: 37,
    paddingBottom: 56,
    paddingHorizontal: 24,
  },
})
