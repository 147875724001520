import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Svg, { Defs, Stop, LinearGradient, Rect } from 'react-native-svg'

import Colors from '../../../styles/Colors'

type GradientProps = {
  stops?: { offset: number; color: string; opacity: number }[]
}

const DEFAULT_STOPS = [
  { offset: 0, color: Colors['layout.black'], opacity: 0 },
  { offset: 0.6979, color: Colors['layout.black'], opacity: 1 },
]

const GradientBackground: React.FC<GradientProps> = ({
  stops = DEFAULT_STOPS,
}) => {
  const [layout, setLayout] = useState({ height: 0, width: 0 })

  return (
    <View
      style={StyleSheet.absoluteFill}
      onLayout={e => setLayout(e.nativeEvent.layout)}>
      <Svg height="100%" width="100%">
        <Defs>
          <LinearGradient
            id="Gradient"
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="0"
            x2="0"
            y2={layout.height}>
            {stops.map(({ offset, color, opacity }, index) => (
              <Stop
                key={index}
                offset={offset}
                stopColor={color}
                stopOpacity={opacity}
              />
            ))}
          </LinearGradient>
        </Defs>
        <Rect
          x="0"
          y="0"
          width={layout.width}
          height={layout.height}
          fill="url(#Gradient)"
        />
      </Svg>
    </View>
  )
}

export default GradientBackground
