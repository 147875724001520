import React from 'react'
import {
  View,
  Modal,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { MemoryTypeEnum } from '../api/types'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import TitleText from '../components/Text/TitleText'
import Typography from '../components/Text/Typography'
import TouchableSvg from '../components/TouchableSvg'
import MemoryTypeSvg from '../screens/timeline/components/MemoryTypeSvg'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface MemoryTypePickerModalProps {
  visible: boolean
  selected: MemoryTypeEnum | undefined
  close: () => void
  setSelected: (type: MemoryTypeEnum) => void
}

export const MemoryTypePickerModal: React.FC<MemoryTypePickerModalProps> = ({
  visible,
  selected,
  close,
  setSelected,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'modals.memoryTypePicker',
  })

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={visible}
      animationType="fade"
      onRequestClose={() => close()}>
      <PickerModal close={() => close()}>
        <Separator height={18} />
        <View style={styles.titleRow}>
          <TitleText
            size="medium"
            color="layout.dark"
            style={appStyles.fullSize}>
            {t('title')}
          </TitleText>
          <TouchableSvg
            name="close"
            color="layout.dark"
            onPress={() => close()}
          />
        </View>
        <ScrollView contentContainerStyle={styles.container}>
          {Object.values(MemoryTypeEnum).map((type, index) => (
            <TouchableOpacity
              key={type}
              style={[
                styles.item,
                type === selected && styles.selectedItem,
                index !== 0 && styles.divider,
              ]}
              onPress={() => {
                setSelected(type)
                close()
              }}>
              <View
                style={[
                  styles.iconHolder,
                  type === selected && styles.selectedIconHolder,
                ]}>
                <MemoryTypeSvg type={type} />
              </View>
              <Separator width={20} />
              <Typography weight="medium" style={styles.itemName}>
                {t(`memoryType.${type}`)}
              </Typography>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  titleRow: {
    paddingHorizontal: 24,
    ...appStyles.inlineContainer,
  },
  container: {
    paddingTop: 37,
    paddingBottom: 56,
    paddingHorizontal: 24,
  },
  item: {
    height: 80,
    borderRadius: 14,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  selectedItem: {
    backgroundColor: Colors['layout.selected'],
  },
  divider: { marginTop: 10 },
  iconHolder: {
    height: 40,
    width: 40,
    borderRadius: 20,
    ...appStyles.center,
    backgroundColor: Colors['layout.gray'],
  },
  selectedIconHolder: { backgroundColor: Colors['brand.action'] },
  itemName: {
    fontSize: 16,
  },
})
