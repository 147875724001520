import React, { ReactChild } from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import Typography from './Text/Typography'
import TouchableSvg from './TouchableSvg'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

const ModalHeader: React.FC<{
  title: string
  close: () => void
  leftItem?: ReactChild
}> = ({ title, close, leftItem }) => {
  const { webLayoutEnabled } = useWebLayout()

  return (
    <View style={[styles.header, webLayoutEnabled && styles.webPadding]}>
      {leftItem}
      <Typography
        weight="medium"
        style={[styles.title, webLayoutEnabled && styles.webTitle]}>
        {title}
      </Typography>
      <TouchableSvg name="close" color="layout.dark" onPress={() => close()} />
    </View>
  )
}

export default ModalHeader

const styles = StyleSheet.create({
  header: {
    paddingVertical: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
    paddingHorizontal: Platform.select({ ios: 10, default: 20 }),
  },
  webPadding: { paddingHorizontal: 30 },
  title: { fontSize: 26 },
  webTitle: { fontSize: 20 },
})
