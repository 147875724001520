import React, { useMemo } from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import ButtonText from '../../components/Text/ButtonText'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import WebModal from '../../components/WebModal'
import { useLocale } from '../../providers/LocaleProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function EventsQuizStartScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.globalEventsQuiz',
  })
  const { push, navigate } =
    useNavigation<MainStackNavigationType<'EventsQuizStart'>>()
  const { locale } = useLocale()

  const backgroundImage = useMemo(
    () =>
      locale === 'en'
        ? require('../../assets/globalEvents/quiz-start-en.png')
        : require('../../assets/globalEvents/quiz-start-de.png'),
    [locale],
  )

  return (
    <WebModal fullHeight>
      <ImageBackground
        resizeMode="cover"
        style={styles.background}
        source={backgroundImage}>
        <SafeAreaView style={styles.container}>
          <SvgIcon name="logo" style={styles.logo} />
          <View>
            <TitleText
              size="large"
              color="layout.white"
              style={styles.titleText}>
              {t('introTitle')}
            </TitleText>
            <Separator height={16} />
            <Typography style={styles.descriptionText}>
              {t('introText')}
            </Typography>
            <Separator height={44} />
            <Button text={t('start')} onPress={() => push('EventsQuiz')} />
            <Separator height={29} />
            <ButtonText
              size="medium"
              color="layout.white"
              style={styles.doLaterButton}
              onPress={() => navigate('Root')}>
              {t('doLater')}
            </ButtonText>
          </View>
        </SafeAreaView>
      </ImageBackground>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  background: { height: '100%', width: '100%' },
  container: {
    marginLeft: 24,
    marginRight: 35,
    ...appStyles.fullSize,
    justifyContent: 'space-between',
  },
  logo: { marginTop: 10, alignSelf: 'flex-end' },
  titleText: {
    fontSize: 40,
    paddingTop: 10,
    lineHeight: undefined, // clear TitleText style because non-standart fontSize
  },
  descriptionText: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors['layout.white'],
  },
  doLaterButton: { marginBottom: 20, textAlign: 'center' },
})
