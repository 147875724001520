import React from 'react'
import { Text, TextProps, StyleSheet, Platform } from 'react-native'

import Colors from '../../styles/Colors'
import FontSizes from '../../styles/FontSizes'
import FontWeights from '../../styles/FontWeights'

interface PropsType extends TextProps {
  size: 'medium' | 'large'
  color: 'layout.white' | 'layout.dark'
}

/**
 * Title text component.
 * Should be adjusted via the style prop as little as possible.
 */
const TitleText: React.FC<PropsType> = ({
  size,
  color,
  children,
  ...props
}: PropsType) => (
  <Text
    {...props}
    style={[
      styles[size],
      {
        fontFamily,
        color: Colors[color],
        fontWeight: FontWeights.bold,
        fontSize: FontSizes[`title.${size}`],
      },
      props.style,
    ]}>
    {children}
  </Text>
)

export default TitleText

const fontFamily = Platform.select({
  android: 'serif',
  ios: 'PTSerif-Bold',
  web: undefined, // TODO
})

const styles = StyleSheet.create({
  /* eslint-disable react-native/no-unused-styles */
  medium: {
    lineHeight: 34,
  },
  large: {
    lineHeight: 40,
  },
  /* eslint-enable react-native/no-unused-styles */
})
