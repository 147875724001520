import React, {
  useRef,
  useMemo,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react'
import { Platform } from 'react-native'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { useMMKVObject, MMKV } from 'react-native-mmkv'

import { useLocale } from './LocaleProvider'
import { useShouldRefetch, QueryKey } from './RefetchProvider'
import buildClient from '../api/apollo-client'
import { LifeStage, User, useGetUserQuery } from '../api/types'
import RestApi from '../core/RestApi'
import { OAuth } from '../types/user-types'

type UserContextType = {
  user: User | undefined
  isLoggedIn: boolean | undefined
  login: (params: { email: string; password: string }) => Promise<void>
  logOut: () => void
  resetPassword: (params: {
    password: string
    password_confirmation: string
    reset_password_token: string
  }) => Promise<void>
  confirmEmail: (params: { confirmation_token: string }) => Promise<void>
  sortedLifeStages: LifeStage[]
  getPublicClient: (params: {
    shareToken: string
  }) => ApolloClient<NormalizedCacheObject>
}

const UserContext = createContext<UserContextType>({
  user: undefined,
  isLoggedIn: undefined,
  login: () => Promise.reject(),
  logOut: () => {},
  resetPassword: () => Promise.reject(),
  confirmEmail: () => Promise.reject(),
  sortedLifeStages: [],
  getPublicClient: () => {
    throw new Error('No provider')
  },
})

const storage = new MMKV({
  id: 'user-store',
  encryptionKey: Platform.OS === 'web' ? undefined : 'XhwIjoxNjg4NTUyN',
})
const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [oAuth, setOAuth] = useMMKVObject<OAuth>('oauth', storage)

  const isLoggedIn = useMemo(() => !!oAuth, [oAuth])

  const { setLocale } = useLocale()

  const logOut = useCallback(() => {
    setOAuth(undefined)
  }, [setOAuth])

  const isRefreshingToken = useRef(false)
  const refreshToken = useCallback(
    (retryCounter: number = 0) => {
      if (isRefreshingToken.current && retryCounter === 0) return
      isRefreshingToken.current = true
      RestApi.post('users/sign_in', {
        refresh_token: oAuth?.refresh_token,
      })
        .then(freshAuth => {
          if (freshAuth) setOAuth(freshAuth)
        })
        .catch(() => {
          if (retryCounter < 3) return refreshToken(retryCounter + 1)
          else logOut()
        })
        .finally(() => {
          isRefreshingToken.current = false
        })
    },
    [logOut, oAuth?.refresh_token, setOAuth],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cache = useMemo(() => new InMemoryCache(), [oAuth?.token])

  const client = useMemo(
    () => buildClient(refreshToken, cache, oAuth),
    [cache, oAuth, refreshToken],
  )

  const getPublicClient: UserContextType['getPublicClient'] = useCallback(
    ({ shareToken }) => buildClient(refreshToken, cache, oAuth, shareToken),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [oAuth?.token, refreshToken],
  )

  const { data, refetch } = useGetUserQuery({ skip: !isLoggedIn, client })
  useShouldRefetch(QueryKey.User, refetch, undefined, !isLoggedIn)
  const user = useMemo(() => data?.me as User, [data])

  useEffect(() => {
    user?.language && setLocale(user.language)
  }, [user?.language, setLocale])

  const login: UserContextType['login'] = useCallback(
    ({ email, password }) =>
      RestApi.post('users/sign_in', {
        email,
        password,
      }).then(oauth => {
        setOAuth(oauth)
      }),
    [setOAuth],
  )

  const resetPassword: UserContextType['resetPassword'] = useCallback(
    ({ password, password_confirmation, reset_password_token }) =>
      RestApi.put('users/password', {
        password,
        password_confirmation,
        reset_password_token,
      }).then(setOAuth),
    [setOAuth],
  )

  const confirmEmail: UserContextType['confirmEmail'] = useCallback(
    ({ confirmation_token }) =>
      RestApi.post('users/confirmation', {
        confirmation_token,
      }).then(setOAuth),
    [setOAuth],
  )

  const sortedLifeStages = useMemo(() => {
    if (!user?.lifeStages?.nodes) return []

    return [...user.lifeStages.nodes].sort(
      (a, b) => (a.startYear ?? 0) - (b.startYear ?? 0),
    )
  }, [user?.lifeStages])

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          user,
          isLoggedIn,
          login,
          logOut,
          resetPassword,
          confirmEmail,
          sortedLifeStages,
          getPublicClient,
        }),
        [
          user,
          isLoggedIn,
          login,
          logOut,
          resetPassword,
          confirmEmail,
          sortedLifeStages,
          getPublicClient,
        ],
      )}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </UserContext.Provider>
  )
}

export default UserProvider

export function useUser() {
  return useContext(UserContext)
}
