import React, { useEffect, useRef } from 'react'
import { View, Animated, StyleSheet, TouchableOpacity } from 'react-native'

import Typography from './Text/Typography'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import FontSizes from '../styles/FontSizes'
import { convertHexToRGBA } from '../utils/colors'

interface ToggleButtonProps {
  value: boolean
  setValue: (value: boolean) => void
  firstOptionText: string
  secondOptionText: string
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  value,
  setValue,
  firstOptionText,
  secondOptionText,
}) => {
  const translation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(translation, {
      toValue: value ? 0 : 128,
      useNativeDriver: true,
      duration: 200,
    }).start()
  }, [value, translation])

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.activeIndicator,
          { transform: [{ translateX: translation }] },
        ]}
      />
      <TouchableOpacity style={styles.button} onPress={() => setValue(true)}>
        <Typography
          weight="medium"
          style={[styles.buttonText, !value && styles.buttonTextInactive]}>
          {firstOptionText}
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity style={styles.button} onPress={() => setValue(false)}>
        <Typography
          weight="medium"
          style={[styles.buttonText, value && styles.buttonTextInactive]}>
          {secondOptionText}
        </Typography>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 284,
    height: 59,
    borderRadius: 27,
    ...appStyles.row,
    alignSelf: 'center',
    marginHorizontal: 22,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.1),
  },
  activeIndicator: {
    left: 0,
    width: 156,
    height: 59,
    borderRadius: 27,
    position: 'absolute',
    backgroundColor: Colors['layout.white'],

    ...appStyles.lightShadow,
    shadowOpacity: 0.15,
  },
  button: {
    width: 142,
    height: 59,
    ...appStyles.center,
    ...appStyles.removedOutline,
  },
  buttonText: {
    fontSize: FontSizes['button.medium'],
  },
  buttonTextInactive: { opacity: 0.5 },
})
