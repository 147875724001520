import { FlatListProps } from 'react-native'

import { MEMORY_HEIGHT } from '../components/MemoryCard'
import { TimelineItemType } from '../TimelineScreen'

const BOTTOM_PADDING = 70
const STAGE_TOP_HEIGHT = 61
export const STAGE_BOTTOM_HEIGHT = 60
export const EMPTY_YEAR_H = 50
export const MEMORY_MARGIN = 8

export function getItemHeight({ content }: TimelineItemType) {
  if (typeof content === 'number') return EMPTY_YEAR_H
  if (content === 'bottom') return BOTTOM_PADDING
  if (content === 'top') return 0
  switch (content.__typename) {
    case 'LifeStageTop':
      return STAGE_TOP_HEIGHT
    case 'LifeStageBottom':
      return STAGE_BOTTOM_HEIGHT
    case 'TimelineMemory':
      return MEMORY_HEIGHT + MEMORY_MARGIN
  }
}

function sumHeights(items: TimelineItemType[]) {
  let sum = 0
  items.forEach(item => {
    sum += getItemHeight(item)
  })
  return sum
}

export const getItemLayout: FlatListProps<TimelineItemType>['getItemLayout'] = (
  items,
  index,
) => {
  if (!items?.length)
    return { index, length: EMPTY_YEAR_H, offset: index * EMPTY_YEAR_H }

  return {
    index,
    length: getItemHeight(items[index]),
    // @ts-ignore
    offset: sumHeights(items.slice(0, index)),
  }
}
