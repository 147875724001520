import React, { useEffect, useRef } from 'react'
import { View, StyleSheet, TouchableOpacity, Animated } from 'react-native'

import Picture from './Picture'
import Separator from './Separator'
import SvgIcon from './SvgIcon'
import useMediaUri from '../hooks/useMediaUri'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaThumbnailProps } from '../types/MediaThumbnail.types'

export const MediaThumbnailSeparator: React.FC = () => <Separator width={8} />

export const MediaThumbnail: React.FC<MediaThumbnailProps> = ({
  item,
  style,
  playIconSize,
  onPress,
  onLongPress,
}) => {
  const isVideo = item.contentType?.startsWith('video') ?? false
  const isAudio = item.contentType?.startsWith('audio') ?? false

  const mediaUri = useMediaUri(item.url ?? '', isVideo)

  const fade = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(fade, {
      toValue: 1,
      duration: 600,
      useNativeDriver: true,
    }).start()
  }, [fade])

  return (
    <Animated.View style={{ opacity: fade }}>
      <TouchableOpacity
        style={[styles.mediaPreview, style]}
        onPress={onPress}
        onLongPress={onLongPress}>
        {!isAudio && mediaUri && <Picture sourceUri={mediaUri} style={style} />}
        {isAudio && (
          <View style={[styles.audioThumbnail, style]}>
            <SvgIcon name="audio" height={46} width={46} />
          </View>
        )}
        {isVideo && (
          <View style={[styles.playIcon, style]}>
            <SvgIcon
              name="video-play"
              width={playIconSize}
              height={playIconSize}
            />
          </View>
        )}
      </TouchableOpacity>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  mediaPreview: {
    backgroundColor: Colors['layout.light'],
  },
  playIcon: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  audioThumbnail: {
    ...appStyles.center,
    backgroundColor: Colors['layout.gray'],
  },
})
