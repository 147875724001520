import React, { PropsWithChildren } from 'react'
import { View, ViewStyle } from 'react-native'

import WebInfoColumn from './WebInfoColumn'
import Drawer from '../navigation/Drawer'
import appStyles from '../styles/app-styles'

const RootContainer: React.FC<
  PropsWithChildren<{ contentContainerStyle?: ViewStyle }>
> = ({ children, contentContainerStyle }) => (
  <View style={[appStyles.fullSize, appStyles.row]}>
    <Drawer />
    <View style={[appStyles.fullSize, contentContainerStyle]}>{children}</View>
    <WebInfoColumn />
  </View>
)

export default RootContainer
