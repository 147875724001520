import React, { memo } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { GetPeopleQuery } from '../../../api/types'
import Avatar from '../../../components/Avatar'
import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import LabelText from '../../../components/Text/LabelText'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { MainStackNavigationType } from '../../../types/navigation-types'
import isEqual from '../../../utils/isEqual'

type PersonNode = GetPeopleQuery['people']['nodes'][0]
type PersonCardProps = {
  item: PersonNode
}

const PersonCard: React.FC<PersonCardProps> = ({
  item: {
    id,
    title,
    gender,
    lastName,
    relation,
    memories,
    firstName,
    pictureUrl,
  },
}) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<MainStackNavigationType<'Root'>>()

  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => navigate('PersonDetails', { personId: id })}>
      <Avatar size={56} gender={gender} uri={pictureUrl} />
      <Separator width={10} />
      <View style={appStyles.fullSize}>
        <Typography weight="medium" style={styles.nameText}>
          {!!title && `${t(`profile.personTitle.${title}`)} `}
          {`${firstName} ${lastName}`}
        </Typography>
        {!!relation && (
          <LabelText size="small">
            {t(`enums.personRelation.${relation}`)}
          </LabelText>
        )}
        <View style={appStyles.inlineContainer}>
          <SvgIcon name="notepad" opacity={0.5} />
          <Separator width={7} />
          <LabelText size="small">{memories?.totalCount}</LabelText>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default memo(
  PersonCard,
  (a, b) =>
    isEqual(
      ['id', 'firstName', 'lastName', 'gender', 'pictureUrl'],
      a.item,
      b.item,
    ) && a.item.memories?.totalCount === b.item.memories?.totalCount,
)

const styles = StyleSheet.create({
  item: {
    height: 97,
    borderRadius: 10,
    paddingHorizontal: 16,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  nameText: { fontSize: 17 },
})
