import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  ISO8601Date: any
  ISO8601DateTime: any
  JSON: any
}

export type Attachment = {
  __typename?: 'Attachment'
  audioFileAdded?: Maybe<Scalars['ISO8601Date']>
  blobId?: Maybe<Scalars['String']>
  blobSizeGb?: Maybe<Scalars['Float']>
  contentType?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['ISO8601Date']>
  dateAdded?: Maybe<Scalars['ISO8601Date']>
  description?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  signedBlobId?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['ID']>
}

export type AttachmentAttachable = {
  __typename?: 'AttachmentAttachable'
  attachableDescription?: Maybe<Scalars['String']>
  attachmentId?: Maybe<Scalars['ID']>
}

/** The connection type for AttachmentAttachable. */
export type AttachmentAttachableConnection = {
  __typename?: 'AttachmentAttachableConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttachmentAttachableEdge>>>
  /** A list of nodes. */
  nodes: Array<AttachmentAttachable>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type AttachmentAttachableEdge = {
  __typename?: 'AttachmentAttachableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: AttachmentAttachable
}

export type AttachmentAttachableInput = {
  attachableDescription?: InputMaybe<Scalars['String']>
  attachmentId: Scalars['ID']
}

/** The connection type for Attachment. */
export type AttachmentConnection = {
  __typename?: 'AttachmentConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttachmentEdge>>>
  /** A list of nodes. */
  nodes: Array<Attachment>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type AttachmentEdge = {
  __typename?: 'AttachmentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Attachment
}

export type AttachmentFilterInput = {
  fileBlob?: InputMaybe<FileFilterInput>
  memories?: InputMaybe<MemoryFilterInput>
  used?: InputMaybe<Scalars['Boolean']>
}

export type AttachmentInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>
  attachableDescription?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  fileBlobId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type Blob = {
  __typename?: 'Blob'
  directUpload: DirectUpload
}

export type CommonMemory = {
  __typename?: 'CommonMemory'
  attachments?: Maybe<AttachmentConnection>
  country?: Maybe<Array<UserCountryEnum>>
  description: Scalars['String']
  eventAt?: Maybe<Scalars['ISO8601Date']>
  id: Scalars['ID']
  pictureContentType?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  quizCategory: QuizCategory
  quizSubCategory?: Maybe<QuizSubCategory>
  title: Scalars['String']
}

export type CommonMemoryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for CommonMemory. */
export type CommonMemoryConnection = {
  __typename?: 'CommonMemoryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommonMemoryEdge>>>
  /** A list of nodes. */
  nodes: Array<CommonMemory>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type CommonMemoryEdge = {
  __typename?: 'CommonMemoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: CommonMemory
}

export type CommonMemoryFilterInput = {
  eventAt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtGt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtGte?: InputMaybe<Scalars['ISO8601Date']>
  eventAtLt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtLte?: InputMaybe<Scalars['ISO8601Date']>
  id?: InputMaybe<Array<Scalars['ID']>>
  not?: InputMaybe<Array<CommonMemoryFilterInput>>
}

export enum DatePrecisionEnum {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

export type DirectUpload = {
  __typename?: 'DirectUpload'
  blobId: Scalars['ID']
  headers: Scalars['JSON']
  signedBlobId: Scalars['ID']
  url: Scalars['String']
}

export type DirectUploadInput = {
  byteSize: Scalars['Int']
  checksum: Scalars['String']
  contentType: Scalars['String']
  filename: Scalars['String']
}

export type FileFilterInput = {
  contentType?: InputMaybe<Scalars['String']>
}

export type HostedPaymentPage = {
  __typename?: 'HostedPaymentPage'
  paymentPageUrl: Scalars['String']
}

export type HostedPaymentPageInput = {
  addonCode?: InputMaybe<Scalars['ID']>
  planCode?: InputMaybe<Scalars['ID']>
  subscriptionId?: InputMaybe<Scalars['ID']>
}

export type Library = {
  __typename?: 'Library'
  attachments?: Maybe<AttachmentConnection>
  id?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['ID']>
}

export type LibraryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<AttachmentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for Library. */
export type LibraryConnection = {
  __typename?: 'LibraryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryEdge>>>
  /** A list of nodes. */
  nodes: Array<Library>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type LibraryEdge = {
  __typename?: 'LibraryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Library
}

export type LifeStage = {
  __typename?: 'LifeStage'
  endYear?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  startYear?: Maybe<Scalars['Int']>
  title: Scalars['String']
}

/** The connection type for LifeStage. */
export type LifeStageConnection = {
  __typename?: 'LifeStageConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LifeStageEdge>>>
  /** A list of nodes. */
  nodes: Array<LifeStage>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type LifeStageEdge = {
  __typename?: 'LifeStageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: LifeStage
}

export type LifeStageInput = {
  endYear?: InputMaybe<Scalars['Int']>
  startYear: Scalars['Int']
  title: Scalars['String']
}

export type LoginToken = {
  __typename?: 'LoginToken'
  logInToken: Scalars['String']
}

export type Memory = {
  __typename?: 'Memory'
  attachmentAttachables?: Maybe<AttachmentAttachableConnection>
  attachments?: Maybe<AttachmentConnection>
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  eventAt?: Maybe<Scalars['ISO8601Date']>
  eventAtPrec?: Maybe<DatePrecisionEnum>
  id: Scalars['ID']
  isShared?: Maybe<Scalars['Boolean']>
  lifeStage?: Maybe<LifeStage>
  memoryBody?: Maybe<Scalars['String']>
  memoryTitle: Scalars['String']
  memoryType: MemoryTypeEnum
  people?: Maybe<PersonConnection>
  pictureContentType?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
}

export type MemoryAttachmentAttachablesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type MemoryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type MemoryPeopleArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for Memory. */
export type MemoryConnection = {
  __typename?: 'MemoryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MemoryEdge>>>
  /** A list of nodes. */
  nodes: Array<Memory>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type MemoryEdge = {
  __typename?: 'MemoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Memory
}

export type MemoryFilterInput = {
  eventAt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtGt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtGte?: InputMaybe<Scalars['ISO8601Date']>
  eventAtLt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtLte?: InputMaybe<Scalars['ISO8601Date']>
  id?: InputMaybe<Array<Scalars['ID']>>
  lifeStageId?: InputMaybe<Array<Scalars['ID']>>
  memoryType?: InputMaybe<Array<Scalars['String']>>
  not?: InputMaybe<Array<MemoryFilterInput>>
  people?: InputMaybe<PersonFilterInput>
}

export type MemoryInput = {
  eventAt?: InputMaybe<Scalars['ISO8601Date']>
  eventAtPrec?: InputMaybe<DatePrecisionEnum>
  isShared?: InputMaybe<Scalars['Boolean']>
  lifeStageId?: InputMaybe<Scalars['ID']>
  memoryBody?: InputMaybe<Scalars['String']>
  memoryTitle: Scalars['String']
  memoryType: MemoryTypeEnum
  pictureBlobId?: InputMaybe<Scalars['String']>
}

export enum MemoryTypeEnum {
  Birth = 'birth',
  Education = 'education',
  Location = 'location',
  Memory = 'memory',
  Relationship = 'relationship',
  Work = 'work',
}

export type Mutation = {
  __typename?: 'Mutation'
  batchCreateUserCategory: Array<UserCategory>
  batchCreateUserSubCategory: Array<UserSubCategory>
  createAttachment: Attachment
  createDirectUpload: Blob
  createLifeStage: LifeStage
  createLoginToken: LoginToken
  createMemory: Memory
  createPerson: Person
  createUserCategory: UserCategory
  createUserSubCategory: UserSubCategory
  deleteAttachment: Scalars['Boolean']
  deleteLifeStage: Scalars['Boolean']
  deleteMemory: Scalars['Boolean']
  deletePerson: Scalars['Boolean']
  deletePersonProfilePicture: Scalars['Boolean']
  deleteProfile: Scalars['Boolean']
  deleteProfilePicture: Scalars['Boolean']
  deleteUserCategory: Scalars['Boolean']
  deleteUserSubCategory: Scalars['Boolean']
  getHostedPaymentPage: HostedPaymentPage
  getHostedPaymentPageForAddon: HostedPaymentPage
  getHostedPaymentPageForUpgradeSubscription: HostedPaymentPage
  getShareToken: Share
  removeShareToken: Scalars['Boolean']
  unsubscribe: Scalars['Boolean']
  updateAttachment: Attachment
  updateLifeStage: LifeStage
  updateMemory: Memory
  updatePerson: Person
  updateProfile: User
}

export type MutationBatchCreateUserCategoryArgs = {
  input: Array<UserCategoryInput>
}

export type MutationBatchCreateUserSubCategoryArgs = {
  input: Array<UserSubCategoryInput>
}

export type MutationCreateAttachmentArgs = {
  input: AttachmentInput
}

export type MutationCreateDirectUploadArgs = {
  input: DirectUploadInput
}

export type MutationCreateLifeStageArgs = {
  input: LifeStageInput
}

export type MutationCreateLoginTokenArgs = {
  userId: Scalars['ID']
}

export type MutationCreateMemoryArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>
  input: MemoryInput
  peopleIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationCreatePersonArgs = {
  attachments?: InputMaybe<Array<AttachmentInput>>
  input: PersonInput
}

export type MutationCreateUserCategoryArgs = {
  input: UserCategoryInput
}

export type MutationCreateUserSubCategoryArgs = {
  input: UserSubCategoryInput
}

export type MutationDeleteAttachmentArgs = {
  id: Scalars['ID']
}

export type MutationDeleteLifeStageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteMemoryArgs = {
  id: Scalars['ID']
}

export type MutationDeletePersonArgs = {
  id: Scalars['ID']
}

export type MutationDeletePersonProfilePictureArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserCategoryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserSubCategoryArgs = {
  id: Scalars['ID']
}

export type MutationGetHostedPaymentPageArgs = {
  input: HostedPaymentPageInput
}

export type MutationGetHostedPaymentPageForAddonArgs = {
  input: HostedPaymentPageInput
}

export type MutationGetHostedPaymentPageForUpgradeSubscriptionArgs = {
  input: HostedPaymentPageInput
}

export type MutationUpdateAttachmentArgs = {
  id: Scalars['ID']
  input: AttachmentInput
}

export type MutationUpdateLifeStageArgs = {
  id: Scalars['ID']
  input: LifeStageInput
}

export type MutationUpdateMemoryArgs = {
  attachableDescription?: InputMaybe<Array<AttachmentAttachableInput>>
  attachments?: InputMaybe<Array<AttachmentInput>>
  id: Scalars['ID']
  input: MemoryInput
  peopleIds?: InputMaybe<Array<Scalars['ID']>>
}

export type MutationUpdatePersonArgs = {
  attachableDescription?: InputMaybe<Array<AttachmentAttachableInput>>
  attachments?: InputMaybe<Array<AttachmentInput>>
  id: Scalars['ID']
  input: PersonInput
}

export type MutationUpdateProfileArgs = {
  input: ProfileInput
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type Person = {
  __typename?: 'Person'
  attachmentAttachables?: Maybe<AttachmentAttachableConnection>
  attachments?: Maybe<AttachmentConnection>
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  dateOfDeath?: Maybe<Scalars['ISO8601Date']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  gender?: Maybe<UserGenderEnum>
  id: Scalars['ID']
  isShared?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  memories?: Maybe<MemoryConnection>
  pictureContentType?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  relation?: Maybe<PersonRelationEnum>
  story?: Maybe<Scalars['String']>
  title?: Maybe<UserTitleEnum>
  userId: Scalars['ID']
}

export type PersonAttachmentAttachablesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type PersonAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type PersonMemoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for Person. */
export type PersonConnection = {
  __typename?: 'PersonConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PersonEdge>>>
  /** A list of nodes. */
  nodes: Array<Person>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type PersonEdge = {
  __typename?: 'PersonEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Person
}

export type PersonFilterInput = {
  id?: InputMaybe<Scalars['ID']>
}

export type PersonInput = {
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']>
  dateOfDeath?: InputMaybe<Scalars['ISO8601Date']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<UserGenderEnum>
  id?: InputMaybe<Scalars['ID']>
  isShared?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  pictureBlobId?: InputMaybe<Scalars['String']>
  relation?: InputMaybe<PersonRelationEnum>
  story?: InputMaybe<Scalars['String']>
  title?: InputMaybe<UserTitleEnum>
}

export enum PersonRelationEnum {
  Aunt = 'aunt',
  Boyfriend = 'boyfriend',
  Brother = 'brother',
  Cousin = 'cousin',
  Daughter = 'daughter',
  Father = 'father',
  Friend = 'friend',
  Girlfriend = 'girlfriend',
  Granddaughter = 'granddaughter',
  Grandfather = 'grandfather',
  Grandmother = 'grandmother',
  Grandson = 'grandson',
  Husband = 'husband',
  Mother = 'mother',
  Partner = 'partner',
  Sister = 'sister',
  Son = 'son',
  Stepbrother = 'stepbrother',
  Stepdaughter = 'stepdaughter',
  Stepfather = 'stepfather',
  Stepmother = 'stepmother',
  Stepsister = 'stepsister',
  Stepson = 'stepson',
  Uncle = 'uncle',
  Wife = 'wife',
}

export type ProfileInput = {
  attachmentsAttributes?: InputMaybe<Array<AttachmentInput>>
  country?: InputMaybe<UserCountryEnum>
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']>
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<UserGenderEnum>
  language?: InputMaybe<UserLanguageEnum>
  lastName?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  pictureBlobId?: InputMaybe<Scalars['String']>
  title?: InputMaybe<UserTitleEnum>
}

export type Query = {
  __typename?: 'Query'
  commonMemories: CommonMemoryConnection
  commonMemory: CommonMemory
  libraries: LibraryConnection
  library: Library
  lifeStage: LifeStage
  lifeStages: LifeStageConnection
  me: User
  memories: MemoryConnection
  memory: Memory
  people: PersonConnection
  person: Person
  quizCategories: QuizCategoryConnection
  quizCategory: QuizCategory
  quizSubCategories: QuizSubCategoryConnection
  quizSubCategory: QuizSubCategory
  timelineEvent: TimelineEvent
  timelineEvents: TimelineEventConnection
  user: User
  users: UserConnection
  zohoAddon: ZohoAddon
  zohoAddons: ZohoAddonConnection
  zohoPlan: ZohoPlan
  zohoPlans: ZohoPlanConnection
}

export type QueryCommonMemoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<CommonMemoryFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryCommonMemoryArgs = {
  id: Scalars['ID']
}

export type QueryLibrariesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryLibraryArgs = {
  id: Scalars['ID']
}

export type QueryLifeStageArgs = {
  id: Scalars['ID']
}

export type QueryLifeStagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryMemoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<MemoryFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryMemoryArgs = {
  id: Scalars['ID']
}

export type QueryPeopleArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryPersonArgs = {
  id: Scalars['ID']
}

export type QueryQuizCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryQuizCategoryArgs = {
  id: Scalars['ID']
}

export type QueryQuizSubCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryQuizSubCategoryArgs = {
  id: Scalars['ID']
}

export type QueryTimelineEventArgs = {
  id: Scalars['ID']
}

export type QueryTimelineEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryZohoAddonArgs = {
  id: Scalars['ID']
}

export type QueryZohoAddonsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryZohoPlanArgs = {
  id: Scalars['ID']
}

export type QueryZohoPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  descending?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

export type QuizCategory = {
  __typename?: 'QuizCategory'
  id: Scalars['ID']
  question: Scalars['String']
  quizSubCategories?: Maybe<QuizSubCategoryConnection>
  tag: Scalars['String']
  translatedTitle: Scalars['String']
}

export type QuizCategoryQuizSubCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for QuizCategory. */
export type QuizCategoryConnection = {
  __typename?: 'QuizCategoryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuizCategoryEdge>>>
  /** A list of nodes. */
  nodes: Array<QuizCategory>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type QuizCategoryEdge = {
  __typename?: 'QuizCategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: QuizCategory
}

export type QuizSubCategory = {
  __typename?: 'QuizSubCategory'
  id: Scalars['ID']
  tag: Scalars['String']
  translatedTitle: Scalars['String']
}

/** The connection type for QuizSubCategory. */
export type QuizSubCategoryConnection = {
  __typename?: 'QuizSubCategoryConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuizSubCategoryEdge>>>
  /** A list of nodes. */
  nodes: Array<QuizSubCategory>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type QuizSubCategoryEdge = {
  __typename?: 'QuizSubCategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: QuizSubCategory
}

export type Share = {
  __typename?: 'Share'
  token: Scalars['String']
}

export type TimelineEvent = {
  __typename?: 'TimelineEvent'
  attachments?: Maybe<AttachmentConnection>
  description?: Maybe<Scalars['String']>
  eventAt?: Maybe<Scalars['ISO8601Date']>
  eventableId?: Maybe<Scalars['ID']>
  eventableType?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lifeStageId?: Maybe<Scalars['ID']>
  pictureContentType?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type TimelineEventAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for TimelineEvent. */
export type TimelineEventConnection = {
  __typename?: 'TimelineEventConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TimelineEventEdge>>>
  /** A list of nodes. */
  nodes: Array<TimelineEvent>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type TimelineEventEdge = {
  __typename?: 'TimelineEventEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: TimelineEvent
}

export type User = {
  __typename?: 'User'
  allocatedSpaceGb?: Maybe<Scalars['Float']>
  country?: Maybe<UserCountryEnum>
  currentAddonCode?: Maybe<Scalars['String']>
  currentPlanCode?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  gender?: Maybe<UserGenderEnum>
  id: Scalars['ID']
  language?: Maybe<UserLanguageEnum>
  lastName?: Maybe<Scalars['String']>
  libraryId?: Maybe<Scalars['ID']>
  lifeStages?: Maybe<LifeStageConnection>
  passive?: Maybe<Scalars['Boolean']>
  pendingReconfirmation: Scalars['Boolean']
  pictureContentType?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  quizCategories?: Maybe<QuizCategoryConnection>
  quizCompleted: Scalars['Boolean']
  quizSubCategories?: Maybe<QuizSubCategoryConnection>
  shareToken?: Maybe<Scalars['String']>
  title?: Maybe<UserTitleEnum>
  usedStorageGb?: Maybe<Scalars['Float']>
}

export type UserLifeStagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type UserQuizCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type UserQuizSubCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type UserCategory = {
  __typename?: 'UserCategory'
  id: Scalars['ID']
  quizCategory?: Maybe<QuizCategory>
}

export type UserCategoryInput = {
  quizCategoryId: Scalars['ID']
}

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>
  /** A list of nodes. */
  nodes: Array<User>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export enum UserCountryEnum {
  At = 'at',
  Ch = 'ch',
  De = 'de',
  Other = 'other',
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: User
}

export enum UserGenderEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

export enum UserLanguageEnum {
  De = 'de',
  En = 'en',
}

export type UserSubCategory = {
  __typename?: 'UserSubCategory'
  id: Scalars['ID']
  quizSubCategory?: Maybe<QuizSubCategory>
}

export type UserSubCategoryInput = {
  quizSubCategoryId: Scalars['ID']
}

export enum UserTitleEnum {
  Dr = 'dr',
  Prof = 'prof',
}

export type ZohoAddon = {
  __typename?: 'ZohoAddon'
  addonCode: Scalars['String']
  description?: Maybe<Scalars['String']>
  expiresInYears?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
}

/** The connection type for ZohoAddon. */
export type ZohoAddonConnection = {
  __typename?: 'ZohoAddonConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ZohoAddonEdge>>>
  /** A list of nodes. */
  nodes: Array<ZohoAddon>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ZohoAddonEdge = {
  __typename?: 'ZohoAddonEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: ZohoAddon
}

export type ZohoPlan = {
  __typename?: 'ZohoPlan'
  active?: Maybe<Scalars['Boolean']>
  allocatedSpace?: Maybe<Scalars['Float']>
  anual?: Maybe<Scalars['Boolean']>
  badgeImageContentType?: Maybe<Scalars['String']>
  badgeImageUrl?: Maybe<Scalars['String']>
  colorCode?: Maybe<Scalars['String']>
  currentlyPopular?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  planCode: Scalars['String']
  price?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  targetUsers?: Maybe<Scalars['String']>
  zohoAddons?: Maybe<ZohoAddonConnection>
}

export type ZohoPlanZohoAddonsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** The connection type for ZohoPlan. */
export type ZohoPlanConnection = {
  __typename?: 'ZohoPlanConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ZohoPlanEdge>>>
  /** A list of nodes. */
  nodes: Array<ZohoPlan>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ZohoPlanEdge = {
  __typename?: 'ZohoPlanEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: ZohoPlan
}

export type CreateDirectUploadMutationVariables = Exact<{
  directUploadInput: DirectUploadInput
}>

export type CreateDirectUploadMutation = {
  __typename?: 'Mutation'
  createDirectUpload: {
    __typename?: 'Blob'
    directUpload: {
      __typename?: 'DirectUpload'
      blobId: string
      signedBlobId: string
      url: string
      headers: any
    }
  }
}

export type CreateAttachmentMutationVariables = Exact<{
  input: AttachmentInput
}>

export type CreateAttachmentMutation = {
  __typename?: 'Mutation'
  createAttachment: {
    __typename?: 'Attachment'
    id: string
    url?: string | null
    contentType?: string | null
    signedBlobId?: string | null
    description?: string | null
    createdAt?: any | null
    blobSizeGb?: number | null
  }
}

export type UpdateAttachmentMutationVariables = Exact<{
  id: Scalars['ID']
  attachmentInput: AttachmentInput
}>

export type UpdateAttachmentMutation = {
  __typename?: 'Mutation'
  updateAttachment: {
    __typename?: 'Attachment'
    id: string
    url?: string | null
    contentType?: string | null
    signedBlobId?: string | null
    description?: string | null
    dateAdded?: any | null
    blobSizeGb?: number | null
  }
}

export type DeleteAttachmentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteAttachmentMutation = {
  __typename?: 'Mutation'
  deleteAttachment: boolean
}

export type GetLibraryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetLibraryQuery = {
  __typename?: 'Query'
  library: {
    __typename?: 'Library'
    id?: string | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
        createdAt?: any | null
        blobSizeGb?: number | null
      }>
    } | null
  }
}

export type UpdateLifeStageMutationVariables = Exact<{
  id: Scalars['ID']
  input: LifeStageInput
}>

export type UpdateLifeStageMutation = {
  __typename?: 'Mutation'
  updateLifeStage: {
    __typename?: 'LifeStage'
    id: string
    title: string
    endYear?: number | null
    startYear?: number | null
  }
}

export type GetMemoriesQueryVariables = Exact<{
  after: Scalars['String']
  first: Scalars['Int']
}>

export type GetMemoriesQuery = {
  __typename?: 'Query'
  memories: {
    __typename?: 'MemoryConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
    }
    nodes: Array<{
      __typename?: 'Memory'
      id: string
      eventAt?: any | null
      eventAtPrec?: DatePrecisionEnum | null
      memoryType: MemoryTypeEnum
      memoryTitle: string
      pictureUrl?: string | null
      lifeStage?: {
        __typename?: 'LifeStage'
        id: string
        title: string
        startYear?: number | null
      } | null
    }>
  }
}

export type GetOtherMemoriesQueryVariables = Exact<{
  after: Scalars['String']
  first: Scalars['Int']
}>

export type GetOtherMemoriesQuery = {
  __typename?: 'Query'
  memories: {
    __typename?: 'MemoryConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
    }
    nodes: Array<{
      __typename?: 'Memory'
      id: string
      createdAt?: any | null
      memoryType: MemoryTypeEnum
      eventAtPrec?: DatePrecisionEnum | null
      memoryTitle: string
      pictureUrl?: string | null
      lifeStage?: { __typename?: 'LifeStage'; id: string; title: string } | null
    }>
  }
}

export type CreateMemoryMutationVariables = Exact<{
  peopleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  input: MemoryInput
  attachments: Array<AttachmentInput> | AttachmentInput
  personCount: Scalars['Int']
}>

export type CreateMemoryMutation = {
  __typename?: 'Mutation'
  createMemory: {
    __typename?: 'Memory'
    id: string
    memoryType: MemoryTypeEnum
    memoryBody?: string | null
    memoryTitle: string
    createdAt?: any | null
    eventAt?: any | null
    eventAtPrec?: DatePrecisionEnum | null
    pictureUrl?: string | null
    lifeStage?: {
      __typename?: 'LifeStage'
      id: string
      title: string
      startYear?: number | null
    } | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      totalCount: number
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    people?: {
      __typename?: 'PersonConnection'
      totalCount: number
      nodes: Array<{
        __typename?: 'Person'
        id: string
        title?: UserTitleEnum | null
        gender?: UserGenderEnum | null
        relation?: PersonRelationEnum | null
        lastName?: string | null
        firstName: string
        pictureUrl?: string | null
        memories?: {
          __typename?: 'MemoryConnection'
          totalCount: number
        } | null
      }>
    } | null
  }
}

export type UpdateMemoryMutationVariables = Exact<{
  id: Scalars['ID']
  peopleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  input: MemoryInput
  attachments: Array<AttachmentInput> | AttachmentInput
  personCount: Scalars['Int']
  attachableDescription?: InputMaybe<
    Array<AttachmentAttachableInput> | AttachmentAttachableInput
  >
}>

export type UpdateMemoryMutation = {
  __typename?: 'Mutation'
  updateMemory: {
    __typename?: 'Memory'
    id: string
    memoryType: MemoryTypeEnum
    memoryBody?: string | null
    memoryTitle: string
    createdAt?: any | null
    eventAt?: any | null
    eventAtPrec?: DatePrecisionEnum | null
    pictureUrl?: string | null
    lifeStage?: {
      __typename?: 'LifeStage'
      id: string
      title: string
      startYear?: number | null
    } | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    people?: {
      __typename?: 'PersonConnection'
      totalCount: number
      nodes: Array<{
        __typename?: 'Person'
        id: string
        title?: UserTitleEnum | null
        gender?: UserGenderEnum | null
        relation?: PersonRelationEnum | null
        lastName?: string | null
        firstName: string
        pictureUrl?: string | null
        memories?: {
          __typename?: 'MemoryConnection'
          totalCount: number
        } | null
      }>
    } | null
  }
}

export type GetCommonMemoriesQueryVariables = Exact<{
  eventAfter: Scalars['ISO8601Date']
}>

export type GetCommonMemoriesQuery = {
  __typename?: 'Query'
  commonMemories: {
    __typename?: 'CommonMemoryConnection'
    nodes: Array<{
      __typename?: 'CommonMemory'
      id: string
      title: string
      eventAt?: any | null
      description: string
      pictureUrl?: string | null
    }>
  }
}

export type GetMemoryQueryVariables = Exact<{
  id: Scalars['ID']
  personCount: Scalars['Int']
}>

export type GetMemoryQuery = {
  __typename?: 'Query'
  memory: {
    __typename?: 'Memory'
    id: string
    memoryType: MemoryTypeEnum
    eventAt?: any | null
    eventAtPrec?: DatePrecisionEnum | null
    memoryTitle: string
    memoryBody?: string | null
    pictureUrl?: string | null
    lifeStage?: { __typename?: 'LifeStage'; id: string; title: string } | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    people?: {
      __typename?: 'PersonConnection'
      totalCount: number
      nodes: Array<{
        __typename?: 'Person'
        id: string
        gender?: UserGenderEnum | null
        pictureUrl?: string | null
      }>
    } | null
  }
}

export type GetSharedMemoryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetSharedMemoryQuery = {
  __typename?: 'Query'
  memory: {
    __typename?: 'Memory'
    id: string
    memoryType: MemoryTypeEnum
    eventAt?: any | null
    eventAtPrec?: DatePrecisionEnum | null
    memoryTitle: string
    memoryBody?: string | null
    pictureUrl?: string | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
  }
}

export type GetCommonMemoryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCommonMemoryQuery = {
  __typename?: 'Query'
  commonMemory: {
    __typename?: 'CommonMemory'
    id: string
    title: string
    eventAt?: any | null
    description: string
    pictureUrl?: string | null
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        description?: string | null
      }>
    } | null
    quizCategory: { __typename?: 'QuizCategory'; tag: string }
    quizSubCategory?: {
      __typename?: 'QuizSubCategory'
      translatedTitle: string
    } | null
  }
}

export type GetMemoryPeopleQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetMemoryPeopleQuery = {
  __typename?: 'Query'
  memory: {
    __typename?: 'Memory'
    id: string
    people?: {
      __typename?: 'PersonConnection'
      nodes: Array<{
        __typename?: 'Person'
        id: string
        title?: UserTitleEnum | null
        gender?: UserGenderEnum | null
        relation?: PersonRelationEnum | null
        lastName?: string | null
        firstName: string
        pictureUrl?: string | null
        memories?: {
          __typename?: 'MemoryConnection'
          totalCount: number
        } | null
      }>
    } | null
  }
}

export type DeleteMemoryMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteMemoryMutation = {
  __typename?: 'Mutation'
  deleteMemory: boolean
}

export type GetPeopleQueryVariables = Exact<{ [key: string]: never }>

export type GetPeopleQuery = {
  __typename?: 'Query'
  people: {
    __typename?: 'PersonConnection'
    nodes: Array<{
      __typename?: 'Person'
      id: string
      title?: UserTitleEnum | null
      gender?: UserGenderEnum | null
      relation?: PersonRelationEnum | null
      lastName?: string | null
      firstName: string
      pictureUrl?: string | null
      memories?: { __typename?: 'MemoryConnection'; totalCount: number } | null
    }>
  }
}

export type SearchPeopleQueryVariables = Exact<{
  search: Scalars['String']
}>

export type SearchPeopleQuery = {
  __typename?: 'Query'
  people: {
    __typename?: 'PersonConnection'
    nodes: Array<{
      __typename?: 'Person'
      id: string
      title?: UserTitleEnum | null
      gender?: UserGenderEnum | null
      lastName?: string | null
      firstName: string
      pictureUrl?: string | null
    }>
  }
}

export type GetPersonQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetPersonQuery = {
  __typename?: 'Query'
  person: {
    __typename?: 'Person'
    id: string
    dateOfBirth?: any | null
    dateOfDeath?: any | null
    firstName: string
    lastName?: string | null
    gender?: UserGenderEnum | null
    relation?: PersonRelationEnum | null
    title?: UserTitleEnum | null
    email?: string | null
    story?: string | null
    pictureUrl?: string | null
    userId: string
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    memories?: { __typename?: 'MemoryConnection'; totalCount: number } | null
  }
}

export type GetPersonMemoriesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetPersonMemoriesQuery = {
  __typename?: 'Query'
  person: {
    __typename?: 'Person'
    id: string
    memories?: {
      __typename?: 'MemoryConnection'
      nodes: Array<{
        __typename?: 'Memory'
        id: string
        eventAt?: any | null
        eventAtPrec?: DatePrecisionEnum | null
        memoryTitle: string
        memoryType: MemoryTypeEnum
        pictureUrl?: string | null
      }>
    } | null
  }
}

export type CreatePersonMutationVariables = Exact<{
  input: PersonInput
  attachments: Array<AttachmentInput> | AttachmentInput
}>

export type CreatePersonMutation = {
  __typename?: 'Mutation'
  createPerson: {
    __typename?: 'Person'
    id: string
    dateOfBirth?: any | null
    dateOfDeath?: any | null
    firstName: string
    lastName?: string | null
    gender?: UserGenderEnum | null
    relation?: PersonRelationEnum | null
    title?: UserTitleEnum | null
    email?: string | null
    story?: string | null
    pictureUrl?: string | null
    userId: string
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    memories?: { __typename?: 'MemoryConnection'; totalCount: number } | null
  }
}

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['ID']
  input: PersonInput
  attachments: Array<AttachmentInput> | AttachmentInput
  attachableDescription?: InputMaybe<
    Array<AttachmentAttachableInput> | AttachmentAttachableInput
  >
}>

export type UpdatePersonMutation = {
  __typename?: 'Mutation'
  updatePerson: {
    __typename?: 'Person'
    id: string
    dateOfBirth?: any | null
    dateOfDeath?: any | null
    firstName: string
    lastName?: string | null
    gender?: UserGenderEnum | null
    relation?: PersonRelationEnum | null
    title?: UserTitleEnum | null
    email?: string | null
    story?: string | null
    pictureUrl?: string | null
    userId: string
    attachments?: {
      __typename?: 'AttachmentConnection'
      nodes: Array<{
        __typename?: 'Attachment'
        id: string
        url?: string | null
        contentType?: string | null
        signedBlobId?: string | null
        description?: string | null
      }>
    } | null
    attachmentAttachables?: {
      __typename?: 'AttachmentAttachableConnection'
      nodes: Array<{
        __typename?: 'AttachmentAttachable'
        attachmentId?: string | null
        attachableDescription?: string | null
      }>
    } | null
    memories?: { __typename?: 'MemoryConnection'; totalCount: number } | null
  }
}

export type DeletePersonMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePersonMutation = {
  __typename?: 'Mutation'
  deletePerson: boolean
}

export type DeletePersonPictureMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePersonPictureMutation = {
  __typename?: 'Mutation'
  deletePersonProfilePicture: boolean
}

export type GetQuizCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetQuizCategoriesQuery = {
  __typename?: 'Query'
  quizCategories: {
    __typename?: 'QuizCategoryConnection'
    nodes: Array<{
      __typename?: 'QuizCategory'
      id: string
      tag: string
      translatedTitle: string
      quizSubCategories?: {
        __typename?: 'QuizSubCategoryConnection'
        nodes: Array<{
          __typename?: 'QuizSubCategory'
          id: string
          tag: string
          translatedTitle: string
        }>
      } | null
    }>
  }
}

export type CreateUserCategoriesMutationVariables = Exact<{
  quizCategories: Array<UserCategoryInput> | UserCategoryInput
}>

export type CreateUserCategoriesMutation = {
  __typename?: 'Mutation'
  batchCreateUserCategory: Array<{ __typename?: 'UserCategory'; id: string }>
}

export type CreateUserSubcategoriesMutationVariables = Exact<{
  quizSubcategories: Array<UserSubCategoryInput> | UserSubCategoryInput
}>

export type CreateUserSubcategoriesMutation = {
  __typename?: 'Mutation'
  batchCreateUserSubCategory: Array<{
    __typename?: 'UserSubCategory'
    id: string
  }>
}

export type CreateUserCategoryMutationVariables = Exact<{
  userCategory: UserCategoryInput
}>

export type CreateUserCategoryMutation = {
  __typename?: 'Mutation'
  createUserCategory: { __typename?: 'UserCategory'; id: string }
}

export type CreateUserSubcategoryMutationVariables = Exact<{
  userSubcategory: UserSubCategoryInput
}>

export type CreateUserSubcategoryMutation = {
  __typename?: 'Mutation'
  createUserSubCategory: { __typename?: 'UserSubCategory'; id: string }
}

export type DeleteUserCategoryMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteUserCategoryMutation = {
  __typename?: 'Mutation'
  deleteUserCategory: boolean
}

export type DeleteUserSubCategoryMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteUserSubCategoryMutation = {
  __typename?: 'Mutation'
  deleteUserSubCategory: boolean
}

export type GetShareTokenMutationVariables = Exact<{ [key: string]: never }>

export type GetShareTokenMutation = {
  __typename?: 'Mutation'
  getShareToken: { __typename?: 'Share'; token: string }
}

export type RemoveShareTokenMutationVariables = Exact<{ [key: string]: never }>

export type RemoveShareTokenMutation = {
  __typename?: 'Mutation'
  removeShareToken: boolean
}

export type GetSubscriptionPlansQueryVariables = Exact<{ [key: string]: never }>

export type GetSubscriptionPlansQuery = {
  __typename?: 'Query'
  zohoPlans: {
    __typename?: 'ZohoPlanConnection'
    nodes: Array<{
      __typename?: 'ZohoPlan'
      allocatedSpace?: number | null
      anual?: boolean | null
      badgeImageUrl?: string | null
      colorCode?: string | null
      currentlyPopular?: boolean | null
      description?: string | null
      id: string
      name?: string | null
      planCode: string
      price?: number | null
      targetUsers?: string | null
      zohoAddons?: {
        __typename?: 'ZohoAddonConnection'
        nodes: Array<{
          __typename?: 'ZohoAddon'
          addonCode: string
          expiresInYears?: number | null
          id: string
          price?: number | null
        }>
      } | null
    }>
  }
}

export type GetPlanPaymentPageMutationVariables = Exact<{
  input: HostedPaymentPageInput
}>

export type GetPlanPaymentPageMutation = {
  __typename?: 'Mutation'
  getHostedPaymentPage: {
    __typename?: 'HostedPaymentPage'
    paymentPageUrl: string
  }
}

export type GetAddonPaymentPageMutationVariables = Exact<{
  input: HostedPaymentPageInput
}>

export type GetAddonPaymentPageMutation = {
  __typename?: 'Mutation'
  getHostedPaymentPageForAddon: {
    __typename?: 'HostedPaymentPage'
    paymentPageUrl: string
  }
}

export type UnsubscribeMutationVariables = Exact<{ [key: string]: never }>

export type UnsubscribeMutation = {
  __typename?: 'Mutation'
  unsubscribe: boolean
}

export type GetUserQueryVariables = Exact<{ [key: string]: never }>

export type GetUserQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    email: string
    title?: UserTitleEnum | null
    gender?: UserGenderEnum | null
    country?: UserCountryEnum | null
    language?: UserLanguageEnum | null
    lastName?: string | null
    firstName?: string | null
    pictureUrl?: string | null
    dateOfBirth?: any | null
    pictureContentType?: string | null
    quizCompleted: boolean
    libraryId?: string | null
    lifeStages?: {
      __typename?: 'LifeStageConnection'
      nodes: Array<{
        __typename?: 'LifeStage'
        id: string
        title: string
        endYear?: number | null
        startYear?: number | null
      }>
    } | null
    quizCategories?: {
      __typename?: 'QuizCategoryConnection'
      nodes: Array<{ __typename?: 'QuizCategory'; id: string }>
    } | null
    quizSubCategories?: {
      __typename?: 'QuizSubCategoryConnection'
      nodes: Array<{ __typename?: 'QuizSubCategory'; id: string }>
    } | null
  }
}

export type UpdateUserMutationVariables = Exact<{
  profileInput: ProfileInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateProfile: {
    __typename?: 'User'
    id: string
    email: string
    title?: UserTitleEnum | null
    gender?: UserGenderEnum | null
    country?: UserCountryEnum | null
    lastName?: string | null
    firstName?: string | null
    pictureUrl?: string | null
    dateOfBirth?: any | null
    pictureContentType?: string | null
  }
}

export type DeleteProfilePictureMutationVariables = Exact<{
  [key: string]: never
}>

export type DeleteProfilePictureMutation = {
  __typename?: 'Mutation'
  deleteProfilePicture: boolean
}

export type GetUserStorageQueryVariables = Exact<{ [key: string]: never }>

export type GetUserStorageQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    usedStorageGb?: number | null
    allocatedSpaceGb?: number | null
  }
}

export type GetCurrentPlanQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentPlanQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    currentPlanCode?: string | null
    currentAddonCode?: string | null
  }
}

export type GetMemoryCreationRestrictionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMemoryCreationRestrictionsQuery = {
  __typename?: 'Query'
  me: { __typename?: 'User'; id: string; passive?: boolean | null }
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutation = {
  __typename?: 'Mutation'
  deleteProfile: boolean
}

export const CreateDirectUploadDocument = gql`
  mutation createDirectUpload($directUploadInput: DirectUploadInput!) {
    createDirectUpload(input: $directUploadInput) {
      directUpload {
        blobId
        signedBlobId
        url
        headers
      }
    }
  }
`
export type CreateDirectUploadMutationFn = Apollo.MutationFunction<
  CreateDirectUploadMutation,
  CreateDirectUploadMutationVariables
>

/**
 * __useCreateDirectUploadMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadMutation, { data, loading, error }] = useCreateDirectUploadMutation({
 *   variables: {
 *      directUploadInput: // value for 'directUploadInput'
 *   },
 * });
 */
export function useCreateDirectUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDirectUploadMutation,
    CreateDirectUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDirectUploadMutation,
    CreateDirectUploadMutationVariables
  >(CreateDirectUploadDocument, options)
}
export type CreateDirectUploadMutationHookResult = ReturnType<
  typeof useCreateDirectUploadMutation
>
export type CreateDirectUploadMutationResult =
  Apollo.MutationResult<CreateDirectUploadMutation>
export type CreateDirectUploadMutationOptions = Apollo.BaseMutationOptions<
  CreateDirectUploadMutation,
  CreateDirectUploadMutationVariables
>
export const CreateAttachmentDocument = gql`
  mutation createAttachment($input: AttachmentInput!) {
    createAttachment(input: $input) {
      id
      url
      contentType
      signedBlobId
      description
      createdAt
      blobSizeGb
    }
  }
`
export type CreateAttachmentMutationFn = Apollo.MutationFunction<
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables
>

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachmentMutation,
    CreateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAttachmentMutation,
    CreateAttachmentMutationVariables
  >(CreateAttachmentDocument, options)
}
export type CreateAttachmentMutationHookResult = ReturnType<
  typeof useCreateAttachmentMutation
>
export type CreateAttachmentMutationResult =
  Apollo.MutationResult<CreateAttachmentMutation>
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables
>
export const UpdateAttachmentDocument = gql`
  mutation updateAttachment($id: ID!, $attachmentInput: AttachmentInput!) {
    updateAttachment(id: $id, input: $attachmentInput) {
      id
      url
      contentType
      signedBlobId
      description
      dateAdded
      blobSizeGb
    }
  }
`
export type UpdateAttachmentMutationFn = Apollo.MutationFunction<
  UpdateAttachmentMutation,
  UpdateAttachmentMutationVariables
>

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attachmentInput: // value for 'attachmentInput'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAttachmentMutation,
    UpdateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAttachmentMutation,
    UpdateAttachmentMutationVariables
  >(UpdateAttachmentDocument, options)
}
export type UpdateAttachmentMutationHookResult = ReturnType<
  typeof useUpdateAttachmentMutation
>
export type UpdateAttachmentMutationResult =
  Apollo.MutationResult<UpdateAttachmentMutation>
export type UpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAttachmentMutation,
  UpdateAttachmentMutationVariables
>
export const DeleteAttachmentDocument = gql`
  mutation deleteAttachment($id: ID!) {
    deleteAttachment(id: $id)
  }
`
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >(DeleteAttachmentDocument, options)
}
export type DeleteAttachmentMutationHookResult = ReturnType<
  typeof useDeleteAttachmentMutation
>
export type DeleteAttachmentMutationResult =
  Apollo.MutationResult<DeleteAttachmentMutation>
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>
export const GetLibraryDocument = gql`
  query getLibrary($id: ID!) {
    library(id: $id) {
      id
      attachments(descending: true) {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
          createdAt
          blobSizeGb
        }
      }
    }
  }
`

/**
 * __useGetLibraryQuery__
 *
 * To run a query within a React component, call `useGetLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLibraryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLibraryQuery,
    GetLibraryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLibraryQuery, GetLibraryQueryVariables>(
    GetLibraryDocument,
    options,
  )
}
export function useGetLibraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLibraryQuery,
    GetLibraryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLibraryQuery, GetLibraryQueryVariables>(
    GetLibraryDocument,
    options,
  )
}
export type GetLibraryQueryHookResult = ReturnType<typeof useGetLibraryQuery>
export type GetLibraryLazyQueryHookResult = ReturnType<
  typeof useGetLibraryLazyQuery
>
export type GetLibraryQueryResult = Apollo.QueryResult<
  GetLibraryQuery,
  GetLibraryQueryVariables
>
export const UpdateLifeStageDocument = gql`
  mutation updateLifeStage($id: ID!, $input: LifeStageInput!) {
    updateLifeStage(id: $id, input: $input) {
      id
      title
      endYear
      startYear
    }
  }
`
export type UpdateLifeStageMutationFn = Apollo.MutationFunction<
  UpdateLifeStageMutation,
  UpdateLifeStageMutationVariables
>

/**
 * __useUpdateLifeStageMutation__
 *
 * To run a mutation, you first call `useUpdateLifeStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLifeStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLifeStageMutation, { data, loading, error }] = useUpdateLifeStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLifeStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLifeStageMutation,
    UpdateLifeStageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLifeStageMutation,
    UpdateLifeStageMutationVariables
  >(UpdateLifeStageDocument, options)
}
export type UpdateLifeStageMutationHookResult = ReturnType<
  typeof useUpdateLifeStageMutation
>
export type UpdateLifeStageMutationResult =
  Apollo.MutationResult<UpdateLifeStageMutation>
export type UpdateLifeStageMutationOptions = Apollo.BaseMutationOptions<
  UpdateLifeStageMutation,
  UpdateLifeStageMutationVariables
>
export const GetMemoriesDocument = gql`
  query getMemories($after: String!, $first: Int!) {
    memories(after: $after, first: $first, order: "event_at") {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        eventAt
        eventAtPrec
        memoryType
        memoryTitle
        pictureUrl
        lifeStage {
          id
          title
          startYear
        }
      }
    }
  }
`

/**
 * __useGetMemoriesQuery__
 *
 * To run a query within a React component, call `useGetMemoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetMemoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemoriesQuery,
    GetMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMemoriesQuery, GetMemoriesQueryVariables>(
    GetMemoriesDocument,
    options,
  )
}
export function useGetMemoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemoriesQuery,
    GetMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMemoriesQuery, GetMemoriesQueryVariables>(
    GetMemoriesDocument,
    options,
  )
}
export type GetMemoriesQueryHookResult = ReturnType<typeof useGetMemoriesQuery>
export type GetMemoriesLazyQueryHookResult = ReturnType<
  typeof useGetMemoriesLazyQuery
>
export type GetMemoriesQueryResult = Apollo.QueryResult<
  GetMemoriesQuery,
  GetMemoriesQueryVariables
>
export const GetOtherMemoriesDocument = gql`
  query getOtherMemories($after: String!, $first: Int!) {
    memories(
      after: $after
      first: $first
      order: "created_at"
      filter: { eventAt: null, lifeStageId: null }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        createdAt
        memoryType
        eventAtPrec
        memoryTitle
        pictureUrl
        lifeStage {
          id
          title
        }
      }
    }
  }
`

/**
 * __useGetOtherMemoriesQuery__
 *
 * To run a query within a React component, call `useGetOtherMemoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherMemoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherMemoriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetOtherMemoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOtherMemoriesQuery,
    GetOtherMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOtherMemoriesQuery, GetOtherMemoriesQueryVariables>(
    GetOtherMemoriesDocument,
    options,
  )
}
export function useGetOtherMemoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOtherMemoriesQuery,
    GetOtherMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOtherMemoriesQuery,
    GetOtherMemoriesQueryVariables
  >(GetOtherMemoriesDocument, options)
}
export type GetOtherMemoriesQueryHookResult = ReturnType<
  typeof useGetOtherMemoriesQuery
>
export type GetOtherMemoriesLazyQueryHookResult = ReturnType<
  typeof useGetOtherMemoriesLazyQuery
>
export type GetOtherMemoriesQueryResult = Apollo.QueryResult<
  GetOtherMemoriesQuery,
  GetOtherMemoriesQueryVariables
>
export const CreateMemoryDocument = gql`
  mutation createMemory(
    $peopleIds: [ID!]
    $input: MemoryInput!
    $attachments: [AttachmentInput!]!
    $personCount: Int!
  ) {
    createMemory(
      input: $input
      peopleIds: $peopleIds
      attachments: $attachments
    ) {
      id
      lifeStage {
        id
        title
        startYear
      }
      memoryType
      memoryBody
      memoryTitle
      createdAt
      eventAt
      eventAtPrec
      pictureUrl
      attachments {
        totalCount
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      people(first: $personCount, after: "") {
        totalCount
        nodes {
          id
          title
          gender
          relation
          lastName
          firstName
          pictureUrl
          memories {
            totalCount
          }
        }
      }
    }
  }
`
export type CreateMemoryMutationFn = Apollo.MutationFunction<
  CreateMemoryMutation,
  CreateMemoryMutationVariables
>

/**
 * __useCreateMemoryMutation__
 *
 * To run a mutation, you first call `useCreateMemoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemoryMutation, { data, loading, error }] = useCreateMemoryMutation({
 *   variables: {
 *      peopleIds: // value for 'peopleIds'
 *      input: // value for 'input'
 *      attachments: // value for 'attachments'
 *      personCount: // value for 'personCount'
 *   },
 * });
 */
export function useCreateMemoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemoryMutation,
    CreateMemoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMemoryMutation,
    CreateMemoryMutationVariables
  >(CreateMemoryDocument, options)
}
export type CreateMemoryMutationHookResult = ReturnType<
  typeof useCreateMemoryMutation
>
export type CreateMemoryMutationResult =
  Apollo.MutationResult<CreateMemoryMutation>
export type CreateMemoryMutationOptions = Apollo.BaseMutationOptions<
  CreateMemoryMutation,
  CreateMemoryMutationVariables
>
export const UpdateMemoryDocument = gql`
  mutation updateMemory(
    $id: ID!
    $peopleIds: [ID!]
    $input: MemoryInput!
    $attachments: [AttachmentInput!]!
    $personCount: Int!
    $attachableDescription: [AttachmentAttachableInput!]
  ) {
    updateMemory(
      id: $id
      input: $input
      peopleIds: $peopleIds
      attachments: $attachments
      attachableDescription: $attachableDescription
    ) {
      id
      lifeStage {
        id
        title
        startYear
      }
      memoryType
      memoryBody
      memoryTitle
      createdAt
      eventAt
      eventAtPrec
      pictureUrl
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      people(first: $personCount, after: "") {
        totalCount
        nodes {
          id
          title
          gender
          relation
          lastName
          firstName
          pictureUrl
          memories {
            totalCount
          }
        }
      }
    }
  }
`
export type UpdateMemoryMutationFn = Apollo.MutationFunction<
  UpdateMemoryMutation,
  UpdateMemoryMutationVariables
>

/**
 * __useUpdateMemoryMutation__
 *
 * To run a mutation, you first call `useUpdateMemoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemoryMutation, { data, loading, error }] = useUpdateMemoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      peopleIds: // value for 'peopleIds'
 *      input: // value for 'input'
 *      attachments: // value for 'attachments'
 *      personCount: // value for 'personCount'
 *      attachableDescription: // value for 'attachableDescription'
 *   },
 * });
 */
export function useUpdateMemoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemoryMutation,
    UpdateMemoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMemoryMutation,
    UpdateMemoryMutationVariables
  >(UpdateMemoryDocument, options)
}
export type UpdateMemoryMutationHookResult = ReturnType<
  typeof useUpdateMemoryMutation
>
export type UpdateMemoryMutationResult =
  Apollo.MutationResult<UpdateMemoryMutation>
export type UpdateMemoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemoryMutation,
  UpdateMemoryMutationVariables
>
export const GetCommonMemoriesDocument = gql`
  query getCommonMemories($eventAfter: ISO8601Date!) {
    commonMemories(order: "event_at", filter: { eventAtGte: $eventAfter }) {
      nodes {
        id
        title
        eventAt
        description
        pictureUrl
      }
    }
  }
`

/**
 * __useGetCommonMemoriesQuery__
 *
 * To run a query within a React component, call `useGetCommonMemoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonMemoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonMemoriesQuery({
 *   variables: {
 *      eventAfter: // value for 'eventAfter'
 *   },
 * });
 */
export function useGetCommonMemoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommonMemoriesQuery,
    GetCommonMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCommonMemoriesQuery,
    GetCommonMemoriesQueryVariables
  >(GetCommonMemoriesDocument, options)
}
export function useGetCommonMemoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommonMemoriesQuery,
    GetCommonMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCommonMemoriesQuery,
    GetCommonMemoriesQueryVariables
  >(GetCommonMemoriesDocument, options)
}
export type GetCommonMemoriesQueryHookResult = ReturnType<
  typeof useGetCommonMemoriesQuery
>
export type GetCommonMemoriesLazyQueryHookResult = ReturnType<
  typeof useGetCommonMemoriesLazyQuery
>
export type GetCommonMemoriesQueryResult = Apollo.QueryResult<
  GetCommonMemoriesQuery,
  GetCommonMemoriesQueryVariables
>
export const GetMemoryDocument = gql`
  query getMemory($id: ID!, $personCount: Int!) {
    memory(id: $id) {
      id
      lifeStage {
        id
        title
      }
      memoryType
      eventAt
      eventAtPrec
      memoryTitle
      memoryBody
      pictureUrl
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      people(first: $personCount, after: "") {
        totalCount
        nodes {
          id
          gender
          pictureUrl
        }
      }
    }
  }
`

/**
 * __useGetMemoryQuery__
 *
 * To run a query within a React component, call `useGetMemoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      personCount: // value for 'personCount'
 *   },
 * });
 */
export function useGetMemoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetMemoryQuery, GetMemoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMemoryQuery, GetMemoryQueryVariables>(
    GetMemoryDocument,
    options,
  )
}
export function useGetMemoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemoryQuery,
    GetMemoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMemoryQuery, GetMemoryQueryVariables>(
    GetMemoryDocument,
    options,
  )
}
export type GetMemoryQueryHookResult = ReturnType<typeof useGetMemoryQuery>
export type GetMemoryLazyQueryHookResult = ReturnType<
  typeof useGetMemoryLazyQuery
>
export type GetMemoryQueryResult = Apollo.QueryResult<
  GetMemoryQuery,
  GetMemoryQueryVariables
>
export const GetSharedMemoryDocument = gql`
  query getSharedMemory($id: ID!) {
    memory(id: $id) {
      id
      memoryType
      eventAt
      eventAtPrec
      memoryTitle
      memoryBody
      pictureUrl
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
    }
  }
`

/**
 * __useGetSharedMemoryQuery__
 *
 * To run a query within a React component, call `useGetSharedMemoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedMemoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedMemoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSharedMemoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSharedMemoryQuery,
    GetSharedMemoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSharedMemoryQuery, GetSharedMemoryQueryVariables>(
    GetSharedMemoryDocument,
    options,
  )
}
export function useGetSharedMemoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSharedMemoryQuery,
    GetSharedMemoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSharedMemoryQuery,
    GetSharedMemoryQueryVariables
  >(GetSharedMemoryDocument, options)
}
export type GetSharedMemoryQueryHookResult = ReturnType<
  typeof useGetSharedMemoryQuery
>
export type GetSharedMemoryLazyQueryHookResult = ReturnType<
  typeof useGetSharedMemoryLazyQuery
>
export type GetSharedMemoryQueryResult = Apollo.QueryResult<
  GetSharedMemoryQuery,
  GetSharedMemoryQueryVariables
>
export const GetCommonMemoryDocument = gql`
  query getCommonMemory($id: ID!) {
    commonMemory(id: $id) {
      id
      title
      eventAt
      description
      pictureUrl
      attachments {
        nodes {
          id
          url
          contentType
          description
        }
      }
      quizCategory {
        tag
      }
      quizSubCategory {
        translatedTitle
      }
    }
  }
`

/**
 * __useGetCommonMemoryQuery__
 *
 * To run a query within a React component, call `useGetCommonMemoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonMemoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonMemoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommonMemoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommonMemoryQuery,
    GetCommonMemoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommonMemoryQuery, GetCommonMemoryQueryVariables>(
    GetCommonMemoryDocument,
    options,
  )
}
export function useGetCommonMemoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommonMemoryQuery,
    GetCommonMemoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCommonMemoryQuery,
    GetCommonMemoryQueryVariables
  >(GetCommonMemoryDocument, options)
}
export type GetCommonMemoryQueryHookResult = ReturnType<
  typeof useGetCommonMemoryQuery
>
export type GetCommonMemoryLazyQueryHookResult = ReturnType<
  typeof useGetCommonMemoryLazyQuery
>
export type GetCommonMemoryQueryResult = Apollo.QueryResult<
  GetCommonMemoryQuery,
  GetCommonMemoryQueryVariables
>
export const GetMemoryPeopleDocument = gql`
  query getMemoryPeople($id: ID!) {
    memory(id: $id) {
      id
      people {
        nodes {
          id
          title
          gender
          relation
          lastName
          firstName
          pictureUrl
          memories {
            totalCount
          }
        }
      }
    }
  }
`

/**
 * __useGetMemoryPeopleQuery__
 *
 * To run a query within a React component, call `useGetMemoryPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoryPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoryPeopleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemoryPeopleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemoryPeopleQuery,
    GetMemoryPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMemoryPeopleQuery, GetMemoryPeopleQueryVariables>(
    GetMemoryPeopleDocument,
    options,
  )
}
export function useGetMemoryPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemoryPeopleQuery,
    GetMemoryPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMemoryPeopleQuery,
    GetMemoryPeopleQueryVariables
  >(GetMemoryPeopleDocument, options)
}
export type GetMemoryPeopleQueryHookResult = ReturnType<
  typeof useGetMemoryPeopleQuery
>
export type GetMemoryPeopleLazyQueryHookResult = ReturnType<
  typeof useGetMemoryPeopleLazyQuery
>
export type GetMemoryPeopleQueryResult = Apollo.QueryResult<
  GetMemoryPeopleQuery,
  GetMemoryPeopleQueryVariables
>
export const DeleteMemoryDocument = gql`
  mutation deleteMemory($id: ID!) {
    deleteMemory(id: $id)
  }
`
export type DeleteMemoryMutationFn = Apollo.MutationFunction<
  DeleteMemoryMutation,
  DeleteMemoryMutationVariables
>

/**
 * __useDeleteMemoryMutation__
 *
 * To run a mutation, you first call `useDeleteMemoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemoryMutation, { data, loading, error }] = useDeleteMemoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemoryMutation,
    DeleteMemoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMemoryMutation,
    DeleteMemoryMutationVariables
  >(DeleteMemoryDocument, options)
}
export type DeleteMemoryMutationHookResult = ReturnType<
  typeof useDeleteMemoryMutation
>
export type DeleteMemoryMutationResult =
  Apollo.MutationResult<DeleteMemoryMutation>
export type DeleteMemoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemoryMutation,
  DeleteMemoryMutationVariables
>
export const GetPeopleDocument = gql`
  query getPeople {
    people {
      nodes {
        id
        title
        gender
        relation
        lastName
        firstName
        pictureUrl
        memories {
          totalCount
        }
      }
    }
  }
`

/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPeopleQuery,
    GetPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPeopleQuery, GetPeopleQueryVariables>(
    GetPeopleDocument,
    options,
  )
}
export function useGetPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleQuery,
    GetPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPeopleQuery, GetPeopleQueryVariables>(
    GetPeopleDocument,
    options,
  )
}
export type GetPeopleQueryHookResult = ReturnType<typeof useGetPeopleQuery>
export type GetPeopleLazyQueryHookResult = ReturnType<
  typeof useGetPeopleLazyQuery
>
export type GetPeopleQueryResult = Apollo.QueryResult<
  GetPeopleQuery,
  GetPeopleQueryVariables
>
export const SearchPeopleDocument = gql`
  query searchPeople($search: String!) {
    people(search: $search) {
      nodes {
        id
        title
        gender
        lastName
        firstName
        pictureUrl
      }
    }
  }
`

/**
 * __useSearchPeopleQuery__
 *
 * To run a query within a React component, call `useSearchPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPeopleQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchPeopleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPeopleQuery,
    SearchPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    options,
  )
}
export function useSearchPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPeopleQuery,
    SearchPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    options,
  )
}
export type SearchPeopleQueryHookResult = ReturnType<
  typeof useSearchPeopleQuery
>
export type SearchPeopleLazyQueryHookResult = ReturnType<
  typeof useSearchPeopleLazyQuery
>
export type SearchPeopleQueryResult = Apollo.QueryResult<
  SearchPeopleQuery,
  SearchPeopleQueryVariables
>
export const GetPersonDocument = gql`
  query getPerson($id: ID!) {
    person(id: $id) {
      id
      dateOfBirth
      dateOfDeath
      firstName
      lastName
      gender
      relation
      title
      email
      story
      pictureUrl
      userId
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      memories {
        totalCount
      }
    }
  }
`

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    options,
  )
}
export function useGetPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonQuery,
    GetPersonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    options,
  )
}
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>
export type GetPersonLazyQueryHookResult = ReturnType<
  typeof useGetPersonLazyQuery
>
export type GetPersonQueryResult = Apollo.QueryResult<
  GetPersonQuery,
  GetPersonQueryVariables
>
export const GetPersonMemoriesDocument = gql`
  query getPersonMemories($id: ID!) {
    person(id: $id) {
      id
      memories {
        nodes {
          id
          eventAt
          eventAtPrec
          memoryTitle
          memoryType
          pictureUrl
        }
      }
    }
  }
`

/**
 * __useGetPersonMemoriesQuery__
 *
 * To run a query within a React component, call `useGetPersonMemoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonMemoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonMemoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonMemoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonMemoriesQuery,
    GetPersonMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPersonMemoriesQuery,
    GetPersonMemoriesQueryVariables
  >(GetPersonMemoriesDocument, options)
}
export function useGetPersonMemoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonMemoriesQuery,
    GetPersonMemoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPersonMemoriesQuery,
    GetPersonMemoriesQueryVariables
  >(GetPersonMemoriesDocument, options)
}
export type GetPersonMemoriesQueryHookResult = ReturnType<
  typeof useGetPersonMemoriesQuery
>
export type GetPersonMemoriesLazyQueryHookResult = ReturnType<
  typeof useGetPersonMemoriesLazyQuery
>
export type GetPersonMemoriesQueryResult = Apollo.QueryResult<
  GetPersonMemoriesQuery,
  GetPersonMemoriesQueryVariables
>
export const CreatePersonDocument = gql`
  mutation createPerson(
    $input: PersonInput!
    $attachments: [AttachmentInput!]!
  ) {
    createPerson(input: $input, attachments: $attachments) {
      id
      dateOfBirth
      dateOfDeath
      firstName
      lastName
      gender
      relation
      title
      email
      story
      pictureUrl
      userId
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      memories {
        totalCount
      }
    }
  }
`
export type CreatePersonMutationFn = Apollo.MutationFunction<
  CreatePersonMutation,
  CreatePersonMutationVariables
>

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonMutation,
    CreatePersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePersonMutation,
    CreatePersonMutationVariables
  >(CreatePersonDocument, options)
}
export type CreatePersonMutationHookResult = ReturnType<
  typeof useCreatePersonMutation
>
export type CreatePersonMutationResult =
  Apollo.MutationResult<CreatePersonMutation>
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonMutation,
  CreatePersonMutationVariables
>
export const UpdatePersonDocument = gql`
  mutation updatePerson(
    $id: ID!
    $input: PersonInput!
    $attachments: [AttachmentInput!]!
    $attachableDescription: [AttachmentAttachableInput!]
  ) {
    updatePerson(
      id: $id
      input: $input
      attachments: $attachments
      attachableDescription: $attachableDescription
    ) {
      id
      dateOfBirth
      dateOfDeath
      firstName
      lastName
      gender
      relation
      title
      email
      story
      pictureUrl
      userId
      attachments {
        nodes {
          id
          url
          contentType
          signedBlobId
          description
        }
      }
      attachmentAttachables {
        nodes {
          attachmentId
          attachableDescription
        }
      }
      memories {
        totalCount
      }
    }
  }
`
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      attachments: // value for 'attachments'
 *      attachableDescription: // value for 'attachableDescription'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >(UpdatePersonDocument, options)
}
export type UpdatePersonMutationHookResult = ReturnType<
  typeof useUpdatePersonMutation
>
export type UpdatePersonMutationResult =
  Apollo.MutationResult<UpdatePersonMutation>
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>
export const DeletePersonDocument = gql`
  mutation deletePerson($id: ID!) {
    deletePerson(id: $id)
  }
`
export type DeletePersonMutationFn = Apollo.MutationFunction<
  DeletePersonMutation,
  DeletePersonMutationVariables
>

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonMutation, { data, loading, error }] = useDeletePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePersonMutation,
    DeletePersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePersonMutation,
    DeletePersonMutationVariables
  >(DeletePersonDocument, options)
}
export type DeletePersonMutationHookResult = ReturnType<
  typeof useDeletePersonMutation
>
export type DeletePersonMutationResult =
  Apollo.MutationResult<DeletePersonMutation>
export type DeletePersonMutationOptions = Apollo.BaseMutationOptions<
  DeletePersonMutation,
  DeletePersonMutationVariables
>
export const DeletePersonPictureDocument = gql`
  mutation deletePersonPicture($id: ID!) {
    deletePersonProfilePicture(id: $id)
  }
`
export type DeletePersonPictureMutationFn = Apollo.MutationFunction<
  DeletePersonPictureMutation,
  DeletePersonPictureMutationVariables
>

/**
 * __useDeletePersonPictureMutation__
 *
 * To run a mutation, you first call `useDeletePersonPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonPictureMutation, { data, loading, error }] = useDeletePersonPictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePersonPictureMutation,
    DeletePersonPictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePersonPictureMutation,
    DeletePersonPictureMutationVariables
  >(DeletePersonPictureDocument, options)
}
export type DeletePersonPictureMutationHookResult = ReturnType<
  typeof useDeletePersonPictureMutation
>
export type DeletePersonPictureMutationResult =
  Apollo.MutationResult<DeletePersonPictureMutation>
export type DeletePersonPictureMutationOptions = Apollo.BaseMutationOptions<
  DeletePersonPictureMutation,
  DeletePersonPictureMutationVariables
>
export const GetQuizCategoriesDocument = gql`
  query getQuizCategories {
    quizCategories {
      nodes {
        id
        quizSubCategories {
          nodes {
            id
            tag
            translatedTitle
          }
        }
        tag
        translatedTitle
      }
    }
  }
`

/**
 * __useGetQuizCategoriesQuery__
 *
 * To run a query within a React component, call `useGetQuizCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuizCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuizCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuizCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetQuizCategoriesQuery,
    GetQuizCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetQuizCategoriesQuery,
    GetQuizCategoriesQueryVariables
  >(GetQuizCategoriesDocument, options)
}
export function useGetQuizCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuizCategoriesQuery,
    GetQuizCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetQuizCategoriesQuery,
    GetQuizCategoriesQueryVariables
  >(GetQuizCategoriesDocument, options)
}
export type GetQuizCategoriesQueryHookResult = ReturnType<
  typeof useGetQuizCategoriesQuery
>
export type GetQuizCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetQuizCategoriesLazyQuery
>
export type GetQuizCategoriesQueryResult = Apollo.QueryResult<
  GetQuizCategoriesQuery,
  GetQuizCategoriesQueryVariables
>
export const CreateUserCategoriesDocument = gql`
  mutation createUserCategories($quizCategories: [UserCategoryInput!]!) {
    batchCreateUserCategory(input: $quizCategories) {
      id
    }
  }
`
export type CreateUserCategoriesMutationFn = Apollo.MutationFunction<
  CreateUserCategoriesMutation,
  CreateUserCategoriesMutationVariables
>

/**
 * __useCreateUserCategoriesMutation__
 *
 * To run a mutation, you first call `useCreateUserCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCategoriesMutation, { data, loading, error }] = useCreateUserCategoriesMutation({
 *   variables: {
 *      quizCategories: // value for 'quizCategories'
 *   },
 * });
 */
export function useCreateUserCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCategoriesMutation,
    CreateUserCategoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserCategoriesMutation,
    CreateUserCategoriesMutationVariables
  >(CreateUserCategoriesDocument, options)
}
export type CreateUserCategoriesMutationHookResult = ReturnType<
  typeof useCreateUserCategoriesMutation
>
export type CreateUserCategoriesMutationResult =
  Apollo.MutationResult<CreateUserCategoriesMutation>
export type CreateUserCategoriesMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCategoriesMutation,
  CreateUserCategoriesMutationVariables
>
export const CreateUserSubcategoriesDocument = gql`
  mutation createUserSubcategories(
    $quizSubcategories: [UserSubCategoryInput!]!
  ) {
    batchCreateUserSubCategory(input: $quizSubcategories) {
      id
    }
  }
`
export type CreateUserSubcategoriesMutationFn = Apollo.MutationFunction<
  CreateUserSubcategoriesMutation,
  CreateUserSubcategoriesMutationVariables
>

/**
 * __useCreateUserSubcategoriesMutation__
 *
 * To run a mutation, you first call `useCreateUserSubcategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubcategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubcategoriesMutation, { data, loading, error }] = useCreateUserSubcategoriesMutation({
 *   variables: {
 *      quizSubcategories: // value for 'quizSubcategories'
 *   },
 * });
 */
export function useCreateUserSubcategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSubcategoriesMutation,
    CreateUserSubcategoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserSubcategoriesMutation,
    CreateUserSubcategoriesMutationVariables
  >(CreateUserSubcategoriesDocument, options)
}
export type CreateUserSubcategoriesMutationHookResult = ReturnType<
  typeof useCreateUserSubcategoriesMutation
>
export type CreateUserSubcategoriesMutationResult =
  Apollo.MutationResult<CreateUserSubcategoriesMutation>
export type CreateUserSubcategoriesMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSubcategoriesMutation,
  CreateUserSubcategoriesMutationVariables
>
export const CreateUserCategoryDocument = gql`
  mutation createUserCategory($userCategory: UserCategoryInput!) {
    createUserCategory(input: $userCategory) {
      id
    }
  }
`
export type CreateUserCategoryMutationFn = Apollo.MutationFunction<
  CreateUserCategoryMutation,
  CreateUserCategoryMutationVariables
>

/**
 * __useCreateUserCategoryMutation__
 *
 * To run a mutation, you first call `useCreateUserCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCategoryMutation, { data, loading, error }] = useCreateUserCategoryMutation({
 *   variables: {
 *      userCategory: // value for 'userCategory'
 *   },
 * });
 */
export function useCreateUserCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCategoryMutation,
    CreateUserCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserCategoryMutation,
    CreateUserCategoryMutationVariables
  >(CreateUserCategoryDocument, options)
}
export type CreateUserCategoryMutationHookResult = ReturnType<
  typeof useCreateUserCategoryMutation
>
export type CreateUserCategoryMutationResult =
  Apollo.MutationResult<CreateUserCategoryMutation>
export type CreateUserCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCategoryMutation,
  CreateUserCategoryMutationVariables
>
export const CreateUserSubcategoryDocument = gql`
  mutation createUserSubcategory($userSubcategory: UserSubCategoryInput!) {
    createUserSubCategory(input: $userSubcategory) {
      id
    }
  }
`
export type CreateUserSubcategoryMutationFn = Apollo.MutationFunction<
  CreateUserSubcategoryMutation,
  CreateUserSubcategoryMutationVariables
>

/**
 * __useCreateUserSubcategoryMutation__
 *
 * To run a mutation, you first call `useCreateUserSubcategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubcategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubcategoryMutation, { data, loading, error }] = useCreateUserSubcategoryMutation({
 *   variables: {
 *      userSubcategory: // value for 'userSubcategory'
 *   },
 * });
 */
export function useCreateUserSubcategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSubcategoryMutation,
    CreateUserSubcategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserSubcategoryMutation,
    CreateUserSubcategoryMutationVariables
  >(CreateUserSubcategoryDocument, options)
}
export type CreateUserSubcategoryMutationHookResult = ReturnType<
  typeof useCreateUserSubcategoryMutation
>
export type CreateUserSubcategoryMutationResult =
  Apollo.MutationResult<CreateUserSubcategoryMutation>
export type CreateUserSubcategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSubcategoryMutation,
  CreateUserSubcategoryMutationVariables
>
export const DeleteUserCategoryDocument = gql`
  mutation deleteUserCategory($id: ID!) {
    deleteUserCategory(id: $id)
  }
`
export type DeleteUserCategoryMutationFn = Apollo.MutationFunction<
  DeleteUserCategoryMutation,
  DeleteUserCategoryMutationVariables
>

/**
 * __useDeleteUserCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteUserCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCategoryMutation, { data, loading, error }] = useDeleteUserCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCategoryMutation,
    DeleteUserCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUserCategoryMutation,
    DeleteUserCategoryMutationVariables
  >(DeleteUserCategoryDocument, options)
}
export type DeleteUserCategoryMutationHookResult = ReturnType<
  typeof useDeleteUserCategoryMutation
>
export type DeleteUserCategoryMutationResult =
  Apollo.MutationResult<DeleteUserCategoryMutation>
export type DeleteUserCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCategoryMutation,
  DeleteUserCategoryMutationVariables
>
export const DeleteUserSubCategoryDocument = gql`
  mutation deleteUserSubCategory($id: ID!) {
    deleteUserSubCategory(id: $id)
  }
`
export type DeleteUserSubCategoryMutationFn = Apollo.MutationFunction<
  DeleteUserSubCategoryMutation,
  DeleteUserSubCategoryMutationVariables
>

/**
 * __useDeleteUserSubCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubCategoryMutation, { data, loading, error }] = useDeleteUserSubCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSubCategoryMutation,
    DeleteUserSubCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUserSubCategoryMutation,
    DeleteUserSubCategoryMutationVariables
  >(DeleteUserSubCategoryDocument, options)
}
export type DeleteUserSubCategoryMutationHookResult = ReturnType<
  typeof useDeleteUserSubCategoryMutation
>
export type DeleteUserSubCategoryMutationResult =
  Apollo.MutationResult<DeleteUserSubCategoryMutation>
export type DeleteUserSubCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSubCategoryMutation,
  DeleteUserSubCategoryMutationVariables
>
export const GetShareTokenDocument = gql`
  mutation getShareToken {
    getShareToken {
      token
    }
  }
`
export type GetShareTokenMutationFn = Apollo.MutationFunction<
  GetShareTokenMutation,
  GetShareTokenMutationVariables
>

/**
 * __useGetShareTokenMutation__
 *
 * To run a mutation, you first call `useGetShareTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareTokenMutation, { data, loading, error }] = useGetShareTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetShareTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetShareTokenMutation,
    GetShareTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GetShareTokenMutation,
    GetShareTokenMutationVariables
  >(GetShareTokenDocument, options)
}
export type GetShareTokenMutationHookResult = ReturnType<
  typeof useGetShareTokenMutation
>
export type GetShareTokenMutationResult =
  Apollo.MutationResult<GetShareTokenMutation>
export type GetShareTokenMutationOptions = Apollo.BaseMutationOptions<
  GetShareTokenMutation,
  GetShareTokenMutationVariables
>
export const RemoveShareTokenDocument = gql`
  mutation removeShareToken {
    removeShareToken
  }
`
export type RemoveShareTokenMutationFn = Apollo.MutationFunction<
  RemoveShareTokenMutation,
  RemoveShareTokenMutationVariables
>

/**
 * __useRemoveShareTokenMutation__
 *
 * To run a mutation, you first call `useRemoveShareTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShareTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShareTokenMutation, { data, loading, error }] = useRemoveShareTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveShareTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveShareTokenMutation,
    RemoveShareTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveShareTokenMutation,
    RemoveShareTokenMutationVariables
  >(RemoveShareTokenDocument, options)
}
export type RemoveShareTokenMutationHookResult = ReturnType<
  typeof useRemoveShareTokenMutation
>
export type RemoveShareTokenMutationResult =
  Apollo.MutationResult<RemoveShareTokenMutation>
export type RemoveShareTokenMutationOptions = Apollo.BaseMutationOptions<
  RemoveShareTokenMutation,
  RemoveShareTokenMutationVariables
>
export const GetSubscriptionPlansDocument = gql`
  query getSubscriptionPlans {
    zohoPlans {
      nodes {
        allocatedSpace
        anual
        badgeImageUrl
        colorCode
        currentlyPopular
        description
        id
        name
        planCode
        price
        targetUsers
        zohoAddons {
          nodes {
            addonCode
            expiresInYears
            id
            price
          }
        }
      }
    }
  }
`

/**
 * __useGetSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >(GetSubscriptionPlansDocument, options)
}
export function useGetSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >(GetSubscriptionPlansDocument, options)
}
export type GetSubscriptionPlansQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansQuery
>
export type GetSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansLazyQuery
>
export type GetSubscriptionPlansQueryResult = Apollo.QueryResult<
  GetSubscriptionPlansQuery,
  GetSubscriptionPlansQueryVariables
>
export const GetPlanPaymentPageDocument = gql`
  mutation getPlanPaymentPage($input: HostedPaymentPageInput!) {
    getHostedPaymentPage(input: $input) {
      paymentPageUrl
    }
  }
`
export type GetPlanPaymentPageMutationFn = Apollo.MutationFunction<
  GetPlanPaymentPageMutation,
  GetPlanPaymentPageMutationVariables
>

/**
 * __useGetPlanPaymentPageMutation__
 *
 * To run a mutation, you first call `useGetPlanPaymentPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPlanPaymentPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPlanPaymentPageMutation, { data, loading, error }] = useGetPlanPaymentPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlanPaymentPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPlanPaymentPageMutation,
    GetPlanPaymentPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GetPlanPaymentPageMutation,
    GetPlanPaymentPageMutationVariables
  >(GetPlanPaymentPageDocument, options)
}
export type GetPlanPaymentPageMutationHookResult = ReturnType<
  typeof useGetPlanPaymentPageMutation
>
export type GetPlanPaymentPageMutationResult =
  Apollo.MutationResult<GetPlanPaymentPageMutation>
export type GetPlanPaymentPageMutationOptions = Apollo.BaseMutationOptions<
  GetPlanPaymentPageMutation,
  GetPlanPaymentPageMutationVariables
>
export const GetAddonPaymentPageDocument = gql`
  mutation getAddonPaymentPage($input: HostedPaymentPageInput!) {
    getHostedPaymentPageForAddon(input: $input) {
      paymentPageUrl
    }
  }
`
export type GetAddonPaymentPageMutationFn = Apollo.MutationFunction<
  GetAddonPaymentPageMutation,
  GetAddonPaymentPageMutationVariables
>

/**
 * __useGetAddonPaymentPageMutation__
 *
 * To run a mutation, you first call `useGetAddonPaymentPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAddonPaymentPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAddonPaymentPageMutation, { data, loading, error }] = useGetAddonPaymentPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAddonPaymentPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetAddonPaymentPageMutation,
    GetAddonPaymentPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GetAddonPaymentPageMutation,
    GetAddonPaymentPageMutationVariables
  >(GetAddonPaymentPageDocument, options)
}
export type GetAddonPaymentPageMutationHookResult = ReturnType<
  typeof useGetAddonPaymentPageMutation
>
export type GetAddonPaymentPageMutationResult =
  Apollo.MutationResult<GetAddonPaymentPageMutation>
export type GetAddonPaymentPageMutationOptions = Apollo.BaseMutationOptions<
  GetAddonPaymentPageMutation,
  GetAddonPaymentPageMutationVariables
>
export const UnsubscribeDocument = gql`
  mutation unsubscribe {
    unsubscribe
  }
`
export type UnsubscribeMutationFn = Apollo.MutationFunction<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeMutation,
    UnsubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(
    UnsubscribeDocument,
    options,
  )
}
export type UnsubscribeMutationHookResult = ReturnType<
  typeof useUnsubscribeMutation
>
export type UnsubscribeMutationResult =
  Apollo.MutationResult<UnsubscribeMutation>
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>
export const GetUserDocument = gql`
  query getUser {
    me {
      id
      email
      title
      gender
      country
      language
      lastName
      firstName
      pictureUrl
      dateOfBirth
      pictureContentType
      quizCompleted
      libraryId
      lifeStages {
        nodes {
          id
          title
          endYear
          startYear
        }
      }
      quizCategories {
        nodes {
          id
        }
      }
      quizSubCategories {
        nodes {
          id
        }
      }
    }
  }
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($profileInput: ProfileInput!) {
    updateProfile(input: $profileInput) {
      id
      email
      title
      gender
      country
      lastName
      firstName
      pictureUrl
      dateOfBirth
      pictureContentType
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      profileInput: // value for 'profileInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const DeleteProfilePictureDocument = gql`
  mutation deleteProfilePicture {
    deleteProfilePicture
  }
`
export type DeleteProfilePictureMutationFn = Apollo.MutationFunction<
  DeleteProfilePictureMutation,
  DeleteProfilePictureMutationVariables
>

/**
 * __useDeleteProfilePictureMutation__
 *
 * To run a mutation, you first call `useDeleteProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfilePictureMutation, { data, loading, error }] = useDeleteProfilePictureMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProfilePictureMutation,
    DeleteProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteProfilePictureMutation,
    DeleteProfilePictureMutationVariables
  >(DeleteProfilePictureDocument, options)
}
export type DeleteProfilePictureMutationHookResult = ReturnType<
  typeof useDeleteProfilePictureMutation
>
export type DeleteProfilePictureMutationResult =
  Apollo.MutationResult<DeleteProfilePictureMutation>
export type DeleteProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfilePictureMutation,
  DeleteProfilePictureMutationVariables
>
export const GetUserStorageDocument = gql`
  query getUserStorage {
    me {
      id
      usedStorageGb
      allocatedSpaceGb
    }
  }
`

/**
 * __useGetUserStorageQuery__
 *
 * To run a query within a React component, call `useGetUserStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStorageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStorageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserStorageQuery,
    GetUserStorageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserStorageQuery, GetUserStorageQueryVariables>(
    GetUserStorageDocument,
    options,
  )
}
export function useGetUserStorageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStorageQuery,
    GetUserStorageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserStorageQuery, GetUserStorageQueryVariables>(
    GetUserStorageDocument,
    options,
  )
}
export type GetUserStorageQueryHookResult = ReturnType<
  typeof useGetUserStorageQuery
>
export type GetUserStorageLazyQueryHookResult = ReturnType<
  typeof useGetUserStorageLazyQuery
>
export type GetUserStorageQueryResult = Apollo.QueryResult<
  GetUserStorageQuery,
  GetUserStorageQueryVariables
>
export const GetCurrentPlanDocument = gql`
  query getCurrentPlan {
    me {
      id
      currentPlanCode
      currentAddonCode
    }
  }
`

/**
 * __useGetCurrentPlanQuery__
 *
 * To run a query within a React component, call `useGetCurrentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentPlanQuery,
    GetCurrentPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentPlanQuery, GetCurrentPlanQueryVariables>(
    GetCurrentPlanDocument,
    options,
  )
}
export function useGetCurrentPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentPlanQuery,
    GetCurrentPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentPlanQuery, GetCurrentPlanQueryVariables>(
    GetCurrentPlanDocument,
    options,
  )
}
export type GetCurrentPlanQueryHookResult = ReturnType<
  typeof useGetCurrentPlanQuery
>
export type GetCurrentPlanLazyQueryHookResult = ReturnType<
  typeof useGetCurrentPlanLazyQuery
>
export type GetCurrentPlanQueryResult = Apollo.QueryResult<
  GetCurrentPlanQuery,
  GetCurrentPlanQueryVariables
>
export const GetMemoryCreationRestrictionsDocument = gql`
  query getMemoryCreationRestrictions {
    me {
      id
      passive
    }
  }
`

/**
 * __useGetMemoryCreationRestrictionsQuery__
 *
 * To run a query within a React component, call `useGetMemoryCreationRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoryCreationRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoryCreationRestrictionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemoryCreationRestrictionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemoryCreationRestrictionsQuery,
    GetMemoryCreationRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMemoryCreationRestrictionsQuery,
    GetMemoryCreationRestrictionsQueryVariables
  >(GetMemoryCreationRestrictionsDocument, options)
}
export function useGetMemoryCreationRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemoryCreationRestrictionsQuery,
    GetMemoryCreationRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMemoryCreationRestrictionsQuery,
    GetMemoryCreationRestrictionsQueryVariables
  >(GetMemoryCreationRestrictionsDocument, options)
}
export type GetMemoryCreationRestrictionsQueryHookResult = ReturnType<
  typeof useGetMemoryCreationRestrictionsQuery
>
export type GetMemoryCreationRestrictionsLazyQueryHookResult = ReturnType<
  typeof useGetMemoryCreationRestrictionsLazyQuery
>
export type GetMemoryCreationRestrictionsQueryResult = Apollo.QueryResult<
  GetMemoryCreationRestrictionsQuery,
  GetMemoryCreationRestrictionsQueryVariables
>
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteProfile
  }
`
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options)
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
}
export default result
