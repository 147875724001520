import React, {
  useRef,
  useMemo,
  useState,
  Dispatch,
  useCallback,
  SetStateAction,
  PropsWithChildren,
} from 'react'
import {
  View,
  Modal,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { MediaDescriptionModal } from './MediaDescriptionModal'
import { MediaViewerModal } from './MediaViewerModal'
import { AttachmentAttachable } from '../api/types'
import { MediaThumbnail } from '../components/MediaThumbnail'
import Separator from '../components/Separator'
import Typography from '../components/Text/Typography'
import TouchableSvg from '../components/TouchableSvg'
import { AssetType } from '../hooks/useMediaActionSheet'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../hooks/useSafeAreaPaddedStyle'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaItem } from '../types/media-types'
import { MediaThumbnailProps } from '../types/MediaThumbnail.types'
import { convertHexToRGBA } from '../utils/colors'

interface MemoryGalleryModalProps {
  title: string
  media: MediaItem[]
  mediaDescriptions: AttachmentAttachable[]
  selectedIndex: number
  canEdit: boolean
  isVisible: boolean
  showMediaActions?: (
    item: MediaItem,
    extraAction?: { text: string; action: () => void },
  ) => void
  setNewMedia?: Dispatch<SetStateAction<AssetType[]>>
  setMediaDescriptions?: Dispatch<SetStateAction<AttachmentAttachable[]>>
  close: () => void
}

const ItemSeparator = () => <Separator height={ITEM_SEPARATOR_HEIGHT} />

interface MediaRenderItemProps {
  index: number
  item: MediaItem
  description?: string
  style: MediaThumbnailProps['style']
  setSelectedItem: Dispatch<SetStateAction<MediaItem | undefined>>
  setMediaViewerIsVisible: Dispatch<SetStateAction<boolean>>
  canEdit: boolean
  setMediaDescriptionIsVisible: Dispatch<SetStateAction<boolean>>
  showMediaActions?: (
    item: MediaItem,
    extraAction?: { text: string; action: () => void },
  ) => void
}

const MediaRenderItem: React.FC<MediaRenderItemProps> = ({
  index,
  item,
  description,
  style,
  setSelectedItem,
  setMediaViewerIsVisible,
  canEdit,
  setMediaDescriptionIsVisible,
  showMediaActions,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.gallery' })
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [descriptionHeight, setDescriptionHeight] = useState<
    number | undefined
  >(DESCRIPTION_HEIGHT)
  const isFirstRender = useRef(true)

  return (
    <View id={index.toString()}>
      <MediaThumbnail
        item={item}
        style={style}
        playIconSize={80}
        onPress={() => {
          setSelectedItem(item)
          setMediaViewerIsVisible(true)
        }}
      />
      {(canEdit || description || item.description) && (
        <View style={{ height: descriptionHeight }}>
          <Separator height={10} />
          <View style={styles.optionsContainer}>
            <TouchableOpacity
              style={appStyles.fullSize}
              disabled={!canEdit || !!description || !!item.description}
              onPress={() => {
                setSelectedItem(item)
                setMediaDescriptionIsVisible(true)
                setDescriptionHeight(undefined)
              }}>
              <Typography
                weight="regular"
                numberOfLines={numberOfLines}
                style={styles.descriptionText}
                onLayout={e => {
                  if (isFirstRender.current) {
                    const height = e.nativeEvent.layout.height
                    if (height / DESCRIPTION_LINE_HEIGHT >= 2) {
                      setIsShowMoreVisible(true)
                      setNumberOfLines(1)
                    }
                    isFirstRender.current = false
                  }
                }}>
                {description ||
                  item.description ||
                  (canEdit ? t('addDescription') : '')}
              </Typography>
              {isShowMoreVisible && (
                <Typography
                  weight="regular"
                  style={{ color: Colors['brand.action'] }}
                  onPress={() => {
                    setNumberOfLines(0)
                    setIsShowMoreVisible(false)
                    setDescriptionHeight(undefined)
                  }}>
                  {t('showMore')}
                </Typography>
              )}
            </TouchableOpacity>
            <Separator width={10} />
            {canEdit && (
              <TouchableSvg
                name="options"
                color="layout.dark"
                onPress={() =>
                  showMediaActions?.(
                    item,
                    !description && !item.description
                      ? undefined
                      : {
                          text: t('editDescription'),
                          action: () => {
                            setSelectedItem(item)
                            setMediaDescriptionIsVisible(true)
                            setDescriptionHeight(undefined)
                          },
                        },
                  )
                }
              />
            )}
          </View>
          <Separator height={30} />
        </View>
      )}
      {!canEdit && !item.description && <ItemSeparator />}
    </View>
  )
}

const SingleItemContainer: React.FC<
  PropsWithChildren<{ webVersionHeight: number | boolean; index: number }>
> = ({ webVersionHeight, index, children }) => {
  if (!webVersionHeight) return <>{children}</>

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        paddingLeft: index % 2 !== 0 ? WEB_COLUMN_GAP : 0,
        height: webVersionHeight as number,
      }}>
      {children}
      <View style={styles.webDividerContainer}>
        <View style={styles.webDivider} />
      </View>
    </View>
  )
}

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const { webLayoutEnabled } = useWebLayout()

  if (!webLayoutEnabled) return <>{children}</>

  return (
    <View style={webStyles.maxWidthHolder}>
      <View style={webStyles.centerContainer}>{children}</View>
    </View>
  )
}

export const MemoryGalleryModal: React.FC<MemoryGalleryModalProps> = ({
  title,
  media,
  mediaDescriptions,
  selectedIndex,
  canEdit,
  isVisible,
  showMediaActions,
  setNewMedia,
  setMediaDescriptions,
  close,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.gallery' })
  const [selectedItem, setSelectedItem] = useState<MediaItem>()
  const [mediaViewerIsVisible, setMediaViewerIsVisible] = useState(false)
  const [mediaDescriptionIsVisible, setMediaDescriptionIsVisible] =
    useState(false)

  const { width, webLayoutEnabled } = useWebLayout()

  const dynamicStyles = useMemo(
    () => buildStyles(width, webLayoutEnabled),
    [width, webLayoutEnabled],
  )

  const saveDescription = (
    item: MediaItem,
    description: string | undefined,
  ) => {
    if (item.__typename === 'NewAttachment') {
      setNewMedia?.(prev =>
        prev.map(m => (m.uri === item.url ? { ...m, description } : m)),
      )
      return
    }
    const newDescriptions = mediaDescriptions.filter(
      d => d.attachmentId !== item.id,
    )
    newDescriptions.push({
      attachmentId: item.id,
      attachableDescription: description,
    })
    setMediaDescriptions?.(newDescriptions)
  }

  const calculateItemHeight = useCallback(
    (index: number, data: MediaItem[] | null | undefined) =>
      (canEdit ||
      data?.[index]?.description ||
      (webLayoutEnabled &&
        data?.[index + (index % 2 === 0 ? 1 : -1)]?.description) // row pair index 0 -> 1, 1 -> 0, ect
        ? DESCRIPTION_HEIGHT
        : ITEM_SEPARATOR_HEIGHT) +
      dynamicStyles.mediaItem.height +
      (webLayoutEnabled ? styles.webDividerContainer.height : 0),
    [canEdit, webLayoutEnabled, dynamicStyles.mediaItem.height],
  )

  const getItemLayout = useCallback(
    (data: MediaItem[] | null | undefined, index: number) => ({
      length: calculateItemHeight(index, data),
      offset:
        data
          ?.slice(0, index)
          .reduce(
            (acc, _, i, array) =>
              acc +
              (!webLayoutEnabled || i % 2 === 0
                ? calculateItemHeight(i, array)
                : 0),
            0,
          ) ?? 0,
      index,
    }),
    [calculateItemHeight, webLayoutEnabled],
  )

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={isVisible}
      animationType="fade"
      onRequestClose={close}>
      <View style={styles.container}>
        <MediaViewerModal
          uri={selectedItem?.url ?? ''}
          fileType={selectedItem?.contentType ?? ''}
          isVisible={mediaViewerIsVisible}
          close={() => setMediaViewerIsVisible(false)}
        />
        <MediaDescriptionModal
          autofocus
          isVisible={mediaDescriptionIsVisible}
          media={selectedItem}
          memorySpecificDescription={
            mediaDescriptions.find(d => d.attachmentId === selectedItem?.id)
              ?.attachableDescription ?? undefined
          }
          saveDescription={saveDescription}
          close={() => setMediaDescriptionIsVisible(false)}
        />
        <View
          style={useSafeAreaPaddedStyle(dynamicStyles.header, headerOptions)}>
          <TouchableSvg name="back" color="layout.dark" onPress={close} />
          <Separator width={23} />
          <View>
            <Typography weight="medium" style={styles.titleText}>
              {t('title')}
            </Typography>
            {!!title && (
              <Typography weight="medium" style={styles.memoryTitleText}>
                {title}
              </Typography>
            )}
          </View>
        </View>
        <Container>
          <FlatList
            data={media}
            initialScrollIndex={webLayoutEnabled ? 0 : selectedIndex}
            // @ts-ignore
            getItemLayout={getItemLayout}
            numColumns={webLayoutEnabled ? 2 : 1}
            contentContainerStyle={useSafeAreaPaddedStyle(
              dynamicStyles.contentContainer,
              {
                insetBottom: true,
              },
            )}
            keyExtractor={(item, _) => item.id}
            renderItem={({ item, index }) => (
              <SingleItemContainer
                index={index}
                webVersionHeight={
                  webLayoutEnabled && calculateItemHeight(index, media)
                }>
                <MediaRenderItem
                  item={item}
                  description={
                    mediaDescriptions.find(d => d.attachmentId === item.id)
                      ?.attachableDescription ?? undefined
                  }
                  index={index}
                  style={dynamicStyles.mediaItem}
                  setSelectedItem={setSelectedItem}
                  setMediaViewerIsVisible={setMediaViewerIsVisible}
                  canEdit={canEdit}
                  setMediaDescriptionIsVisible={setMediaDescriptionIsVisible}
                  showMediaActions={showMediaActions}
                />
              </SingleItemContainer>
            )}
          />
        </Container>
      </View>
    </Modal>
  )
}

const borderColor = convertHexToRGBA(Colors['layout.gray'], 0.5)
const ITEM_SEPARATOR_HEIGHT = 35
const DESCRIPTION_HEIGHT = 90
const DESCRIPTION_LINE_HEIGHT = 29

const WEB_COLUMN_GAP = 80

const buildStyles = (width: number, webLayoutEnabled: boolean) => {
  const ITEM_SIZE = webLayoutEnabled
    ? Math.min((width - 48 - WEB_COLUMN_GAP) / 2, 538)
    : width - 48
  const styles = StyleSheet.create({
    contentContainer: {
      paddingVertical: webLayoutEnabled ? 20 : 12,
      paddingHorizontal: webLayoutEnabled ? 0 : 24,
    },
    header: webLayoutEnabled
      ? {
          height: 90,
          borderColor,
          borderBottomWidth: 1,
          paddingHorizontal: 30,
          ...appStyles.inlineContainer,
        }
      : {
          paddingTop: 20,
          paddingLeft: 15,
          paddingBottom: 29,
          ...appStyles.lightShadow,
          ...appStyles.inlineContainer,
          backgroundColor: Colors['layout.white'],
        },
    mediaItem: {
      width: ITEM_SIZE,
      height: webLayoutEnabled ? (ITEM_SIZE / 538) * 314 : ITEM_SIZE,
    },
  })

  return styles
}

const webStyles = StyleSheet.create({
  maxWidthHolder: {
    ...appStyles.row,
    ...appStyles.center,
    ...appStyles.fullSize,
  },
  centerContainer: {
    height: '100%',
    maxWidth: 1200,
    ...appStyles.row,
    ...appStyles.fullSize,
    paddingHorizontal: 30,
  },
})

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  titleText: {
    fontSize: 20,
    lineHeight: 24,
  },
  memoryTitleText: {
    fontSize: 16,
    lineHeight: 20,
  },
  webDividerContainer: {
    height: 51,
    paddingTop: 10,
    marginTop: 'auto',
  },
  webDivider: {
    height: 1,
    backgroundColor: borderColor,
  },
  descriptionText: {
    fontSize: 16,
    marginTop: 5.5,
    lineHeight: DESCRIPTION_LINE_HEIGHT,
    color: Colors['layout.black'],
  },
  optionsContainer: {
    ...appStyles.row,
    justifyContent: 'space-between',
  },
})
