import React, {
  useMemo,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  useCallback,
} from 'react'

import { QueryResult } from '@apollo/client'

import loggingCore from '../core/logging-core'

export enum QueryKey {
  User,
  People,
  Memories,
  SearchPeople,
  OtherMemories,
  CommonMemories,
}

type RefetchState = 'pending' | 'in-progress' | 'failed'

type RefetchStatesType = { [key in QueryKey]?: RefetchState }

type RefetchContextType = {
  requireRefetch: (queryKey: QueryKey) => void
  _refetchStates: RefetchStatesType
  _setRefetchState: (
    queryKey: QueryKey,
    state: RefetchState | undefined,
  ) => void
}

const RefetchContext = createContext<RefetchContextType>({
  requireRefetch: () => {},
  _refetchStates: {},
  _setRefetchState: () => {},
})

const RefetchProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [_refetchStates, setRefetchStates] = useState<RefetchStatesType>({})

  const _setRefetchState = useCallback(
    (queryKey: QueryKey, state: RefetchState | undefined) =>
      setRefetchStates(c => ({ ...c, [queryKey]: state })),
    [],
  )

  const requireRefetch = useCallback(
    (queryKey: QueryKey) => _setRefetchState(queryKey, 'pending'),
    [_setRefetchState],
  )

  return (
    <RefetchContext.Provider
      value={useMemo(
        () => ({ _refetchStates, _setRefetchState, requireRefetch }),
        [_refetchStates, _setRefetchState, requireRefetch],
      )}>
      {children}
    </RefetchContext.Provider>
  )
}

export default RefetchProvider

export function useIsRefreshing(...queryKeys: QueryKey[]) {
  const { _refetchStates } = useContext(RefetchContext)
  return useMemo(
    () => !!queryKeys.find(key => _refetchStates[key] === 'in-progress'),
    [_refetchStates, queryKeys],
  )
}

export function useSetShouldRefetch() {
  return useContext(RefetchContext).requireRefetch
}

export function useShouldRefetch<T extends QueryResult>(
  queryKey: QueryKey,
  refetch: T['refetch'],
  intialVariables?: T['variables'],
  skip?: boolean,
) {
  const { _refetchStates, _setRefetchState } = useContext(RefetchContext)

  useEffect(() => {
    if (_refetchStates[queryKey] === 'pending' && !skip) {
      _setRefetchState(queryKey, 'in-progress')
      refetch(intialVariables)
        .then(() => _setRefetchState(queryKey, undefined))
        .catch(e => {
          loggingCore.log('requested refresh failed', queryKey, e)
          _setRefetchState(queryKey, 'failed')
        })
    }
  }, [
    _refetchStates,
    refetch,
    queryKey,
    _setRefetchState,
    intialVariables,
    skip,
  ])
}
