import { Asset } from 'react-native-image-picker'

import { AudioType } from './useAudioActionSheet'
import { useDirectUpload } from './useDirectUpload'
import { AssetType } from './useMediaActionSheet'
import {
  GetMemoryQuery,
  AttachmentInput,
  AttachmentAttachable,
} from '../api/types'

export type AttachmentNodes = Exclude<
  GetMemoryQuery['memory']['attachments'],
  null | undefined
>['nodes']

export default () => {
  const { uploadMedia } = useDirectUpload()

  const uploadAttachments: (
    newMediaList: AssetType[],
    newAudioList: AudioType[],
    oldAudioAttachments: AttachmentNodes,
    oldAudioList: AttachmentNodes,
    oldMediaAttachments: AttachmentNodes,
    oldMediaList: AttachmentNodes,
    descriptions: AttachmentAttachable[],
    additionalPicture?: Asset,
  ) => Promise<AttachmentInput[]> = (
    newMediaList,
    newAudioList,
    oldAudioAttachments,
    oldAudioList,
    oldMediaAttachments,
    oldMediaList,
    descriptions,
    additionalPicture,
  ) => {
    return Promise.all(
      (additionalPicture
        ? [
            uploadMedia(
              additionalPicture.uri,
              null,
              additionalPicture.fileName,
              additionalPicture.type,
            ),
          ]
        : []
      )
        .concat(
          newMediaList.map(({ uri, fileName, type, description }) => {
            return uploadMedia(uri, description, fileName, type)
          }),
        )
        .concat(
          newAudioList.map(({ fileCopyUri, description, name, type }) => {
            return uploadMedia(fileCopyUri ?? '', description, name, type)
          }),
        ),
    ).then(uploadedFiles => {
      const oldAttachments: AttachmentInput[] = []
      oldAudioAttachments?.forEach(({ id }) => {
        const attachment = oldAudioList.find(old => old.id === id)
        oldAttachments.push({
          id,
          fileBlobId: attachment?.signedBlobId,
          description: attachment?.description,
          _destroy: !attachment,
        })
      })

      oldMediaAttachments?.forEach(({ id }) => {
        const attachment = oldMediaList.find(old => old.id === id)
        const description = descriptions.find(
          d => d.attachmentId === id,
        )?.attachableDescription
        oldAttachments.push({
          id,
          fileBlobId: attachment?.signedBlobId,
          attachableDescription: description,
          _destroy: !attachment,
        })
      })

      const attachments: AttachmentInput[] = (
        uploadedFiles.map(({ fileBlobId, description }) => ({
          fileBlobId,
          description,
          attachableDescription: description,
        })) as AttachmentInput[]
      ).concat(oldAttachments)
      return attachments
    })
  }
  return uploadAttachments
}
