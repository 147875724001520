import { Animated, ImageStyle, ViewStyle } from 'react-native'

export function scaleImageStyle(
  animationProgress: Animated.Value,
): Animated.WithAnimatedObject<ImageStyle> {
  return {
    transform: [
      {
        scaleX: animationProgress.interpolate({
          inputRange: [0, 0.2],
          outputRange: [1.2, 1],
          extrapolate: 'clamp',
        }),
      },
      {
        scaleY: animationProgress.interpolate({
          inputRange: [0, 0.2],
          outputRange: [1.2, 1],
          extrapolate: 'clamp',
        }),
      },
    ],
  }
}

export function animatedGradientStyle(
  animationProgress: Animated.Value,
): Animated.WithAnimatedObject<ViewStyle> {
  return {
    opacity: animationProgress.interpolate({
      inputRange: [0, 0.4],
      outputRange: [0, 0.7],
      extrapolate: 'clamp',
    }),
  }
}

export function animatedTextContainerStyle(
  animationProgress: Animated.Value,
): Animated.WithAnimatedObject<ViewStyle> {
  return {
    opacity: animationProgress.interpolate({
      inputRange: [0.3, 0.6],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    transform: [
      {
        translateY: animationProgress.interpolate({
          inputRange: [0.3, 0.6],
          outputRange: [-40, 0],
          extrapolate: 'clamp',
        }),
      },
    ],
  }
}

export function animatedFinalElementStyle(
  animationProgress: Animated.Value,
): Animated.WithAnimatedObject<ViewStyle> {
  return {
    opacity: animationProgress.interpolate({
      inputRange: [0.85, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
  }
}
