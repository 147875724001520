import React, { useEffect, useRef, useState, useMemo } from 'react'
import { View, StyleSheet, StatusBar, Animated } from 'react-native'

import { useNavigation, CommonActions } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import {
  scaleImageStyle,
  animatedGradientStyle,
  animatedFinalElementStyle,
  animatedTextContainerStyle,
} from './animations'
import GradientOverlay from './components/GradientOverlay'
import Button from '../../components/Button'
import FaqLink from '../../components/FaqLink'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import { useLocale } from '../../providers/LocaleProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

const DURATION = 2400
const MIN_DELAY = 1000

export default function WelcomeScreen(): JSX.Element {
  const { dispatch } =
    useNavigation<UnauthorizedStackNavigationType<'Welcome'>>()
  const { t } = useTranslation()

  const { webLayoutEnabled } = useWebLayout()
  const { locale } = useLocale()
  const imageTimestamp = useRef(Date.now())
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const isReady = useMemo(
    () => !!locale && isImageLoaded,
    [locale, isImageLoaded],
  )
  const animationProgress = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (isReady) {
      Animated.timing(animationProgress, {
        toValue: 1,
        duration: DURATION,
        useNativeDriver: true,
        delay: Math.max(0, MIN_DELAY - (Date.now() - imageTimestamp.current)),
      }).start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  const onButtonPress = () => {
    dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'AuthRoot' }],
      }),
    )
  }

  return (
    <View style={appStyles.fullSize}>
      <StatusBar barStyle="light-content" />
      <Animated.Image
        style={[styles.image, scaleImageStyle(animationProgress)]}
        source={require('../../assets/splash.jpeg')}
        onLoadEnd={() => {
          imageTimestamp.current = Date.now()
          setIsImageLoaded(true)
        }}
      />
      {isReady && (
        <Animated.View
          style={[
            styles.gradientHolder,
            animatedGradientStyle(animationProgress),
          ]}>
          <GradientOverlay />
        </Animated.View>
      )}
      <SafeAreaView style={appStyles.fullSize}>
        <View
          style={[
            styles.contentContainer,
            webLayoutEnabled && styles.webContentContainer,
          ]}>
          <SvgIcon name="logo" style={styles.logo} />
          {isReady && (
            <>
              <Animated.View
                style={animatedFinalElementStyle(animationProgress)}>
                <LanguagePickerButton light />
              </Animated.View>
              <Animated.View
                style={[
                  styles.bottomContainer,
                  animatedTextContainerStyle(animationProgress),
                  webLayoutEnabled && styles.webBottomContainer,
                ]}>
                <TitleText color="layout.white" size="large">
                  {t('screens.welcome.title')}
                </TitleText>
                <Separator height={20} />
                <Typography weight="light" style={styles.subtitle}>
                  {t('screens.welcome.subtitle')}
                </Typography>
                {!webLayoutEnabled && (
                  <Animated.View
                    style={[
                      styles.button,
                      animatedFinalElementStyle(animationProgress),
                    ]}>
                    <Separator height={30} />
                    <Button
                      style={styles.button}
                      text={t('common.next')}
                      onPress={onButtonPress}
                    />
                    <Separator height={46} />
                    <FaqLink />
                  </Animated.View>
                )}
              </Animated.View>
            </>
          )}
        </View>
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    top: '-19%',
    left: '-10%',
    width: '120%',
    height: '122%',
    position: 'absolute',
    backgroundColor: Colors['layout.black'],
  },
  gradientHolder: { ...StyleSheet.absoluteFillObject, opacity: 0.7 },
  contentContainer: {
    flex: 1,
    marginTop: 10,
    paddingHorizontal: 24,
  },
  webContentContainer: {
    marginTop: 47,
    paddingHorizontal: 40,
  },
  logo: { position: 'absolute', right: 40 },
  bottomContainer: {
    minHeight: '40%',
    paddingBottom: 48,
    marginTop: 'auto',
  },
  webBottomContainer: {
    maxWidth: 500,
    paddingBottom: 90,
    minHeight: undefined,
    paddingHorizontal: 60,
  },
  subtitle: {
    fontSize: 20,
    maxWidth: '70%',
    color: Colors['layout.white'],
  },
  button: { marginTop: 'auto' },
})
