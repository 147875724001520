import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import FlagSvg from './FlagSvg'
import SvgIcon from './SvgIcon'
import ButtonText from './Text/ButtonText'
import { useLocale } from '../providers/LocaleProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainStackNavigationType } from '../types/navigation-types'

const LanguagePickerButton: React.FC<{ light?: boolean }> = ({ light }) => {
  const { navigate } = useNavigation<MainStackNavigationType<'Root'>>()
  const { t } = useTranslation()
  const { locale } = useLocale()

  const color = light ? 'layout.white' : 'layout.dark'

  return (
    <View style={styles.holder}>
      <TouchableOpacity
        // @ts-ignore
        hitSlop={10}
        style={appStyles.inlineContainer}
        onPress={() => navigate('LanguagePicker')}>
        {!!locale && (
          <View style={styles.flagContainer}>
            <FlagSvg locale={locale} width={26} height={26} />
          </View>
        )}
        <ButtonText size="large" color={color} style={styles.text}>
          {t('code')}
        </ButtonText>
        <SvgIcon name="chevron" color={Colors[color]} />
      </TouchableOpacity>
    </View>
  )
}

export default LanguagePickerButton

const styles = StyleSheet.create({
  holder: {
    flexWrap: 'wrap', // prevents flexing to full width
    flexDirection: 'row',
  },
  flagContainer: { borderRadius: 13, overflow: 'hidden' },
  text: {
    marginLeft: 7,
    marginRight: 8,
  },
})
