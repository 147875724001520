import React, { useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { EventsQuizSubcategory } from './EventsQuizSubcategory'
import { QuizCategory, QuizSubCategory } from '../../../api/types'
import Separator from '../../../components/Separator'
import TitleText from '../../../components/Text/TitleText'
import { ToggleButton } from '../../../components/ToggleButton'
import { useWebLayout } from '../../../providers/WebLayoutProvider'

interface EventsQuizCategoryProps {
  width: number
  category: QuizCategory
  enableCategory: (id: string) => void
  disableCategory: (id: string) => void
  enableSubcategory: (id: string) => void
  disableSubcategory: (id: string) => void
}

export const EventsQuizCategory: React.FC<EventsQuizCategoryProps> = ({
  width,
  category,
  enableCategory,
  disableCategory,
  enableSubcategory,
  disableSubcategory,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'common' })
  const [isCategoryEnabled, setIsCategoryEnabled] = useState(true)
  const { webLayoutEnabled } = useWebLayout()

  return (
    <View style={[styles.container, { width }]}>
      <TitleText
        size="medium"
        color="layout.dark"
        style={[styles.titleText, webLayoutEnabled && styles.webPadding]}>
        {category.translatedTitle}
      </TitleText>
      <Separator height={35} />
      <ToggleButton
        value={isCategoryEnabled}
        setValue={value => {
          setIsCategoryEnabled(value)
          value ? enableCategory(category.id) : disableCategory(category.id)
        }}
        firstOptionText={t('yes')}
        secondOptionText={t('no')}
      />

      <Separator height={30} />
      {isCategoryEnabled && (
        <FlatList
          keyExtractor={subCategory => subCategory.id}
          data={category.quizSubCategories?.nodes as QuizSubCategory[]}
          contentContainerStyle={webLayoutEnabled && styles.webPadding}
          renderItem={({ item }) => (
            <EventsQuizSubcategory
              subcategory={item}
              enableSubcategory={enableSubcategory}
              disableSubcategory={disableSubcategory}
            />
          )}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: 24 },
  titleText: { fontSize: 26 },
  webPadding: {
    paddingHorizontal: 70,
  },
})
