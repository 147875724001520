import { useEffect } from 'react'
import { NativeSyntheticEvent } from 'react-native'

export default function useOnWebEnterPress(callback: () => void) {
  useEffect(() => {
    const onKeydown = (e: NativeSyntheticEvent<{ key: string }>) => {
      if (e.nativeEvent.key === 'Enter') {
        e.preventDefault()
        callback()
      }
    }
    // @ts-ignore
    window.addEventListener('keydown', onKeydown)

    // @ts-ignore
    return () => window.removeEventListener('keydown', onKeydown)
  }, [callback])
}
