import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import { ApolloError } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  UserTitleEnum,
  UserGenderEnum,
  GetUserDocument,
  UserCountryEnum,
  useUpdateUserMutation,
  useDeleteProfilePictureMutation,
} from '../../api/types'
import Avatar from '../../components/Avatar'
import HeaderButton from '../../components/HeaderButton'
import {
  DateInputField,
  TextInputField,
  PickerInputField,
} from '../../components/InputFields'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import WebModal from '../../components/WebModal'
import loggingCore from '../../core/logging-core'
import {
  getTitleItems,
  getGenderItems,
  getCountriesItems,
} from '../../helpers/ProfileFormItems'
import { useDirectUpload } from '../../hooks/useDirectUpload'
import { useProfilePictureActionSheet } from '../../hooks/useProfilePictureActionSheet'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import useStateRef from '../../hooks/useStateRef'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { useUser } from '../../providers/UserProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function ProfileSettingsEditScree(): JSX.Element {
  const { goBack } =
    useNavigation<MainStackNavigationType<'ProfileSettingsEdit'>>()
  const { t } = useTranslation()
  const setShouldRefetch = useSetShouldRefetch()
  const { user } = useUser()
  const [updateUser, { client }] = useUpdateUserMutation()
  const [deleteProfilePicture] = useDeleteProfilePictureMutation()
  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)

  const titleItems = useMemo(() => getTitleItems(t), [t])
  const genderItems = useMemo(() => getGenderItems(t), [t])
  const countriesItems = useMemo(() => getCountriesItems(t), [t])

  const [gender, setGender] = useState<UserGenderEnum>(UserGenderEnum.Other)
  const [title, setTitle] = useState<UserTitleEnum | null>(null)
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [birthday, setBirthday] = useState<Date>(new Date())
  const [country, setCountry] = useState<UserCountryEnum>(UserCountryEnum.Other)
  const [nameError, setNameError] = useState<string>('')
  const [surnameError, setSurnameError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailErrorHint, setEmailErrorHint] = useState<string>('')
  const [pictureUrl, setPictureUrl] = useState<string | null>(null)

  const [picture, showPictureOptions] = useProfilePictureActionSheet()
  const { uploadMedia } = useDirectUpload()

  useEffect(() => {
    if (!user) return

    setTitle(user.title ?? null)
    setName(user.firstName ?? '')
    setSurname(user.lastName ?? '')
    setEmail(user.email ?? '')
    setPictureUrl(user.pictureUrl ?? null)
    setBirthday(new Date(user.dateOfBirth))
    setGender(user.gender ?? UserGenderEnum.Other)
    setCountry(user.country ?? UserCountryEnum.Other)
  }, [user])

  const updatePicture = useCallback(async () => {
    if (picture) {
      const { uri, fileName, type } = picture
      return uploadMedia(uri, null, fileName, type)
    }

    if (user?.pictureUrl && !pictureUrl) {
      await deleteProfilePicture()
    }
  }, [picture, uploadMedia, pictureUrl, user?.pictureUrl, deleteProfilePicture])

  const saveProfile = useCallback(() => {
    if (getIsSaving()) return
    let hasErrors = false
    if (!name) {
      setNameError(t('profile.error.noName'))
      hasErrors = true
    }
    if (!surname) {
      setSurnameError(t('profile.error.noSurname'))
      hasErrors = true
    }
    if (!email) {
      setEmailError(t('profile.error.noEmail'))
      hasErrors = true
    }
    if (hasErrors) return

    setIsSaving(true)

    updatePicture()
      .then(uploadData =>
        updateUser({
          variables: {
            profileInput: {
              email,
              country,
              gender,
              title,
              firstName: name,
              lastName: surname,
              dateOfBirth: birthday,
              pictureBlobId: uploadData?.fileBlobId,
            },
          },
        }),
      )
      .then(result => {
        if (!result?.data?.updateProfile)
          throw new Error('Error while saving data')
        if (country !== user?.country) {
          setShouldRefetch(QueryKey.CommonMemories)
        }
        client.cache.updateQuery(
          {
            query: GetUserDocument,
          },
          previous => ({
            me: {
              ...previous.me,
              ...result?.data?.updateProfile,
            },
          }),
        )
        goBack()
      })
      .catch((error: ApolloError) => {
        // @ts-ignore
        const message = error.graphQLErrors[0].extensions?.fullMessages[0]
        if (message) {
          switch (message) {
            case 'Email has already been taken':
              setEmailErrorHint(t('profile.error.emailTaken'))
              return setEmailError(t('profile.error.emailTaken'))
            case 'Email is invalid':
              setEmailErrorHint(t('profile.error.emailInvalid'))
              return setEmailError(t('profile.error.emailInvalid'))
          }
        }
        loggingCore.error(`Error creating account: ${error}`)
      })
      .finally(() => setIsSaving(false))
  }, [
    t,
    name,
    title,
    email,
    gender,
    goBack,
    surname,
    country,
    birthday,
    updateUser,
    getIsSaving,
    setIsSaving,
    client.cache,
    updatePicture,
    user?.country,
    setShouldRefetch,
  ])

  return (
    <WebModal>
      <Loading blocking loading={isSaving} />
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <HeaderButton secondary text={t('common.cancel')} onPress={goBack} />
        <HeaderButton text={t('common.save')} onPress={saveProfile} />
      </View>
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.contentContainer}>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={10}
            style={styles.pictureContainer}
            onPress={() =>
              showPictureOptions({
                hasPictureUrl: !!pictureUrl,
                removePictureUrl: () => setPictureUrl(null),
              })
            }>
            <Avatar gender={gender} uri={picture?.uri ?? pictureUrl} />
            <SvgIcon
              name="add"
              color={Colors['layout.dark']}
              style={styles.addPictureButton}
            />
          </TouchableOpacity>
          <Separator height={30} />
          <PickerInputField
            selectedKey={gender}
            options={genderItems}
            label={t('profile.gender.title')}
            modalTitle={t('modals.genderPicker.title')}
            value={genderItems.find(item => item.key === gender)?.label ?? ''}
            onSelectKey={key => setGender(key as UserGenderEnum)}
          />
          <PickerInputField
            selectedKey={title}
            options={titleItems}
            label={t('profile.personTitle.title')}
            modalTitle={t('modals.titlePicker.title')}
            value={titleItems.find(item => item.key === title)?.label ?? ''}
            onSelectKey={key => setTitle(key as UserTitleEnum)}
          />
          <TextInputField
            value={name}
            maxLength={30}
            autoCapitalize="words"
            errorMessage={nameError}
            label={t('profile.name')}
            clearError={() => setNameError('')}
            onChangeValue={setName}
          />
          <TextInputField
            maxLength={30}
            value={surname}
            autoCapitalize="words"
            errorMessage={surnameError}
            label={t('profile.surname')}
            clearError={() => setSurnameError('')}
            onChangeValue={setSurname}
          />
          <TextInputField
            value={email}
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            hint={emailErrorHint}
            clearError={() => {
              setEmailError('')
              setEmailErrorHint('')
            }}
            errorMessage={emailError}
            label={t('profile.email')}
            textContentType="emailAddress"
            onChangeValue={setEmail}
          />
          <DateInputField
            value={birthday}
            label={t('profile.birthday')}
            onChangeValue={setBirthday}
          />
          <PickerInputField
            selectedKey={country}
            options={countriesItems}
            label={t('profile.country.title')}
            modalTitle={t('modals.countryPicker.title')}
            value={countriesItems.find(c => c.key === country)?.label ?? ''}
            onSelectKey={key => setCountry(key as UserCountryEnum)}
          />
          <Separator height={30} />
        </ScrollView>
      </KeyboardAvoidingView>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 15,
    ...appStyles.row,
    paddingBottom: 23,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  contentContainer: {
    paddingTop: 30,
    paddingHorizontal: 25,
    ...appStyles.fullSize,
  },
  pictureContainer: {
    marginRight: 'auto',
  },
  addPictureButton: {
    left: 55,
    zIndex: 1,
    bottom: -30,
    position: 'absolute',
  },
})
