import { useMemo } from 'react'
import { ViewStyle } from 'react-native'

import { useSafeAreaInsets, EdgeInsets } from 'react-native-safe-area-context'

type Options = {
  insetTop?: boolean
  insetLeft?: boolean
  insetBottom?: boolean
  insetRight?: boolean
}

const firstNumber = (
  ...candidates: (string | number | undefined)[]
): number | undefined => {
  for (let candidate of candidates) {
    if (typeof candidate === 'string') {
      candidate = parseFloat(candidate)
    }
    if (!isNaN(candidate!)) {
      return candidate
    }
  }
}

export const paddStyleWithInsets = (
  style: ViewStyle | null,
  insets: EdgeInsets,
  options: Options,
) => {
  const { insetTop, insetLeft, insetBottom, insetRight } = options
  const {
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    ...nonPadding
  } = style || {}

  // @ts-ignore
  const top = firstNumber(paddingTop, paddingVertical, padding, 0)! // shush! - 0 is number
  // @ts-ignore
  const bottom = firstNumber(paddingBottom, paddingVertical, padding, 0)!
  // @ts-ignore
  const left = firstNumber(paddingLeft, paddingHorizontal, padding, 0)!
  // @ts-ignore
  const right = firstNumber(paddingRight, paddingHorizontal, padding, 0)!
  return {
    ...nonPadding,
    paddingTop: top + (insetTop ? insets.top : 0),
    paddingBottom: bottom + (insetBottom ? insets.bottom : 0),
    paddingLeft: left + (insetLeft ? insets.left : 0),
    paddingRight: right + (insetRight ? insets.right : 0),
  }
}

export default function useSafeAreaPaddedStyle(
  style: ViewStyle,
  options: Options,
): ViewStyle {
  const insets = useSafeAreaInsets()

  return useMemo(
    () => paddStyleWithInsets(style, insets, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      style,
      insets,
      options.insetTop,
      options.insetLeft,
      options.insetRight,
      options.insetBottom,
    ],
  )
}

export const headerOptions: Options = {
  insetTop: true,
  insetLeft: true,
  insetRight: true,
}
