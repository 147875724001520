import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import { UserGenderEnum } from '../api/types'
import FemaleSvg from '../assets/svg/gender/placeholder/female.svg'
import MaleSvg from '../assets/svg/gender/placeholder/male.svg'
import OtherSvg from '../assets/svg/gender/placeholder/other.svg'

const AvatarPlaceholder: React.FC<
  SvgProps & { gender: UserGenderEnum | null | undefined }
> = ({ gender, ...props }) => {
  const Component = useMemo(() => {
    switch (gender) {
      case UserGenderEnum.Male:
        return MaleSvg
      case UserGenderEnum.Female:
        return FemaleSvg
      default:
        return OtherSvg
    }
  }, [gender])

  return <Component {...props} viewBox="0 0 100 100" />
}

export default AvatarPlaceholder
