import React from 'react'
import { View, Text, StyleSheet, Linking } from 'react-native'

import { useTranslation } from 'react-i18next'

import LabelText from './Text/LabelText'
import { useLocale } from '../providers/LocaleProvider'
import appStyles from '../styles/app-styles'
import { getFAQLink } from '../utils/landingLinks'

const FaqLink: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.authRoot.faq' })
  const { locale } = useLocale()
  const onPress = () => Linking.openURL(getFAQLink(locale))

  return (
    <View style={appStyles.horizontalCenter}>
      <LabelText size="small">{t('gotQuestions')}</LabelText>
      <LabelText size="small">
        {`${t('findAnswers')} `}
        <Text style={styles.link} onPress={onPress}>
          {t('findAnswersLink')}
        </Text>
      </LabelText>
    </View>
  )
}

export default FaqLink

const styles = StyleSheet.create({
  link: { textDecorationLine: 'underline' },
})
