import React from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import MemoryTypeSvg from './MemoryTypeSvg'
import { Memory, MemoryTypeEnum } from '../../../api/types'
import Picture from '../../../components/Picture'
import Separator from '../../../components/Separator'
import Typography from '../../../components/Text/Typography'
import useFormatMemoryDate from '../../../hooks/useFormatMemoryDate'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { MainStackNavigationType } from '../../../types/navigation-types'
import { mixinOpacity } from '../../../utils/colors'
import { CommonMemoryNode } from '../TimelineScreen'

export const MEMORY_HEIGHT = 97

const MemoryCard: React.FC<{
  memory: Memory | CommonMemoryNode
}> = ({ memory }) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation<MainStackNavigationType<'Timeline'>>()

  const formatMemoryDate = useFormatMemoryDate()
  const eventAt = memory.eventAt
    ? formatMemoryDate(memory)
    : memory.__typename === 'Memory' && memory.lifeStage
    ? t('screens.timeline.lifeStageMemory', {
        lifeStage: memory.lifeStage.title,
      })
    : undefined

  const isCommon = memory.__typename === 'CommonMemory'

  return (
    <TouchableOpacity
      style={[styles.item, isCommon && styles.commonItem]}
      onPress={() => {
        navigate('MemoryDetails', {
          memoryId: memory.id,
          isCommon,
        })
      }}>
      <Separator width={16} />
      <View style={styles.iconHolder}>
        {memory.pictureUrl ? (
          <Picture style={styles.iconHolder} sourceUri={memory.pictureUrl} />
        ) : (
          <MemoryTypeSvg
            width={30}
            height={30}
            type={
              memory.__typename === 'Memory'
                ? memory.memoryType
                : MemoryTypeEnum.Memory
            }
          />
        )}
      </View>
      <Separator width={10} />
      <View style={appStyles.fullSize}>
        {eventAt && (
          <Typography
            weight="light"
            numberOfLines={2}
            style={styles.eventAtText}>
            {eventAt}
          </Typography>
        )}
        <Separator height={2} />
        <Typography weight="medium" style={styles.title} numberOfLines={3}>
          {memory.__typename === 'Memory'
            ? memory.memoryTitle
            : memory.__typename === 'CommonMemory'
            ? memory.title
            : ''}
        </Typography>
      </View>
    </TouchableOpacity>
  )
}

export default MemoryCard

const styles = StyleSheet.create({
  item: {
    borderRadius: 10,
    height: MEMORY_HEIGHT,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  commonItem: {
    backgroundColor: mixinOpacity(Colors['layout.dark'], 0.2),
  },
  iconHolder: {
    width: 56,
    height: 56,
    borderRadius: 6,
    ...appStyles.center,
    backgroundColor: Colors['layout.gray'],
  },
  eventAtText: {
    opacity: 0.7,
    fontSize: 15,
  },
  title: {
    fontSize: 17,
    width: '100%',
  },
})
