import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Linking,
  Platform,
  StyleSheet,
  SectionList,
  SectionListData,
  TouchableOpacity,
  SectionListRenderItem,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import HeaderIconButton, {
  HEADER_ICON_BUTTON_SIZE,
} from '../../components/HeaderIconButton'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import SvgIcon, { SvgName } from '../../components/SvgIcon'
import LabelText from '../../components/Text/LabelText'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import UserStorageInfo from '../../components/UserStorageInfo'
import CONFIG from '../../config/app-config'
import { getCodePushVersion } from '../../helpers/CodePushVersion'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useLocale } from '../../providers/LocaleProvider'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'
import { getTermsLink } from '../../utils/landingLinks'

interface SettingsItemType {
  icon: SvgName
  label: string
  showArrow: boolean
  onPress: () => void
}

interface SectionType {
  title: string
  data: SettingsItemType[]
}

const renderItem: SectionListRenderItem<SettingsItemType, SectionType> = ({
  item,
}) => {
  return (
    <TouchableOpacity
      style={[styles.item, item.showArrow && styles.itemWithArrow]}
      onPress={item.onPress}>
      <View style={appStyles.inlineContainer}>
        <Separator width={7} />
        <SvgIcon
          name={item.icon}
          color={convertHexToRGBA(Colors['layout.dark'], 0.3)}
        />
        <Separator width={18} />
        <LabelText size="medium" color="layout.dark">
          {item.label}
        </LabelText>
      </View>
      {item.showArrow && (
        <View style={appStyles.row}>
          <SvgIcon name="arrow" />
          <Separator width={12} />
        </View>
      )}
    </TouchableOpacity>
  )
}

const renderSectionHeader = ({
  section: { title },
}: {
  section: SectionListData<SettingsItemType, SectionType>
}) =>
  title ? (
    <>
      <Separator height={30} />
      <Typography style={styles.sectionHeaderText}>{title}</Typography>
      <Separator height={4} />
    </>
  ) : (
    <></>
  )

const renderSeparator = () => <View style={styles.itemDivider} />

const renderHeader = () => (
  <View style={styles.languagePickerContainer}>
    <LanguagePickerButton />
  </View>
)

const RenderFooter: React.FC = () => {
  const [codePushVersion, setCodePushVersion] = useState<string>()
  useEffect(() => {
    getCodePushVersion().then(setCodePushVersion)
  }, [])

  return (
    <>
      <Separator height={55} />
      <UserStorageInfo />
      <Separator height={30} />
      <Typography style={styles.appVersionText}>
        {`v${CONFIG.APP_VERSION()}${
          codePushVersion ? ` - ${codePushVersion}` : ''
        }`}
      </Typography>
      <Separator height={10} />
    </>
  )
}

export default function SettingsScreen(): JSX.Element {
  const { logOut } = useUser()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.settings' })
  const { webLayoutEnabled } = useWebLayout()
  const { navigate, goBack } =
    useNavigation<MainStackNavigationType<'Settings'>>()
  const { locale } = useLocale()

  const settingsItems: SettingsItemType[] = useMemo(
    () => [
      {
        icon: 'profile',
        label: t('profileSettings.title'),
        showArrow: true,
        onPress: () => navigate('ProfileSettingsView'),
      },
      {
        icon: 'global',
        label: t('globalEventSettings'),
        showArrow: true,
        onPress: () => navigate('GlobalEventSettings'),
      },
      {
        icon: 'card',
        label: t(
          Platform.OS === 'web'
            ? 'subscriptions.title'
            : 'subscriptions.upgradeInfo.title',
        ),
        showArrow: true,
        onPress: () =>
          navigate(Platform.OS === 'web' ? 'Subscriptions' : 'UpgradeInfo'),
      },
      {
        icon: 'terms',
        label: t('termsAndConditions'),
        showArrow: true,
        onPress: () => Linking.openURL(getTermsLink(locale)),
      },
      {
        icon: 'signout',
        label: t('signOut'),
        showArrow: false,
        onPress: logOut,
      },
    ],
    [t, logOut, navigate, locale],
  )

  const settingsSections: SectionListData<SettingsItemType, SectionType>[] =
    useMemo(
      () => [
        {
          title: '',
          data: settingsItems,
        },
      ],
      [settingsItems],
    )

  return (
    <RootContainer contentContainerStyle={styles.container}>
      <View
        style={[
          useSafeAreaPaddedStyle(styles.header, headerOptions),
          webLayoutEnabled ? styles.webHeaderModifier : appStyles.lightShadow,
        ]}>
        <TitleText size="medium" color="layout.dark">
          {t('title')}
        </TitleText>
        {!webLayoutEnabled && (
          <HeaderIconButton iconName="close" onPress={goBack} />
        )}
      </View>
      <SectionList
        renderItem={renderItem}
        style={appStyles.fullSize}
        sections={settingsSections}
        alwaysBounceVertical={false}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={RenderFooter}
        ListFooterComponentStyle={styles.footer}
        ItemSeparatorComponent={renderSeparator}
        renderSectionHeader={renderSectionHeader}
        SectionSeparatorComponent={renderSeparator}
        contentContainerStyle={useSafeAreaPaddedStyle(styles.contentContainer, {
          insetBottom: true,
        })}
      />
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    paddingBottom: 24,
    paddingHorizontal: 24,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
    minHeight: HEADER_ICON_BUTTON_SIZE + 39, // 39 = paddingTop + paddingBottom
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  webHeaderModifier: { borderBottomWidth: 1 },
  languagePickerContainer: {
    paddingTop: 32,
    paddingLeft: 22,
    paddingBottom: 18,
  },
  contentContainer: {
    flexGrow: 1,
    paddingHorizontal: 24,
    height: Platform.select({ web: 0 }),
  },
  item: {
    paddingVertical: 15,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  itemWithArrow: {
    paddingVertical: 10,
  },
  itemDivider: {
    height: 1,
    opacity: 0.5,
    backgroundColor: Colors['layout.gray'],
  },
  sectionHeaderText: {
    fontSize: 16,
    color: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  footer: { flexGrow: 1, justifyContent: 'flex-end' },
  appVersionText: {
    opacity: 0.7,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
  },
})
