import React, { useState } from 'react'
import {
  Platform,
  Pressable,
  TextStyle,
  ViewStyle,
  StyleSheet,
  PressableProps,
} from 'react-native'

import ButtonText from './Text/ButtonText'
import Colors from '../styles/Colors'
import { convertHexToRGBA, mixinOpacity } from '../utils/colors'

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'header'
  | 'destructive'
  | 'destructive-intense'
  | 'disabled'
  | 'inverted'
  | 'inverted-gray'

interface ButtonColors {
  backgroundColor: string
  textColor: keyof typeof Colors
  hoverColor?: string
  pressedColor?: string
  borderColor?: string
}

function getButtonColors(buttonType: ButtonType): ButtonColors {
  switch (buttonType) {
    case 'primary':
      return {
        backgroundColor: Colors['brand.action'],
        textColor: 'layout.white',
        hoverColor: mixinOpacity(
          Colors['brand.action'],
          0.8,
          Colors['layout.dark'],
        ),
        pressedColor: mixinOpacity(
          Colors['brand.action'],
          0.6,
          Colors['layout.dark'],
        ),
      }
    case 'inverted':
      return {
        backgroundColor: Colors['layout.white'],
        textColor: 'brand.action',
        borderColor: Colors['brand.action'],
        hoverColor: mixinOpacity(
          Colors['brand.action'],
          0.1,
          Colors['layout.white'],
        ),
        pressedColor: mixinOpacity(
          Colors['brand.action'],
          0.3,
          Colors['layout.white'],
        ),
      }
    case 'inverted-gray':
      return {
        backgroundColor: Colors['layout.white'],
        textColor: 'layout.black',
        borderColor: Colors['layout.black'],
        hoverColor: mixinOpacity(
          Colors['layout.black'],
          0.1,
          Colors['layout.white'],
        ),
        pressedColor: mixinOpacity(
          Colors['layout.black'],
          0.3,
          Colors['layout.white'],
        ),
      }
    case 'secondary':
      return {
        backgroundColor: 'transtparent',
        borderColor: Colors['layout.gray-dark'],
        textColor: 'layout.gray-dark',
        hoverColor: mixinOpacity(
          Colors['layout.gray-dark'],
          0.1,
          Colors['layout.white'],
        ),
        pressedColor: mixinOpacity(
          Colors['layout.gray-dark'],
          0.3,
          Colors['layout.white'],
        ),
      }
    case 'header':
      return {
        backgroundColor: Colors['layout.white'],
        textColor: 'layout.dark',
        hoverColor: Colors['layout.white'],
        pressedColor: mixinOpacity(
          Colors['layout.white'],
          0.9,
          Colors['layout.dark'],
        ),
      }
    case 'destructive':
      return {
        backgroundColor: Colors['timeline.light-red'],
        textColor: 'layout.white',
        hoverColor: mixinOpacity(
          Colors['timeline.light-red'],
          0.8,
          Colors['layout.dark'],
        ),
        pressedColor: mixinOpacity(
          Colors['timeline.light-red'],
          0.6,
          Colors['layout.dark'],
        ),
      }
    case 'destructive-intense':
      return {
        backgroundColor: Colors['brand.red'],
        textColor: 'layout.white',
        hoverColor: mixinOpacity(
          Colors['brand.red'],
          0.8,
          Colors['layout.dark'],
        ),
        pressedColor: mixinOpacity(
          Colors['brand.red'],
          0.6,
          Colors['layout.dark'],
        ),
      }
    case 'disabled':
      return {
        backgroundColor: convertHexToRGBA(Colors['brand.action'], 0.5),
        textColor: 'layout.white',
      }
  }
}

const Button: React.FC<
  Omit<PressableProps, 'children' | 'style'> & {
    text: string
    type?: ButtonType
    style?: ViewStyle
    textStyle?: TextStyle
  }
> = ({ text, type = 'primary', style, textStyle, ...props }) => {
  const { backgroundColor, textColor, hoverColor, pressedColor, borderColor } =
    getButtonColors(type)
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Pressable
      disabled={type === 'disabled'}
      {...props}
      style={({ pressed }) => [
        styles.button,
        style,
        { backgroundColor },
        isHovered && {
          backgroundColor: hoverColor,
        },
        !!borderColor && {
          borderColor,
          borderWidth: Platform.select({ web: 2, default: 1 }),
        },
        pressed && {
          backgroundColor: pressedColor,
        },
      ]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}>
      <ButtonText
        size="large"
        color={textColor}
        style={[styles.text, textStyle]}>
        {text}
      </ButtonText>
    </Pressable>
  )
}

export default Button

const styles = StyleSheet.create({
  button: {
    padding: 15,
    width: '100%',
    maxWidth: 244,
    borderRadius: 27,
    alignSelf: 'center',
    backgroundColor: Colors['brand.action'],
  },
  text: {
    textAlign: 'center',
  },
})
