import React, { forwardRef } from 'react'
import {
  Text,
  Platform,
  TextStyle,
  StyleProp,
  TextProperties,
  TextInputProperties,
  TextInput,
} from 'react-native'

import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import FontWeights from '../../styles/FontWeights'

type Weight = 'light' | 'regular' | 'medium' | 'bold'
export type TypographyStyle = Omit<TextStyle, 'fontWeight' | 'fontFamily'>

type TypographyProps<T extends TextProperties | TextInputProperties> = Omit<
  T,
  'styles'
> & {
  style?: StyleProp<TypographyStyle>
  weight?: Weight
}

function getTypeface(
  weight: Weight = 'regular',
): Pick<TextStyle, 'fontWeight' | 'fontFamily'> {
  if (Platform.OS === 'ios')
    return { fontFamily: 'SF Compact', fontWeight: FontWeights[weight] }

  if (Platform.OS === 'android') {
    switch (weight) {
      case 'light':
        return { fontFamily: 'OpenSans-Light' }
      case 'medium':
        return { fontFamily: 'OpenSans-Medium' }
      case 'bold':
        return { fontFamily: 'OpenSans-Bold' }

      default:
        return { fontFamily: 'OpenSans' }
    }
  }

  return { fontWeight: FontWeights[weight] } // TODO: web fontFamily
}

const Typography: React.FC<TypographyProps<TextProperties>> = ({
  style,
  weight,
  ...props
}) => <Text {...props} style={[defaultStyle, style, getTypeface(weight)]} />
export default Typography

/* eslint-disable-next-line react/display-name */
export const TypographyInput = forwardRef<
  TextInput,
  TypographyProps<TextInputProperties>
>(({ style, weight, ...props }, ref) => (
  <TextInput
    {...props}
    ref={ref}
    style={[defaultStyle, style, getTypeface(weight)]}
  />
))

const defaultStyle: TextStyle = {
  color: Colors['layout.dark'],
  ...appStyles.removedOutline,
}
