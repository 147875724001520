import React from 'react'
import { TextProps, StyleSheet } from 'react-native'

import Typography from './Typography'
import Colors from '../../styles/Colors'
import FontSizes from '../../styles/FontSizes'

interface PropsType extends TextProps {
  size: 'small' | 'medium' | 'large'
  color: keyof typeof Colors
}

/**
 * Button text component.
 * Should be adjusted via the style prop as little as possible.
 */
const ButtonText: React.FC<PropsType> = ({
  size,
  color,
  children,
  ...props
}: PropsType) => (
  <Typography
    weight="medium"
    {...props}
    style={[
      styles[size],
      {
        color: Colors[color],
        fontSize: FontSizes[`button.${size}`],
      },
      props.style,
    ]}>
    {children}
  </Typography>
)

export default ButtonText

const styles = StyleSheet.create({
  /* eslint-disable react-native/no-unused-styles */
  small: {
    lineHeight: 17,
  },
  medium: {
    lineHeight: 19,
  },
  large: {
    lineHeight: 21,
  },
  /* eslint-enable react-native/no-unused-styles */
})
