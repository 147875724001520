/**
 * Formats date to locale aware string
 * @param sizeGb - file size in GB to format
 * @returns `string` - file size in MB
 */

export const convertFileSize = (sizeGb: number) => {
  const sizeMb = sizeGb * 1000
  return `${sizeMb < 1 ? '<1' : Math.round(sizeMb)} MB`
}
