import React, { useState, useCallback, Dispatch, SetStateAction } from 'react'
import {
  View,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { MediaThumbnail, MediaThumbnailSeparator } from './MediaThumbnail'
import SvgIcon from './SvgIcon'
import Typography from './Text/Typography'
import WebMediaList from './WebMediaList'
import { AttachmentAttachable } from '../api/types'
import { AssetType } from '../hooks/useMediaActionSheet'
import { MemoryGalleryModal } from '../modals/MemoryGalleryModal'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaItem } from '../types/media-types'

interface MediaListProps {
  canEdit: boolean
  isMediaLoading?: boolean
  media: MediaItem[]
  mediaDescriptions: AttachmentAttachable[]
  galleryTitle: string
  showMediaActions?: (
    item: MediaItem,
    extraAction?: {
      text: string
      action: () => void
    },
  ) => void
  setNewMedia?: Dispatch<SetStateAction<AssetType[]>>
  setMediaDescriptions?: Dispatch<SetStateAction<AttachmentAttachable[]>>
  addMedia?: () => void
  useWebVersion?: boolean
}

export const MediaList: React.FC<MediaListProps> = ({
  canEdit,
  isMediaLoading,
  media,
  mediaDescriptions,
  galleryTitle,
  showMediaActions,
  setNewMedia,
  setMediaDescriptions,
  addMedia,
  useWebVersion,
}) => {
  const { t } = useTranslation()
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0)
  const [memoryGalleryIsVisible, setMemoryGalleryIsVisible] = useState(false)

  const onPress = useCallback((index: number) => {
    setSelectedMediaIndex(index)
    setMemoryGalleryIsVisible(true)
  }, [])

  const onLongPress = useCallback(
    (item: MediaItem) => canEdit && showMediaActions?.(item),
    [canEdit, showMediaActions],
  )

  return (
    <>
      <MemoryGalleryModal
        canEdit={canEdit}
        title={galleryTitle}
        media={media}
        mediaDescriptions={mediaDescriptions}
        selectedIndex={selectedMediaIndex}
        isVisible={memoryGalleryIsVisible}
        showMediaActions={showMediaActions}
        setNewMedia={setNewMedia}
        setMediaDescriptions={setMediaDescriptions}
        close={() => setMemoryGalleryIsVisible(false)}
      />
      {useWebVersion ? (
        <WebMediaList
          attachments={media}
          onPress={onPress}
          onLongPress={onLongPress}
        />
      ) : (
        <FlatList
          horizontal
          data={media}
          overScrollMode="never"
          style={styles.mediaList}
          alwaysBounceHorizontal={false}
          ItemSeparatorComponent={MediaThumbnailSeparator}
          contentContainerStyle={styles.container}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => (
            <MediaThumbnail
              item={item}
              style={styles.mediaItem}
              playIconSize={46}
              onPress={() => onPress(index)}
              onLongPress={() => onLongPress(item)}
            />
          )}
          ListHeaderComponent={
            canEdit ? (
              <View style={appStyles.inlineContainer}>
                <TouchableOpacity style={styles.addButton} onPress={addMedia}>
                  <SvgIcon name="add-media" />
                  <Typography weight="bold" style={styles.addMediaText}>
                    {t('screens.createMemory.addMedia')}
                  </Typography>
                </TouchableOpacity>
                {isMediaLoading && (
                  <>
                    <View
                      style={[
                        styles.mediaItem,
                        appStyles.center,
                        { backgroundColor: Colors['layout.white'] },
                      ]}>
                      <ActivityIndicator color={Colors['brand.action']} />
                    </View>
                    <MediaThumbnailSeparator />
                  </>
                )}
              </View>
            ) : null
          }
        />
      )}
    </>
  )
}

const MEDIA_ITEM_SIZE = 110
const styles = StyleSheet.create({
  container: { paddingHorizontal: 25 },
  mediaList: { flexGrow: 0 },
  addButton: {
    marginRight: 8,
    borderWidth: 1,
    borderRadius: 10,
    ...appStyles.center,
    borderStyle: 'dashed',
    width: MEDIA_ITEM_SIZE,
    height: MEDIA_ITEM_SIZE,
    borderColor: Colors['layout.dark'],
    backgroundColor: Colors['layout.white'],
  },
  mediaItem: {
    borderRadius: 10,
    width: MEDIA_ITEM_SIZE,
    height: MEDIA_ITEM_SIZE,
  },
  addMediaText: {
    fontSize: 11,
  },
})
