// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function errorIsLimitReached(e: any) {
  return (
    !!e.graphQLErrors?.[0]?.extensions?.fullMessages?.find?.(
      (message: string) => message === 'Not enough free space for upload',
    ) ||
    (e.graphQLErrors?.[0]?.path?.[0] === 'createDirectUpload' &&
      e.graphQLErrors?.[0]?.extensions?.message ===
        'You are not authorized to access this page.')
  )
}
