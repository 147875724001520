import React, { useEffect, useMemo, useRef } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import { useTranslation } from 'react-i18next'

import { freeAddon } from './components/PassiveMembershipCard'
import { PassiveMembershipRowMobile } from './components/PassiveMembershipRowMobile'
import { ZohoAddon } from '../../api/types'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import { TermsAndConditionsRow } from '../../components/TermsAndConditionsRow'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import { useLocale } from '../../providers/LocaleProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../utils/colors'

export const SubscriptionsScreenMobilePassive: React.FC<
  SubscriptionsScreenProps
> = ({
  unsubscribe,
  selectedPlan,
  termsAccepted,
  selectedAddon,
  setSelectedPlan,
  currentPlanCode,
  currentAddonCode,
  setSelectedAddon,
  setTermsAccepted,
  openPaymentPageUrl,
}) => {
  const { locale } = useLocale()
  const { t } = useTranslation()
  const scrollViewRef = useRef<ScrollView>(null)
  const addons: ZohoAddon[] = useMemo(() => {
    if (!selectedPlan?.zohoAddons?.nodes) return []
    return [freeAddon, ...selectedPlan.zohoAddons.nodes]
  }, [selectedPlan?.zohoAddons?.nodes])

  const selectedAddonCode = useMemo(() => {
    const addonCode =
      addons.find(addon => addon.addonCode === selectedAddon?.addonCode)
        ?.addonCode ?? ''
    if (currentPlanCode === selectedPlan?.planCode && !addonCode)
      return currentAddonCode ?? ''
    return addonCode
  }, [
    addons,
    currentPlanCode,
    currentAddonCode,
    selectedPlan?.planCode,
    selectedAddon?.addonCode,
  ])

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ y: 0, animated: true })
  }, [])

  useEffect(() => {
    setSelectedAddon(freeAddon)
  }, [setSelectedAddon])

  return (
    <ScrollView
      ref={scrollViewRef}
      style={styles.container}
      contentContainerStyle={styles.scrollContentContainer}>
      <View style={styles.header}>
        <TouchableSvg
          name="back"
          color="layout.dark"
          onPress={() => {
            setSelectedPlan()
            setSelectedAddon()
            setTermsAccepted(false)
          }}
        />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('screens.settings.subscriptions.title')}
        </Typography>
      </View>
      <Separator height={15} />
      <View style={styles.contentContainer}>
        <TitleText size="medium" color="layout.dark" style={styles.titleText}>
          {t('screens.settings.subscriptions.passiveMembership.title')}
        </TitleText>
        <Separator height={20} />
        <Typography weight="light" style={styles.descriptionText}>
          {t('screens.settings.subscriptions.passiveMembership.description')}
        </Typography>
        <Separator height={20} />
        <View style={styles.addonsContainer}>
          <Separator height={10} />
          <TitleText
            size="medium"
            color="layout.dark"
            style={{ color: selectedPlan?.colorCode ?? Colors['layout.dark'] }}>
            {selectedPlan?.name}
          </TitleText>
          <Typography style={styles.planText}>
            {t('screens.settings.subscriptions.passiveMembership.shortTitle')}
          </Typography>
          <Separator height={10} />
          <View style={styles.line} />
          <Separator height={20} />
          {!selectedPlan?.planCode && (
            <Typography weight="bold" style={styles.freePlanAddonText}>
              {t('screens.settings.subscriptions.freePlan.passiveSubscription')}
            </Typography>
          )}
          {addons.map(addon => (
            <View key={addon.id}>
              <PassiveMembershipRowMobile
                addon={addon}
                isSelected={selectedAddonCode === addon.addonCode}
                setSelected={setSelectedAddon}
              />
              <Separator height={10} />
            </View>
          ))}
          <Separator height={10} />
        </View>
        {selectedPlan?.planCode &&
          (selectedPlan?.planCode !== (currentPlanCode ?? '') ||
            selectedAddon?.addonCode !== (currentAddonCode ?? '')) && (
            <>
              <Separator height={20} />
              <TitleText
                size="medium"
                color="layout.dark"
                style={styles.titleText}>
                {t('screens.settings.subscriptions.summary')}
              </TitleText>
              <Separator height={10} />
              {selectedPlan?.planCode !== currentPlanCode && (
                <View style={styles.priceRow}>
                  <Typography style={styles.planText}>
                    {t(
                      `screens.settings.subscriptions.planSubscription.${
                        selectedPlan?.anual ? 'annual' : 'monthly'
                      }`,
                      { planName: selectedPlan?.name },
                    )}
                  </Typography>
                  <Separator width={10} />
                  <Typography style={styles.priceText}>
                    {`${(selectedPlan?.price ?? 0).toLocaleString(locale, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} €`}
                  </Typography>
                </View>
              )}
              <Separator height={10} />
              {selectedAddon?.addonCode !== currentAddonCode && (
                <View style={styles.priceRow}>
                  <Typography style={styles.planText}>
                    {t(
                      'screens.settings.subscriptions.planSubscription.addon',
                      {
                        planName: selectedPlan?.name,
                        timePeriod: selectedAddon?.expiresInYears
                          ? t(
                              'screens.settings.subscriptions.extended.timePeriod',
                              {
                                years: selectedAddon?.expiresInYears,
                              },
                            )
                          : t(
                              'screens.settings.subscriptions.freePlan.timePeriod',
                            ),
                      },
                    )}
                  </Typography>
                  <Separator width={10} />
                  <Typography style={styles.priceText}>
                    {`${(selectedAddon?.price ?? 0).toLocaleString(locale, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} €`}
                  </Typography>
                </View>
              )}
              <Separator height={10} />
              <View style={styles.totalPriceContainer}>
                <TitleText
                  size="medium"
                  color="layout.dark"
                  style={styles.totalText}>
                  {t('screens.settings.subscriptions.total')}
                </TitleText>
                <Separator width={10} />
                <TitleText
                  size="medium"
                  color="layout.dark"
                  style={styles.titleText}>
                  {`${(
                    (selectedPlan?.planCode === currentPlanCode
                      ? 0
                      : selectedPlan?.price ?? 0) + (selectedAddon?.price ?? 0)
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} €`}
                </TitleText>
              </View>
              <Typography style={styles.vatText}>
                {t('screens.settings.subscriptions.vatIncluded')}
              </Typography>
              <Separator height={20} />
              <TermsAndConditionsRow
                isAccepted={termsAccepted}
                setIsAccepted={setTermsAccepted}
              />
              <Separator height={40} />
              <Button
                type={selectedPlan && termsAccepted ? 'primary' : 'disabled'}
                style={appStyles.removedOutline}
                text={t('common.proceed')}
                onPress={openPaymentPageUrl}
              />
            </>
          )}
        {currentPlanCode && (
          <>
            <Separator height={50} />
            <Typography>
              {t('screens.settings.subscriptions.cancel.followLink')}
            </Typography>
            <Separator height={18} />
            <Button
              type="secondary"
              text={t('screens.settings.subscriptions.cancel.title')}
              style={styles.unsubscribeButton}
              onPress={unsubscribe}
            />
          </>
        )}
      </View>
      <Separator height={50} />
      <UserStorageInfo />
      <Separator height={40} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    height: 0,
    flexGrow: 1,
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    paddingHorizontal: 25,
  },
  addonsContainer: {
    borderRadius: 10,
    paddingHorizontal: 14,
    backgroundColor: Colors['layout.white'],
  },
  line: {
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.2),
  },
  titleText: { fontSize: 26 },
  descriptionText: { fontSize: 16 },
  freePlanAddonText: {
    fontSize: 14,
    textAlign: 'center',
  },
  planText: { fontSize: 14 },
  totalText: {
    fontSize: 18,
    lineHeight: 26,
  },
  totalPriceContainer: {
    ...appStyles.row,
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  priceRow: {
    ...appStyles.row,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  priceText: {
    fontSize: 14,
    flexShrink: 0,
  },
  vatText: {
    fontSize: 14,
    textAlign: 'right',
  },
  unsubscribeButton: {
    padding: 5,
    maxWidth: 130,
  },
})
