import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react'
import {
  View,
  Animated,
  Platform,
  Dimensions,
  StyleSheet,
  PanResponder,
  TouchableOpacity,
  LayoutChangeEvent,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { useUser } from './UserProvider'
import { useWebLayout } from './WebLayoutProvider'
import { useGetUserStorageQuery } from '../api/types'
import SvgIcon, { SvgName } from '../components/SvgIcon'
import Typography from '../components/Text/Typography'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type BannerData = {
  icon?: SvgName
  title?: string
  description: string
  color: keyof typeof Colors
}

const BannerContext = createContext<(data: BannerData) => void>(() => {})

const BannerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { webLayoutEnabled } = useWebLayout()
  const [bannerData, setBannerData] = useState<BannerData>()
  const translateY = useRef(new Animated.Value(0)).current
  const translateX = useRef(new Animated.Value(0)).current
  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: Animated.event([null, { dx: translateX }], {
        useNativeDriver: false, // PanResponder does not support native driver
      }),
      onPanResponderRelease: (e, { vx, dx }) => {
        const screenWidth = Dimensions.get('screen').width

        const velocity = vx >= 0 ? Math.max(vx, 0.1) : Math.min(vx, -0.1)
        if (Math.abs(vx) >= 0.5 || Math.abs(dx) >= 0.5 * screenWidth) {
          Animated.spring(translateX, {
            velocity,
            useNativeDriver: true,
            toValue: dx > 0 ? screenWidth : -screenWidth,
          }).start(() => setBannerData(undefined))
        } else {
          Animated.spring(translateX, {
            velocity,
            toValue: 0,
            bounciness: 10,
            useNativeDriver: true,
          }).start()
        }
      },
    }),
  ).current

  const heightRef = useRef<number>(1000)
  const hasEntred = useRef(false)
  const animateEnterance = useCallback(() => {
    if (hasEntred.current) return
    hasEntred.current = true

    translateY.setValue(-heightRef.current)
    Animated.timing(translateY, { useNativeDriver: true, toValue: 0 }).start()
  }, [translateY])

  useEffect(() => {
    if (!bannerData) return

    hasEntred.current = false
    translateY.setValue(-1000)
    translateX.setValue(0)
    setTimeout(animateEnterance, 1000)
  }, [bannerData, translateY, translateX, animateEnterance])

  const { t } = useTranslation()
  const { isLoggedIn } = useUser()
  useGetUserStorageQuery({
    skip: !isLoggedIn,
    nextFetchPolicy: 'cache-only',
    onCompleted: ({ me: { usedStorageGb, allocatedSpaceGb } }) => {
      if (
        usedStorageGb &&
        allocatedSpaceGb &&
        usedStorageGb / allocatedSpaceGb > 0.9
      ) {
        setBannerData({
          icon: 'alert',
          color: 'layout.dark',
          description: t(
            usedStorageGb < allocatedSpaceGb
              ? `banner.dataLimitApproaching.${
                  Platform.OS === 'web' ? 'web' : 'mobile'
                }`
              : `banner.dataLimitReached.${
                  Platform.OS === 'web' ? 'web' : 'mobile'
                }`,
          ),
        })
      }
    },
  })

  useEffect(() => setBannerData(undefined), [isLoggedIn])

  return (
    <BannerContext.Provider value={setBannerData}>
      {children}
      {bannerData && (
        <Animated.View
          style={[
            styles.banner,
            {
              backgroundColor: Colors[bannerData.color],
              transform: [{ translateX }, { translateY }],
            },
          ]}
          onLayout={(e: LayoutChangeEvent) => {
            heightRef.current = e.nativeEvent.layout.height
            animateEnterance()
          }}
          {...(!webLayoutEnabled && panResponder.panHandlers)}>
          {bannerData.icon && (
            <SvgIcon name={bannerData.icon} style={styles.icon} />
          )}
          <View style={styles.textColumn}>
            {!!bannerData.title && (
              <Typography weight="medium" style={styles.title}>
                {bannerData.title}
              </Typography>
            )}
            <Typography style={styles.description}>
              {bannerData.description}
            </Typography>
          </View>
          {webLayoutEnabled && (
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setBannerData(undefined)}>
              <SvgIcon
                width={28}
                height={28}
                name="close"
                color={Colors['layout.white']}
              />
            </TouchableOpacity>
          )}
        </Animated.View>
      )}
    </BannerContext.Provider>
  )
}

export default BannerProvider

export function useBanner() {
  return useContext(BannerContext)
}

const styles = StyleSheet.create({
  banner: {
    gap: 12,
    top: 60,
    left: 10,
    right: 10,
    padding: 12,
    borderRadius: 10,
    ...appStyles.row,
    position: 'absolute',
    ...appStyles.lightShadow,
  },
  icon: { alignSelf: 'center' },
  textColumn: {
    gap: 5,
    ...appStyles.fullSize,
    ...appStyles.verticalCenter,
  },
  title: {
    fontSize: 17,
    color: Colors['layout.white'],
  },
  description: {
    fontSize: 15,
    color: Colors['layout.white'],
  },
  closeButton: { margin: -10 },
})
