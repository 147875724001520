import { useCallback, useRef, useState, Dispatch, SetStateAction } from 'react'

function useStateRef<T>(initial: T): [T, Dispatch<SetStateAction<T>>, () => T] {
  const ref = useRef(initial)
  const [value, setVal] = useState(initial)
  const set: Dispatch<SetStateAction<T>> = useCallback(
    (arg: T | ((current: T) => T)) => {
      const val = arg instanceof Function ? arg(ref.current) : arg
      ref.current = val
      setVal(val)
    },
    [setVal],
  )

  const getCurrent = useCallback(() => ref.current, [])

  return [value, set, getCurrent]
}

export default useStateRef
