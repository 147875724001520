import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { View, FlatList, StyleSheet, Platform } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { GlobalEventCategory } from './components/GlobalEventCategory'
import {
  QuizCategory,
  useGetUserQuery,
  useGetQuizCategoriesQuery,
  useCreateUserCategoryMutation,
  useDeleteUserCategoryMutation,
  useCreateUserSubcategoryMutation,
  useDeleteUserSubCategoryMutation,
} from '../../api/types'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import loggingCore from '../../core/logging-core'
import { useListState } from '../../hooks/useListState'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'

const Footer: React.FC = () => {
  const safeAreaPaddedStyle = useSafeAreaPaddedStyle(
    { paddingBottom: 10 },
    { insetBottom: true },
  )
  return <View style={safeAreaPaddedStyle} />
}

export default function GlobalEventSettingsScreen(): JSX.Element {
  const { goBack } =
    useNavigation<MainStackNavigationType<'GlobalEventSettings'>>()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.settings' })
  const { data: categoriesData } = useGetQuizCategoriesQuery()
  const { data: userData } = useGetUserQuery({ fetchPolicy: 'network-only' })
  const { webLayoutEnabled } = useWebLayout()

  const shouldRefetchRef = useRef<boolean>(false)
  const setShouldRefetch = useSetShouldRefetch()
  useEffect(() => {
    return () => {
      if (shouldRefetchRef.current) {
        setShouldRefetch(QueryKey.CommonMemories)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [createCategory, { loading: isCreatingCategory }] =
    useCreateUserCategoryMutation()
  const [removeCategory, { loading: isDeletingCategory }] =
    useDeleteUserCategoryMutation()
  const [createSubcategory, { loading: isCreatingSubcategory }] =
    useCreateUserSubcategoryMutation()
  const [removeSubcategory, { loading: isDeletingSubcategory }] =
    useDeleteUserSubCategoryMutation()

  const isLoading = useMemo(
    () =>
      isCreatingCategory ||
      isDeletingCategory ||
      isCreatingSubcategory ||
      isDeletingSubcategory,
    [
      isCreatingCategory,
      isDeletingCategory,
      isCreatingSubcategory,
      isDeletingSubcategory,
    ],
  )

  const [
    enabledCategoryIds,
    enableCategory,
    disableCategory,
    setEnabledCategoryIds,
  ] = useListState<string>([])
  const [
    enabledSubcategoryIds,
    enableSubcategory,
    disableSubcategory,
    setEnabledSubcategoryIds,
  ] = useListState<string>([])

  useEffect(() => {
    setEnabledCategoryIds(
      userData?.me?.quizCategories?.nodes.flatMap(category => category.id) ??
        [],
    )
  }, [setEnabledCategoryIds, userData?.me?.quizCategories?.nodes])
  useEffect(() => {
    setEnabledSubcategoryIds(
      userData?.me?.quizSubCategories?.nodes.flatMap(
        subcategory => subcategory.id,
      ) ?? [],
    )
  }, [setEnabledSubcategoryIds, userData?.me?.quizSubCategories?.nodes])

  const handleCategoryChange = useCallback(
    (categoryId: string, enable: boolean) => {
      if (isLoading) return
      shouldRefetchRef.current = true
      if (enable) {
        createCategory({
          variables: {
            userCategory: { quizCategoryId: categoryId },
          },
        })
          .then(() => enableCategory(categoryId))
          .catch(loggingCore.error)
      } else {
        removeCategory({
          variables: {
            id: categoryId,
          },
        })
          .then(() => {
            disableCategory(categoryId)
            const subcategories = categoriesData?.quizCategories.nodes
              .find(category => category.id === categoryId)
              ?.quizSubCategories?.nodes.map(subcategory => subcategory.id)

            setEnabledSubcategoryIds(enabled =>
              enabled.filter(id => !subcategories?.includes(id)),
            )
          })
          .catch(loggingCore.error)
      }
    },
    [
      isLoading,
      createCategory,
      enableCategory,
      removeCategory,
      disableCategory,
      setEnabledSubcategoryIds,
      categoriesData?.quizCategories.nodes,
    ],
  )

  const handleSubcategoryChange = useCallback(
    (subcategoryId: string, enable: boolean) => {
      if (isLoading) return
      shouldRefetchRef.current = true
      if (enable) {
        createSubcategory({
          variables: {
            userSubcategory: { quizSubCategoryId: subcategoryId },
          },
        })
          .then(() => enableSubcategory(subcategoryId))
          .catch(loggingCore.error)
      } else {
        removeSubcategory({
          variables: {
            id: subcategoryId,
          },
        })
          .then(() => disableSubcategory(subcategoryId))
          .catch(loggingCore.error)
      }
    },
    [
      isLoading,
      createSubcategory,
      enableSubcategory,
      removeSubcategory,
      disableSubcategory,
    ],
  )

  const renderSeparator = () => <View style={styles.itemDivider} />

  return (
    <RootContainer>
      <View
        style={[
          useSafeAreaPaddedStyle(styles.header, headerOptions),
          webLayoutEnabled ? styles.webHeaderModifier : appStyles.lightShadow,
        ]}>
        <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.titleText}>
          {t('globalEventSettings')}
        </Typography>
      </View>
      <FlatList
        ListFooterComponent={Footer}
        keyExtractor={category => category.id}
        ItemSeparatorComponent={renderSeparator}
        contentContainerStyle={styles.contentContainer}
        data={categoriesData?.quizCategories.nodes as QuizCategory[]}
        renderItem={({ item }) => (
          <GlobalEventCategory
            category={item}
            handleCategoryChange={handleCategoryChange}
            enabledSubcategoryIds={enabledSubcategoryIds}
            isEnabled={enabledCategoryIds.includes(item.id)}
            handleSubcategoryChange={handleSubcategoryChange}
          />
        )}
      />
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 20,
    paddingLeft: 15,
    paddingBottom: 29,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  webHeaderModifier: { borderBottomWidth: 1 },
  titleText: {
    fontSize: 20,
    lineHeight: 24,
  },
  itemDivider: {
    height: 1,
    opacity: 0.5,
    backgroundColor: Colors['layout.gray'],
  },
  contentContainer: {
    flexGrow: 1,
    marginTop: 15,
    marginHorizontal: 24,
    height: Platform.select({ web: 0 }),
  },
})
