import { useCallback } from 'react'

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'

export default () => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useTranslation()
  return useCallback(
    (
      mediaType: 'video' | 'image' | 'audio',
      action: () => void,
      extraActions: { text: string; action: () => void }[] = [],
    ) => {
      showActionSheetWithOptions(
        {
          options: [
            ...extraActions.map(({ text }) => text),
            t(`screens.createMemory.${mediaType}Options.delete`),
            t('common.cancel'),
          ],
          cancelButtonIndex: extraActions.length + 1,
          destructiveButtonIndex: extraActions.length,
          useModal: true,
        },
        buttonIndex => {
          if (buttonIndex === extraActions.length) {
            action()
          } else if (buttonIndex !== undefined) {
            extraActions[buttonIndex]?.action?.()
          }
        },
      )
    },
    [t, showActionSheetWithOptions],
  )
}
