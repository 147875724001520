import React, { useState, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import loggingCore from '../../core/logging-core'
import { useLocale } from '../../providers/LocaleProvider'
import { useUser } from '../../providers/UserProvider'
import appStyles from '../../styles/app-styles'
import {
  UnauthorizedStackParamsType,
  UnauthorizedStackNavigationType,
} from '../../types/navigation-types'

export default function EmailConfirmationScreen(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } =
    useNavigation<UnauthorizedStackNavigationType<'EmailConfirmation'>>()
  const { params } =
    useRoute<RouteProp<UnauthorizedStackParamsType, 'EmailConfirmation'>>()
  const { confirmEmail } = useUser()

  const { setLocale } = useLocale()
  useEffect(() => {
    params?.language && setLocale(params.language === 'en' ? 'en' : 'de')
  }, [params?.language, setLocale])

  const confirmation_token = params?.confirmation_token
  const [isVerifying, setIsVerifying] = useState<boolean>(!!confirmation_token)

  useEffect(() => {
    if (confirmation_token) {
      confirmEmail({ confirmation_token })
        .then(() => setIsVerifying(false))
        .catch(error => {
          setIsVerifying(false)
          loggingCore.error(error)
        })
    }
  }, [confirmEmail, confirmation_token])

  const renderContent = () => {
    if (isVerifying) return <Loading blocking loading />

    return (
      <>
        <View style={styles.imageHolder}>
          <SvgIcon name="letter" />
          <Separator height={80} />
        </View>
        <View style={styles.contentHolder}>
          <TitleText size="large" color="layout.dark" style={styles.title}>
            {t(`screens.verification.unverified.title`)}
          </TitleText>
          <Separator height={16} />
          <Typography style={styles.subtitle}>
            {t(`screens.verification.unverified.subtitle`)}
          </Typography>
        </View>
        <Button
          type="primary"
          text={t('common.continue')}
          onPress={() => navigate('SignIn')}
        />
        <Separator height={48} />
      </>
    )
  }

  return (
    <SafeAreaView style={appStyles.fullSize}>{renderContent()}</SafeAreaView>
  )
}

const styles = StyleSheet.create({
  imageHolder: {
    flex: 2,
    justifyContent: 'flex-end',
    ...appStyles.horizontalCenter,
  },
  contentHolder: {
    flex: 1,
    paddingHorizontal: 25,
  },
  title: {
    fontSize: 40,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 24,
  },
})
