import React, { PropsWithChildren, useRef, useEffect } from 'react'
import { View, Pressable, StyleSheet, Animated } from 'react-native'

import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

const PickerModal: React.FC<
  PropsWithChildren<{ close: () => void; extraTranslateY?: Animated.Value }>
> = ({ close, extraTranslateY, children }) => {
  const translateY = useRef(new Animated.Value(10000)).current
  const hasEntered = useRef(false)

  const { webLayoutEnabled } = useWebLayout()

  useEffect(() => {
    if (webLayoutEnabled || hasEntered.current) {
      translateY.setValue(0)
    }
  }, [webLayoutEnabled, translateY])

  return (
    <View style={appStyles.fullSize}>
      <Pressable style={styles.backdrop} onPress={close} />
      <View style={styles.maxWidthHolder} pointerEvents="box-none">
        <View style={styles.contentContainer} pointerEvents="box-none">
          <Animated.View
            style={[
              styles.contentHolder,
              webLayoutEnabled && styles.webContentHolder,
              {
                transform: [
                  {
                    translateY: extraTranslateY
                      ? Animated.add(extraTranslateY, translateY)
                      : translateY,
                  },
                ],
              },
            ]}
            onLayout={e => {
              if (hasEntered.current) return

              hasEntered.current = true

              if (webLayoutEnabled) return
              translateY.setValue(e.nativeEvent.layout.height)
              Animated.timing(translateY, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true,
              }).start()
            }}>
            {children}
          </Animated.View>
        </View>
      </View>
    </View>
  )
}

export default PickerModal

const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors['layout.modal-background'],
  },
  maxWidthHolder: {
    paddingTop: 30,
    ...appStyles.row,
    ...appStyles.center,
    ...appStyles.fullSize,
  },
  contentContainer: {
    maxWidth: 600,
    height: '100%',
    ...appStyles.fullSize,
  },
  contentHolder: {
    width: '100%',
    minHeight: '30%',
    maxHeight: '80%',
    marginTop: 'auto',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: Colors['layout.light'],
  },
  webContentHolder: {
    marginBottom: 'auto',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
})
