import { useCallback } from 'react'
import { Platform } from 'react-native'

import { useTranslation } from 'react-i18next'

import { useGetMemoryCreationRestrictionsQuery } from '../api/types'
import loggingCore from '../core/logging-core'
import { useBanner } from '../providers/BannerProvider'

export default function usePassiveUserCheck() {
  const { t } = useTranslation()
  const { refetch } = useGetMemoryCreationRestrictionsQuery({
    skip: true,
    fetchPolicy: 'network-only',
  })
  const showBanner = useBanner()

  const checkIsPassiveUser = useCallback(
    async (skip: boolean) => {
      if (skip) return Promise.resolve(false)
      return refetch()
        .then(({ data }) => {
          const passive = data?.me?.passive
          if (!passive) return false
          const descriptionSuffix = Platform.OS === 'web' ? 'web' : 'mobile'

          showBanner({
            icon: 'alert',
            color: 'brand.red',
            description: t(
              `banner.passiveCannotAddMemories.${descriptionSuffix}`,
            ),
          })
          return true
        })
        .catch(loggingCore.error)
    },
    [t, showBanner, refetch],
  )

  return checkIsPassiveUser
}
