import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export const useListState = <T,>(
  initState: T[],
  isEqual: (a: T, b: T) => boolean = (a, b) => a === b,
): [
  T[],
  (item: T) => void,
  (item: T) => void,
  Dispatch<SetStateAction<T[]>>,
] => {
  const [state, setState] = useState<T[]>(initState)

  const push = useCallback((item: T) => {
    setState(prev => [...prev, item])
  }, [])

  const pop = useCallback(
    (item: T) => {
      setState(prev => prev.filter(i => !isEqual(i, item)))
    },
    [isEqual],
  )

  return [state, push, pop, setState]
}
