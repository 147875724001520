import React, { PropsWithChildren } from 'react'
import { View, StyleSheet } from 'react-native'

import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

const ModalFooter: React.FC<PropsWithChildren> = ({ children }) => (
  <View style={styles.footer}>{children}</View>
)

export default ModalFooter

const styles = StyleSheet.create({
  footer: {
    padding: 20,
    backgroundColor: Colors['layout.white'],
    ...appStyles.lightShadow,
    shadowRadius: 2,
    shadowOffset: { height: 0, width: 0 },
  },
})
