import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import EnglishSvg from '../assets/svg/flag/english.svg'
import GermanSvg from '../assets/svg/flag/german.svg'
import { Locale } from '../types/localization-types'

const FlagSvg: React.FC<SvgProps & { locale: Locale }> = ({
  locale,
  ...props
}) => {
  const Component = useMemo(() => {
    switch (locale) {
      case 'en':
        return EnglishSvg
      case 'de':
        return GermanSvg
    }
  }, [locale])

  return <Component viewBox="0 0 40 40" {...props} />
}

export default FlagSvg
