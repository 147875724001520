import React from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'

import { ApolloError } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useUnsubscribeMutation } from '../../api/types'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import Drawer from '../../navigation/Drawer'
import { useBanner } from '../../providers/BannerProvider'
import {
  useWebLayout,
  SIDE_COLUMN_WIDTH,
} from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { SUBSCRIPTIONS_CONTENT_MIN_WIDTH } from '../subscriptions/SubscriptionsScreen'

export default function UnsubscribeScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions.cancel',
  })
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'Unsubscribe'>>()
  const { webLayoutEnabled } = useWebLayout()
  const [unsubscribe] = useUnsubscribeMutation()
  const dimensions = useWindowDimensions()
  const showDrawer =
    dimensions.width >= SUBSCRIPTIONS_CONTENT_MIN_WIDTH + SIDE_COLUMN_WIDTH
  const showBanner = useBanner()

  return (
    <View style={styles.container}>
      {showDrawer && <Drawer />}
      <View style={appStyles.fullSize}>
        <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.headerText}>
            {t('confirmation')}
          </Typography>
        </View>
        <View style={styles.contentContainer}>
          <Separator height={35} />
          <TitleText size="medium" color="layout.dark">
            {t('warning.title')}
          </TitleText>
          <Separator height={30} />
          <View style={styles.infoContainer}>
            <View style={appStyles.inlineContainer}>
              <SvgIcon name="cross" />
              <Typography weight="medium" style={styles.warningText}>
                {t('warning.activeUntilPaidEnds')}
              </Typography>
            </View>
          </View>
          <Separator height={10} />
          <View style={styles.infoContainer}>
            <View style={appStyles.inlineContainer}>
              <SvgIcon name="cross" />
              <Typography weight="medium" style={styles.warningText}>
                {t('warning.resetToFree')}
              </Typography>
            </View>
          </View>
          <Separator height={35} />
          <TitleText size="medium" color="layout.dark">
            {t('note.title')}
          </TitleText>
          <Separator height={30} />
          <View style={styles.infoContainer}>
            <View style={appStyles.inlineContainer}>
              <SvgIcon name="cross" />
              <Typography weight="medium" style={styles.warningText}>
                {t('note.confirmation')}
              </Typography>
            </View>
          </View>
          <Separator height={40} />
          <Button
            type="destructive"
            text={t('title')}
            style={webLayoutEnabled ? styles.buttonWeb : styles.button}
            onPress={() => {
              unsubscribe()
                .then(() => navigate('Timeline'))
                .catch(err => {
                  if (
                    (err as ApolloError)?.graphQLErrors?.[0]?.extensions
                      ?.message === 'already_unsubscribed'
                  ) {
                    showBanner({
                      icon: 'alert',
                      color: 'brand.red',
                      description: t('alreadyUnsubscribed'),
                    })
                  }
                })
            }}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.row,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.white'],
  },
  contentContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 24,
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 20,
    paddingBottom: 29,
    paddingHorizontal: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  infoContainer: {
    marginHorizontal: 10,
  },
  warningText: {
    fontSize: 18,
    ...appStyles.fullSize,
  },
  button: {
    marginBottom: 48,
    marginTop: 'auto',
  },
  buttonWeb: {
    marginTop: 40,
    alignSelf: 'flex-start',
  },
})
