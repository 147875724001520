import { useEffect } from 'react'
import { Platform } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { MainStackNavigationType } from '../types/navigation-types'

/**
 * Prevents closing current screen in case of unsaved changes
 */
export default function useUnsavedChanges(
  isUnsaved: boolean,
  getShouldSkip?: () => boolean,
) {
  const navigation = useNavigation<MainStackNavigationType<'Root'>>()

  useEffect(() => {
    if (!isUnsaved) return

    const unsub = navigation.addListener('beforeRemove', e => {
      // If we don't have unsaved changes, then we don't need to do anything
      if (getShouldSkip?.()) return

      // Prevent default behavior of leaving the screen
      e.preventDefault()

      navigation.navigate('UnsavedChanges', { closeAction: e.data.action })
    })

    if (Platform.OS !== 'web') return unsub
    // @ts-ignore
    window.onbeforeunload = () => ''

    return () => {
      unsub()
      // @ts-ignore
      window.onbeforeunload = undefined
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, isUnsaved])
}
