import React from 'react'
import { ImageBackground, StyleSheet, Platform } from 'react-native'

import { useTranslation } from 'react-i18next'

import ProgressBar from '../../../components/ProgressBar'
import TouchableSvg from '../../../components/TouchableSvg'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { mixinOpacity } from '../../../utils/colors'

const cultureHeaderImage = require('../../../assets/globalEvents/culture.jpeg')
const politicsHeaderImage = require('../../../assets/globalEvents/politics.jpeg')
const sportsHeaderImage = require('../../../assets/globalEvents/sports.jpeg')
const quizHeaderImages = [
  sportsHeaderImage,
  cultureHeaderImage,
  politicsHeaderImage,
]

interface EventsQuizHeaderProps {
  stepIndex: number
  quizLength: number
  navigateBack: () => void
}

export const EventsQuizHeader: React.FC<EventsQuizHeaderProps> = ({
  stepIndex,
  quizLength,
  navigateBack,
}) => {
  const { t } = useTranslation()

  return (
    <ImageBackground
      imageStyle={styles.headerImage}
      source={quizHeaderImages[stepIndex]}
      style={[appStyles.inlineContainer, styles.header]}>
      <TouchableSvg
        name="back"
        color="layout.white"
        style={styles.backButton}
        onPress={navigateBack}
      />
      <ProgressBar
        maxSteps={quizLength}
        progressOpacity={0.5}
        textColor="layout.white"
        currentStep={stepIndex + 1}
        progressColor="layout.white"
        stepTitle={t('screens.globalEventsQuiz.question')}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  headerImage: {
    width: Platform.select({ web: '100%' }),
    backgroundColor: mixinOpacity(
      Colors['layout.dark'],
      0.5,
      Colors['layout.black'],
    ),
  },
  header: {
    paddingTop: 51,
    paddingLeft: 15,
    paddingRight: 43,
    paddingBottom: 41,
  },
  backButton: { marginRight: 10 },
})
