import React, {
  useMemo,
  useState,
  Dispatch,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react'

type MediaLibraryContextType = {
  isSelecting: boolean
  isUploadingMedia: boolean
  setIsSelecting: Dispatch<React.SetStateAction<boolean>>
  setIsUploadingMedia: Dispatch<React.SetStateAction<boolean>>
}

const MediaLibraryContext = createContext<MediaLibraryContextType>({
  isSelecting: false,
  isUploadingMedia: false,
  setIsSelecting: () => {},
  setIsUploadingMedia: () => {},
})

const MediaLibraryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const [isUploadingMedia, setIsUploadingMedia] = useState(false)

  return (
    <MediaLibraryContext.Provider
      value={useMemo(
        () => ({
          isSelecting,
          isUploadingMedia,
          setIsSelecting,
          setIsUploadingMedia,
        }),
        [isSelecting, isUploadingMedia],
      )}>
      {children}
    </MediaLibraryContext.Provider>
  )
}

export default MediaLibraryProvider

export function useMediaLibraryState() {
  return useContext(MediaLibraryContext)
}
