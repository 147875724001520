import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'

import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { LoadingProps } from '../types/Loading.types'

const Loading: React.FC<LoadingProps> = ({ loading, blocking }) => {
  if (!loading) return null

  return (
    <>
      {blocking && <View style={styles.overlay} />}
      <View style={styles.hudView}>
        <ActivityIndicator size="large" color={Colors['brand.action']} />
      </View>
    </>
  )
}

export default Loading

const styles = StyleSheet.create({
  hudView: {
    top: '40%',
    padding: 20,
    zIndex: 1000,
    borderRadius: 10,
    alignSelf: 'center',
    position: 'absolute',
    ...appStyles.lightShadow,
    backgroundColor: Colors['layout.white'],
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    ...appStyles.center,
    zIndex: 1000,
    backgroundColor: '#0002',
  },
})
