import React, { PropsWithChildren } from 'react'
import { View, TouchableOpacity, StyleSheet, Linking } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Button from './Button'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import Typography from '../components/Text/Typography'
import { useWebLayout, SIDE_COLUMN_WIDTH } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import FontSizes from '../styles/FontSizes'
import { MainStackNavigationType } from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'

const LEARN_MORE_LINK = 'https://relefant.eu/faq.html'
const ANDROID_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.relefant.app'
const IOS_STORE_LINK = 'https://apps.apple.com/us/app/relefant/id6462407658'

const openUrlOnPress = (url: string) => () => Linking.openURL(url)

const WebInfoColumn: React.FC<PropsWithChildren> = ({ children }) => {
  const { navigate } = useNavigation<MainStackNavigationType<'Root'>>()
  const { t } = useTranslation()

  const webLayout = useWebLayout()
  if (!webLayout.showRightColumn) return null

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Typography weight="medium" style={styles.sectionTitle}>
          {t('webInfo.title')}
        </Typography>
        <Separator height={10} />
        <Typography>{t('webInfo.subtitle')}</Typography>
        <Separator height={4} />
        <Typography
          style={styles.learnMoreButton}
          onPress={openUrlOnPress(LEARN_MORE_LINK)}>
          {t('webInfo.learnMore')}
        </Typography>
      </View>
      <View style={styles.section}>
        <Typography weight="medium" style={styles.sectionTitle}>
          {t('webInfo.appTitle')}
        </Typography>
        <Separator height={10} />
        <Typography>{t('webInfo.appDescription')}</Typography>
        <Separator height={4} />
        <SvgIcon name="app-logo" />
        <Separator height={10} />
        <View style={appStyles.row}>
          <TouchableOpacity onPress={openUrlOnPress(IOS_STORE_LINK)}>
            <SvgIcon name="store-ios" />
          </TouchableOpacity>
          <Separator width={10} />
          <TouchableOpacity onPress={openUrlOnPress(ANDROID_STORE_LINK)}>
            <SvgIcon name="store-android" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.section}>
        <SvgIcon name="subscriptions" />
        <Separator height={10} />

        <Typography weight="medium" style={styles.sectionTitle}>
          {t('webInfo.subscriptionFreeDescription')}
        </Typography>
        <Separator height={10} />
        <Button
          text={t('webInfo.subscriptionButton')}
          style={styles.subscriptionsButton}
          onPress={() => navigate('Subscriptions')}
        />
      </View>
      {children}
    </View>
  )
}

export default WebInfoColumn

const borderColor = convertHexToRGBA(Colors['layout.gray'], 0.5)
const styles = StyleSheet.create({
  container: {
    borderColor,
    paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 122,
    borderLeftWidth: 1,
    width: SIDE_COLUMN_WIDTH,
    backgroundColor: Colors['layout.white'],
  },
  section: {
    borderColor,
    paddingVertical: 25,
    borderBottomWidth: 1,
  },
  sectionTitle: { fontSize: FontSizes['label.medium'] },
  learnMoreButton: {
    alignSelf: 'flex-start',
    color: Colors['brand.action'],
  },
  subscriptionsButton: {
    width: '70%',
    alignSelf: 'flex-start',
  },
})
