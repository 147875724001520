import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  View,
  FlatList,
  Platform,
  StyleSheet,
  SectionList,
  ListRenderItem,
  RefreshControl,
  SectionListData,
  TouchableOpacity,
  ScrollView,
  BackHandler,
} from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { useActionSheet } from '@expo/react-native-action-sheet'
import {
  useRoute,
  RouteProp,
  useNavigation,
  CommonActions,
  useIsFocused,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { FilterItem } from './components/FilterItem'
import { MediaLibraryWrapper } from './components/MediaLibraryWrapper'
import {
  DatePrecisionEnum,
  useGetLibraryQuery,
  useDeleteAttachmentMutation,
  useUpdateAttachmentMutation,
} from '../../api/types'
import HeaderButton from '../../components/HeaderButton'
import HeaderIconButton from '../../components/HeaderIconButton'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import { MediaThumbnail } from '../../components/MediaThumbnail'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import ButtonText from '../../components/Text/ButtonText'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import { shareAttachments } from '../../helpers/ShareAttachments'
import { useListState } from '../../hooks/useListState'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { MediaDescriptionModal } from '../../modals/MediaDescriptionModal'
import { useLocale } from '../../providers/LocaleProvider'
import { useMediaLibraryState } from '../../providers/MediaLibraryProvider'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MediaItem } from '../../types/media-types'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { formatDate } from '../../utils/date'
import { convertFileSize } from '../../utils/fileSize'

type GallerySectionType = {
  date: Date
  data: MediaItem[][]
}

export type FilterType = 'all' | 'picture' | 'video' | 'audio'
const ALL_FILTERS: FilterType[] = ['picture', 'video', 'audio', 'all']
type SortingType = 'date' | 'size'

const ItemRowSeparator = () => <Separator height={5} />

const ITEM_GAP_WIDTH = 5
const CONTENT_HORIZONTAL_PADDING = 10
const LIST_HEADER_HORIZONTAL_PADDING = 14
const CONTENT_HORIZONTAL_PADDING_MODAL = 30

export default function MediaLibraryScreen(): JSX.Element {
  const { t } = useTranslation()
  const { navigate, goBack, dispatch } =
    useNavigation<MainStackNavigationType<'MediaLibraryPicker'>>()
  const { params } =
    useRoute<RouteProp<MainStackParamsType, 'MediaLibraryPicker'>>()
  const { webLayoutEnabled } = useWebLayout()
  const focused = useIsFocused()
  const isInAddMode = useRef(!!params?.routeKey)
  const [viewWidth, setViewWidth] = useState(3 * ITEM_GAP_WIDTH)
  const itemWidth = useMemo(
    () =>
      (viewWidth -
        (webLayoutEnabled
          ? isInAddMode.current
            ? CONTENT_HORIZONTAL_PADDING_MODAL
            : CONTENT_HORIZONTAL_PADDING
          : 0) *
          2 -
        3 * ITEM_GAP_WIDTH) /
      4,
    [viewWidth, webLayoutEnabled],
  )
  const { locale } = useLocale()
  const [selectedFilter, setSelectedFilter] = useState<FilterType>(
    params?.availableFilters?.[0] ?? 'picture',
  )
  const filtersViewref = useRef<ScrollView>(null)
  const [selectedIds, pushSelectedId, popSelectedId, setSelectedIds] =
    useListState<string>([])
  const [mediaDescriptionIsVisible, setMediaDescriptionIsVisible] =
    useState(false)
  const [selectedItem, setSelectedItem] = useState<MediaItem>()
  const { showActionSheetWithOptions } = useActionSheet()
  const { user } = useUser()
  const [selectedSorting, setSelectedSorting] = useState<SortingType>('date')
  const { data, loading, refetch, networkStatus } = useGetLibraryQuery({
    skip: !user?.libraryId,
    variables: { id: user?.libraryId ?? '' },
  })
  const [updateAttachment] = useUpdateAttachmentMutation()
  const [deleteAttachment] = useDeleteAttachmentMutation()
  const [isShareLoading, setIsShareLoading] = useState(false)
  const { isSelecting, isUploadingMedia, setIsSelecting } =
    useMediaLibraryState()

  useEffect(() => {
    if (isInAddMode.current || !focused || !isSelecting) return

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        setIsSelecting(false)
        return true
      },
    )

    return () => backHandler.remove()
  }, [focused, isSelecting, setIsSelecting])

  useEffect(() => {
    setIsSelecting(isInAddMode.current)
    return () => setIsSelecting(false)
  }, [setIsSelecting])

  useEffect(() => {
    if (!isSelecting) setSelectedIds([])
  }, [isSelecting, setSelectedIds])

  const sortedLibraryData = useMemo(() => {
    const attachments = [...(data?.library.attachments?.nodes ?? [])]
    if (selectedSorting !== 'size') return attachments
    return (
      attachments
        .filter(a => a.url !== null)
        .sort((a, b) => (b.blobSizeGb ?? 0) - (a.blobSizeGb ?? 0)) ?? []
    )
  }, [data, selectedSorting])

  const libraryData: GallerySectionType[] = useMemo(() => {
    if (selectedSorting !== 'date')
      return [{ date: new Date(), data: [sortedLibraryData] }]
    const sections: GallerySectionType[] = []
    sortedLibraryData.forEach(item => {
      if (item.url === null) return
      const date = new Date(item.createdAt)
      const year = date.getFullYear()
      const month = date.getMonth()
      const section = sections.find(
        sec => sec.date.getFullYear() === year && sec.date.getMonth() === month,
      )
      if (section) {
        section.data[0].push(item)
      } else {
        sections.push({
          date,
          data: [[item]],
        })
      }
    })
    return sections
  }, [sortedLibraryData, selectedSorting])

  const filteredLibraryData: GallerySectionType[] = useMemo(() => {
    const filteredData = (libraryData as GallerySectionType[]).map(section => {
      const filteredSection = section.data[0].filter(item => {
        switch (selectedFilter) {
          case 'picture':
            return item.contentType?.startsWith('image') ?? false
          case 'video':
            return item.contentType?.startsWith('video') ?? false
          case 'audio':
            return item.contentType?.startsWith('audio') ?? false
          default:
            return (
              !isInAddMode.current ||
              (!item.contentType?.startsWith('audio') ?? false)
            )
        }
      })
      return {
        date: section.date,
        data: [filteredSection],
      }
    })
    return filteredData.filter(section => section.data[0].length > 0)
  }, [libraryData, selectedFilter])

  const allCount = useMemo(
    () => sortedLibraryData.filter(item => item.url !== null).length,
    [sortedLibraryData],
  )

  const pictureCount = useMemo(
    () =>
      sortedLibraryData.reduce((accumulator, item) => {
        if (item.contentType?.startsWith('image')) {
          return accumulator + 1
        }

        return accumulator
      }, 0),
    [sortedLibraryData],
  )

  const videoCount = useMemo(
    () =>
      sortedLibraryData.reduce((accumulator, item) => {
        if (item.contentType?.startsWith('video')) {
          return accumulator + 1
        }

        return accumulator
      }, 0),
    [sortedLibraryData],
  )

  const audioCount = useMemo(
    () =>
      sortedLibraryData.reduce((accumulator, item) => {
        if (item.contentType?.startsWith('audio')) {
          return accumulator + 1
        }

        return accumulator
      }, 0),
    [sortedLibraryData],
  )

  const counts: { [key: string]: number } = useMemo(
    () => ({
      all: allCount,
      picture: pictureCount,
      video: videoCount,
      audio: audioCount,
    }),
    [allCount, audioCount, pictureCount, videoCount],
  )

  const renderSectionHeader: (info: {
    section: SectionListData<MediaItem[], GallerySectionType>
  }) => React.ReactElement = ({ section: { date } }) =>
    selectedSorting === 'date' ? (
      <View style={styles.sectionHeader}>
        <Typography weight="medium" style={styles.sectionHeaderText}>
          {formatDate(date, locale, DatePrecisionEnum.Month)}
        </Typography>
      </View>
    ) : (
      <></>
    )

  const renderItem: ListRenderItem<MediaItem> = ({ item }) => {
    const isSelected = selectedIds.includes(item.id)
    return (
      <View>
        <MediaThumbnail
          item={item}
          style={[
            styles.itemContainer,
            { height: itemWidth, width: itemWidth },
            webLayoutEnabled && styles.itemContainerRounded,
          ]}
          playIconSize={50}
          onPress={() => {
            if (!isSelecting) {
              if (item.contentType?.startsWith('audio') ?? false) {
                navigate('AudioPlayback', {
                  url: item.url ?? '',
                  name: item.description ?? '',
                })
                return
              }

              setSelectedItem(item)
              setMediaDescriptionIsVisible(true)
              return
            }
            isSelected ? popSelectedId(item.id) : pushSelectedId(item.id)
          }}
          onLongPress={() => {
            if (webLayoutEnabled) return
            if (!isSelected) pushSelectedId(item.id)
            setIsSelecting(true)
          }}
        />
        {selectedSorting === 'size' && (
          <View style={styles.fileSizeBadge}>
            <Typography>{convertFileSize(item.blobSizeGb ?? 0)}</Typography>
          </View>
        )}
        {isSelected && (
          <SvgIcon name="selected" style={styles.selectionCheck} />
        )}
      </View>
    )
  }

  const renderSection: ListRenderItem<MediaItem[]> = ({ item }) => (
    <FlatList
      numColumns={4}
      data={item}
      renderItem={renderItem}
      keyExtractor={media => media.id}
      ItemSeparatorComponent={ItemRowSeparator}
    />
  )

  const renderListHeader = () => (
    <>
      {webLayoutEnabled && (
        <View style={styles.tabHeaderWeb}>
          <ListHeader
            title={t('screens.mediaLibrary.title')}
            subtitle={t('screens.mediaLibrary.subtitle')}
            negatablePaddingSource={{
              ...styles.tabHeaderWeb,
              paddingHorizontal:
                styles.tabHeaderWeb.paddingHorizontal +
                (isInAddMode.current
                  ? CONTENT_HORIZONTAL_PADDING_MODAL
                  : CONTENT_HORIZONTAL_PADDING),
            }}>
            <HeaderIconButton iconName="sort" onPress={showSortActionSheet} />
            {!isInAddMode.current && (
              <>
                <Separator width={10} />
                <HeaderButton
                  secondary
                  text={
                    isSelecting
                      ? t('common.cancel')
                      : t('screens.mediaLibrary.select')
                  }
                  onPress={() => setIsSelecting(c => !c)}
                />
              </>
            )}
          </ListHeader>
        </View>
      )}
      <ScrollView
        ref={filtersViewref}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.listFiltersContainer}>
        {(params?.availableFilters ?? ALL_FILTERS).map((filter, index) => (
          <View key={filter} style={appStyles.row}>
            <FilterItem
              title={
                t(`screens.mediaLibrary.${filter}`) + ` (${counts[filter]})`
              }
              isSelected={selectedFilter === filter}
              onPress={() => {
                setSelectedFilter(filter)
                setTimeout(() => {
                  if (index >= 2) filtersViewref.current?.scrollToEnd()
                }, 0)
              }}
            />
            {index !== ALL_FILTERS.length && <Separator width={8} />}
          </View>
        ))}
      </ScrollView>
      {!webLayoutEnabled && (
        <>
          <View style={styles.tabHeader}>
            <ListHeader
              title={t('screens.mediaLibrary.title')}
              subtitle={t('screens.mediaLibrary.subtitle')}>
              <HeaderIconButton iconName="sort" onPress={showSortActionSheet} />
            </ListHeader>
          </View>
        </>
      )}
    </>
  )

  const optionsStyle = useSafeAreaPaddedStyle(styles.selectionContainer, {
    insetBottom: true,
  })

  const showSortActionSheet = () =>
    showActionSheetWithOptions(
      {
        title: t('screens.mediaLibrary.sort.title'),
        options: [
          t('screens.mediaLibrary.sort.size'),
          t('screens.mediaLibrary.sort.date'),
          t('common.cancel'),
        ],
        cancelButtonIndex: 2,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            setSelectedSorting('size')
            break
          case 1:
            setSelectedSorting('date')
            break
          default:
            break
        }
      },
    )

  const getSelectedAttachments = useCallback(
    () => sortedLibraryData.filter(item => selectedIds.includes(item.id)),
    [selectedIds, sortedLibraryData],
  )

  const showCreateMemoryActionSheet = useCallback(() => {
    if (selectedIds.length === 0) return
    showActionSheetWithOptions(
      {
        options: [t('screens.mediaLibrary.createMemory'), t('common.cancel')],
        cancelButtonIndex: 1,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0: {
            const selectedAttachments = getSelectedAttachments()
            navigate('MemoryForm', {
              attachments: {
                media: selectedAttachments.filter(
                  item => !item.contentType?.startsWith('audio'),
                ),
                audio: selectedAttachments.filter(item =>
                  item.contentType?.startsWith('audio'),
                ),
              },
            })
            break
          }
          default:
            break
        }
      },
    )
  }, [
    t,
    navigate,
    selectedIds.length,
    getSelectedAttachments,
    showActionSheetWithOptions,
  ])

  const showDeleteActionSheet = useCallback(() => {
    if (selectedIds.length === 0) return
    showActionSheetWithOptions(
      {
        options: [t('screens.mediaLibrary.delete'), t('common.cancel')],
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            getSelectedAttachments().forEach(item => {
              deleteAttachment({
                variables: { id: item.id },
                update(cache) {
                  const normalizedId = cache.identify({
                    id: item.id,
                    __typename: 'Attachment',
                  })
                  cache.evict({ id: normalizedId })
                  cache.gc()
                },
              })
            })
            setIsSelecting(false)
            break
          default:
            break
        }
      },
    )
  }, [
    t,
    setIsSelecting,
    deleteAttachment,
    selectedIds.length,
    getSelectedAttachments,
    showActionSheetWithOptions,
  ])

  const shareSelected = useCallback(() => {
    if (selectedIds.length === 0) return
    setIsShareLoading(true)
    shareAttachments(
      getSelectedAttachments(),
      () => setIsShareLoading(false),
      () => {
        setIsSelecting(false)
      },
    )
  }, [setIsSelecting, selectedIds.length, getSelectedAttachments])

  const addMediaToForm = useCallback(() => {
    const selectedAttachments = getSelectedAttachments()
    const media = selectedAttachments.filter(
      a => !a.contentType?.startsWith('audio'),
    )
    const audio = selectedAttachments.filter(a =>
      a.contentType?.startsWith('audio'),
    )
    dispatch({
      ...CommonActions.setParams({
        addedAttachments: { media, audio },
      }),
      source: params?.routeKey,
    })
    goBack()
  }, [dispatch, getSelectedAttachments, goBack, params?.routeKey])

  const sectionListStyle = useSafeAreaPaddedStyle(
    {
      paddingBottom: isSelecting ? 100 : webLayoutEnabled ? 10 : 150,
      paddingHorizontal: webLayoutEnabled
        ? isInAddMode.current
          ? CONTENT_HORIZONTAL_PADDING_MODAL
          : CONTENT_HORIZONTAL_PADDING
        : 0,
    },
    {
      insetBottom: true,
    },
  )

  const headerStyle = useSafeAreaPaddedStyle(styles.header, headerOptions)

  return (
    <MediaLibraryWrapper isInAddMode={isInAddMode.current}>
      <Loading
        blocking={isShareLoading}
        loading={isShareLoading || isUploadingMedia}
      />
      {isInAddMode.current && (
        <View style={headerStyle}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <HeaderButton
            secondary
            text={t('common.add')}
            onPress={addMediaToForm}
          />
        </View>
      )}
      <MediaDescriptionModal
        isVisible={mediaDescriptionIsVisible}
        autofocus={false}
        media={selectedItem}
        saveDescription={(item, description) => {
          updateAttachment({
            variables: {
              id: item.id,
              attachmentInput: {
                id: item.id,
                description,
                fileBlobId: item.signedBlobId,
              },
            },
          })
        }}
        close={() => setMediaDescriptionIsVisible(false)}
      />
      <View
        style={appStyles.fullSize}
        onLayout={event => {
          const { width } = event.nativeEvent.layout
          setViewWidth(width)
        }}>
        <SectionList
          overScrollMode="never"
          style={styles.sectionList}
          renderItem={renderSection}
          ListHeaderComponent={renderListHeader}
          contentContainerStyle={sectionListStyle}
          renderSectionHeader={renderSectionHeader}
          keyExtractor={(_item, index) => index.toString()}
          sections={filteredLibraryData as GallerySectionType[]}
          refreshControl={
            <RefreshControl
              refreshing={loading || networkStatus === NetworkStatus.refetch}
              onRefresh={refetch}
            />
          }
        />
      </View>
      <View pointerEvents="box-none" style={styles.footer}>
        {isSelecting && (
          <View style={optionsStyle}>
            <Typography style={styles.selectedText}>
              {t('screens.mediaLibrary.selected', {
                fileCount: selectedIds.length,
              })}
            </Typography>
            <Separator width={10} />
            {!isInAddMode.current && (
              <View style={styles.actionButtonsContainer}>
                <TouchableOpacity
                  // @ts-ignore
                  hitSlop={10}
                  onPress={showCreateMemoryActionSheet}>
                  <ButtonText
                    size="medium"
                    numberOfLines={1}
                    color="brand.blue">
                    {t('screens.mediaLibrary.createMemory')}
                  </ButtonText>
                </TouchableOpacity>
                <Separator width={5} />
                {Platform.OS !== 'web' && (
                  <TouchableOpacity
                    // @ts-ignore
                    hitSlop={10}
                    onPress={shareSelected}>
                    <SvgIcon
                      name="share-ios"
                      height={40}
                      width={40}
                      color={Colors['brand.blue']}
                    />
                  </TouchableOpacity>
                )}
                <Separator width={5} />
                <TouchableOpacity
                  // @ts-ignore
                  hitSlop={10}
                  onPress={showDeleteActionSheet}>
                  <SvgIcon
                    name="bin"
                    height={40}
                    width={40}
                    color={Colors['brand.red']}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        )}
      </View>
    </MediaLibraryWrapper>
  )
}

const styles = StyleSheet.create({
  tabHeader: {
    marginLeft: -8,
    paddingTop: 10,
    marginRight: -10,
    paddingHorizontal: 34,
  },
  tabHeaderWeb: {
    paddingTop: 27,
    paddingHorizontal: LIST_HEADER_HORIZONTAL_PADDING,
  },
  header: {
    paddingTop: 20,
    paddingBottom: 29,
    paddingHorizontal: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  sectionList: {
    height: 0,
  },
  listFiltersContainer: {
    padding: 10,
  },
  sectionHeader: {
    backgroundColor: Colors['layout.light'],
  },
  sectionHeaderText: {
    fontSize: 20,
    marginLeft: 24,
    marginVertical: 15,
  },
  itemContainer: {
    marginRight: 5,
  },
  itemContainerRounded: {
    borderRadius: 15,
  },
  selectionCheck: {
    right: 8,
    bottom: 3,
    position: 'absolute',
  },
  fileSizeBadge: {
    left: 5,
    bottom: 5,
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 5,
    position: 'absolute',
    backgroundColor: Colors['layout.white'],
  },
  selectionContainer: {
    ...appStyles.row,
    paddingVertical: 24,
    paddingHorizontal: 10,
    alignItems: 'baseline',
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  actionButtonsContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  selectedText: {
    fontSize: 15,
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
})
