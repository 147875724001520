import React from 'react'

import { SubscriptionsScreenMobileActive } from './SubscriptionsScreenMobileActive'
import { SubscriptionsScreenMobilePassive } from './SubscriptionsScreenMobilePassive'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'

export const SubscriptionsScreenMobile: React.FC<
  SubscriptionsScreenProps
> = props =>
  props.selectedPlan ? (
    <SubscriptionsScreenMobilePassive {...props} />
  ) : (
    <SubscriptionsScreenMobileActive {...props} />
  )
