import React, { useCallback, useMemo } from 'react'
import {
  View,
  Modal,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  ListRenderItem,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon, { SvgName } from '../components/SvgIcon'
import ButtonText from '../components/Text/ButtonText'
import TitleText from '../components/Text/TitleText'
import { useMediaLibraryAddActionSheet } from '../hooks/useMediaLibraryAddActionSheet'
import usePassiveUserCheck from '../hooks/usePassiveUserCheck'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainStackNavigationType } from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'

interface CreateItemType {
  icon: SvgName
  label: string
  onPress: () => void
}

const Divider = () => <Separator height={10} />

export default function CreateContentModal(): JSX.Element {
  const { goBack, replace } =
    useNavigation<MainStackNavigationType<'CreateContent'>>()
  const { t } = useTranslation(undefined, { keyPrefix: 'modals.createContent' })
  const checkIsPassiveUser = usePassiveUserCheck()
  const [showAddActionSheet] = useMediaLibraryAddActionSheet()

  const createItems: CreateItemType[] = useMemo(
    () => [
      {
        icon: 'new-memory',
        label: t('newMemory'),
        onPress: () =>
          checkIsPassiveUser(false).then(isPassive => {
            if (isPassive) return
            replace('MemoryTimePicker')
          }),
      },
      {
        icon: 'drawer-People',
        label: t('newPerson'),
        onPress: () => {
          replace('PersonForm')
        },
      },
      {
        icon: 'drawer-MediaLibrary',
        label: t('newMedia'),
        onPress: () => {
          goBack()
          setTimeout(() => showAddActionSheet(), 1000)
        },
      },
    ],
    [t, checkIsPassiveUser, replace, goBack, showAddActionSheet],
  )

  const renderItem: ListRenderItem<CreateItemType> = useCallback(
    ({ item }) => (
      <TouchableOpacity style={styles.item} onPress={item.onPress}>
        <SvgIcon name={item.icon} color={Colors['layout.dark']} />
        <Separator width={14} />
        <ButtonText color="layout.dark" size="medium">
          {item.label}
        </ButtonText>
      </TouchableOpacity>
    ),
    [],
  )

  return (
    <Modal
      transparent
      statusBarTranslucent
      animationType="fade"
      onRequestClose={goBack}>
      <PickerModal close={goBack}>
        <View style={styles.container}>
          <View style={appStyles.inlineContainer}>
            <View style={appStyles.fullSize}>
              <TitleText color="layout.dark" size="medium">
                {t('title')}
              </TitleText>
            </View>
            <TouchableOpacity
              // @ts-ignore
              hitSlop={10}
              onPress={goBack}>
              <SvgIcon name="close" />
            </TouchableOpacity>
          </View>

          <FlatList
            data={createItems}
            renderItem={renderItem}
            keyExtractor={item => item.label}
            ItemSeparatorComponent={Divider}
            contentContainerStyle={styles.flatlistContentContainer}
          />
        </View>
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: 24, paddingTop: 18 },
  flatlistContentContainer: { paddingVertical: 34 },
  item: {
    height: 80,
    width: '100%',
    borderRadius: 10,
    paddingHorizontal: 20,
    ...appStyles.inlineContainer,
    backgroundColor: convertHexToRGBA(Colors['layout.white'], 0.5),
  },
})
