import React, { useMemo } from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import WebModal from '../../components/WebModal'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function EventsQuizSuccessScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.globalEventsQuiz',
  })
  const { reset } =
    useNavigation<MainStackNavigationType<'EventsQuizSuccess'>>()
  const { webLayoutEnabled } = useWebLayout()

  const backgroundImage = useMemo(
    () =>
      webLayoutEnabled
        ? require('../../assets/globalEvents/quiz-success-web.png')
        : require('../../assets/globalEvents/quiz-success-mobile.png'),
    [webLayoutEnabled],
  )

  return (
    <WebModal fullHeight>
      <ImageBackground
        resizeMode="cover"
        style={styles.background}
        source={backgroundImage}>
        <SafeAreaView style={styles.container}>
          <SvgIcon name="logo" style={styles.logo} />
          <View>
            <TitleText
              size="medium"
              color="layout.white"
              style={styles.titleText}>
              {t('successTitle')}
            </TitleText>
            <Separator height={13} />
            <Typography style={styles.descriptionText}>
              {t('successText')}
            </Typography>
            <Separator height={46} />
            <Button
              text={t('goToTimeline')}
              style={styles.goToTimelineButton}
              onPress={() => {
                reset({
                  index: 0,
                  routes: [{ name: 'Root' }],
                })
              }}
            />
          </View>
        </SafeAreaView>
      </ImageBackground>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  background: { width: '100%', height: '100%' },
  container: {
    marginLeft: 24,
    marginRight: 35,
    ...appStyles.fullSize,
    justifyContent: 'space-between',
  },
  logo: { marginTop: 10, alignSelf: 'flex-end' },
  titleText: { fontSize: 40, paddingTop: 10, marginRight: 44 },
  descriptionText: {
    fontSize: 20,
    lineHeight: 30,
    marginRight: 73,
    color: Colors['layout.white'],
  },
  goToTimelineButton: { marginBottom: 20 },
})
