import React, { PropsWithChildren } from 'react'
import { Image, ImageBackground, StyleProp, ViewStyle } from 'react-native'

type PictureProps = {
  sourceUri: string
  style?: StyleProp<ViewStyle>
  hasGradient?: boolean
}

// TODO: Add gradient support
const Picture: React.FC<PropsWithChildren<PictureProps>> = props => {
  if (props.children) {
    return (
      <ImageBackground source={{ uri: props.sourceUri }} style={props.style}>
        {props.children}
      </ImageBackground>
    )
  }
  // @ts-ignore
  return <Image source={{ uri: props.sourceUri }} style={props.style} />
}

export default Picture
