import { Locale } from '../types/localization-types'

export const getTermsLink = (locale: Locale | undefined) => {
  switch (locale) {
    case 'de':
      return 'https://relefant.eu/nutzungsbedingungen.html'
    case 'en':
    default:
      return 'https://relefant.eu/en/terms-of-use.html'
  }
}

export const getFAQLink = (locale: Locale | undefined) => {
  switch (locale) {
    case 'de':
      return 'https://relefant.eu/faq.html'
    case 'en':
    default:
      return 'https://relefant.eu/en/faq.html'
  }
}
