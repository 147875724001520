import { Platform, StyleSheet, ViewStyle } from 'react-native'

import Colors from './Colors'

export default StyleSheet.create({
  fullSize: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  inlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalCenter: {
    justifyContent: 'center',
  },
  horizontalCenter: {
    alignItems: 'center',
  },
  lightShadow: {
    elevation: 4,
    shadowColor: Colors['layout.black'],
    shadowOpacity: 0.05,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 14,
  },
  heavyShadow: {
    elevation: 10,
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 18,
    shadowOpacity: 0.15,
    shadowColor: Colors['layout.black'],
  },
  removedOutline:
    Platform.OS === 'web' ? ({ outlineStyle: 'none' } as ViewStyle) : {},
})
