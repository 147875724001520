import React from 'react'
import { View, StyleSheet } from 'react-native'

import { useTranslation } from 'react-i18next'

import Button from '../components/Button'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import Typography from '../components/Text/Typography'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type Props = {
  title: string
  subtitle: string
  confirmText: string
  close: () => void
  onConfirm: () => void
}
const DestructionConfirmationDialog: React.FC<Props> = ({
  title,
  subtitle,
  confirmText,
  close,
  onConfirm,
}) => {
  const { t } = useTranslation()
  const { webLayoutEnabled } = useWebLayout()
  return (
    <PickerModal close={close}>
      <View style={[styles.container, webLayoutEnabled && styles.webContainer]}>
        <SvgIcon name="exclaimation" />
        <Separator height={webLayoutEnabled ? 60 : 20} />
        <Typography weight="bold" style={styles.title}>
          {title}
        </Typography>
        <Separator height={20} />
        <Typography style={styles.subtitle}>{subtitle}</Typography>
        <Separator height={webLayoutEnabled ? 80 : 20} />
        <View style={styles.buttonRow}>
          <Button
            type="inverted-gray"
            style={styles.button}
            text={t('common.cancel')}
            onPress={close}
          />
          <Separator width={10} />
          <Button
            style={styles.button}
            type="destructive-intense"
            text={confirmText}
            onPress={onConfirm}
          />
        </View>
      </View>
    </PickerModal>
  )
}

export default DestructionConfirmationDialog

const styles = StyleSheet.create({
  container: {
    padding: 20,
    ...appStyles.center,
  },
  webContainer: {
    paddingTop: 100,
    paddingBottom: 60,
    paddingHorizontal: 100,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    color: Colors['layout.black'],
  },
  subtitle: {
    fontSize: 15,
    textAlign: 'center',
  },
  buttonRow: {
    width: '100%',
    ...appStyles.row,
  },
  button: {
    width: undefined,
    ...appStyles.fullSize,
  },
})
