import React, { useState, useMemo } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'

import { useTranslation } from 'react-i18next'

import { MediaThumbnail } from './MediaThumbnail'
import Typography from './Text/Typography'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaItem } from '../types/media-types'

const WebMediaList: React.FC<{
  attachments: MediaItem[]
  onPress: (index: number) => void
  onLongPress: (item: MediaItem) => void
}> = ({ attachments, onPress, onLongPress }) => {
  const { t } = useTranslation()
  const [availableWidth, setAvailableWidth] = useState<number>()
  const pictureWidth = useMemo(
    () => availableWidth && (availableWidth - 3 * styles.pictureRow.gap) / 4,
    [availableWidth],
  )

  return (
    <View
      style={styles.container}
      onLayout={e => setAvailableWidth(e.nativeEvent.layout.width)}>
      <View style={styles.pictureRow}>
        {(attachments.length <= 4 ? attachments : attachments.slice(0, 3)).map(
          (media, index) => (
            <MediaThumbnail
              key={media.id}
              item={media}
              playIconSize={42}
              style={[styles.picture, { width: pictureWidth }]}
              onPress={() => onPress(index)}
              onLongPress={() => onLongPress(media)}
            />
          ),
        )}
        {attachments.length > 4 && (
          <TouchableOpacity
            style={[
              styles.picture,
              styles.plusMoreHolder,
              { width: pictureWidth },
            ]}
            onPress={() => onPress(0)}>
            <Typography weight="bold">
              {t('screens.createMemory.plusMore', {
                n: attachments.length - 3,
              })}
            </Typography>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

export default WebMediaList

const styles = StyleSheet.create({
  container: { width: '100%' },
  pictureRow: {
    gap: 8,
    ...appStyles.row,
  },
  picture: {
    aspectRatio: 1,
    borderRadius: 8,
  },
  plusMoreHolder: {
    ...appStyles.center,
    backgroundColor: Colors['layout.gray'],
  },
})
