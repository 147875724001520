export default {
  'layout.dark': '#1D352A',
  'layout.primary': '#61726A',
  'layout.medium': '#B7BCB9',
  'layout.light': '#F5F5F5',
  'layout.white': '#FFFFFF',
  'layout.gray': '#D0D7DC',
  'layout.gray-dark': '#343434',
  'layout.black': '#000000',
  'layout.shadow': '#101B41',
  'layout.selected': '#E1E3E2',
  'layout.icon': '#D0D7DC',
  'layout.modal-background': '#00000066',
  'timeline.light-red': '#D65931',
  'timeline.dark-orange': '#DD7A3F',
  'timeline.orange': '#EEB54F',
  'timeline.yellow': '#F8D966',
  'timeline.yellow-shading-light': '#F3C02B',
  'timeline.yellow-shading-dark': '#E4A400',
  'audio.light-gray': '#D2D7D4',
  'audio.dark-gray': '#8E9A94',
  'brand.red': '#C72A14',
  'brand.blue': '#0F74FF',
  'brand.original': '#8EC2A5',
  'brand.action': '#66C398',
} as const
