import React from 'react'
import { View, StyleSheet } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Button from '../components/Button'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import TitleText from '../components/Text/TitleText'
import Typography from '../components/Text/Typography'

export default function AccountDeletedModal(): JSX.Element {
  const { goBack } = useNavigation()
  const { t } = useTranslation()

  return (
    <PickerModal close={goBack}>
      <View style={styles.container}>
        <TitleText size="medium" color="layout.dark">
          {t('modals.accountDeleted.title')}
        </TitleText>
        <Typography weight="medium" style={styles.text}>
          {t('modals.accountDeleted.subtitle')}
        </Typography>
        <Separator height={50} />
        <SvgIcon style={styles.checkIcon} name="check-filled" />
        <Separator height={50} />
        <Button text={t('common.continue')} onPress={goBack} />
      </View>
    </PickerModal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingBottom: 48,
    paddingHorizontal: 24,
  },
  text: {
    fontSize: 18,
  },
  checkIcon: {
    alignSelf: 'center',
  },
})
