import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

import SvgIcon, { SvgName } from './SvgIcon'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

type TouchableSvgProps = Omit<TouchableOpacityProps, 'children'> & {
  name: SvgName
  color: keyof typeof Colors
}

const TouchableSvg: React.FC<TouchableSvgProps> = ({
  name,
  color,
  style,
  ...props
}: TouchableSvgProps) => (
  <TouchableOpacity
    style={[appStyles.center, styles.container, style]}
    {...props}>
    <SvgIcon name={name} color={Colors[color]} />
  </TouchableOpacity>
)

export default TouchableSvg

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: 40,
  },
})
