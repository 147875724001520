import React, { useMemo } from 'react'
import {
  View,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native'

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  Memory,
  Attachment,
  useGetMemoryQuery,
  useGetCommonMemoryQuery,
  useGetSharedMemoryQuery,
  useGetMemoryCreationRestrictionsLazyQuery,
} from '../../api/types'
import { AudioList } from '../../components/AudioList'
import Avatar from '../../components/Avatar'
import HeaderIconButton from '../../components/HeaderIconButton'
import Loading from '../../components/Loading'
import { MediaList } from '../../components/MediaList'
import MemoryHeader from '../../components/MemoryHeader'
import Picture from '../../components/Picture'
import Separator from '../../components/Separator'
import ShareButton from '../../components/ShareButton'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import useFormatMemoryDate from '../../hooks/useFormatMemoryDate'
import useMemoryHeaderUri from '../../hooks/useMemoryHeaderUri'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useBanner } from '../../providers/BannerProvider'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'
import { TIMELINE_COLORS } from '../timeline/TimelineScreen'

export const MAX_PEOPLE_ROW_COUNT = 4

export default function MemoryDetailsScreen(): JSX.Element {
  const { params } = useRoute<RouteProp<MainStackParamsType, 'MemoryDetails'>>()
  const { goBack, navigate } =
    useNavigation<MainStackNavigationType<'MemoryDetails'>>()
  const { sortedLifeStages, getPublicClient, isLoggedIn } = useUser()
  const { t } = useTranslation()
  const formatMemoryDate = useFormatMemoryDate()

  const [getMemoryCreationRestrictions] =
    useGetMemoryCreationRestrictionsLazyQuery()
  const showBanner = useBanner()

  const publicClient = useMemo(
    () =>
      params.shareToken
        ? getPublicClient({ shareToken: params.shareToken })
        : undefined,
    [getPublicClient, params.shareToken],
  )

  const { webLayoutEnabled, setShowFullWidth } = useWebLayout()

  const { data, loading } = useGetMemoryQuery({
    variables: { id: params.memoryId, personCount: MAX_PEOPLE_ROW_COUNT },
    skip: params.isCommon || params.isShared,
  })

  const { data: sharedData, loading: sharedLoading } = useGetSharedMemoryQuery({
    variables: { id: params.memoryId },
    skip: !params.isShared,
    client: publicClient,
  })

  const commonMemoryQuery = useGetCommonMemoryQuery({
    variables: { id: params.memoryId },
    skip: !params.isCommon,
  })

  const memory = useMemo(
    () =>
      params.isCommon
        ? commonMemoryQuery.data?.commonMemory
        : data?.memory ?? sharedData?.memory,
    [
      data?.memory,
      params.isCommon,
      sharedData?.memory,
      commonMemoryQuery.data?.commonMemory,
    ],
  )

  const shareMemory = useMemo(() => {
    if (!params.isCommon && !params.isShared) return memory as Memory
  }, [memory, params.isCommon, params.isShared])

  const [attachmentsMedia, attachmentsAudio] = useMemo(() => {
    const media: Attachment[] = []
    const audio: Attachment[] = []
    memory?.attachments?.nodes?.forEach(attachment => {
      if (!attachment.contentType || !attachment.url) return

      if (attachment.contentType.startsWith('audio')) {
        audio.push(attachment)
      } else {
        media.push(attachment)
      }
    })
    return [media, audio]
  }, [memory?.attachments?.nodes])

  const mediaDescriptions = useMemo(() => {
    if (memory?.__typename === 'Memory')
      return memory?.attachmentAttachables?.nodes ?? []
    return []
  }, [memory])

  const goEdit = useMemo(() => {
    if (memory?.__typename === 'Memory' && !params.isShared)
      return () =>
        getMemoryCreationRestrictions().then(resp => {
          if (resp.data?.me?.passive) {
            showBanner({
              icon: 'alert',
              color: 'brand.red',
              description: t('banner.passiveCannotEditMemories'),
            })
            return
          }
          navigate('MemoryForm', {
            editMemory: memory,
            attachments: { audio: attachmentsAudio, media: attachmentsMedia },
            attachmentDescriptions: mediaDescriptions,
          })
        })
  }, [
    t,
    memory,
    navigate,
    showBanner,
    params.isShared,
    attachmentsAudio,
    attachmentsMedia,
    mediaDescriptions,
    getMemoryCreationRestrictions,
  ])

  const labels = useMemo(() => {
    const items: { text: string; color?: string }[] = []
    if (memory?.__typename === 'Memory') {
      const { lifeStage } = memory as Memory
      if (lifeStage) {
        items.push({
          text: lifeStage.title,
          color:
            Colors[
              TIMELINE_COLORS[
                sortedLifeStages.findIndex(({ id }) => id === lifeStage?.id)
              ]
            ],
        })
      }
      items.push({
        text: t(`modals.memoryTypePicker.memoryType.${memory.memoryType}`),
      })
    }

    if (memory?.__typename === 'CommonMemory') {
      ;[
        memory.quizCategory.tag,
        memory.quizSubCategory?.translatedTitle,
      ].forEach(text => !!text && items.push({ text }))
    }
    return items
  }, [memory, t, sortedLifeStages])

  const memoryHeaderUri = useMemoryHeaderUri(
    memory?.pictureUrl,
    attachmentsMedia[0],
  )

  const renderLabels = () => (
    <View style={styles.labelsContainer}>
      {labels.map(({ text, color }, i) => (
        <View
          key={String(i)}
          style={[styles.labelBubble, !!color && { backgroundColor: color }]}>
          <Typography key={String(i)} weight="medium" style={styles.labelText}>
            {text}
          </Typography>
        </View>
      ))}
    </View>
  )

  const renderDate = () =>
    memory?.eventAt && (
      <Typography weight="regular" style={styles.dateText}>
        {formatMemoryDate(memory)}
      </Typography>
    )

  const renderTaggedPeople = () =>
    !!data?.memory.people?.totalCount && (
      <>
        <Separator height={15} />
        <TouchableOpacity
          style={[
            appStyles.inlineContainer,
            Platform.select({ web: { alignSelf: 'flex-start' } }),
          ]}
          onPress={() =>
            navigate('MemoryPeople', {
              memoryId: params.memoryId,
              memoryTitle: data.memory.memoryTitle,
            })
          }>
          {data.memory.people.nodes.map(({ gender, pictureUrl }, index) => (
            <View
              key={index}
              style={[
                styles.personHolder,
                index !== 0 && styles.personOverlap,
                {
                  zIndex: -index,
                },
              ]}>
              <Avatar gender={gender} uri={pictureUrl} size={50} />
            </View>
          ))}
          <Separator width={4} />
          {data.memory.people.totalCount > MAX_PEOPLE_ROW_COUNT && (
            <Typography>
              {t('screens.createMemory.plusMore', {
                n: data.memory.people.totalCount - MAX_PEOPLE_ROW_COUNT,
              })}
            </Typography>
          )}
          <Separator width={6} />
          <SvgIcon name="arrow" style={styles.peopleButtonArrow} />
        </TouchableOpacity>
      </>
    )

  const renderAudioAttachments = (withLargeDividers?: boolean) =>
    attachmentsAudio.length > 0 && (
      <>
        <Separator height={34} />
        <AudioList
          canEdit={false}
          audio={attachmentsAudio}
          withLargeDividers={withLargeDividers}
        />
      </>
    )

  const title =
    memory?.__typename === 'Memory'
      ? memory.memoryTitle
      : memory?.__typename === 'CommonMemory'
      ? memory.title
      : ''

  const description =
    memory?.__typename === 'Memory'
      ? memory.memoryBody
      : memory?.__typename === 'CommonMemory'
      ? memory.description
      : ''

  const appHeaderStyle = useMemo(
    () =>
      memoryHeaderUri
        ? styles.headerImage
        : { backgroundColor: Colors['layout.light'] },
    [memoryHeaderUri],
  )
  const appHeaderPaddedStyle = useSafeAreaPaddedStyle(
    appHeaderStyle,
    headerOptions,
  )

  const renderContent = () => {
    if (webLayoutEnabled)
      return (
        <>
          <View style={webStyles.headerHolder}>
            <View style={webStyles.header}>
              <TouchableOpacity
                onPress={() => {
                  if (!isLoggedIn) setShowFullWidth(false)
                  goBack()
                }}>
                <SvgIcon name="back" color={Colors['layout.dark']} />
              </TouchableOpacity>
              <View style={appStyles.fullSize} />
              {shareMemory && <ShareButton shareContent={shareMemory} />}
              {goEdit && (
                <>
                  <Separator width={10} />
                  <HeaderIconButton iconName="pencil" onPress={goEdit} />
                </>
              )}
            </View>
          </View>
          <View style={webStyles.maxWidthHolder}>
            <View style={webStyles.contentContainer}>
              <View style={[webStyles.column, webStyles.mainColumn]}>
                {renderLabels()}
                <Separator height={6} />
                {renderDate()}
                <Separator height={6} />
                <TitleText size="medium" color="layout.dark">
                  {title}
                </TitleText>
                {renderTaggedPeople()}
                <Separator height={26} />
                <Typography weight="regular" style={styles.bodyText}>
                  {description}
                </Typography>
              </View>

              {(memoryHeaderUri ||
                (memory?.attachments?.nodes?.length ?? 0) > 0) && (
                <>
                  <Separator width={20} />
                  <View style={[webStyles.column, webStyles.mediaColumn]}>
                    {(memoryHeaderUri || attachmentsMedia.length > 0) && (
                      <>
                        {memoryHeaderUri && (
                          <Picture
                            sourceUri={memoryHeaderUri}
                            style={webStyles.highlightedPicture}
                          />
                        )}
                        {attachmentsMedia.length > 0 && (
                          <>
                            <Separator height={15} />
                            <MediaList
                              useWebVersion
                              canEdit={false}
                              media={attachmentsMedia}
                              mediaDescriptions={mediaDescriptions}
                              galleryTitle={title}
                            />
                          </>
                        )}
                      </>
                    )}
                    {renderAudioAttachments(true)}
                  </View>
                </>
              )}
            </View>
          </View>
        </>
      )

    return (
      <ScrollView
        style={appStyles.fullSize}
        contentContainerStyle={styles.scrollContentContainer}>
        {memoryHeaderUri ? (
          <Picture
            hasGradient
            sourceUri={memoryHeaderUri}
            style={appHeaderPaddedStyle}>
            <MemoryHeader
              goBack={goBack}
              goEdit={goEdit}
              shareContent={shareMemory}
              buttonColor="layout.white"
            />
          </Picture>
        ) : (
          <View style={appHeaderPaddedStyle}>
            <MemoryHeader
              goBack={goBack}
              goEdit={goEdit}
              shareContent={shareMemory}
              buttonColor="layout.dark"
              backgroundColor="layout.light"
            />
          </View>
        )}
        <View style={[appStyles.fullSize, styles.content]}>
          <View style={styles.paddedContainer}>
            <Separator height={25} />
            {renderLabels()}
            <Separator height={7} />
            {renderDate()}
            <Separator height={4} />
            <TitleText size="medium" color="layout.dark">
              {title}
            </TitleText>
            {renderTaggedPeople()}
          </View>

          {attachmentsMedia.length > 0 && (
            <>
              <Separator height={27} />
              <MediaList
                canEdit={false}
                media={attachmentsMedia}
                mediaDescriptions={mediaDescriptions}
                galleryTitle={title}
              />
            </>
          )}
          <View style={styles.paddedContainer}>{renderAudioAttachments()}</View>
          <View style={styles.paddedContainer}>
            <Separator height={34} />
            <Typography weight="regular" style={styles.bodyText}>
              {description}
            </Typography>
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <View
      style={[appStyles.fullSize, { backgroundColor: Colors['layout.white'] }]}>
      <Loading loading={loading || sharedLoading} />
      {renderContent()}
    </View>
  )
}

const borderColor = convertHexToRGBA(Colors['layout.gray'], 0.5)

const webStyles = StyleSheet.create({
  headerHolder: {
    height: 90,
    borderColor,
    ...appStyles.row,
    ...appStyles.center,
    borderBottomWidth: 1,
    backgroundColor: Colors['layout.white'],
  },
  header: {
    maxWidth: 1200,
    marginHorizontal: 50,
    ...appStyles.fullSize,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  maxWidthHolder: {
    paddingTop: 30,
    ...appStyles.row,
    ...appStyles.center,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    maxWidth: 1300,
    height: '100%',
    ...appStyles.row,
    ...appStyles.fullSize,
    paddingHorizontal: 30,
  },
  column: {
    borderRadius: 10,
    marginBottom: 30,
    alignSelf: 'flex-start',
    backgroundColor: Colors['layout.white'],
  },
  mainColumn: {
    flex: 5,
    paddingTop: 30,
    paddingBottom: 50,
    paddingHorizontal: 30,
  },
  mediaColumn: {
    flex: 3,
    padding: 20,
  },
  highlightedPicture: { height: 250, borderRadius: 10 },
})

const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  headerImage: {
    height: 375,
    backgroundColor: Colors['layout.light'],
  },
  content: {
    backgroundColor: Colors['layout.white'],
  },
  labelsContainer: {
    rowGap: 8,
    columnGap: 8,
    flexWrap: 'wrap',
    ...appStyles.inlineContainer,
  },
  labelBubble: {
    borderRadius: 20,
    paddingVertical: 4,
    paddingHorizontal: 10,
    backgroundColor: Colors['layout.gray'],
  },
  labelText: {
    fontSize: 14,
    color: Colors['layout.white'],
  },
  dateText: {
    fontSize: 16,
    opacity: 0.7,
    color: Colors['layout.dark'],
  },
  paddedContainer: { paddingHorizontal: 25 },
  personHolder: {
    borderWidth: 1,
    borderRadius: 25,
    overflow: 'hidden',
    borderColor: Colors['layout.gray'],
  },
  personOverlap: { marginLeft: -20 },
  peopleButtonArrow: { marginLeft: 'auto' },
  bodyText: {
    fontSize: 18,
    lineHeight: 31,
    color: Colors['layout.dark'],
  },
})
