export default {
  'title.large': 40,
  'title.medium': 26,
  'label.small': 14,
  'label.medium': 16,
  'label.large': 18,
  'button.small': 14,
  'button.medium': 16,
  'button.large': 18,
}
