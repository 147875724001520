import { useState } from 'react'
import { Platform, ViewStyle } from 'react-native'

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'
import {
  Asset,
  launchCamera,
  launchImageLibrary,
  ImagePickerResponse,
} from 'react-native-image-picker'

interface ShowPictureActionSheetProps {
  hasPictureUrl: boolean
  containerStyle?: ViewStyle
  removePictureUrl: () => void
}

export const useProfilePictureActionSheet = (): [
  Asset | undefined,
  (props: ShowPictureActionSheetProps) => void,
] => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useTranslation()

  const [picture, setPicture] = useState<Asset>()

  const showPictureActionSheet: (
    props: ShowPictureActionSheetProps,
  ) => void = ({ hasPictureUrl, removePictureUrl, containerStyle }) => {
    const handlePickerResponse = (response: ImagePickerResponse) => {
      if (response.assets) {
        removePictureUrl()
        setPicture(response.assets[0])
      }
    }

    const openCameraPhoto = () => {
      launchCamera({ mediaType: 'photo' }, handlePickerResponse)
    }

    const openMediaLibrary = () => {
      launchImageLibrary({ mediaType: 'photo' }, handlePickerResponse)
    }

    const options = [
      t('screens.createMemory.addMediaOptions.takePicture'),
      t('screens.createMemory.addMediaOptions.chooseFromLibrary'),
      t('common.cancel'),
    ]

    const optionsWithDelete = [
      ...options.slice(0, 2),
      t('screens.createMemory.imageOptions.delete'),
      options[2],
    ]

    const hasPicture = hasPictureUrl || picture

    showActionSheetWithOptions(
      {
        containerStyle,
        options: hasPicture ? optionsWithDelete : options,
        cancelButtonIndex: hasPicture ? 3 : 2,
        destructiveButtonIndex: hasPicture ? 2 : undefined,
        disabledButtonIndices: Platform.OS === 'web' ? [0] : [],
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            openCameraPhoto()
            break
          case 1:
            openMediaLibrary()
            break
          case 2:
            if (picture) {
              setPicture(undefined)
              break
            }
            if (hasPictureUrl) {
              removePictureUrl()
            }
            break
          default:
            break
        }
      },
    )
  }

  return [picture, showPictureActionSheet]
}
