import { useMemo, useRef } from 'react'

/**
 * Checks whether or not arrays are same length and the contained elements have the same id
 */
export function isArrayEqual<T extends { id: string }>(
  a: T[] | undefined,
  b: T[] | undefined,
): boolean {
  if (!a && !b) return true

  return (
    a?.length === b?.length &&
    a?.findIndex((value, index) => value.id !== b?.[index].id) === -1
  )
}

/**
 * Tracks whether passed values have changed since the initial render
 *
 * If updating the "saved" state is needed, implement:
 * const onSaved = useCallback(() => (initial.current = values), [...values])
 */
export default function useIsUnsaved(
  ...values: (
    | string
    | number
    | boolean
    | undefined
    | Date
    | null
    | { id: string }[]
  )[]
) {
  const initial = useRef(values)
  const isUnsaved = useMemo(
    () =>
      initial.current.findIndex((val, index) => {
        const current = values[index]
        return Array.isArray(val) && Array.isArray(current)
          ? !isArrayEqual(val, current)
          : val instanceof Date && current instanceof Date
          ? val.valueOf() !== current.valueOf()
          : val !== current
      }) !== -1,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...values],
  )

  return isUnsaved
}
