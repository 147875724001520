import React, { PropsWithChildren, useMemo } from 'react'
import { View, Pressable, StyleSheet, useWindowDimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

const WebModal: React.FC<
  PropsWithChildren<{ fullHeight?: boolean; withoutBackground?: boolean }>
> = ({ children, fullHeight, withoutBackground }) => {
  const { webLayoutEnabled } = useWebLayout()
  const { height } = useWindowDimensions()

  const childContainerStyle = useMemo(
    () => [
      styles.childContainer,
      webLayoutEnabled && { ...styles.webContainer, maxHeight: height * 0.8 },
      (!webLayoutEnabled || fullHeight) && appStyles.fullSize,
    ],
    [webLayoutEnabled, height, fullHeight],
  )

  const { goBack } = useNavigation()
  return (
    <View style={styles.container}>
      {webLayoutEnabled && !withoutBackground && (
        <Pressable style={styles.backdrop} onPress={goBack} />
      )}
      <View style={childContainerStyle}>{children}</View>
    </View>
  )
}

export default WebModal

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    ...appStyles.center,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors['layout.modal-background'],
  },
  childContainer: {
    width: '100%',
    backgroundColor: Colors['layout.light'],
  },
  webContainer: {
    maxWidth: 900,
    borderRadius: 20,
    overflow: 'hidden',
  },
})
