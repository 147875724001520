import React, { useEffect, useState } from 'react'
import {
  View,
  Modal,
  Platform,
  Dimensions,
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { MediaViewerModal } from './MediaViewerModal'
import HeaderButton from '../components/HeaderButton'
import { MediaThumbnail } from '../components/MediaThumbnail'
import Separator from '../components/Separator'
import { TypographyInput } from '../components/Text/Typography'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../hooks/useSafeAreaPaddedStyle'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaItem } from '../types/media-types'
import { convertHexToRGBA } from '../utils/colors'

interface MediaDescriptionModalProps {
  isVisible: boolean
  media?: MediaItem
  memorySpecificDescription?: string
  autofocus: boolean
  saveDescription: (item: MediaItem, description: string | undefined) => void
  close: () => void
}

export const MediaDescriptionModal: React.FC<MediaDescriptionModalProps> = ({
  isVisible,
  media,
  memorySpecificDescription,
  autofocus,
  saveDescription,
  close,
}) => {
  const { t } = useTranslation()
  const [mediaViewerIsVisible, setMediaViewerIsVisible] = useState(false)
  const [description, setDescription] = useState<string>()
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setDescription(memorySpecificDescription ?? media?.description ?? '')
  }, [media, memorySpecificDescription])

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={isVisible}
      animationType="fade"
      onRequestClose={() => close}>
      <View style={styles.container}>
        <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
          <HeaderButton secondary text={t('common.cancel')} onPress={close} />
          <HeaderButton
            text={t('common.save')}
            onPress={() => {
              if (media) {
                saveDescription(media, description)
              }
              close()
            }}
          />
        </View>
        <MediaViewerModal
          uri={media?.url ?? ''}
          fileType={media?.contentType ?? ''}
          isVisible={mediaViewerIsVisible}
          close={() => setMediaViewerIsVisible(false)}
        />
        <KeyboardAvoidingView
          style={appStyles.fullSize}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={useSafeAreaPaddedStyle(
              styles.contentContainer,
              {
                insetBottom: true,
              },
            )}>
            {media && (
              <MediaThumbnail
                item={media}
                style={styles.mediaItem}
                playIconSize={80}
                onPress={() => setMediaViewerIsVisible(true)}
              />
            )}
            <Separator height={10} />
            <TypographyInput
              multiline
              autoFocus={autofocus}
              scrollEnabled={false}
              placeholder={t('screens.gallery.addDescription')}
              value={description}
              weight="medium"
              style={[
                styles.descriptionInput,
                isFocused && styles.descriptionInputFocused,
              ]}
              placeholderTextColor={convertHexToRGBA(
                Colors['layout.dark'],
                0.5,
              )}
              onChangeText={setDescription}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </Modal>
  )
}

const dimensions = Dimensions.get('window')
const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    ...appStyles.row,
    paddingBottom: 23,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  contentContainer: {
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  mediaItem: {
    borderRadius: 10,
    width: dimensions.width - 48,
    height: dimensions.height - 270,
  },
  descriptionInput: {
    fontSize: 15,
    lineHeight: 25,
    minHeight: 120,
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 16,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    textAlignVertical: 'top',
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  descriptionInputFocused: {
    borderColor: Colors['brand.action'],
  },
})
