import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  View,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native'

import {
  useRoute,
  RouteProp,
  StackActions,
  useNavigation,
} from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import {
  UserTitleEnum,
  UserGenderEnum,
  GetPersonDocument,
  PersonRelationEnum,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useDeletePersonMutation,
  useDeletePersonPictureMutation,
  AttachmentAttachable,
} from '../../api/types'
import { AudioItem, AudioList } from '../../components/AudioList'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import DestructionConfirmationDialog from '../../components/DestructionConfirmationDialog'
import HeaderButton from '../../components/HeaderButton'
import {
  TextInputField,
  DateInputField,
  PickerInputField,
} from '../../components/InputFields'
import Loading from '../../components/Loading'
import { MediaList } from '../../components/MediaList'
import ModalFooter from '../../components/ModalFooter'
import ModalHeader from '../../components/ModalHeader'
import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import TitleText from '../../components/Text/TitleText'
import Typography, { TypographyInput } from '../../components/Text/Typography'
import WebModal from '../../components/WebModal'
import loggingCore from '../../core/logging-core'
import {
  getTitleItems,
  getGenderItems,
  getRelationItems,
} from '../../helpers/ProfileFormItems'
import { useAudioActionSheet } from '../../hooks/useAudioActionSheet'
import useIsUnsaved from '../../hooks/useIsUnsaved'
import { useListState } from '../../hooks/useListState'
import { useMediaActionSheet } from '../../hooks/useMediaActionSheet'
import { useProfilePictureActionSheet } from '../../hooks/useProfilePictureActionSheet'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import useShowRemoveMediaActionSheet from '../../hooks/useShowRemoveMediaActionSheet'
import useStateRef from '../../hooks/useStateRef'
import useUnsavedChanges from '../../hooks/useUnsavedChanges'
import useUploadAttachments, {
  AttachmentNodes,
} from '../../hooks/useUploadAttachments'
import { AudioDescriptionModal } from '../../modals/AudioDescriptionModal'
import { useBanner } from '../../providers/BannerProvider'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MediaItem } from '../../types/media-types'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { convertHexToRGBA } from '../../utils/colors'
import errorIsLimitReached from '../../utils/errorIsLimitReached'
import { FilterType } from '../media-library/MediaLibraryScreen'

export default function PersonFormScreen(): JSX.Element {
  const setShouldRefetch = useSetShouldRefetch()
  const { params, key } =
    useRoute<RouteProp<MainStackParamsType, 'PersonForm'>>()
  const { navigate, goBack, dispatch, pop, setParams } =
    useNavigation<MainStackNavigationType<'PersonForm'>>()
  const { t } = useTranslation()
  const showBanner = useBanner()

  const showRemoveMediaActionSheet = useShowRemoveMediaActionSheet()
  const uploadAttachments = useUploadAttachments()

  const [createPerson, { client }] = useCreatePersonMutation()
  const [updatePerson] = useUpdatePersonMutation()
  const [deletePerson] = useDeletePersonMutation()
  const [deletePersonPicture] = useDeletePersonPictureMutation()

  const genderItems = useMemo(() => getGenderItems(t), [t])
  const titleItems = useMemo(() => getTitleItems(t), [t])
  const relationItems = useMemo(() => getRelationItems(t), [t])

  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false)
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false)

  const [gender, setGender] = useState<UserGenderEnum | undefined>(
    params?.editPerson?.gender ?? undefined,
  )
  const [title, setTitle] = useState<UserTitleEnum | undefined>(
    params?.editPerson?.title ?? undefined,
  )
  const [relation, setRelation] = useState<PersonRelationEnum | undefined>(
    params?.editPerson?.relation ?? undefined,
  )
  const [name, setName] = useState<string>(params?.editPerson?.firstName ?? '')
  const [nameError, setNameError] = useState<string>('')
  const [surname, setSurname] = useState<string>(
    params?.editPerson?.lastName ?? '',
  )
  const [birthday, setBirthday] = useState<Date | null>(
    params?.editPerson?.dateOfBirth
      ? new Date(params.editPerson.dateOfBirth)
      : null,
  )
  const [deathDate, setDeathDate] = useState<Date | null>(
    params?.editPerson?.dateOfDeath
      ? new Date(params.editPerson.dateOfDeath)
      : null,
  )
  const [email, setEmail] = useState<string>(params?.editPerson?.email ?? '')
  const [isPersonPresent, setIsPersonPresent] = useState<boolean>(
    !params?.editPerson?.dateOfDeath,
  )
  const [story, setStory] = useState<string>(params?.editPerson?.story ?? '')

  const [pictureUrl, setPictureUrl] = useState<string | null>(
    params?.editPerson?.pictureUrl ?? null,
  )
  const [picture, showPictureOptions] = useProfilePictureActionSheet()

  const [oldMediaList, setOldMedia] = useState<AttachmentNodes>(
    params?.attachments?.media ?? [],
  )

  const [
    mediaDescriptions,
    _pushDescription,
    popMediaDescription,
    setMediaDescriptions,
  ] = useListState<AttachmentAttachable>(
    params?.attachmentDescriptions ?? [],
    (a, b) => a.attachmentId === b.attachmentId,
  )

  useEffect(
    () => setOldMedia(params?.attachments?.media ?? []),
    [params?.attachments?.media],
  )

  const addFromRelefantLibrary = (availableFilters: FilterType[]) => {
    navigate('MediaLibraryPicker', {
      routeKey: key,
      availableFilters,
    })
  }

  const [
    newMediaList,
    addMedia,
    showMediaOptions,
    setNewMedia,
    isMediaLoading,
  ] = useMediaActionSheet(false, () =>
    addFromRelefantLibrary(['all', 'picture', 'video']),
  )
  const mediaList: MediaItem[] = useMemo(
    () => [
      ...newMediaList.map(asset => ({
        __typename: 'NewAttachment' as const,
        id: asset.uri ?? '',
        url: asset.uri,
        contentType: asset.type,
        signedBlobId: null,
        description: asset.description,
      })),
      ...oldMediaList,
    ],
    [oldMediaList, newMediaList],
  )
  const [oldAudioList, setOldAudios] = useState<AttachmentNodes>(
    params?.attachments?.audio ?? [],
  )

  useEffect(
    () => setOldAudios(params?.attachments?.audio ?? []),
    [params?.attachments?.audio],
  )

  useEffect(() => {
    setParams({
      attachments: {
        media: [...(params?.addedAttachments?.media ?? []), ...oldMediaList],
        audio: [...(params?.addedAttachments?.audio ?? []), ...oldAudioList],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.addedAttachments])

  const [newAudioList, addAudio, showAudioOptions, pushAudio, setNewAudioList] =
    useAudioActionSheet(
      false,
      () =>
        navigate('AudioRecorder', {
          routeKey: key,
        }),
      () => addFromRelefantLibrary(['audio']),
    )
  const audioList = useMemo(
    () => [
      ...newAudioList.map(asset => ({
        __typename: 'NewAttachment' as const,
        asset,
      })),
      ...oldAudioList,
    ],
    [oldAudioList, newAudioList],
  )

  const [audioToRename, setAudioToRename] = useState<AudioItem>()

  useEffect(() => {
    if (params?.recordedAudio) pushAudio(params.recordedAudio)
  }, [params?.recordedAudio, pushAudio])

  const shouldSkipUnsavedCheck = useRef(false)
  const isUnsaved = useIsUnsaved(
    email,
    title,
    story,
    gender,
    relation,
    name,
    surname,
    birthday,
    deathDate,
    oldMediaList,
    oldAudioList,
  )
  useUnsavedChanges(
    isUnsaved || newAudioList.length > 0 || newMediaList.length > 0,
    () => shouldSkipUnsavedCheck.current,
  )

  const onSavePress = () => {
    if (getIsSaving()) return
    if (!name) {
      setNameError(t('screens.createPerson.nameError'))
      return
    }

    setIsSaving(true)

    if (!picture && params?.editPerson?.pictureUrl && !pictureUrl) {
      deletePersonPicture({
        variables: { id: params.editPerson.id },
      })
    }

    uploadAttachments(
      newMediaList,
      newAudioList,
      params?.attachments?.audio ?? [],
      oldAudioList,
      params?.attachments?.media ?? [],
      oldMediaList,
      mediaDescriptions,
      picture,
    )
      .then(attachments => {
        const input = {
          email,
          title,
          story,
          gender,
          relation,
          firstName: name,
          lastName: surname,
          dateOfBirth: birthday,
          dateOfDeath: deathDate,
          pictureBlobId: picture ? attachments[0].fileBlobId : null,
        }

        return params?.editPerson
          ? updatePerson({
              variables: {
                id: params.editPerson.id,
                input,
                attachments: attachments.slice(picture ? 1 : 0),
                // @ts-ignore
                attachableDescription: mediaDescriptions.map(d => ({
                  attachmentId: d.attachmentId,
                  attachableDescription: d.attachableDescription,
                })),
              },
            }).then(({ data }) => data?.updatePerson)
          : createPerson({
              variables: {
                input,
                attachments: attachments.slice(picture ? 1 : 0),
              },
            }).then(({ data }) => data?.createPerson)
      })
      .then(person => {
        client.cache.evict({
          fieldName: 'library',
        })
        client.cache.writeQuery({
          query: GetPersonDocument,
          variables: { id: person?.id },
          data: { person },
        })
        setShouldRefetch(QueryKey.People)
        setShouldRefetch(QueryKey.SearchPeople)
        shouldSkipUnsavedCheck.current = true
        dispatch(
          StackActions.replace('PersonDetails', { personId: person?.id }),
        )
        if (params?.editPerson) pop()
      })

      .catch(e => {
        if (errorIsLimitReached(e)) {
          showBanner({
            icon: 'alert',
            color: 'brand.red',
            description: t(
              `banner.dataLimitReached.${
                Platform.OS === 'web' ? 'web' : 'mobile'
              }`,
            ),
          })
        } else {
          loggingCore.error(e)
        }
      })
      .finally(() => setIsSaving(false))
  }

  const onDeleteConfirmed = () => {
    if (getIsSaving() || !params?.editPerson) return

    setIsSaving(true)

    deletePerson({
      variables: { id: params?.editPerson.id },
    })
      .then(() => {
        setShouldRefetch(QueryKey.People)
        setShouldRefetch(QueryKey.SearchPeople)
        shouldSkipUnsavedCheck.current = true
        pop(2)
      })
      .finally(() => setIsSaving(false))
  }

  const onDeletePress = () => setIsDeleteConfirmationVisible(true)

  const dismissDeleteConfirmation = () => setIsDeleteConfirmationVisible(false)

  const showMediaActions = (
    item: MediaItem,
    extraAction?: { text: string; action: () => void },
  ) =>
    item.__typename === 'NewAttachment'
      ? showMediaOptions(item, undefined, extraAction)
      : showRemoveMediaActionSheet(
          item.contentType?.includes('image') ? 'image' : 'video',
          () => {
            setOldMedia(c => c.filter(({ id }) => id !== item.id))
            popMediaDescription({ attachmentId: item.id })
          },
          extraAction && [extraAction],
        )

  const showAudioActions = (item: AudioItem) =>
    item.__typename === 'NewAttachment'
      ? showAudioOptions(item.asset, () => setAudioToRename(item))
      : showRemoveMediaActionSheet(
          'audio',
          () => {
            setOldAudios(c => c.filter(({ id }) => id !== item.id))
            popMediaDescription({ attachmentId: item.id })
          },
          [
            {
              text: t('screens.createMemory.audioOptions.rename'),
              action: () => setAudioToRename(item),
            },
          ],
        )

  const renameAudio = (item: AudioItem, description: string | undefined) => {
    if (item.__typename === 'NewAttachment') {
      setNewAudioList(prev =>
        prev.map(a =>
          a.fileCopyUri === item.asset.fileCopyUri ? { ...a, description } : a,
        ),
      )
    } else {
      setOldAudios(prev =>
        prev.map(a => (a.id === item.id ? { ...a, description } : a)),
      )
    }
  }

  const appHeaderStyle = useSafeAreaPaddedStyle(styles.header, headerOptions)

  const { webLayoutEnabled } = useWebLayout()

  const paddedStyle = useMemo(
    () => ({ paddingHorizontal: webLayoutEnabled ? 30 : 25 }),
    [webLayoutEnabled],
  )

  const titleText = t(
    params?.editPerson
      ? 'screens.editPerson.title'
      : 'screens.createPerson.title',
  )

  return (
    <WebModal>
      <Loading blocking loading={isSaving} />
      {webLayoutEnabled ? (
        <ModalHeader title={titleText} close={goBack} />
      ) : (
        <View style={appHeaderStyle}>
          <HeaderButton secondary text={t('common.cancel')} onPress={goBack} />
          <HeaderButton text={t('common.save')} onPress={onSavePress} />
        </View>
      )}
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          style={appStyles.fullSize}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={useSafeAreaPaddedStyle(
            styles.scrollContentContainer,
            { insetBottom: true },
          )}>
          <Separator height={33} />
          <View style={paddedStyle}>
            {!webLayoutEnabled && (
              <>
                <TitleText size="medium" color="layout.dark">
                  {titleText}
                </TitleText>
                <Separator height={2} />
                <Typography weight="light" style={styles.subtitle}>
                  {t('screens.createPerson.basicInfo')}
                </Typography>
                <Separator height={32} />
              </>
            )}
            <TouchableOpacity
              // @ts-ignore
              hitSlop={10}
              style={styles.pictureContainer}
              onPress={() =>
                showPictureOptions({
                  hasPictureUrl: !!pictureUrl,
                  removePictureUrl: () => setPictureUrl(null),
                })
              }>
              <Avatar gender={gender} uri={picture?.uri ?? pictureUrl} />
              <SvgIcon
                name="add"
                color={Colors['layout.dark']}
                style={styles.addPictureButton}
              />
            </TouchableOpacity>
            <Separator height={24} />
            <PickerInputField
              selectedKey={gender}
              options={genderItems}
              label={t('profile.gender.title')}
              modalTitle={t('modals.genderPicker.title')}
              value={genderItems.find(item => item.key === gender)?.label ?? ''}
              onSelectKey={selectedKey =>
                setGender(selectedKey as UserGenderEnum)
              }
            />
            <PickerInputField
              selectedKey={title}
              options={titleItems}
              label={t('profile.personTitle.title')}
              modalTitle={t('modals.titlePicker.title')}
              value={titleItems.find(item => item.key === title)?.label ?? ''}
              onSelectKey={selectedKey =>
                setTitle(selectedKey as UserTitleEnum)
              }
            />
            <TextInputField
              value={name}
              maxLength={30}
              autoCapitalize="words"
              errorMessage={nameError}
              label={t('profile.name')}
              clearError={() => setNameError('')}
              onChangeValue={setName}
            />
            <TextInputField
              maxLength={30}
              value={surname}
              autoCapitalize="words"
              errorMessage=""
              label={t('profile.surname')}
              clearError={() => {}}
              onChangeValue={setSurname}
            />
            <PickerInputField
              selectedKey={relation}
              options={relationItems}
              label={t('screens.createPerson.relation')}
              modalTitle={t('modals.relationPicker.title')}
              value={
                relationItems.find(item => item.key === relation)?.label ?? ''
              }
              onSelectKey={selectedKey =>
                setRelation(selectedKey as PersonRelationEnum)
              }
            />
            <TextInputField
              value={email}
              inputMode="email"
              autoComplete="email"
              autoCapitalize="none"
              hint=""
              clearError={() => {}}
              errorMessage=""
              label={t('profile.email')}
              textContentType="emailAddress"
              onChangeValue={setEmail}
            />
            <DateInputField
              value={birthday}
              errorMessage=""
              label={t('profile.birthday')}
              clearError={() => {}}
              onChangeValue={setBirthday}
            />
            <Separator height={10} />
            <TouchableOpacity
              style={appStyles.inlineContainer}
              onPress={() => setIsPersonPresent(prev => !prev)}>
              <CheckBox isChecked={isPersonPresent} />
              <Separator width={10} />
              <Typography style={styles.checkboxText}>
                {t('screens.createPerson.personIsPresent')}
              </Typography>
            </TouchableOpacity>
            <Separator height={10} />
            {!isPersonPresent && (
              <DateInputField
                value={deathDate}
                errorMessage=""
                label={t('screens.createPerson.deathDate')}
                clearError={() => {}}
                onChangeValue={setDeathDate}
              />
            )}
            <Separator height={16} />
            <Typography weight="light" style={styles.subtitle}>
              {t('screens.createPerson.tellMore')}
            </Typography>
            <Separator height={12} />
            <TypographyInput
              multiline
              scrollEnabled={false}
              weight="medium"
              style={[
                styles.descriptionInput,
                isDescriptionFocused && styles.focusedInput,
              ]}
              value={story}
              placeholder={t('screens.createMemory.writeStory')}
              placeholderTextColor={placeholderTextColor}
              onFocus={() => setIsDescriptionFocused(true)}
              onBlur={() => setIsDescriptionFocused(false)}
              onChangeText={setStory}
            />
          </View>
          <Separator height={34} />
          <View>
            <MediaList
              canEdit
              isMediaLoading={isMediaLoading}
              media={mediaList}
              mediaDescriptions={mediaDescriptions}
              galleryTitle={name}
              showMediaActions={showMediaActions}
              setNewMedia={setNewMedia}
              setMediaDescriptions={setMediaDescriptions}
              addMedia={addMedia}
            />
            <Separator height={34} />
            <View style={paddedStyle}>
              <AudioList
                canEdit
                audio={audioList}
                addAudio={addAudio}
                showAudioActions={showAudioActions}
              />
            </View>
          </View>
          <AudioDescriptionModal
            audio={audioToRename}
            saveDescription={renameAudio}
            close={() => setAudioToRename(undefined)}
          />

          {params?.editPerson && (
            <>
              <Separator height={34} />
              <Button
                type="destructive"
                text={t('screens.editPerson.deletePerson')}
                style={styles.deleteButton}
                onPress={onDeletePress}
              />
            </>
          )}
        </ScrollView>
      </KeyboardAvoidingView>
      {webLayoutEnabled && (
        <ModalFooter>
          <Button
            text={t('common.save')}
            style={styles.webSaveButton}
            onPress={onSavePress}
          />
        </ModalFooter>
      )}
      <Modal
        transparent
        statusBarTranslucent
        animationType="fade"
        visible={isDeleteConfirmationVisible}
        onRequestClose={dismissDeleteConfirmation}>
        <DestructionConfirmationDialog
          confirmText={t('common.delete')}
          title={t('screens.editPerson.deletePerson')}
          subtitle={t('screens.editPerson.deletePersonDescription')}
          close={dismissDeleteConfirmation}
          onConfirm={onDeleteConfirmed}
        />
      </Modal>
    </WebModal>
  )
}

const placeholderTextColor = convertHexToRGBA(Colors['layout.dark'], 0.5)
const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  header: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    paddingHorizontal: 25,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  pictureContainer: {
    marginRight: 'auto',
  },
  addPictureButton: {
    left: 55,
    zIndex: 1,
    bottom: -30,
    position: 'absolute',
  },
  subtitle: {
    fontSize: 14,
    opacity: 0.7,
    lineHeight: 17,
  },
  checkboxText: {
    fontSize: 13,
    lineHeight: 16,
  },
  descriptionInput: {
    fontSize: 15,
    lineHeight: 25,
    minHeight: 120,
    borderWidth: 1,
    borderRadius: 14,
    paddingVertical: 16,
    paddingHorizontal: 20,
    textAlignVertical: 'top',
    ...appStyles.lightShadow,
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  focusedInput: {
    borderColor: Colors['brand.action'],
  },
  deleteButton: {
    marginTop: 'auto',
  },
  webSaveButton: { maxWidth: 580 },
})
