import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Linking, useWindowDimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { SubscriptionsScreenMobile } from './SubscriptionsScreenMobile'
import { SubscriptionsScreenWeb } from './SubscriptionsScreenWeb'
import {
  ZohoPlan,
  ZohoAddon,
  useGetCurrentPlanQuery,
  HostedPaymentPageInput,
  useGetSubscriptionPlansQuery,
  useGetPlanPaymentPageMutation,
  useGetAddonPaymentPageMutation,
} from '../../api/types'
import Loading from '../../components/Loading'
import loggingCore from '../../core/logging-core'
import { MainStackNavigationType } from '../../types/navigation-types'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'

export const SUBSCRIPTIONS_CONTENT_MIN_WIDTH = 1000

export default function SubscriptionsScreen(): JSX.Element {
  const { navigate } = useNavigation<MainStackNavigationType<'Subscriptions'>>()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const dimensions = useWindowDimensions()
  const { data: plansData } = useGetSubscriptionPlansQuery()
  const { data: currentPlanData, refetch } = useGetCurrentPlanQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const onFocus = () => refetch()
    // @ts-ignore
    window.addEventListener('focus', onFocus)
    return () => {
      // @ts-ignore
      window.removeEventListener('focus', onFocus)
    }
  }, [refetch])

  const [getPlanPaymentPage] = useGetPlanPaymentPageMutation()
  const [getAddonPaymentPage] = useGetAddonPaymentPageMutation()
  const [showAnnual, setShowAnnual] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState<ZohoPlan>()
  const [selectedAddon, setSelectedAddon] = useState<ZohoAddon>()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const freePlan: ZohoPlan = useMemo(() => {
    return {
      id: '',
      allocatedSpace: 0.5,
      name: t('freePlan.name'),
      description: t('freePlan.description'),
      targetUsers: t('freePlan.targetUsers'),
      planCode: '',
    }
  }, [t])

  const plans: ZohoPlan[] = useMemo(() => {
    if (!plansData) return []
    const filteredPlans = plansData.zohoPlans.nodes.filter(
      plan => plan.anual === showAnnual,
    ) as ZohoPlan[]
    return [freePlan, ...filteredPlans]
  }, [plansData, freePlan, showAnnual])

  const currentPlanCode = currentPlanData?.me.currentPlanCode

  const currentAddonCode = currentPlanData?.me.currentAddonCode

  const resetSelectedPlan = useCallback(() => {
    setSelectedPlan(plans.find(plan => plan.planCode === currentPlanCode))
  }, [currentPlanCode, plans])

  useEffect(() => {
    resetSelectedPlan()
  }, [resetSelectedPlan, plans])

  useEffect(() => {
    const currentPlan = plansData?.zohoPlans?.nodes?.find(
      plan => plan.planCode === currentPlanCode,
    ) as ZohoPlan
    if (currentPlan && currentPlan.anual !== showAnnual)
      setShowAnnual(currentPlan.anual ?? true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanCode, plansData?.zohoPlans?.nodes])

  const openPaymentPageUrl = useCallback(async () => {
    setIsLoading(true)
    const input: HostedPaymentPageInput = {
      planCode: selectedPlan?.planCode,
      addonCode:
        selectedAddon?.addonCode === '' ? undefined : selectedAddon?.addonCode,
    }

    const url = await (currentPlanCode === selectedPlan?.planCode
      ? getAddonPaymentPage({
          variables: { input },
        })
          .then(({ data }) => data?.getHostedPaymentPageForAddon.paymentPageUrl)
          .catch(error => {
            loggingCore.error(error)
            return undefined
          })
      : getPlanPaymentPage({
          variables: { input },
        })
          .then(({ data }) => data?.getHostedPaymentPage.paymentPageUrl)
          .catch(error => {
            loggingCore.error(error)
            return undefined
          }))

    if (url) Linking.openURL(url)
    setIsLoading(false)
  }, [
    setIsLoading,
    currentPlanCode,
    getPlanPaymentPage,
    getAddonPaymentPage,
    selectedPlan?.planCode,
    selectedAddon?.addonCode,
  ])

  const unsubscribe = useCallback(() => navigate('Unsubscribe'), [navigate])

  const webLayoutEnabled = dimensions.width >= SUBSCRIPTIONS_CONTENT_MIN_WIDTH

  const props: SubscriptionsScreenProps = {
    plans,
    showAnnual,
    unsubscribe,
    selectedPlan,
    setShowAnnual,
    termsAccepted,
    selectedAddon,
    currentPlanCode,
    setSelectedPlan,
    setSelectedAddon,
    currentAddonCode,
    setTermsAccepted,
    resetSelectedPlan,
    openPaymentPageUrl,
  }

  return (
    <>
      <Loading blocking loading={isLoading} />
      {webLayoutEnabled ? (
        <SubscriptionsScreenWeb {...props} />
      ) : (
        <SubscriptionsScreenMobile {...props} />
      )}
    </>
  )
}
