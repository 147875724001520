import React from 'react'
import { StyleSheet, View } from 'react-native'

import ReactPlayer from 'react-player'

import HeaderIconButton from './HeaderIconButton'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MediaViewerProps } from '../types/media-viewer-types'

const VideoViewer: React.FC<MediaViewerProps> = ({ uri, onGoBack }) => {
  return (
    <View style={styles.container}>
      <ReactPlayer controls playing url={uri} />
      <View style={styles.closeButton}>
        <HeaderIconButton iconName="close" onPress={onGoBack} />
      </View>
    </View>
  )
}

export default VideoViewer

const styles = StyleSheet.create({
  container: {
    ...appStyles.center,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.black'],
  },
  closeButton: {
    top: 10,
    right: 20,
    position: 'absolute',
  },
})
