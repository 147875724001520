import React, { useEffect, useMemo, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { PassiveMembershipRow } from './PassiveMembershipRow'
import { ZohoAddon, ZohoPlan } from '../../../api/types'
import Separator from '../../../components/Separator'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { convertHexToRGBA } from '../../../utils/colors'

export const freeAddon: ZohoAddon = {
  id: '',
  price: 0,
  addonCode: '',
}

export const PassiveMembershipCard: React.FC<{
  plan: ZohoPlan
  isCurrent: boolean
  selectedPlan?: ZohoPlan
  selectedAddon?: ZohoAddon
  currentAddonCode?: string | null
  setSelectedAddon: (selected: ZohoAddon) => void
}> = ({
  plan,
  isCurrent,
  selectedPlan,
  selectedAddon,
  currentAddonCode,
  setSelectedAddon,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })
  const [isHovered, setIsHovered] = useState(false)

  const isSelected = useMemo(
    () => plan.id === selectedPlan?.id,
    [selectedPlan, plan],
  )

  useEffect(() => {
    setSelectedAddon(freeAddon)
  }, [isSelected, setSelectedAddon])

  const addons: ZohoAddon[] = useMemo(() => {
    if (!plan.zohoAddons?.nodes) return []
    return [freeAddon, ...plan.zohoAddons.nodes]
  }, [plan.zohoAddons?.nodes])

  const selectedAddonCode = useMemo(() => {
    const addonCode =
      addons.find(addon => addon.addonCode === selectedAddon?.addonCode)
        ?.addonCode ?? ''
    if (isCurrent && !addonCode) return currentAddonCode ?? ''
    return addonCode
  }, [addons, currentAddonCode, isCurrent, selectedAddon?.addonCode])

  return (
    <Pressable
      disabled={!isSelected}
      style={[
        styles.container,
        isSelected && !isCurrent && styles.containerSelected,
        isSelected && styles.containerHighlighted,
        isHovered && appStyles.heavyShadow,
      ]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}>
      <Separator height={20} />
      <Typography weight="bold" style={styles.descriptionText}>
        {t('extended.title', { planName: plan.name })}
      </Typography>
      <Separator height={10} />
      <View style={styles.line} />
      <Separator height={10} />
      <View pointerEvents={isSelected ? 'auto' : 'none'}>
        {addons.map(addon => (
          <View key={addon.id}>
            <PassiveMembershipRow
              addon={addon}
              isSelected={selectedAddonCode === addon.addonCode}
              setSelected={setSelectedAddon}
            />
            <Separator height={10} />
          </View>
        ))}
      </View>
      <Separator height={10} />
    </Pressable>
  )
}

export const PassiveMembershipEmptyCard: React.FC<{ showActive: boolean }> = ({
  showActive,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.settings' })
  return (
    <View
      style={[
        styles.container,
        appStyles.center,
        showActive && styles.containerHighlighted,
      ]}>
      <Typography weight="bold" style={styles.descriptionText}>
        {t('subscriptions.freePlan.passiveSubscription')}
      </Typography>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    opacity: 0.5,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    paddingHorizontal: 14,
    ...appStyles.fullSize,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  containerSelected: {
    borderColor: Colors['brand.action'],
  },
  containerHighlighted: {
    opacity: 1,
  },
  line: {
    height: 1,
    backgroundColor: convertHexToRGBA(Colors['layout.black'], 0.2),
  },
  descriptionText: {
    fontSize: 14,
    textAlign: 'center',
  },
})
