import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { QuizSubCategory } from '../../../api/types'
import { CheckBox } from '../../../components/CheckBox'
import Separator from '../../../components/Separator'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'

interface GlobalEventSubcategoryProps {
  subcategory: QuizSubCategory
  isEnabled: boolean
  handleSubcategoryChange: (subcategoryId: string, enable: boolean) => void
}

export const GlobalEventSubcategory: React.FC<GlobalEventSubcategoryProps> = ({
  subcategory,
  isEnabled,
  handleSubcategoryChange,
}) => (
  <TouchableOpacity
    style={appStyles.inlineContainer}
    onPress={() => {
      handleSubcategoryChange(subcategory.id, !isEnabled)
    }}>
    <Separator width={20} />
    <CheckBox isChecked={isEnabled} />
    <Separator width={10} />
    <Typography style={styles.text}>{subcategory.translatedTitle}</Typography>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    lineHeight: 18,
  },
})
