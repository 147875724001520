import React from 'react'
import { TextProps, StyleSheet } from 'react-native'

import Typography from './Typography'
import Colors from '../../styles/Colors'
import FontSizes from '../../styles/FontSizes'

interface PropsType extends TextProps {
  size: 'small' | 'medium' | 'large'
  color?: 'layout.primary' | 'layout.dark'
}

/**
 * Label text component.
 * Should be adjusted via the style prop as little as possible.
 */
const LabelText: React.FC<PropsType> = ({
  size,
  color = 'layout.primary',
  children,
  ...props
}: PropsType) => (
  <Typography
    weight={weightMap[size]}
    {...props}
    style={[
      styles[size],
      {
        color: Colors[color],
        fontSize: FontSizes[`label.${size}`],
      },
      props.style,
    ]}>
    {children}
  </Typography>
)

export default LabelText

const weightMap: { [key: string]: 'light' | 'regular' | 'medium' } = {
  small: 'light',
  medium: 'regular',
  large: 'medium',
}
const styles = StyleSheet.create({
  /* eslint-disable react-native/no-unused-styles */
  small: {
    lineHeight: 17,
  },
  medium: {
    lineHeight: 16,
  },
  large: {
    lineHeight: 22,
  },
  /* eslint-enable react-native/no-unused-styles */
})
