import React from 'react'
import { View, StyleSheet } from 'react-native'

import Typography from './Text/Typography'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { convertHexToRGBA } from '../utils/colors'

export interface ProgressBarPropsType {
  stepTitle: string
  currentStep: number
  maxSteps: number
  textColor: keyof typeof Colors
  progressColor?: keyof typeof Colors
  progressOpacity?: number
}

const ProgressBar: React.FC<ProgressBarPropsType> = ({
  stepTitle,
  currentStep,
  maxSteps,
  textColor,
  progressColor = 'layout.dark',
  progressOpacity = 0.2,
}: ProgressBarPropsType) => (
  <View style={appStyles.fullSize}>
    <Typography
      weight="medium"
      style={[styles.stepText, { color: Colors[textColor] }]}>
      {`${stepTitle} ${currentStep} `}
      <Typography
        weight="medium"
        style={{
          color: convertHexToRGBA(Colors[textColor], 0.5),
        }}>
        {`/ ${maxSteps}`}
      </Typography>
    </Typography>
    <View style={styles.stepIndicatorsContainer}>
      {Array.from({ length: maxSteps }).map((_, index) => (
        <View
          key={index}
          style={[
            styles.stepIndicator,
            {
              backgroundColor: Colors[progressColor],
              opacity: progressOpacity,
            },
            index < currentStep && styles.stepIndicatorActive,
          ]}
        />
      ))}
    </View>
  </View>
)

export default ProgressBar

const styles = StyleSheet.create({
  stepText: {
    fontSize: 20,
  },
  stepIndicatorsContainer: {
    height: 6,
    flexGrow: 1,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stepIndicator: {
    height: 6,
    borderRadius: 30,
    marginHorizontal: 2,
    ...appStyles.fullSize,
  },
  stepIndicatorActive: {
    opacity: 1,
    backgroundColor: Colors['brand.action'],
  },
})
