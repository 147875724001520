import { Platform } from 'react-native'

import CONFIG from '../config/app-config'

function request(
  method: 'POST' | 'GET' | 'PUT' | 'DELETE',
  path: string,
  body?: FormData | object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL()}/api/v1/${path}`, {
        method,
        // @ts-ignore
        headers: Platform.select({
          web:
            body instanceof FormData
              ? {}
              : { 'Content-Type': 'application/json' },
          default: {
            'Content-Type':
              body instanceof FormData
                ? 'multipart/form-data'
                : 'application/json',
          },
        }),
        body: body instanceof FormData ? body : body && JSON.stringify(body),
      })

      if (!response.ok) {
        return reject(response)
      }

      const text = await response.text()
      const json = JSON.parse(text)

      resolve(json)
    } catch (error) {
      reject(error)
    }
  })
}

export default {
  post: (path: string, body?: FormData | object) => request('POST', path, body),
  put: (path: string, body: object) => request('PUT', path, body),
}
