import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import de from './de.json'
import en from './en.json'
import core from '../core/core'
import { Locale } from '../types/localization-types'

export const translations = {
  de: { translation: de },
  en: { translation: en },
}

if (!i18next.isInitialized) {
  i18next
    .use(initReactI18next)
    .init({
      resources: translations,
      debug: __DEV__,
      compatibilityJSON: 'v3',
      lng: 'en' as Locale,
    })
    .catch(error => core.logging.error('Error initializing i18next', error))
}
