import { HttpLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { name } from '../../app.json'
import CONFIG from '../config/app-config'
import core from '../core/core'
import { OAuth } from '../types/user-types'
import {
  getShareTokenHeader,
  getAuthorizationHeader,
} from '../utils/http-headers'

const httpLink = (isPublic: boolean) =>
  new HttpLink({
    uri: isPublic
      ? CONFIG.GRAPHQL_ENDPOINT_PUBLIC()
      : CONFIG.GRAPHQL_ENDPOINT(),
  })

const buildAuthLink = (oauth?: OAuth, shareToken?: string) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...getAuthorizationHeader(oauth?.token),
      ...getShareTokenHeader(shareToken),
    },
  }))

const buildErrorLink = (refreshToken: () => void) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.message) {
          case 'Signature has expired':
            refreshToken()
            return
          default:
            core.logging.warn('Graphql error', {
              message: (err.extensions?.fullMessages as string[])?.[0],
              operation,
            })
        }
      }
    }

    if (networkError) {
      core.logging.warn(
        'Network error',
        JSON.stringify(networkError, undefined, 2),
        operation,
      )
    }
  })

const buildClient = (
  refreshToken: () => void,
  cache: InMemoryCache,
  oauth?: OAuth,
  shareToken?: string,
) =>
  new ApolloClient({
    name: shareToken ? 'PublicClient' : name,
    link: buildAuthLink(oauth, shareToken)
      .concat(buildErrorLink(refreshToken))
      .concat(httpLink(!!shareToken)),
    cache,
  })

export default buildClient
