import React from 'react'
import { Platform, ScrollView, StyleSheet, RefreshControl } from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import MemoriesList from './components/MemoriesList'
import { useGetOtherMemoriesQuery } from '../../api/types'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import RootContainer from '../../components/RootContainer'
import Separator from '../../components/Separator'
import useSafeAreaPaddedStyle from '../../hooks/useSafeAreaPaddedStyle'
import { useShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import appStyles from '../../styles/app-styles'

const intialVariables = { after: '', first: 10000 }

export default function OtherMemoriesScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.otherMemories',
  })

  // TODO PAGINATE
  const { data, refetch, networkStatus } = useGetOtherMemoriesQuery({
    variables: intialVariables,
  })
  useShouldRefetch(QueryKey.OtherMemories, refetch, intialVariables)

  return (
    <RootContainer>
      <Loading loading={networkStatus === NetworkStatus.loading} />
      <ScrollView
        style={appStyles.fullSize}
        fadingEdgeLength={60}
        contentContainerStyle={useSafeAreaPaddedStyle(
          styles.scrollContentContainer,
          { insetBottom: true },
        )}
        refreshControl={
          <RefreshControl
            refreshing={networkStatus === NetworkStatus.refetch}
            onRefresh={refetch}
          />
        }>
        <ListHeader
          title={t('title')}
          subtitle={t('subtitle')}
          negatablePaddingSource={styles.scrollContentContainer}
        />
        <MemoriesList memories={data?.memories.nodes} />
        <Separator height={100} />
      </ScrollView>
    </RootContainer>
  )
}

const styles = StyleSheet.create({
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 27,
    height: Platform.select({ web: 0 }),
    paddingHorizontal: 24,
  },
})
