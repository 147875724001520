import React, { useMemo, useCallback } from 'react'
import {
  View,
  Modal,
  FlatList,
  StyleSheet,
  ListRenderItem,
  TouchableOpacity,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useUpdateUserMutation } from '../api/types'
import FlagSvg from '../components/FlagSvg'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import SvgIcon from '../components/SvgIcon'
import ButtonText from '../components/Text/ButtonText'
import TitleText from '../components/Text/TitleText'
import { translations } from '../locales/config'
import { useLocale } from '../providers/LocaleProvider'
import { useUser } from '../providers/UserProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { Locale } from '../types/localization-types'
import { convertHexToRGBA } from '../utils/colors'

const Divider = () => <Separator height={10} />

export default function LanguagePickerModal(): JSX.Element {
  const { goBack } = useNavigation()
  const { locale, setLocale } = useLocale()
  const { t } = useTranslation()
  const { user, isLoggedIn } = useUser()
  const [updateUser] = useUpdateUserMutation()

  const renderItem: ListRenderItem<Locale> = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={[
          styles.item,
          item === locale && { backgroundColor: Colors['layout.white'] },
        ]}
        onPress={() => {
          setLocale(item)
          if (isLoggedIn && user) {
            updateUser({
              variables: {
                profileInput: {
                  email: user.email,
                  // @ts-ignore
                  language: item,
                },
              },
            })
          }
        }}>
        <FlagSvg locale={item} height={40} width={40} style={styles.flag} />
        <ButtonText color="layout.dark" size="medium">
          {translations[item].translation.name}
        </ButtonText>
      </TouchableOpacity>
    ),
    [locale, setLocale, isLoggedIn, user, updateUser],
  )

  const data = useMemo(() => Object.keys(translations) as Locale[], [])

  return (
    <Modal
      transparent
      statusBarTranslucent
      animationType="fade"
      onRequestClose={goBack}>
      <PickerModal close={goBack}>
        <View style={styles.container}>
          <View style={appStyles.inlineContainer}>
            <View style={appStyles.fullSize}>
              <TitleText color="layout.dark" size="medium">
                {t('modals.languagePicker.title')}
              </TitleText>
            </View>
            <TouchableOpacity
              // @ts-ignore
              hitSlop={10}
              onPress={goBack}>
              <SvgIcon name="close" />
            </TouchableOpacity>
          </View>

          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={item => item}
            ItemSeparatorComponent={Divider}
            contentContainerStyle={styles.flatlistContentContainer}
          />
        </View>
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: 24, paddingTop: 18 },
  flatlistContentContainer: { paddingBottom: 56, paddingTop: 37 },
  item: {
    height: 80,
    width: '100%',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: convertHexToRGBA(Colors['layout.white'], 0.5),
  },
  flag: { margin: 20 },
})
