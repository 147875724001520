import React, { useState, useCallback } from 'react'
import {
  View,
  Platform,
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
  TouchableOpacity,
} from 'react-native'

import { useNavigation, RouteProp, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DividerText from '../../components/DividerText'
import {
  TextInputField,
  PasswordInputField,
} from '../../components/InputFields'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SocialLoginRow, { SocialLogin } from '../../components/SocialLoginRow'
import LabelText from '../../components/Text/LabelText'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import core from '../../core/core'
import useOnWebEnterPress from '../../hooks/useOnWebEnterPress'
import useStateRef from '../../hooks/useStateRef'
import { useUser } from '../../providers/UserProvider'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  UnauthorizedStackParamsType,
  UnauthorizedStackNavigationType,
} from '../../types/navigation-types'

export default function SignInScreen(): JSX.Element {
  const { params } =
    useRoute<RouteProp<UnauthorizedStackParamsType, 'SignIn'>>()
  const { navigate } =
    useNavigation<UnauthorizedStackNavigationType<'SignIn'>>()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.signIn' })

  const { login } = useUser()

  const [isLoading, setIsLoading, getIsLoading] = useStateRef(false)
  const [email, setEmail] = useState<string>(params?.email ?? '')
  const [emailErrorKey, setEmailErrorKey] = useState<'error.noEmail'>()
  const [password, setPassword] = useState<string>('')
  const [passwordErrorKey, setPasswordErrorKey] = useState<'error.noPassword'>()
  const [didLoginFail, setDidLoginFail] = useState<boolean>(false)
  const loginFailMessage = didLoginFail ? ' ' : ''

  const { webLayoutEnabled } = useWebLayout()

  const onSignInButtonPress = useCallback(() => {
    if (getIsLoading()) return
    if (!email || !password) {
      !email && setEmailErrorKey('error.noEmail')
      !password && setPasswordErrorKey('error.noPassword')
      return
    }
    setIsLoading(true)
    login({ email, password })
      .catch(error => {
        switch (error.status) {
          case 401:
            setDidLoginFail(true)
            break
          case 403:
            navigate('EmailConfirmation')
            break
          default:
            // TODO: Alert
            core.logging.error(error)
        }
      })
      .finally(() => setIsLoading(false))
  }, [email, password, login, setIsLoading, getIsLoading, navigate])

  useOnWebEnterPress(onSignInButtonPress)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSocialLoginPress = useCallback((type: SocialLogin) => {
    console.log('TODO login with', type)
  }, [])

  return (
    <SafeAreaView style={appStyles.fullSize}>
      <Loading blocking loading={isLoading} />
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          style={styles.container}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={styles.scrollContentContainer}>
          {!webLayoutEnabled && (
            <View style={styles.languagePickerContainer}>
              <LanguagePickerButton />
            </View>
          )}
          {webLayoutEnabled ? (
            <View style={appStyles.fullSize} />
          ) : (
            <Separator height={35} />
          )}
          <TitleText color="layout.dark" size="medium">
            {t('title')}
          </TitleText>
          <View style={styles.centerBlock}>
            {/* TODO: Swap when social login is ready */}
            {/* <Separator height={40} />
            <SocialLoginRow onSocialLoginPress={onSocialLoginPress} />
            <Separator height={40} />
            <DividerText text={t('or')} /> */}
            <Separator height={40} />
            <View
              style={[
                styles.inputContainer,
                webLayoutEnabled && styles.webInputContainer,
              ]}>
              <TextInputField
                value={email}
                autoComplete="email"
                autoCapitalize="none"
                keyboardType="email-address"
                label={t('emailPlaceholder')}
                errorMessage={
                  emailErrorKey ? t(emailErrorKey) : loginFailMessage
                }
                clearError={() => {
                  setEmailErrorKey(undefined)
                  setDidLoginFail(false)
                }}
                onChangeValue={setEmail}
              />
              <PasswordInputField
                value={password}
                label={t('passwordPlaceholder')}
                errorMessage={
                  passwordErrorKey ? t(passwordErrorKey) : loginFailMessage
                }
                clearError={() => {
                  setPasswordErrorKey(undefined)
                  setDidLoginFail(false)
                }}
                onChangeValue={setPassword}
              />
              <Typography weight="light" style={styles.loginFailMessage}>
                {didLoginFail && t('error.loginFail')}
              </Typography>
            </View>
            <Separator height={10} />
            <Button text={t('button')} onPress={onSignInButtonPress} />
            <Separator height={20} />
            <LabelText size="small">{t('registerPrompt')}</LabelText>
            <Separator height={20} />
            <Button
              type="inverted"
              text={t('register')}
              onPress={() => navigate('AuthRoot')}
            />
            <Separator height={40} />
          </View>
          {webLayoutEnabled && <View style={appStyles.fullSize} />}
          <TouchableOpacity
            // @ts-ignore
            hitSlop={20}
            onPress={() => navigate('ForgotPassword')}>
            <LabelText size="small">{t('forgotPassword')}</LabelText>
          </TouchableOpacity>
          <Separator height={40} />
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 5,
    alignItems: 'center',
  },
  languagePickerContainer: {
    marginLeft: 24,
    marginRight: 'auto',
  },
  centerBlock: {
    ...appStyles.center,
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  inputContainer: {
    width: '100%',
    columnGap: 10,
    paddingHorizontal: 25,
  },
  webInputContainer: {
    paddingHorizontal: 100,
  },
  loginFailMessage: {
    height: 30,
    fontSize: 13,
    lineHeight: 15,
    paddingLeft: 6,
    color: Colors['brand.red'],
  },
})
