/**
 * Prepares Authorization header for HTTP requests
 * @param authToken - Auth token to use.
 * @returns `{ Authorization: string }` - Object containing "Authorization" key and value to be spread within request headers.
 */
export const getAuthorizationHeader: (authToken?: string) => {
  Authorization: `Bearer ${string}` | ''
} = authToken => ({
  Authorization: authToken ? `Bearer ${authToken}` : '',
})

/**
 * Prepares Authorization header for HTTP requests
 * @param shareToken - Auth token to use.
 * @returns `{ Authorization: string }` - Object containing "Authorization" key and value to be spread within request headers.
 */
export const getShareTokenHeader: (shareToken?: string) => {
  ShareToken: `${string}` | ''
} = shareToken => ({
  ShareToken: shareToken ? `${shareToken}` : '',
})
