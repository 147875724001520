import { useEffect } from 'react'
import { Platform } from 'react-native'

import SoftInputMode from 'react-native-set-soft-input-mode'

export default function () {
  useEffect(() => {
    if (Platform.OS !== 'android') return

    SoftInputMode.set(SoftInputMode.ADJUST_NOTHING)
    return () => SoftInputMode.set(SoftInputMode.ADJUST_RESIZE)
  }, [])
}
