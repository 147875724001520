import React from 'react'
import { StyleSheet, TouchableOpacityProps } from 'react-native'

import Button from './Button'
import appStyles from '../styles/app-styles'

const HeaderButton: React.FC<
  Pick<TouchableOpacityProps, 'onPress'> & { text: string; secondary?: boolean }
> = ({ onPress, text, secondary }) => (
  <Button
    text={text}
    // @ts-ignore
    hitSlop={10}
    type={secondary ? 'header' : 'primary'}
    style={styles.button}
    onPress={onPress}
  />
)

export default HeaderButton

const styles = StyleSheet.create({
  button: {
    width: undefined,
    paddingHorizontal: 22,
    ...appStyles.lightShadow,
  },
})
