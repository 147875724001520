import { useCallback } from 'react'

import Clipboard from '@react-native-clipboard/clipboard'
import { useTranslation } from 'react-i18next'

import { Memory, Person } from '../api/types'
import { useBanner } from '../providers/BannerProvider'

export default function useNativeShare() {
  const { t } = useTranslation()
  const showBanner = useBanner()

  const nativeShare = useCallback(
    (url: string, content: Memory | Person) => {
      Clipboard.setString(url)
      showBanner({
        title: t(
          `banner.contentShared.${
            content.__typename === 'Memory' ? 'memory' : 'person'
          }.title`,
        ),
        icon: 'check-filled',
        color: 'brand.action',
        description: t(
          `banner.contentShared.${
            content.__typename === 'Memory' ? 'memory' : 'person'
          }.pasteInstruction`,
        ),
      })
    },
    [showBanner, t],
  )

  return nativeShare
}
