import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, Platform, StyleSheet, SafeAreaView } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { EventsQuizCategory } from './components/EventsQuizCategory'
import { EventsQuizHeader } from './components/EventsQuizHeader'
import {
  QuizCategory,
  useGetQuizCategoriesQuery,
  useCreateUserCategoriesMutation,
  useCreateUserSubcategoriesMutation,
} from '../../api/types'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import WebModal from '../../components/WebModal'
import loggingCore from '../../core/logging-core'
import { useBackAwareFlatList } from '../../hooks/useBackAwareFlatList'
import { useListState } from '../../hooks/useListState'
import useStateRef from '../../hooks/useStateRef'
import { useSetShouldRefetch, QueryKey } from '../../providers/RefetchProvider'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function EventsQuizScreen(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'common' })
  const { reset } = useNavigation<MainStackNavigationType<'EventsQuiz'>>()
  const { stepIndex, flatListRef, scrollToIndex, navigateBack } =
    useBackAwareFlatList()

  const { data, loading } = useGetQuizCategoriesQuery()
  const [isSaving, setIsSaving, getIsSaving] = useStateRef(false)
  const [createCategories] = useCreateUserCategoriesMutation()
  const [createSubcategories] = useCreateUserSubcategoriesMutation()
  const setShouldRefetch = useSetShouldRefetch()

  const quizLength = useMemo(
    () => data?.quizCategories.nodes.length ?? 0,
    [data],
  )

  const [
    enabledCategoryIds,
    enableCategory,
    disableCategory,
    setEnabledCategoryIds,
  ] = useListState<string>([])
  const [enabledSubcategoryIds, enableSubcategory, disableSubcategory] =
    useListState<string>([])

  useEffect(() => {
    setEnabledCategoryIds(data?.quizCategories.nodes.map(c => c.id) ?? [])
  }, [setEnabledCategoryIds, data?.quizCategories.nodes])

  const removeEnabledCategory = useCallback(
    (id: string) => {
      disableCategory(id)
      data?.quizCategories.nodes
        .find(c => c.id === id)
        ?.quizSubCategories?.nodes.forEach(s => disableSubcategory(s.id))
    },
    [disableCategory, disableSubcategory, data?.quizCategories.nodes],
  )

  const saveCategories = useCallback(() => {
    if (getIsSaving()) return

    setIsSaving(true)
    const createCategoriesPromise = createCategories({
      variables: {
        quizCategories: enabledCategoryIds.map(c => ({
          quizCategoryId: c,
        })),
      },
    })

    const createSubcategoriesPromise =
      enabledSubcategoryIds.length === 0
        ? Promise.resolve()
        : createSubcategories({
            variables: {
              quizSubcategories: enabledSubcategoryIds.map(s => ({
                quizSubCategoryId: s,
              })),
            },
          })

    Promise.all([createCategoriesPromise, createSubcategoriesPromise])
      .then(() => {
        setShouldRefetch(QueryKey.CommonMemories)
        reset({
          index: 1,
          routes: [{ name: 'Root' }, { name: 'EventsQuizSuccess' }],
        })
      })
      .catch(loggingCore.error)
      .finally(() => setIsSaving(false))
  }, [
    reset,
    setIsSaving,
    getIsSaving,
    setShouldRefetch,
    createCategories,
    enabledCategoryIds,
    createSubcategories,
    enabledSubcategoryIds,
  ])

  const onNextPress = useCallback(() => {
    if (stepIndex < quizLength - 1) {
      scrollToIndex(stepIndex + 1)
      return
    }
    saveCategories()
  }, [stepIndex, quizLength, saveCategories, scrollToIndex])

  const [width, setWidth] = useState(0)

  return (
    <WebModal fullHeight withoutBackground>
      <Loading loading={loading || isSaving} blocking={isSaving} />
      <EventsQuizHeader
        stepIndex={stepIndex}
        quizLength={quizLength}
        navigateBack={navigateBack}
      />
      <Separator height={30} />
      <FlatList
        ref={flatListRef}
        horizontal
        data={data?.quizCategories.nodes as QuizCategory[]}
        scrollEnabled={false}
        keyExtractor={category => category.id}
        renderItem={({ item }) => (
          <EventsQuizCategory
            width={width}
            category={item}
            enableCategory={enableCategory}
            enableSubcategory={enableSubcategory}
            disableCategory={removeEnabledCategory}
            disableSubcategory={disableSubcategory}
          />
        )}
        showsHorizontalScrollIndicator={false}
        onLayout={e => setWidth(e.nativeEvent.layout.width)}
      />
      <SafeAreaView>
        <Button
          text={t('next')}
          style={styles.nextButton}
          onPress={onNextPress}
        />
        <Separator height={Platform.select({ web: 30, default: 0 })} />
      </SafeAreaView>
    </WebModal>
  )
}

const styles = StyleSheet.create({
  nextButton: { marginBottom: 10 },
})
