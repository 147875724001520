import React, { useRef } from 'react'
import { View, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { ActiveMembershipCard } from './components/ActiveMembershipCard'
import {
  PassiveMembershipCard,
  PassiveMembershipEmptyCard,
} from './components/PassiveMembershipCard'
import { SUBSCRIPTIONS_CONTENT_MIN_WIDTH } from './SubscriptionsScreen'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import { TermsAndConditionsRow } from '../../components/TermsAndConditionsRow'
import TitleText from '../../components/Text/TitleText'
import Typography from '../../components/Text/Typography'
import { ToggleButton } from '../../components/ToggleButton'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import Drawer from '../../navigation/Drawer'
import { useLocale } from '../../providers/LocaleProvider'
import { SIDE_COLUMN_WIDTH } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'
import { SubscriptionsScreenProps } from '../../types/SubscriptionScreen.types'
import { convertHexToRGBA } from '../../utils/colors'

export const SubscriptionsScreenWeb: React.FC<SubscriptionsScreenProps> = ({
  plans,
  showAnnual,
  unsubscribe,
  selectedPlan,
  selectedAddon,
  termsAccepted,
  setShowAnnual,
  setSelectedPlan,
  currentPlanCode,
  currentAddonCode,
  setSelectedAddon,
  setTermsAccepted,
  resetSelectedPlan,
  openPaymentPageUrl,
}) => {
  const { locale } = useLocale()
  const dimensions = useWindowDimensions()
  const { goBack } = useNavigation<MainStackNavigationType<'Subscriptions'>>()
  const scrollViewRef = useRef<ScrollView>(null)
  const { t } = useTranslation()

  const showDrawer =
    dimensions.width >= SUBSCRIPTIONS_CONTENT_MIN_WIDTH + SIDE_COLUMN_WIDTH

  return (
    <View style={styles.container}>
      {showDrawer && <Drawer />}
      <ScrollView
        ref={scrollViewRef}
        style={[
          styles.scrollContainer,
          {
            maxWidth: showDrawer ? SUBSCRIPTIONS_CONTENT_MAX_WIDTH : undefined,
          },
        ]}
        contentContainerStyle={styles.scrollContentContainer}>
        <View style={styles.header}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.headerText}>
            {t('screens.settings.subscriptions.title')}
          </Typography>
        </View>
        <View style={styles.contentContainer}>
          <Separator height={26} />
          <View style={styles.planSwitcherContainer}>
            <TitleText
              size="medium"
              color="layout.dark"
              style={styles.titleText}>
              {t('screens.settings.subscriptions.choosePlan')}
            </TitleText>
            <Separator height={20} />
            <ToggleButton
              value={showAnnual}
              setValue={setShowAnnual}
              firstOptionText={t('screens.settings.subscriptions.annual')}
              secondOptionText={t('screens.settings.subscriptions.monthly')}
            />
          </View>
          <Separator height={30} />
          <TitleText size="medium" color="layout.dark" style={styles.titleText}>
            {t('screens.settings.subscriptions.activeMembership.title')}
          </TitleText>
          <Separator height={10} />
          <Typography weight="light" style={styles.descriptionText}>
            {t('screens.settings.subscriptions.activeMembership.description')}
          </Typography>
          <Separator height={10} />
          <View style={appStyles.row}>
            {plans.map((plan, index) => (
              <View key={plan.id} style={[appStyles.fullSize, appStyles.row]}>
                <ActiveMembershipCard
                  plan={plan}
                  isSelected={plan.id === selectedPlan?.id}
                  currentPlanCode={currentPlanCode}
                  setSelectedPlan={selected => {
                    selected ? setSelectedPlan(selected) : resetSelectedPlan()
                    selected &&
                      setTimeout(() => scrollViewRef.current?.scrollToEnd(), 50)
                    setTermsAccepted(false)
                  }}
                />
                {index !== 3 && <Separator width={20} />}
              </View>
            ))}
          </View>
          <Separator height={30} />
          <TitleText size="medium" color="layout.dark" style={styles.titleText}>
            {t('screens.settings.subscriptions.passiveMembership.title')}
          </TitleText>
          <Separator height={10} />
          <Typography weight="light" style={styles.descriptionText}>
            {t('screens.settings.subscriptions.passiveMembership.description')}
          </Typography>
          <Separator height={10} />
          <View style={appStyles.row}>
            <View style={[appStyles.fullSize, appStyles.row]}>
              <PassiveMembershipEmptyCard
                showActive={!currentPlanCode && !selectedPlan}
              />
              <Separator width={20} />
            </View>
            {plans.slice(1, 4).map((plan, index) => (
              <View key={plan.id} style={[appStyles.fullSize, appStyles.row]}>
                <PassiveMembershipCard
                  plan={plan}
                  selectedPlan={selectedPlan}
                  currentAddonCode={currentAddonCode}
                  selectedAddon={selectedAddon}
                  isCurrent={plan.planCode === currentPlanCode}
                  setSelectedAddon={setSelectedAddon}
                />
                {index !== 2 && <Separator width={20} />}
              </View>
            ))}
          </View>
          <Separator height={30} />
          {((selectedPlan &&
            selectedPlan?.planCode !== (currentPlanCode ?? '')) ||
            selectedAddon?.addonCode !== (currentAddonCode ?? '')) && (
            <>
              <TitleText
                size="medium"
                color="layout.dark"
                style={styles.titleText}>
                {t('screens.settings.subscriptions.summary')}
              </TitleText>
              <Separator height={10} />
              {selectedPlan?.planCode !== currentPlanCode && (
                <View style={appStyles.row}>
                  <Typography style={styles.planText}>
                    {t(
                      `screens.settings.subscriptions.planSubscription.${
                        selectedPlan?.anual ? 'annual' : 'monthly'
                      }`,
                      { planName: selectedPlan?.name },
                    )}
                  </Typography>
                  <Separator width={10} />
                  <View style={styles.dottedLine} />
                  <Separator width={10} />
                  <Typography style={styles.priceText}>
                    {`${(selectedPlan?.price ?? 0).toLocaleString(locale, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} €`}
                  </Typography>
                </View>
              )}
              <Separator height={10} />
              {selectedAddon?.addonCode !== currentAddonCode && (
                <View style={appStyles.row}>
                  <Typography style={styles.planText}>
                    {t(
                      'screens.settings.subscriptions.planSubscription.addon',
                      {
                        planName: selectedPlan?.name,
                        timePeriod: selectedAddon?.expiresInYears
                          ? t(
                              'screens.settings.subscriptions.extended.timePeriod',
                              {
                                years: selectedAddon?.expiresInYears,
                              },
                            )
                          : t(
                              'screens.settings.subscriptions.freePlan.timePeriod',
                            ),
                      },
                    )}
                  </Typography>
                  <Separator width={10} />
                  <View style={styles.dottedLine} />
                  <Separator width={10} />
                  <Typography style={styles.priceText}>
                    {`${(selectedAddon?.price ?? 0).toLocaleString(locale, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} €`}
                  </Typography>
                </View>
              )}
              <Separator height={10} />
              <View style={styles.totalPriceContainer}>
                <TitleText
                  size="medium"
                  color="layout.dark"
                  style={styles.totalText}>
                  {t('screens.settings.subscriptions.total')}
                </TitleText>
                <Separator width={10} />
                <TitleText
                  size="medium"
                  color="layout.dark"
                  style={styles.titleText}>
                  {`${(
                    (selectedPlan?.planCode === currentPlanCode
                      ? 0
                      : selectedPlan?.price ?? 0) + (selectedAddon?.price ?? 0)
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} €`}
                </TitleText>
              </View>
              <Typography style={styles.vatText}>
                {t('screens.settings.subscriptions.vatIncluded')}
              </Typography>
              <Separator height={20} />
              <TermsAndConditionsRow
                isAccepted={termsAccepted}
                setIsAccepted={setTermsAccepted}
              />
              <Separator height={40} />
              <Button
                type={selectedPlan && termsAccepted ? 'primary' : 'disabled'}
                style={styles.proceedButton}
                text={t('common.proceed')}
                onPress={openPaymentPageUrl}
              />
              <Separator height={18} />
            </>
          )}
          {currentPlanCode && (
            <>
              <Separator height={40} />
              <Typography>
                {t('screens.settings.subscriptions.cancel.followLink')}
              </Typography>
              <Separator height={18} />
              <Button
                type="secondary"
                text={t('screens.settings.subscriptions.cancel.title')}
                style={styles.unsubscribeButton}
                onPress={unsubscribe}
              />
            </>
          )}
          <Separator height={50} />
          <UserStorageInfo />
          <Separator height={40} />
        </View>
      </ScrollView>
    </View>
  )
}

const SUBSCRIPTIONS_CONTENT_MAX_WIDTH = 1600
const styles = StyleSheet.create({
  container: {
    ...appStyles.row,
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.white'],
  },
  scrollContainer: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    height: 0,
    flexGrow: 1,
    borderRightWidth: 1,
    backgroundColor: Colors['layout.light'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 24,
    borderBottomWidth: 1,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  planSwitcherContainer: {
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
  },
  contentContainer: {
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  titleText: { fontSize: 26 },
  totalText: { fontSize: 18 },
  descriptionText: { fontSize: 16 },
  planText: { fontSize: 14 },
  dottedLine: {
    borderBottomWidth: 2,
    ...appStyles.fullSize,
    borderStyle: 'dotted',
    borderColor: convertHexToRGBA(Colors['layout.dark'], 0.5),
  },
  totalPriceContainer: {
    ...appStyles.row,
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  },
  priceText: {
    width: 80,
    fontSize: 14,
    textAlign: 'right',
  },
  vatText: {
    fontSize: 14,
    textAlign: 'right',
  },
  proceedButton: { alignSelf: 'flex-start' },
  unsubscribeButton: {
    padding: 5,
    maxWidth: 130,
    alignSelf: 'flex-start',
  },
})
