import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { ZohoAddon } from '../../../api/types'
import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import Typography from '../../../components/Text/Typography'
import { useLocale } from '../../../providers/LocaleProvider'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { convertHexToRGBA } from '../../../utils/colors'

export const PassiveMembershipRowMobile: React.FC<{
  addon: ZohoAddon
  isSelected: boolean
  setSelected: (selected: ZohoAddon) => void
}> = ({ addon, isSelected, setSelected }) => {
  const { locale } = useLocale()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions',
  })

  return (
    <TouchableOpacity
      style={[styles.container, isSelected && styles.containerSelected]}
      onPress={() => setSelected(addon)}>
      <View style={appStyles.inlineContainer}>
        {isSelected && (
          <>
            <SvgIcon name="check" viewBox="5 1 18 18" height={18} width={18} />
            <Separator width={6} />
          </>
        )}
        <Typography
          style={[
            styles.timePeriodText,
            isSelected && styles.timePeriodTextSelected,
          ]}>
          {addon.expiresInYears
            ? t('extended.timePeriod', { years: addon.expiresInYears })
            : t('freePlan.timePeriod')}
        </Typography>
      </View>
      <Typography weight="bold" style={styles.priceText}>
        {`${addon.price?.toLocaleString(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} €`}
      </Typography>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 15,
    paddingHorizontal: 20,
    ...appStyles.removedOutline,
    ...appStyles.inlineContainer,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.light'],
    borderColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
  },
  containerSelected: {
    borderColor: Colors['brand.action'],
    backgroundColor: Colors['layout.white'],
  },
  timePeriodText: {
    fontSize: 14,
    color: convertHexToRGBA(Colors['layout.black'], 0.5),
  },
  timePeriodTextSelected: {
    color: Colors['layout.black'],
  },
  priceText: {
    fontSize: 14,
  },
})
