import React from 'react'
import { View } from 'react-native'

interface Props {
  width?: number
  height?: number
}

/**
 * Separator component to render blank vertical/horizontal space.
 * @param height - `number` - the height of the separator
 * @param width - `number` - the width of the separator
 */
const Separator: React.FC<Props> = ({ width = 1, height = 1 }) => (
  <View style={{ height, width }} />
)

export default Separator
