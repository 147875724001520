import React, { useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Avatar from '../../components/Avatar'
import {
  PersonInfo,
  PersonInfoItem,
  PersonInfoItemSeparator,
} from '../../components/PersonInfoItem'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import {
  getGenderItems,
  getRelationItems,
} from '../../helpers/ProfileFormItems'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import { useLocale } from '../../providers/LocaleProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import { formatDate } from '../../utils/date'

export default function PersonInfoScreen(): JSX.Element {
  const { t } = useTranslation()
  const { goBack } = useNavigation<MainStackNavigationType<'PersonInfo'>>()
  const { params } = useRoute<RouteProp<MainStackParamsType, 'PersonInfo'>>()
  const locale = useLocale()

  const personInfo: PersonInfo[] = useMemo(() => {
    const genderItems = getGenderItems(t)
    const relationItems = getRelationItems(t)
    return [
      {
        label: t('profile.gender.title'),
        value:
          genderItems.find(g => g.key === params.person?.gender)?.label ?? '',
      },
      {
        label: t('screens.createPerson.relation'),
        value:
          relationItems.find(g => g.key === params.person?.relation)?.label ??
          '',
      },
      { label: t('profile.email'), value: params.person?.email ?? '' },
      {
        label: t('profile.birthday'),
        value: params.person?.dateOfBirth
          ? formatDate(new Date(params.person?.dateOfBirth), locale.locale)
          : '',
      },
      params.person?.dateOfDeath
        ? {
            label: t('screens.createPerson.deathDate'),
            value: formatDate(
              new Date(params.person.dateOfDeath),
              locale.locale,
            ),
          }
        : {},
    ].filter(item => item.label) as PersonInfo[]
  }, [locale.locale, params.person, t])

  return (
    <View style={styles.container}>
      <View style={appStyles.fullSize}>
        <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
          <View style={appStyles.inlineContainer}>
            <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
            <Separator width={5} />
            <Typography weight="medium" style={styles.titleText}>
              {t('screens.personDetails.title')}
            </Typography>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={appStyles.inlineContainer}>
            <Avatar
              gender={params.person?.gender}
              uri={params.person?.pictureUrl}
            />
            <Separator width={20} />
            <View>
              <Typography style={styles.nameText}>
                {params.person?.firstName}
              </Typography>
              <Typography weight="medium" style={styles.surnameText}>
                {params.person?.lastName}
              </Typography>
            </View>
          </View>
          <Separator height={20} />
          <FlatList
            data={personInfo}
            scrollEnabled={false}
            renderItem={PersonInfoItem}
            keyExtractor={item => item.label}
            ItemSeparatorComponent={PersonInfoItemSeparator}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    ...appStyles.row,
    paddingBottom: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  titleText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    marginTop: 30,
    marginHorizontal: 25,
    ...appStyles.fullSize,
  },
  nameText: {
    fontSize: 16,
    lineHeight: 18,
  },
  surnameText: {
    fontSize: 22,
    lineHeight: 24,
  },
})
