import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'

import SvgIcon, { SvgName } from './SvgIcon'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { convertHexToRGBA } from '../utils/colors'

const HeaderIconButton: React.FC<
  Pick<TouchableOpacityProps, 'onPress'> & {
    iconName: SvgName
    opacity?: number
  }
> = ({ onPress, iconName, opacity = 1 }) => (
  <TouchableOpacity
    // @ts-ignore
    hitSlop={10}
    style={[
      styles.settingsButton,
      opacity === 1 && styles.shadow,
      {
        backgroundColor: convertHexToRGBA(Colors['layout.white'], opacity),
      },
    ]}
    onPress={onPress}>
    <SvgIcon name={iconName} color={Colors['layout.dark']} />
  </TouchableOpacity>
)

export default HeaderIconButton

export const HEADER_ICON_BUTTON_SIZE = 50

const styles = StyleSheet.create({
  settingsButton: {
    borderRadius: 20,
    ...appStyles.center,
    width: HEADER_ICON_BUTTON_SIZE,
    height: HEADER_ICON_BUTTON_SIZE,
    backgroundColor: Colors['layout.white'],
  },
  shadow: {
    ...appStyles.lightShadow,
    elevation: 3,
  },
})
