/**
 * Formats date to locale aware string
 * @param date - date to format
 * @param locale - locale to use
 * @param precision - necessary precsion
 * @returns `string` - formatted date string
 */

import { DatePrecisionEnum } from '../api/types'
import { Locale } from '../types/localization-types'

export const formatDate: (
  date: Date,
  locale: Locale | undefined,
  precision?: DatePrecisionEnum | null,
) => string = (date, locale, precision) => {
  const options = {
    year: 'numeric',
    month: precision === DatePrecisionEnum.Year ? undefined : 'long',
    day:
      precision === DatePrecisionEnum.Year ||
      precision === DatePrecisionEnum.Month
        ? undefined
        : 'numeric',
  }
  // @ts-ignore
  return date.toLocaleDateString(locale, options)
}
