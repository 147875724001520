import { useEffect, useState } from 'react'

import { Attachment } from '../api/types'

// TODO: Implement video thumbnail in memory header for web
export default function useMemoryHeaderUri(
  highlightedPictureUrl?: string | null,
  attachment?: Attachment,
): string | undefined {
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (highlightedPictureUrl) {
      setUrl(highlightedPictureUrl)
      return
    }

    if (attachment && attachment.contentType?.startsWith('video')) return

    setUrl(attachment?.url ?? undefined)
  }, [attachment, highlightedPictureUrl])

  return url
}
