import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'

import { QuizSubCategory } from '../../../api/types'
import { CheckBox } from '../../../components/CheckBox'
import Separator from '../../../components/Separator'
import LabelText from '../../../components/Text/LabelText'
import appStyles from '../../../styles/app-styles'

interface EventsQuizSubcategoryProps {
  subcategory: QuizSubCategory
  enableSubcategory: (id: string) => void
  disableSubcategory: (id: string) => void
}

export const EventsQuizSubcategory: React.FC<EventsQuizSubcategoryProps> = ({
  subcategory,
  enableSubcategory,
  disableSubcategory,
}) => {
  const [checked, setChecked] = useState(false)
  return (
    <TouchableOpacity
      style={appStyles.inlineContainer}
      onPress={() => {
        checked
          ? disableSubcategory(subcategory.id)
          : enableSubcategory(subcategory.id)
        setChecked(!checked)
      }}>
      <Separator width={19} />
      <CheckBox isChecked={checked} />
      <Separator width={15} />
      <LabelText size="large" color="layout.dark">
        {subcategory.translatedTitle}
      </LabelText>
    </TouchableOpacity>
  )
}
