import React from 'react'
import {
  View,
  Platform,
  FlatList,
  StyleSheet,
  RefreshControl,
} from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useGetPersonMemoriesQuery } from '../../api/types'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import MemoryCard from '../timeline/components/MemoryCard'

const Divider = () => <Separator height={10} />

export default function PersonMemoriesScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.personMemories',
  })
  const { goBack } = useNavigation<MainStackNavigationType<'PersonMemories'>>()
  const { params } =
    useRoute<RouteProp<MainStackParamsType, 'PersonMemories'>>()

  const { data, refetch, loading, networkStatus } = useGetPersonMemoriesQuery({
    variables: { id: params.id },
  })

  return (
    <View style={styles.container}>
      <Loading loading={loading} />
      <View style={appStyles.fullSize}>
        <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
          <View style={appStyles.inlineContainer}>
            <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
            <Separator width={5} />
            <Typography weight="medium" style={styles.headerText}>
              {params.firstName}
              {params.lastName ? ` ${params.lastName} ` : ' '}
              {t('relatedMemories')}
            </Typography>
          </View>
        </View>
        <FlatList
          data={data?.person.memories?.nodes}
          ItemSeparatorComponent={Divider}
          ListHeaderComponent={
            <ListHeader
              title={t('title')}
              subtitle={t('memoryList', {
                firstName: params.firstName,
              })}
              negatablePaddingSource={styles.contentContainer}
            />
          }
          contentContainerStyle={useSafeAreaPaddedStyle(
            styles.contentContainer,
            { insetBottom: true },
          )}
          renderItem={({ item }) => <MemoryCard memory={item} />}
          refreshControl={
            <RefreshControl
              refreshing={networkStatus === NetworkStatus.refetch}
              onRefresh={refetch}
            />
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  header: {
    paddingTop: 15,
    ...appStyles.row,
    paddingBottom: 25,
    paddingHorizontal: 15,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  contentContainer: {
    paddingHorizontal: 24,
    height: Platform.select({ web: 0 }),
  },
})
