import React, {
  useMemo,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react'

import { useTranslation } from 'react-i18next'
import { useMMKVObject } from 'react-native-mmkv'

import { Locale } from '../types/localization-types'
import getDeviceLanguage from '../utils/getDeviceLanguage'

type LocaleContextType = {
  locale: Locale | undefined
  setLocale: (locale: Locale) => void
}

function getDefaultLocale(): Promise<Locale> {
  return Promise.resolve(getDeviceLanguage()).then(deviceLanguage => {
    if (typeof deviceLanguage === 'string' && deviceLanguage.startsWith('de')) {
      return 'de'
    }
    return 'en'
  })
}

const LocaleContext = createContext<LocaleContextType>({
  locale: undefined,
  setLocale: () => {},
})

const LocaleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation()
  const [locale, setLocale] = useMMKVObject<Locale>('locale')

  useEffect(() => {
    if (!locale && setLocale) {
      getDefaultLocale().then(setLocale)
    }
  }, [locale, setLocale])

  useEffect(() => {
    if (!locale) return

    i18n.changeLanguage(locale)
  }, [locale, i18n])

  return (
    <LocaleContext.Provider
      value={useMemo(() => ({ locale, setLocale }), [locale, setLocale])}>
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider

export function useLocale() {
  return useContext(LocaleContext)
}
