import { useEffect, useState } from 'react'

import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator'

import loggingCore from '../core/logging-core'

export default function useMediaUri(
  uri: string,
  isVideo: boolean,
): string | undefined {
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (isVideo) {
      fetch(uri)
        .then(response => response.blob())
        .then(file => {
          // @ts-ignore
          return generateVideoThumbnails(file, 1, '')
        })
        .then(thumbnails => {
          setUrl(thumbnails[0])
        })
        .catch(loggingCore.error)
    } else {
      setUrl(uri)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return url
}
