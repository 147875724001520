import React from 'react'
import {
  View,
  FlatList,
  Platform,
  StyleSheet,
  RefreshControl,
} from 'react-native'

import { NetworkStatus } from '@apollo/client'
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { useGetMemoryPeopleQuery } from '../../api/types'
import ListHeader from '../../components/ListHeader'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import {
  MainStackParamsType,
  MainStackNavigationType,
} from '../../types/navigation-types'
import PersonCard from '../people-list/components/PersonCard'

const Divider = () => <Separator height={9} />
export default function MemoryPeopleScreen(): JSX.Element {
  const { t } = useTranslation()

  const { goBack } = useNavigation<MainStackNavigationType<'MemoryPeople'>>()
  const { params } = useRoute<RouteProp<MainStackParamsType, 'MemoryPeople'>>()
  const { data, refetch, networkStatus } = useGetMemoryPeopleQuery({
    variables: { id: params.memoryId },
  })

  return (
    <View style={appStyles.fullSize}>
      <Loading loading={networkStatus === NetworkStatus.loading} />
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <View style={appStyles.inlineContainer}>
          <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
          <Separator width={5} />
          <Typography weight="medium" style={styles.headerText}>
            {params.memoryTitle}
          </Typography>
        </View>
      </View>
      <FlatList
        style={appStyles.fullSize}
        data={data?.memory.people?.nodes}
        ItemSeparatorComponent={Divider}
        renderItem={({ item }) => <PersonCard item={item} />}
        contentContainerStyle={useSafeAreaPaddedStyle(
          styles.scrollContentContainer,
          { insetBottom: true },
        )}
        refreshControl={
          <RefreshControl
            refreshing={networkStatus === NetworkStatus.refetch}
            onRefresh={() => refetch()}
          />
        }
        ListHeaderComponent={
          <ListHeader
            title={t('screens.memoryPeople.title')}
            subtitle={t('screens.memoryPeople.subtitle', params)}
            negatablePaddingSource={styles.scrollContentContainer}
          />
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 15,
    paddingLeft: 15,
    ...appStyles.row,
    paddingBottom: 25,
    ...appStyles.lightShadow,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 27,
    paddingHorizontal: 24,
    height: Platform.select({ web: 0 }),
  },
})
