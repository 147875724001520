import React, { useMemo } from 'react'

import { SvgProps } from 'react-native-svg'

import { MemoryTypeEnum } from '../../../api/types'
import BriefcaseSvg from '../../../assets/svg/memory/briefcase.svg'
import CakeSvg from '../../../assets/svg/memory/cake.svg'
import CapSvg from '../../../assets/svg/memory/cap.svg'
import HeartSvg from '../../../assets/svg/memory/heart.svg'
import LocationSvg from '../../../assets/svg/memory/location.svg'
import PinSvg from '../../../assets/svg/memory/pin.svg'

const MemoryTypeSvg: React.FC<SvgProps & { type: MemoryTypeEnum }> = ({
  type,
  ...props
}) => {
  const [Component, viewBox] = useMemo(() => {
    switch (type) {
      case MemoryTypeEnum.Birth:
        return [CakeSvg, '0 0 20 22']
      case MemoryTypeEnum.Education:
        return [CapSvg, '0 0 27 22']
      case MemoryTypeEnum.Location:
        return [LocationSvg, '0 0 20 26']
      case MemoryTypeEnum.Memory:
        return [PinSvg, '0 0 20 20']
      case MemoryTypeEnum.Relationship:
        return [HeartSvg, '0 0 24 20']
      case MemoryTypeEnum.Work:
        return [BriefcaseSvg, '0 0 26 22']
    }
  }, [type])

  return <Component viewBox={viewBox} {...props} />
}

export default MemoryTypeSvg
