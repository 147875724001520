import React, { useCallback } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DividerText from '../../components/DividerText'
import FaqLink from '../../components/FaqLink'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import Separator from '../../components/Separator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SocialLoginRow, { SocialLogin } from '../../components/SocialLoginRow'
import LabelText from '../../components/Text/LabelText'
import TitleText from '../../components/Text/TitleText'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default function AuthRootScreen(): JSX.Element {
  const { navigate } =
    useNavigation<UnauthorizedStackNavigationType<'AuthRoot'>>()
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.authRoot' })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSocialLoginPress = useCallback((type: SocialLogin) => {
    console.log('TODO login with', type)
  }, [])

  const { webLayoutEnabled } = useWebLayout()

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={appStyles.fullSize}
        contentContainerStyle={styles.contentContainer}>
        {!webLayoutEnabled && (
          <View style={styles.languagePickerContainer}>
            <LanguagePickerButton />
          </View>
        )}
        {webLayoutEnabled ? (
          <View style={appStyles.fullSize} />
        ) : (
          <Separator height={35} />
        )}
        <TitleText
          color="layout.dark"
          size={webLayoutEnabled ? 'large' : 'medium'}>
          {t('title')}
        </TitleText>
        <Separator height={10} />
        <LabelText size="medium">{t('subtitle')}</LabelText>
        <View style={styles.authProvidersContainer}>
          {webLayoutEnabled && <Separator height={100} />}
          {/* TODO: Swap when social login is ready */}
          {/* <LabelText size="medium">{t('signInWith')}</LabelText>
          <Separator height={40} />
          <SocialLoginRow onSocialLoginPress={onSocialLoginPress} />
          <Separator height={40} />
          <DividerText text={t('or')} />
          <Separator height={40} /> */}
          <Button
            text={t('withEmail')}
            onPress={() => navigate('Registration')}
          />
          <Separator height={20} />
          <LabelText size="small">{t('signInPrompt')}</LabelText>
          <Separator height={20} />
          <Button
            type="inverted"
            text={t('signIn')}
            onPress={() => navigate('SignIn')}
          />
        </View>

        {webLayoutEnabled && (
          <View style={styles.faqContainer}>
            <FaqLink />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    flexGrow: 1,
    paddingTop: 5,
    alignItems: 'center',
  },
  languagePickerContainer: {
    marginRight: 'auto',
    marginLeft: 24,
  },
  authProvidersContainer: {
    ...appStyles.center,
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  faqContainer: {
    flex: 2,
    ...appStyles.center,
  },
})
