import React from 'react'

import RadioSelectedSvg from '../assets/svg/radio-selected.svg'
import RadioSvg from '../assets/svg/radio.svg'

export const RadioButton: React.FC<{ isSelected: boolean }> = ({
  isSelected,
}) => {
  const Svg = isSelected ? RadioSelectedSvg : RadioSvg
  return <Svg height={20} width={20} viewBox="0 0 32 32" />
}
