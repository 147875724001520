import React from 'react'
import { View, StyleSheet, Modal, Platform } from 'react-native'

import { useTranslation } from 'react-i18next'

import { DatePrecisionEnum } from '../api/types'
import Button from '../components/Button'
import DatePicker from '../components/DatePicker'
import ModalHeader from '../components/ModalHeader'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface DatePickerModalProps {
  visible: boolean
  selectedDate: Date
  precision?: DatePrecisionEnum
  allowPrecisionChange: boolean
  close: (select?: boolean) => void
  setSelectedDate: (date: Date) => void
  setPrecision?: (precision: DatePrecisionEnum) => void
}

export const DatePickerModal: React.FC<DatePickerModalProps> = ({
  close,
  visible,
  precision = DatePrecisionEnum.Day,
  selectedDate,
  setPrecision,
  setSelectedDate,
  allowPrecisionChange,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={visible}
      animationType="fade"
      onRequestClose={() => close()}>
      <PickerModal close={() => close()}>
        <View
          style={[
            styles.container,
            Platform.select({ web: styles.webContainer }),
          ]}>
          <ModalHeader title={t('modals.datePicker.select')} close={close} />
          <View
            style={[
              styles.contentContainer,
              Platform.select({ web: styles.webContentContainer }),
            ]}>
            <Separator height={Platform.select({ ios: 0, default: 50 })} />
            <DatePicker
              date={selectedDate}
              precision={precision}
              allowPrecisionChange={allowPrecisionChange}
              setPrecision={setPrecision}
              onDateChange={setSelectedDate}
            />
            <Separator height={Platform.select({ ios: 0, default: 50 })} />
          </View>
          <View style={styles.selectButtonContainer}>
            <Button text={t('common.proceed')} onPress={() => close(true)} />
          </View>
        </View>
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    backgroundColor: Platform.select({
      web: Colors['layout.light'],
      default: Colors['layout.white'],
    }),
  },
  webContainer: {
    ...appStyles.fullSize,
  },
  contentContainer: {
    paddingHorizontal: Platform.select({ ios: 5, default: 20 }),
  },
  webContentContainer: {
    ...appStyles.fullSize,
    justifyContent: 'center',
  },
  selectButtonContainer: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: Platform.select({ ios: 0, default: 20 }),
    paddingBottom: Platform.select({ ios: 30, default: 20 }),
    backgroundColor: Colors['layout.white'],
  },
})
