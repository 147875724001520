import { Dispatch, SetStateAction } from 'react'

import { useActionSheet } from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'
import { useFilePicker } from 'use-file-picker'

import { AudioType } from './useAudioActionSheet'
import { useDirectUpload } from './useDirectUpload'
import { useListState } from './useListState'
import useShowRemoveMediaActionSheet from './useShowRemoveMediaActionSheet'
import { GetLibraryDocument, useCreateAttachmentMutation } from '../api/types'
import { useUser } from '../providers/UserProvider'

export const useAudioActionSheet = (
  uploadDirectly: boolean,
  recordAudio: () => void,
  addFromRelefantLibrary?: () => void,
): [
  AudioType[],
  () => void,
  (audio: AudioType, showRenameAudioModal: () => void) => void,
  (audio: AudioType) => void,
  Dispatch<SetStateAction<AudioType[]>>,
  boolean,
] => {
  const { user } = useUser()
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useTranslation()
  const { uploadMedia, isUploading } = useDirectUpload()
  const [createAttachment, { client }] = useCreateAttachmentMutation()
  const showRemoveMediaActionSheet = useShowRemoveMediaActionSheet()

  const [audioList, pushAudio, popAudio, setAudioList] =
    useListState<AudioType>([], (a, b) => a.uri === b.uri)

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ['audio/*'],
    multiple: true,
    onFilesSuccessfullySelected(fileData) {
      const newAudios: AudioType[] = []
      // @ts-ignore
      fileData.plainFiles.forEach((file, index) => {
        newAudios.push({
          uri: fileData.filesContent[index].content,
          fileCopyUri: fileData.filesContent[index].content,
          size: file.size,
          type: file.type,
          name: file.name,
          description: file.name,
        } as AudioType)
      })
      if (uploadDirectly) {
        Promise.all(
          newAudios.map(audio =>
            uploadMedia(
              audio.uri,
              audio.description,
              audio.name,
              audio.type,
            ).then(({ fileBlobId, description }) =>
              createAttachment({
                variables: {
                  input: {
                    fileBlobId,
                    description,
                  },
                },
              }),
            ),
          ),
        ).then(results =>
          client.cache.updateQuery(
            {
              query: GetLibraryDocument,
              variables: { id: user?.libraryId },
            },
            data => {
              if (!data) return
              return {
                library: {
                  id: user?.libraryId,
                  attachments: {
                    nodes: [
                      ...results.map(res => res.data?.createAttachment),
                      ...(data?.library.attachments?.nodes ?? []),
                    ],
                  },
                },
              }
            },
          ),
        )
      } else {
        setAudioList([...newAudios, ...audioList])
      }
    },
  })

  const showAddAudioActionSheet = () => {
    const options = [
      t('screens.createMemory.addAudioOptions.recordAudio'),
      t('screens.createMemory.addAudioOptions.chooseFromFiles'),
      ...(addFromRelefantLibrary
        ? [t('screens.createMemory.addMediaOptions.chooseUsed')]
        : []),
      t('common.cancel'),
    ]

    const cancelButtonIndex = addFromRelefantLibrary ? 3 : 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case cancelButtonIndex:
            break
          case 0:
            recordAudio()
            break
          case 1:
            openFilePicker()
            break
          case 2:
            addFromRelefantLibrary?.()
            break
          default:
            break
        }
      },
    )
  }

  const showAudioOptionsActionSheet = (
    audio: AudioType,
    showRenameAudioModal: () => void,
  ) =>
    showRemoveMediaActionSheet('audio', () => popAudio(audio), [
      {
        text: t('screens.createMemory.audioOptions.rename'),
        action: showRenameAudioModal,
      },
    ])

  return [
    audioList,
    showAddAudioActionSheet,
    showAudioOptionsActionSheet,
    pushAudio,
    setAudioList,
    isUploading,
  ]
}
