const pad = (num: number): string => {
  return ('00' + num).slice(-2)
}

export const mmss = (secs: number): string => {
  let minutes = Math.floor(secs / 60)
  let hours = Math.floor(minutes / 60)

  secs = secs % 60
  minutes = minutes % 60
  hours = hours % 60

  return (hours > 0 ? pad(hours) + ':' : '') + pad(minutes) + ':' + pad(secs)
}
