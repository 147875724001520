/**
 * TODO: Log errors, breadcrumbs and warnings to a monitoring platform of choice
 */

const log = console.log

const warn = console.warn

const error = console.error

export default { log, warn, error }
