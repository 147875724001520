import React, { useState, useCallback } from 'react'
import {
  View,
  Platform,
  ScrollView,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import Button from '../../components/Button'
import { TextInputField } from '../../components/InputFields'
import LanguagePickerButton from '../../components/LanguagePickerButton'
import Loading from '../../components/Loading'
import Separator from '../../components/Separator'
import TitleText from '../../components/Text/TitleText'
import loggingCore from '../../core/logging-core'
import RestApi from '../../core/RestApi'
import useStateRef from '../../hooks/useStateRef'
import { useWebLayout } from '../../providers/WebLayoutProvider'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { UnauthorizedStackNavigationType } from '../../types/navigation-types'

export default function ForgotPasswordScreen(): JSX.Element {
  const { navigate, goBack } =
    useNavigation<UnauthorizedStackNavigationType<'ForgotPassword'>>()
  const { t } = useTranslation()

  const { webLayoutEnabled } = useWebLayout()

  const [isLoading, setIsLoading, getIsLoading] = useStateRef(false)
  const [email, setEmail] = useState<string>('')

  const onRecoverPress = useCallback(() => {
    if (getIsLoading()) return

    setIsLoading(true)
    RestApi.post('users/password', { email })
      .then(() => {
        navigate('EmailConfirmation') // TODO instead show "If account exists, email sent"
      })
      .catch(loggingCore.error)
      .finally(() => setIsLoading(false))
  }, [email, getIsLoading, setIsLoading, navigate])

  return (
    <SafeAreaView style={appStyles.fullSize}>
      <Loading blocking loading={isLoading} />
      <KeyboardAvoidingView
        style={appStyles.fullSize}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView
          style={styles.container}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={styles.scrollContentContainer}>
          {!webLayoutEnabled && (
            <View style={styles.languagePickerContainer}>
              <LanguagePickerButton />
            </View>
          )}
          {webLayoutEnabled ? (
            <View style={appStyles.fullSize} />
          ) : (
            <Separator height={35} />
          )}
          <TitleText color="layout.dark" size="medium">
            {t('screens.forgotPassword.title')}
          </TitleText>
          <View style={styles.centerBlock}>
            <Separator height={40} />
            <View
              style={[
                styles.inputContainer,
                webLayoutEnabled && styles.webInputContainer,
              ]}>
              <TextInputField
                value={email}
                autoComplete="email"
                autoCapitalize="none"
                keyboardType="email-address"
                label={t('screens.forgotPassword.emailPlaceholder')}
                errorMessage=""
                clearError={() => {}}
                onChangeValue={setEmail}
              />
            </View>
            <Separator height={30} />
            <Button
              text={t('screens.forgotPassword.recover')}
              onPress={onRecoverPress}
            />
            <Separator height={50} />
            <Button
              type="inverted"
              text={t('common.cancel')}
              onPress={goBack}
            />
          </View>
          {webLayoutEnabled && <View style={appStyles.fullSize} />}
          <Separator height={15} />
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors['layout.light'],
  },
  scrollContentContainer: {
    flexGrow: 1,
    paddingTop: 5,
    alignItems: 'center',
  },
  languagePickerContainer: {
    marginLeft: 24,
    marginRight: 'auto',
  },
  centerBlock: {
    width: '100%',
    marginTop: 'auto',
    ...appStyles.center,
    marginBottom: 'auto',
  },
  inputContainer: {
    width: '100%',
    paddingHorizontal: 25,
  },
  webInputContainer: {
    paddingHorizontal: 100,
  },
})
