import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  View,
  Animated,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleSheet,
} from 'react-native'

import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { useTranslation } from 'react-i18next'

import Separator from '../components/Separator'
import SvgIcon, { SvgName } from '../components/SvgIcon'
import Typography from '../components/Text/Typography'
import { useWebLayout } from '../providers/WebLayoutProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { MainTabsParamsType } from '../types/navigation-types'
import { convertHexToRGBA } from '../utils/colors'

type TabRoute = keyof MainTabsParamsType
function getTab(index: number): TabRoute {
  switch (index) {
    case 0:
      return 'Timeline'
    case 1:
      return 'OtherMemories'
    case 2:
      return 'People'
    default:
      return 'MediaLibrary'
  }
}

const useNativeDriver = true

type TabProps = {
  text: string
  icon: SvgName
  indicatorWidth: number
  isSelected: boolean
} & Pick<TouchableOpacityProps, 'onPress' | 'onLongPress'>

const Tab: React.FC<TabProps> = ({
  icon,
  text,
  onPress,
  indicatorWidth,
  isSelected,
  onLongPress,
}) => {
  const opacity = useRef(new Animated.Value(isSelected ? 1 : 0)).current
  const margin = useRef(
    new Animated.Value(isSelected ? -BAR_PADDING + INDICATOR_MARGIN : 0),
  ).current

  useEffect(() => {
    Animated.parallel([
      Animated.timing(opacity, {
        duration: 600,
        useNativeDriver,
        toValue: isSelected ? 1 : 0,
      }),
      Animated.spring(margin, {
        toValue: isSelected ? -BAR_PADDING + INDICATOR_MARGIN : 0,
        useNativeDriver: false,
      }),
    ]).start()
  }, [isSelected, margin, opacity])

  return (
    <Animated.View
      style={[
        styles.tabItem,
        {
          marginLeft: margin,
          marginRight: margin,
          width: isSelected ? indicatorWidth : TAB_ITEM_SIZE,
        },
      ]}>
      <TouchableOpacity
        // @ts-ignore
        hitSlop={10}
        style={appStyles.horizontalCenter}
        onPress={onPress}
        onLongPress={onLongPress}>
        <View style={styles.tabContentRow}>
          <SvgIcon
            name={icon}
            color={
              isSelected
                ? Colors['brand.original']
                : convertHexToRGBA(Colors['layout.dark'], 0.5)
            }
          />
          {isSelected && (
            <Animated.View style={[styles.tabTextHolder, { opacity }]}>
              <Typography style={styles.tabText}>{text}</Typography>
            </Animated.View>
          )}
        </View>
      </TouchableOpacity>
    </Animated.View>
  )
}

const TabBar: React.FC<BottomTabBarProps> = ({ state, insets, navigation }) => {
  const [barWidth, setBarWidth] = useState<number>()
  const { t } = useTranslation()
  const { isWeb } = useWebLayout()

  const indicatorWidth = useMemo(
    () => (!barWidth ? 0 : barWidth / 2.5),
    [barWidth],
  )

  const indicatorOffset = useMemo(() => {
    if (!barWidth) return 5

    switch (state.index) {
      case 1:
        return (barWidth - indicatorWidth) / 3
      case 2:
        return ((barWidth - indicatorWidth) * 2) / 3
      case 3:
        return barWidth - indicatorWidth - INDICATOR_MARGIN
    }
    return INDICATOR_MARGIN
  }, [barWidth, state.index, indicatorWidth])

  const translateX = useRef(new Animated.Value(indicatorOffset)).current

  useEffect(() => {
    Animated.spring(translateX, {
      useNativeDriver,
      toValue: indicatorOffset,
    }).start()
  }, [indicatorOffset, translateX])

  return (
    <View
      style={[
        styles.container,
        { ...insets, top: undefined },
        !isWeb && styles.mobileContainer,
      ]}>
      <View
        style={styles.bar}
        onLayout={e => setBarWidth(e.nativeEvent.layout.width)}>
        <Animated.View
          style={[
            styles.indicator,
            {
              width: indicatorWidth,
              transform: [{ translateX }],
            },
          ]}
        />
        {state.routes.map((route, index) => {
          const isSelected = state.index === index

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            })

            if (!isSelected && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({
                merge: true,
                name: route.name,
                params: undefined,
              })
            }
          }

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            })
          }

          const name = getTab(index)

          return (
            <Tab
              key={index}
              icon={`drawer-${name}`}
              isSelected={isSelected}
              text={t(`bottomTabs.${name}`)}
              indicatorWidth={indicatorWidth}
              onPress={onPress}
              onLongPress={onLongPress}
            />
          )
        })}
      </View>
      {(barWidth ?? 0) >= MAX_WIDTH_FOR_INLINE && <Separator width={9} />}
      <TouchableOpacity
        // @ts-ignore
        hitSlop={20}
        style={[
          styles.plusButtonContainer,
          (barWidth ?? 0) < MAX_WIDTH_FOR_INLINE && styles.plusButtonTop,
        ]}
        onPress={() => navigation.navigate('CreateContent')}>
        <View style={styles.plusButton}>
          <SvgIcon
            name="plus"
            width={20}
            height={20}
            color={Colors['layout.white']}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default TabBar

const BAR_PADDING = 20
const TAB_ITEM_SIZE = 33
const INDICATOR_MARGIN = 5
const MAX_WIDTH_FOR_INLINE = 430

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    position: 'absolute',
    marginHorizontal: 10,
    ...appStyles.inlineContainer,
  },
  mobileContainer: {
    right: 0,
    maxWidth: 500,
    left: undefined,
  },
  bar: {
    height: 60,
    borderRadius: 30,
    ...appStyles.row,
    paddingVertical: 5,
    ...appStyles.fullSize,
    ...appStyles.heavyShadow,
    paddingHorizontal: BAR_PADDING,
    justifyContent: 'space-between',
    backgroundColor: Colors['layout.white'],
  },
  indicator: {
    left: 0,
    zIndex: -5,
    borderRadius: 25,
    position: 'absolute',
    top: INDICATOR_MARGIN,
    bottom: INDICATOR_MARGIN,
    backgroundColor: Colors['layout.dark'],
  },
  tabItem: {
    width: TAB_ITEM_SIZE,
    borderRadius: TAB_ITEM_SIZE / 2,
  },
  tabContentRow: {
    height: 50,
    ...appStyles.inlineContainer,
  },
  tabTextHolder: {
    height: '100%',
    paddingHorizontal: 8,
    ...appStyles.verticalCenter,
  },
  tabText: {
    fontSize: 12,
    color: Colors['layout.white'],
  },
  plusButtonContainer: {
    width: 60,
    height: 60,
    borderRadius: 30,
    ...appStyles.center,
    ...appStyles.heavyShadow,
    backgroundColor: Colors['layout.white'],
  },
  plusButton: {
    width: 54,
    height: 54,
    borderRadius: 27,
    ...appStyles.center,
    backgroundColor: Colors['layout.dark'],
  },
  plusButtonTop: {
    right: 0,
    bottom: 75,
    position: 'absolute',
  },
})
