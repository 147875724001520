import React, { useCallback, useRef, useState, useEffect } from 'react'
import {
  View,
  Animated,
  Keyboard,
  TextInput,
  Pressable,
  ViewProps,
  StyleSheet,
  TextInputProps,
} from 'react-native'

import SvgIcon from './SvgIcon'
import Typography, { TypographyInput } from './Text/Typography'
import TouchableSvg from './TouchableSvg'
import {
  ProfileFormItemKeyType,
  ProfileFormItemType,
} from '../helpers/ProfileFormItems'
import { DatePickerModal } from '../modals/DatePickerModal'
import { ItemPickerModal } from '../modals/ItemPickerModal'
import { useLocale } from '../providers/LocaleProvider'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'
import { formatDate } from '../utils/date'

interface InputContainerPropsType extends ViewProps {
  label: string
  cursor?: string // https://github.com/necolas/react-native-web/blob/dc9ecfbc84c7e6cbf0c36c537ac1b0a86b7e43c1/packages/react-native-web/src/types/styles.js#L132
  notEmpty: boolean
  isFocused: boolean
  hint?: string
  onPress?: () => void
  onFocus?: () => void
  errorMessage?: string
  icon?: React.ReactNode
}

interface TextInputFieldPropsType extends TextInputProps {
  label: string
  value: string
  errorMessage: string
  hint?: string
  icon?: React.ReactNode
  clearError: () => void
  onChangeValue: (text: string) => void
}

interface PickerInputFieldPropsType {
  label: string
  modalTitle: string
  options: ProfileFormItemType[]
  value: string
  selectedKey?: ProfileFormItemKeyType
  onSelectKey: (key: ProfileFormItemKeyType) => void
}

interface DateInputFieldPropsType {
  label: string
  value: Date | null
  errorMessage?: string
  clearError?: () => void
  onChangeValue: (date: Date) => void
}

const InputContainer: React.FC<InputContainerPropsType> = ({
  hint,
  icon,
  label,
  cursor,
  onPress,
  onFocus,
  children,
  notEmpty,
  isFocused,
  errorMessage,
}) => {
  const labelScale = useRef(new Animated.Value(1)).current
  const labelTranslateY = useRef(new Animated.Value(1)).current

  useEffect(() => {
    const isSmall = isFocused || notEmpty || !!errorMessage
    Animated.parallel([
      Animated.timing(labelScale, {
        toValue: isSmall ? 13 / 15 : 1,
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.timing(labelTranslateY, {
        toValue: isSmall ? -15 : 0,
        duration: 200,
        useNativeDriver: true,
      }),
    ]).start()
  }, [isFocused, notEmpty, errorMessage, labelScale, labelTranslateY])

  return (
    <>
      <Pressable
        style={[
          styles.container,
          // @ts-ignore
          { cursor },
          isFocused && styles.containerFocused,
          !!errorMessage && styles.containerError,
        ]}
        onFocus={onFocus}
        onPress={onPress}>
        <Animated.View
          style={[
            styles.inputLabelContainer,
            {
              transform: [
                { scale: labelScale },
                {
                  translateX: labelScale.interpolate({
                    inputRange: [13 / 15, 1],
                    outputRange: [-24, 0],
                  }),
                },
                { translateY: labelTranslateY },
              ],
            },
          ]}>
          <Typography
            weight="light"
            numberOfLines={1}
            style={[styles.inputLabelText]}>
            {label}
          </Typography>
        </Animated.View>
        <View style={styles.inputChildrenContainer}>
          {children}
          {icon}
        </View>
      </Pressable>
      {!!hint && (
        <Typography
          weight="light"
          style={[styles.hintText, !!errorMessage && styles.hintTextError]}>
          {hint}
        </Typography>
      )}
    </>
  )
}

export const TextInputField: React.FC<TextInputFieldPropsType> = ({
  hint,
  label,
  value,
  children,
  icon,
  clearError,
  onChangeValue,
  errorMessage,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef<TextInput | null>(null)
  const focusInput = () => inputRef.current?.focus()

  return (
    <InputContainer
      label={label}
      cursor="text"
      notEmpty={value.length > 0}
      isFocused={isFocused}
      errorMessage={errorMessage}
      hint={hint}
      icon={icon}
      onFocus={focusInput}
      onPress={focusInput}>
      <TypographyInput
        ref={inputRef}
        weight="medium"
        style={[
          styles.inputField,
          styles.inputText,
          !!errorMessage && !value && styles.inputTextError,
        ]}
        value={value}
        placeholder={errorMessage}
        placeholderTextColor={Colors['brand.red']}
        onChangeText={onChangeValue}
        onFocus={() => {
          setIsFocused(true)
          clearError()
        }}
        onBlur={() => setIsFocused(false)}
        {...props}
      />
      {children}
    </InputContainer>
  )
}

export const PasswordInputField: React.FC<
  Omit<TextInputFieldPropsType, 'secureTextEntry'>
> = props => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  return (
    <TextInputField
      textContentType="newPassword"
      autoCapitalize="none"
      autoComplete="password"
      {...props}
      icon={
        <TouchableSvg
          name="eye"
          color="layout.dark"
          // @ts-ignore
          hitSlop={5}
          onPress={() => setIsPasswordVisible(prev => !prev)}
        />
      }
      secureTextEntry={!isPasswordVisible}
    />
  )
}

export const PickerInputField: React.FC<PickerInputFieldPropsType> = ({
  label,
  value,
  options,
  modalTitle,
  selectedKey,
  onSelectKey,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const pickerAnimation = useRef(new Animated.Value(0)).current

  const toggleModal = useCallback(
    (visible: boolean) => {
      Keyboard.dismiss()
      setIsFocused(visible)
      Animated.timing(pickerAnimation, {
        toValue: visible ? 1 : 0,
        useNativeDriver: true,
      }).start()
    },
    [pickerAnimation],
  )

  return (
    <InputContainer
      label={label}
      isFocused={isFocused}
      icon={
        <Animated.View
          style={[
            styles.actionIcon,
            {
              transform: [
                {
                  rotate: pickerAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '180deg'],
                  }),
                },
              ],
            },
          ]}>
          <SvgIcon name="chevron" color={Colors['layout.dark']} opacity={0.5} />
        </Animated.View>
      }
      notEmpty={selectedKey !== undefined}
      onPress={() => toggleModal(true)}>
      <ItemPickerModal
        title={modalTitle}
        items={options}
        visible={isFocused}
        selectedKey={selectedKey}
        close={() => toggleModal(false)}
        setSelectedKey={onSelectKey}
      />
      <Typography weight="medium" style={styles.inputText}>
        {value}
      </Typography>
    </InputContainer>
  )
}

export const DateInputField: React.FC<DateInputFieldPropsType> = ({
  label,
  value,
  clearError,
  errorMessage,
  onChangeValue,
}) => {
  const locale = useLocale()
  const [isFocused, setIsFocused] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const toggleModal = (visible: boolean) => {
    Keyboard.dismiss()
    clearError && clearError()
    setIsFocused(visible)
    setModalVisible(visible)
  }
  return (
    <InputContainer
      label={label}
      notEmpty={!!value}
      isFocused={isFocused}
      errorMessage={errorMessage}
      icon={
        <SvgIcon
          name="calendar"
          color={Colors['layout.dark']}
          style={styles.actionIcon}
        />
      }
      onPress={() => toggleModal(true)}>
      <DatePickerModal
        visible={modalVisible}
        allowPrecisionChange={false}
        selectedDate={value ?? new Date()}
        close={() => toggleModal(false)}
        setSelectedDate={onChangeValue}
      />
      <Typography
        weight="medium"
        style={[styles.inputText, !!errorMessage && styles.inputTextError]}>
        {errorMessage || (value && formatDate(value, locale.locale))}
      </Typography>
    </InputContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 69,
    borderWidth: 1,
    paddingLeft: 20,
    borderRadius: 10,
    marginVertical: 6,
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderColor: Colors['layout.white'],
    backgroundColor: Colors['layout.white'],
  },
  containerFocused: {
    borderColor: Colors['brand.action'],
  },
  containerError: {
    borderColor: Colors['brand.red'],
  },
  inputLabelContainer: {
    width: 300,
  },
  inputLabelText: {
    opacity: 0.7,
    fontSize: 15,
  },
  inputChildrenContainer: {
    left: 0,
    right: 20,
    position: 'absolute',
    ...appStyles.inlineContainer,
    alignContent: 'space-between',
  },
  inputField: {
    justifyContent: 'center',
  },
  inputText: {
    flex: 1,
    fontSize: 15,
    marginTop: 20,
    paddingLeft: 20,
  },
  inputTextError: {
    opacity: 0.4,
    color: Colors['brand.red'],
  },
  hintText: {
    fontSize: 13,
    opacity: 0.7,
    paddingLeft: 6,
  },
  hintTextError: {
    opacity: 1,
    color: Colors['brand.red'],
  },
  actionIcon: {
    marginRight: 10,
    color: Colors['layout.dark'],
  },
})
