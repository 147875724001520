import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Typography from '../../../components/Text/Typography'
import Colors from '../../../styles/Colors'
import { convertHexToRGBA } from '../../../utils/colors'

export const FilterItem: React.FC<{
  title: string
  isSelected: boolean
  onPress: () => void
}> = ({ title, isSelected, onPress }) => (
  <TouchableOpacity
    style={[
      styles.container,
      isSelected && {
        backgroundColor: Colors['layout.dark'],
      },
    ]}
    onPress={onPress}>
    <Typography
      weight="regular"
      style={[styles.text, isSelected && { color: Colors['layout.white'] }]}>
      {title}
    </Typography>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: convertHexToRGBA(Colors['layout.gray'], 0.5),
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    color: convertHexToRGBA(Colors['layout.dark'], 0.6),
  },
})
