import logging from './logging-core'

class Core {
  /**
   * Used to handle logging.
   */
  logging = logging
}

export default new Core()
