import React, { useMemo } from 'react'
import {
  View,
  Modal,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native'

import { useTranslation } from 'react-i18next'

import { LifeStage } from '../api/types'
import PickerModal from '../components/PickerModal'
import Separator from '../components/Separator'
import TitleText from '../components/Text/TitleText'
import Typography from '../components/Text/Typography'
import TouchableSvg from '../components/TouchableSvg'
import { useUser } from '../providers/UserProvider'
import { TIMELINE_COLORS } from '../screens/timeline/TimelineScreen'
import appStyles from '../styles/app-styles'
import Colors from '../styles/Colors'

interface LifeStagePickerModalProps {
  visible: boolean
  selected: LifeStage | undefined
  close: () => void
  setSelected: (type: LifeStage) => void
}

export const LifeStagePickerModal: React.FC<LifeStagePickerModalProps> = ({
  visible,
  selected,
  close,
  setSelected,
}) => {
  const { t } = useTranslation()
  const { sortedLifeStages } = useUser()
  const filteredLifeStages = useMemo(
    () =>
      sortedLifeStages.filter(
        stage => stage.startYear && stage.startYear <= new Date().getFullYear(),
      ),
    [sortedLifeStages],
  )

  return (
    <Modal
      transparent
      statusBarTranslucent
      visible={visible}
      animationType="fade"
      onRequestClose={() => close()}>
      <PickerModal close={() => close()}>
        <ScrollView contentContainerStyle={styles.container}>
          <View style={appStyles.row}>
            <TitleText
              size="medium"
              color="layout.dark"
              style={appStyles.fullSize}>
              {t('modals.lifeStagePicker.title')}
            </TitleText>
            <TouchableSvg
              name="close"
              color="layout.dark"
              onPress={() => close()}
            />
          </View>
          <Separator height={35} />
          {filteredLifeStages.map((stage, index) => (
            <TouchableOpacity
              key={stage.id}
              style={[
                styles.item,
                stage.id === selected?.id && styles.selectedItem,
                index !== 0 && styles.divider,
              ]}
              onPress={() => {
                close()
                setSelected(stage)
              }}>
              <View
                style={[
                  styles.indicator,
                  { backgroundColor: Colors[TIMELINE_COLORS[index]] },
                ]}
              />
              <Separator width={20} />
              <Typography weight="medium" style={styles.itemName}>
                {stage.title}
              </Typography>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </PickerModal>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: 24, paddingVertical: 30 },
  item: {
    height: 59,
    borderRadius: 14,
    paddingHorizontal: 20,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  selectedItem: {
    backgroundColor: Colors['layout.selected'],
  },
  divider: { marginTop: 10 },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  itemName: {
    fontSize: 16,
  },
})
