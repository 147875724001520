import { useCallback, useRef, useState } from 'react'
import { FlatList } from 'react-native'

import { useBackHandler } from '@react-native-community/hooks'
import { useIsFocused, useNavigation } from '@react-navigation/native'

export const useBackAwareFlatList = () => {
  const [stepIndex, setStepIndex] = useState(0)
  const flatListRef = useRef<FlatList>(null)
  const { goBack } = useNavigation()
  const focused = useIsFocused()

  const scrollToIndex = useCallback((index: number) => {
    if (flatListRef.current) {
      setStepIndex(index)
      flatListRef.current.scrollToIndex({
        index,
        animated: true,
      })
    }
  }, [])

  const navigateBack = useCallback(() => {
    if (stepIndex > 0) {
      scrollToIndex(stepIndex - 1)
      return
    }
    goBack()
  }, [goBack, scrollToIndex, stepIndex])

  const backActionHandler = useCallback(() => {
    if (stepIndex > 0 && focused) {
      scrollToIndex(stepIndex - 1)
      return true
    }
    return false
  }, [stepIndex, scrollToIndex, focused])

  useBackHandler(backActionHandler)

  return { stepIndex, flatListRef, scrollToIndex, navigateBack }
}
