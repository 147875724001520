import React from 'react'
import {
  View,
  StyleSheet,
  ListRenderItem,
  TouchableOpacity,
} from 'react-native'

import MemoryCard from './MemoryCard'
import { DatePrecisionEnum } from '../../../api/types'
import Separator from '../../../components/Separator'
import SvgIcon from '../../../components/SvgIcon'
import Typography from '../../../components/Text/Typography'
import appStyles from '../../../styles/app-styles'
import Colors from '../../../styles/Colors'
import { mixinOpacity, convertHexToRGBA } from '../../../utils/colors'
import {
  EMPTY_YEAR_H,
  MEMORY_MARGIN,
  getItemHeight,
} from '../helpers/layoutHelpers'
import { TimelineItemType } from '../TimelineScreen'

const TimelineItem: ListRenderItem<TimelineItemType> = ({ item }) => {
  const itemHeight = getItemHeight(item)

  if (item.content === 'top') {
    return (
      <View style={{ height: itemHeight }}>
        <View
          style={[
            styles.timeline,
            styles.timelineTop,
            { backgroundColor: item.lineColor },
          ]}
        />
      </View>
    )
  }
  if (typeof item.content === 'number') {
    const year: number = item.content
    return (
      <View
        style={{
          height: itemHeight,
        }}>
        <View style={[styles.timeline, { backgroundColor: item.lineColor }]} />
        <View style={styles.yearRow}>
          <TouchableOpacity
            // @ts-ignore
            hitSlop={10}
            style={appStyles.inlineContainer}
            onPress={() => {
              const date = new Date(0)
              date.setFullYear(year)
              item.onPlusPress({
                timestamp: date.valueOf(),
                prec: DatePrecisionEnum.Year,
              })
            }}>
            <Typography
              adjustsFontSizeToFit
              weight="bold"
              numberOfLines={1}
              style={styles.yearText}>
              {year}
            </Typography>
            <View style={styles.plusBubble}>
              <SvgIcon name="plus" color={plusColor} height={8} width={8} />
            </View>
          </TouchableOpacity>
          <View style={styles.yearDivider} />
        </View>
      </View>
    )
  }
  if (item.content === 'bottom') {
    return (
      <View style={{ height: itemHeight }}>
        <View
          style={[
            styles.timeline,
            styles.timelineBottom,
            { backgroundColor: item.lineColor },
          ]}
        />
      </View>
    )
  }

  const stageItem = item.content

  switch (stageItem.__typename) {
    case 'LifeStageTop':
      return (
        <View style={[styles.lifeStageHolder, { height: itemHeight }]}>
          {stageItem.previousColor && (
            <View
              style={[
                styles.timeline,
                styles.timelineLifeStagePrevious,
                {
                  backgroundColor: mixinOpacity(
                    Colors[stageItem.previousColor],
                    0.5,
                    Colors['layout.light'],
                  ),
                },
              ]}
            />
          )}
          <View
            style={[
              styles.timeline,
              styles.timelineLifeStage,
              {
                backgroundColor: item.lineColor,
              },
            ]}
          />
          <View style={styles.lifeStageBackground} />
          <View
            style={[
              styles.lifeStageHeader,
              { backgroundColor: Colors[stageItem.color] },
            ]}>
            <View style={appStyles.fullSize}>
              <Typography
                weight="bold"
                numberOfLines={2}
                style={styles.lifeStageTitle}>
                {stageItem.stage.title}
              </Typography>
              <Typography weight="light" style={styles.lifeStageDuration}>
                {`${stageItem.stage.startYear || '...'} - ${
                  stageItem.stage.endYear || '...'
                }`}
              </Typography>
            </View>
            <TouchableOpacity
              style={styles.lifeStageEdit}
              // @ts-ignore
              hitSlop={20}
              onPress={stageItem.onEditPress}>
              <SvgIcon name="pencil" color={Colors['layout.white']} />
            </TouchableOpacity>
          </View>
        </View>
      )
    case 'LifeStageBottom':
      return (
        <View style={[styles.lifeStageHolder, { height: itemHeight }]}>
          <View
            style={[
              styles.lifeStageBackground,
              styles.lifeStageBottomBackground,
            ]}
          />
          <View
            style={[
              styles.timeline,
              {
                backgroundColor: item.lineColor,
              },
            ]}
          />
          <Separator height={19} />
          <TouchableOpacity
            // @ts-ignore
            hitSlop={15}
            style={styles.lifeStageAddMemory}
            onPress={() => item.onPlusPress(stageItem.stage)}>
            <Separator width={14} />
            <View
              style={[
                styles.plusBubble,
                { backgroundColor: Colors[stageItem.color] },
              ]}>
              <SvgIcon
                width={8}
                height={8}
                name="plus"
                color={Colors['layout.white']}
              />
            </View>
            <Separator width={10} />
            <Typography
              numberOfLines={2}
              weight="medium"
              style={[
                appStyles.fullSize,
                {
                  color: Colors[stageItem.color],
                },
              ]}>
              {stageItem.addButtonText}
            </Typography>
          </TouchableOpacity>
        </View>
      )
    case 'TimelineMemory':
      return (
        <View
          style={[
            stageItem.parentType === 'Year'
              ? styles.yearMemoryHolder
              : styles.lifeStageMemoryHolder,
            { height: itemHeight },
          ]}>
          {stageItem.parentType === 'LifeStage' && (
            <View style={styles.lifeStageBackground} />
          )}
          <View
            style={[
              styles.timeline,
              {
                backgroundColor: item.lineColor,
              },
            ]}
          />
          <MemoryCard memory={stageItem.memory} />
        </View>
      )
  }
}

export default TimelineItem

const LINE_WIDTH = 8
const BUBBLE_SIZE = 22
const YEAR_TEXT_WIDTH = 40
const YEAR_TEXT_HEIGHT = 14.5
const LINE_OFFSET = YEAR_TEXT_WIDTH + (BUBBLE_SIZE - LINE_WIDTH) / 2

const plusColor = mixinOpacity(Colors['layout.dark'], 0.5)

const styles = StyleSheet.create({
  timeline: {
    top: 5,
    bottom: -6,
    width: LINE_WIDTH,
    left: LINE_OFFSET,
    position: 'absolute',
  },
  yearRow: {
    height: EMPTY_YEAR_H,
    ...appStyles.inlineContainer,
  },
  yearText: {
    textAlign: 'center',
    width: YEAR_TEXT_WIDTH,
    fontSize: YEAR_TEXT_HEIGHT,
  },
  plusBubble: {
    borderRadius: 11,
    width: BUBBLE_SIZE,
    height: BUBBLE_SIZE,
    ...appStyles.center,
    backgroundColor: Colors['layout.white'],

    ...appStyles.lightShadow,
    shadowOffset: { width: 0, height: 4 },
    elevation: 1,
  },
  yearDivider: {
    height: 1,
    flexGrow: 1,
    opacity: 0.5,
    backgroundColor: Colors['layout.gray'],
  },
  yearMemoryHolder: {
    paddingBottom: MEMORY_MARGIN,
    paddingLeft: LINE_OFFSET + LINE_WIDTH + MEMORY_MARGIN,
  },
  timelineTop: {
    top: 0,
    borderTopLeftRadius: LINE_WIDTH / 2,
    borderTopRightRadius: LINE_WIDTH / 2,
  },
  timelineBottom: {
    borderBottomLeftRadius: LINE_WIDTH / 2,
    borderBottomRightRadius: LINE_WIDTH / 2,
  },
  lifeStageHolder: {
    width: '100%',
    paddingLeft: LINE_OFFSET,
  },
  lifeStageBackground: {
    ...StyleSheet.absoluteFillObject,
    top: 20,
    bottom: -21,
    left: LINE_WIDTH + LINE_OFFSET,
    backgroundColor: Colors['layout.white'],
  },
  lifeStageBottomBackground: {
    bottom: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  lifeStageHeader: {
    height: 61,
    borderRadius: 10,
    paddingHorizontal: 20,
    ...appStyles.inlineContainer,
  },
  lifeStageTitle: {
    fontSize: 18,
    lineHeight: 20,
    color: Colors['layout.white'],
  },
  lifeStageDuration: {
    color: Colors['layout.white'],
  },
  lifeStageEdit: {
    width: 32,
    height: 32,
    borderRadius: 10,
    ...appStyles.center,
    backgroundColor: convertHexToRGBA(Colors['layout.white'], 0.2),
  },
  lifeStageMemoryHolder: {
    paddingTop: MEMORY_MARGIN,
    paddingRight: MEMORY_MARGIN,
    paddingLeft: LINE_WIDTH + MEMORY_MARGIN + LINE_OFFSET,
  },
  lifeStageAddMemory: {
    paddingLeft: LINE_WIDTH,
    ...appStyles.inlineContainer,
  },
  timelineLifeStagePrevious: {
    height: 41,
    bottom: undefined,
  },
  timelineLifeStage: {
    top: 40,
  },
})
