import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import Separator from '../../components/Separator'
import SvgIcon from '../../components/SvgIcon'
import Typography from '../../components/Text/Typography'
import TouchableSvg from '../../components/TouchableSvg'
import UserStorageInfo from '../../components/UserStorageInfo'
import useSafeAreaPaddedStyle, {
  headerOptions,
} from '../../hooks/useSafeAreaPaddedStyle'
import appStyles from '../../styles/app-styles'
import Colors from '../../styles/Colors'
import { MainStackNavigationType } from '../../types/navigation-types'

export default function UpgradeInfoScreen(): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'screens.settings.subscriptions.upgradeInfo',
  })
  const { goBack } = useNavigation<MainStackNavigationType<'UpgradeInfo'>>()

  return (
    <View style={styles.container}>
      <View style={useSafeAreaPaddedStyle(styles.header, headerOptions)}>
        <TouchableSvg name="back" color="layout.dark" onPress={goBack} />
        <Separator width={5} />
        <Typography weight="medium" style={styles.headerText}>
          {t('title')}
        </Typography>
      </View>
      <View style={styles.contentContainer}>
        <View style={[appStyles.fullSize, appStyles.center]}>
          <SvgIcon name="logo-colored" />
        </View>
        <View style={[appStyles.fullSize]}>
          <Typography style={[styles.text]}>{t('description')}</Typography>
          <Separator height={40} />
          <Typography style={[styles.text]}>
            {t('visitToUpgrade')}
            <Typography style={styles.linkText}>{t('link')}</Typography>
          </Typography>
        </View>
        <UserStorageInfo />
        <Separator height={40} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    ...appStyles.fullSize,
    backgroundColor: Colors['layout.light'],
  },
  contentContainer: {
    ...appStyles.fullSize,
    paddingHorizontal: 78,
  },
  header: {
    paddingTop: 20,
    paddingBottom: 29,
    paddingHorizontal: 24,
    ...appStyles.lightShadow,
    ...appStyles.inlineContainer,
    backgroundColor: Colors['layout.white'],
  },
  headerText: {
    fontSize: 20,
    lineHeight: 24,
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  linkText: {
    textDecorationLine: 'underline',
  },
})
